export const fopConstants = {
    // Actions:
    FOP_MISSING_INFO: 'FOP_MISSING_INFO',
    UPDATE_FOP_FORM: 'UPDATE_FOP_FORM',

    // Add Fop Form:
    CARD_INFORMATION: 'cardInfo',
    HOLDER_INFORMATION: 'holderInfo',
    CARMEL_OPTIONS: 'carmelOptions',
};

export const paymentTypes = {
    // Actions:
    HOUSE_ACCOUNT:"CH",
    CREDIT_CARD:"CC",
    PAY_CASH:"CA",
};

export const fopCardTypeRegex = {
    AE: /^3[47][0-9]{5,}$/,
    VI: /^4[0-9]{6,}([0-9]{3})?$/,
    MC: /^(5[1-5][0-9]{4}|677189)[0-9]{5,}$/,
    DC: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    DS: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))[0-9]{0,10})|((5067)|(4576)|(4011))[0-9]{0,12})$/,
    Hipercard: /^(606282|3841)[0-9]{5,}$/,
    UnionPay: /^(62|88)[0-9]{5,}$/,
};