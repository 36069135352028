import {generalService} from "../general/services";
import qs from 'qs';
import axios from 'axios';
import {store} from '../index';
import {carmelConstAPIMethods, carmelMethodsConstants, fopCardTypeRegex, generalConstants} from "../../constants";
import {formsConstants} from "../../constants/forms.constants";
import {carmelConstAPI} from "../../constants/carmel.constants";
import {paymentTypes} from "../../constants/fop.constants";
import {appHelp} from "../../helpers/app";
import {userConstLists} from "../../constants/user.constants";

export const carmelServices = {
    loadBasicInfo,
    serviceByAddr,
    getAirlineList,
    getPriceList,
    tripUpdate,
    addFop,
    deleteFop,
    updateCreditCard,
    sendCustActivate,
    sendRateDriver,
    submitReferralCode,
    refreshToken
};

function serviceByAddr(addressType, currentLocation) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;
    let Addr = state.trip[addressType];

    if (!Addr) {
        if (currentLocation && currentLocation.lat && currentLocation.lng)
            Addr = {
                latitude: currentLocation.lat,
                longitude: currentLocation.lng,
            };
    }
    // delete Addr.types;
    return generalService.requestSender(
        carmelConstAPIMethods.SERVICE_BY_ADDRESS,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            },
            Addr,
        }
    );
}

function getAirlineList(airlineCode) {
    const state = store.getState();
    const {CoreCredentials} = state.authentication;
    const requestOptions = {
        data: {
            "calls": [
                {
                    "method": carmelConstAPIMethods.AIRLINE_LIST,
                    "params": {
                        "Credentials": {
                            ...CoreCredentials,
                        },
                        "airportList": [
                            airlineCode
                        ],
                        'topAirlines': true
                    }
                }
            ]
        },
        headers: {
            "Content-Type": "application/json",
        },
        method: 'POST',
    };
    return axios(generalConstants.API_ENDPOINT, requestOptions).then(response => {
        return response
    })
}

function loadBasicInfo() {
    const {CoreCredentials} = store.getState().authentication;

    // The credentials object without any cust login details.
    // The API not working with those fields full.
    const sharedParams = {
        Credentials: {
            ...CoreCredentials,
            accessToken: generalConstants.CARMEL_API_KEY
        }

    };

    const airportListService = {
        method: carmelConstAPIMethods.AIRPORT_LIST,
        params: {
            airportListType: 'Service'
        }
    };
    const airportListAll = {
        method: carmelConstAPIMethods.AIRPORT_LIST,
        params: {
            airportListType: 'All'
        }
    };

    const countryList = {
        method: carmelMethodsConstants.COUNTRY_LIST
    };
    const stateList = {
        method: carmelMethodsConstants.STATE_LIST
    };
    const fopTypeList = {
        method: carmelMethodsConstants.FOP_TYPE_LIST
    };

    // Gathering all lists required for loading the app.
    return generalService.multipleCallsSender(
        sharedParams,
        [
            countryList,
            fopTypeList,
            airportListService,
            airportListAll,
            stateList
        ],
        'loadBasicInfo'
    );
}

function getPriceList() {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;
    const trip = state.trip;

    return generalService.requestSender(
        carmelConstAPIMethods.PRICE_LIST,
        {
            Credentials: {
                ...Credentials,
                ...CoreCredentials,
            },
            Trip: {
                "asap": false,
                "cancelReason": "",
                "canceled": false,
                "completed": false,
                "custArrived": false,
                "custFirstTrip": false,
                "luggQty": 0,
                "passQty": 0,
                "prepaid": false,
                "puInstructions": false,
                "requestedDriverGender": "",
                "serviceType": "",
                "showExtraCharge": false,
                "showPrepay": false,
                "tripId": 0,
                "tripOptions": [],
                "tripStatus": "",
                "useCarCash": false,
                "verifyTerminal": false,
                ...trip,
            }
        },
    );
}


function tripUpdate(eneble = null) {
    let state = store.getState(),
        {Credentials, CoreCredentials} = state.authentication,
        {trip} = state,
        {Cust} = state.user;
    const guestUserForm = state.forms[formsConstants.REGISTER_FORM];
    if (guestUserForm && Credentials.custAuthType === carmelConstAPI.authTypeGuest) {
        //creating cust to guest user
        Cust = {
            "lastName": guestUserForm[formsConstants.REGISTER_LNAME],
            "guestUser": true,
            "firstName": guestUserForm[formsConstants.REGISTER_FNAME],
            "phone": {
                "number": guestUserForm[formsConstants.REGISTER_PHONE],
                "countryCode": guestUserForm[formsConstants.REGISTER_COUNTRY].id
            },
            "emailAddr": guestUserForm[formsConstants.REGISTER_EMAIL],
        }
    }

    if (trip.fop.fopCode === paymentTypes.HOUSE_ACCOUNT) {
        let form = state.forms[formsConstants.HOUSE_USER_FORM];
        trip.account = {};
        trip.account[formsConstants.HOUSE_USERNAME] = form[formsConstants.HOUSE_USERNAME];
        trip.account[formsConstants.HOUSE_PASSWORD] = form[formsConstants.HOUSE_PASSWORD];
    }
    trip.cust = Cust;

    let useCarCash = false;
    if (trip.fare.carCashAmt)
        useCarCash = true;
    return generalService.requestSender(
        carmelConstAPIMethods.TRIP_UPDATE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            Trip: {
                ...trip,
                useCarCash
            },
        }
    );
}

function addFop() {
    const state = store.getState();
    const {CoreCredentials, Credentials} = state.authentication;
    const forms = state.forms;
    const {fopList} = state.user.lists.fop;
    const creditCardForm = forms[formsConstants.CREDIT_CARD];

    let fopCode = Object.keys(fopCardTypeRegex).filter(cardType => (
            creditCardForm.cardNumber.match(fopCardTypeRegex[cardType])
        )
    );

    let seq = 1;

    if (appHelp.validateArr(fopList) && fopList.length === 1) {
        let cardSeq = fopList[0].cardSeq;
        if (cardSeq) {
            seq = cardSeq % 2 + 1;
        }
    }
    let Fop = {};
    if (creditCardForm.prepayType === 'AI' || creditCardForm.prepayType === 'BASE')
        Fop = {
            ...creditCardForm,
            cardSeq: seq,
            fopCode: fopCode[0],
            autoCharge: true,
            onFile: false,
            saveNew: true,
            updateMode: 'Insert',
        };
    else
        Fop = {
            ...creditCardForm,
            cardSeq: seq,
            fopCode: fopCode[0],
            prepayType: "DFLT",
            autoCharge: false,
            saveNew: true,
            updateMode: 'Insert',
        };

    return generalService.requestSender(
        carmelConstAPIMethods.CUST_FOP_UPDATE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            Fop
        }
    );
}

function deleteFop(cardSeq,fopId) {
    const {Credentials, CoreCredentials} = store.getState().authentication;
    return generalService.requestSender(
        carmelConstAPIMethods.CUST_FOP_DELETE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            cardSeq,
            fopId
        }
    );
}

function updateCreditCard() {
    const state = store.getState();
    const {CoreCredentials, Credentials} = state.authentication;
    const forms = state.forms;
    const {fopList} = state.user.lists.fop;

    const creditCardForm = forms[formsConstants.CREDIT_CARD];

    let fopCode = Object.keys(fopCardTypeRegex).filter(cardType => (
            creditCardForm.cardNumber.match(fopCardTypeRegex[cardType])
        )
    );
    debugger

    let Fop = {};
    if (creditCardForm.prepayType === 'AI' || creditCardForm.prepayType === 'BASE')
        Fop = {
            ...creditCardForm,
            fopCode: fopCode && fopCode[0] ? fopCode[0] : creditCardForm[formsConstants.FOP_CODE],
            autoCharge: true,
            onFile: false,
            saveNew: true,
            updateMode: 'Update',
        };
    else
        Fop = {
            ...creditCardForm,
            fopCode: fopCode && fopCode[0] ? fopCode[0] : creditCardForm[formsConstants.FOP_CODE],
            prepayType: "DFLT",
            autoCharge: false,
            saveNew: true,
            updateMode: 'Update',
        };

    return generalService.requestSender(
        carmelConstAPIMethods.CUST_FOP_UPDATE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            Fop
        }
    );

}

function sendCustActivate(validationCode) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;
    const custActivateForm = state.forms[formsConstants.CUST_ACTIVATE_FORM];

    let phoneNumber = '';
    let countryCode = '';
    if (custActivateForm && custActivateForm[formsConstants.CUST_ACTIVATE_PHONE] && custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY]) {
        //get inputs from cust activate form
        phoneNumber = custActivateForm[formsConstants.CUST_ACTIVATE_PHONE];
        countryCode = custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY].id;
    }
    return generalService.requestSender(
        carmelConstAPIMethods.CUST_ACTIVATE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            },
            "CustActivation": {
                "phone": {
                    "number": phoneNumber,
                    "countryCode": countryCode
                },
                "requestCode": !validationCode, //if there is user input set request to false else true
                "userInput": validationCode ? validationCode : ''
            }
        }
    );
};


function sendRateDriver(ratingMessage, ratingId, tripId) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;


    let params = {
        'Credentials': {
            ...Credentials,
            ...CoreCredentials
        },
        'DriverRating': {
            ratingId: ratingId,
            ratingMessage: ratingMessage,
            ratingTitle: 'RATING TITLE',
            tripId: tripId
        }
    };

    return generalService.requestSender(
        carmelConstAPIMethods.DRIVER_RATING_REQUEST,
        params
    );
}

function submitReferralCode(sendCode, enterCode, referralCode) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;
    const {referral} = state.user.lists;
    return generalService.requestSender(
        carmelConstAPIMethods.REFERRAL_CODE_SUBMIT,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            },
            "AppReferral": {
                ...referral[userConstLists.REFERRAL_LIST],
                'enterCode': enterCode,
                "sendCode": sendCode,
                "referralCode": referralCode,
            },
        }
    );
};

function refreshToken() {
    const refreshToken = appHelp.getCookie('refresh_token');
    const body = {
        grant_type: "refresh_token",
        client_id: "b848b13e-243d-4269-8f55-3d6c3d383785",
        scope: "public;trip;user",
        server_token: generalConstants.CARMEL_API_KEY,
        refresh_token: refreshToken
    };

    return axios({
        method: 'post',
        url: generalConstants.TOKEN_REFRESH_ENDPOINT, //https://api.carmellimo.com/JobsWebServ/oauth2/v1/token
        data: qs.stringify({
            ...body
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });

}