import {carmelConstantsActions, userConstants, userConstLists} from "../../../../constants";


export function fop(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_BASIC_INFO_SUCCESS:
            return {
                ...state,
                [userConstLists.CUST_FOP_LIST]: action.results[userConstLists.CUST_FOP_LIST],
            };
        case carmelConstantsActions.DELETE_FOP_SUCCESS:
            // Remove the item in the
            // state[userConstLists.CUST_FOP_LIST].splice(action.fop.cardSeq - 1, 1);
            const newFopList = state[userConstLists.CUST_FOP_LIST].filter(fop => (
                fop.fopId !== action.fop
            ));

            return {
                ...state,
                [userConstLists.CUST_FOP_LIST]: newFopList
            };
        case carmelConstantsActions.ADD_FOP_SUCCESS:
            return {
                ...state,
                [userConstLists.CUST_FOP_LIST]:
                    state[userConstLists.CUST_FOP_LIST].concat([action.response])
            };
        case userConstants.CLEAR_USER_LISTS:
            return {
                ...state,
                [userConstLists.CUST_FOP_LIST]: {}
            };
        case userConstLists.ADD_GUEST_FOP:
            return {
                ...state,
                [userConstLists.CUST_FOP_LIST]: [action.card]
            };

        default:
            return state;
    }
}