import React, {Fragment, useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ModalWrapper from "../ModalWrapper";

import {formsConstants} from "../../../constants/forms.constants";
import {formsActions, carmelActions, uiActions} from "../../../store/actions";
import {modalsTypes} from "../../../constants";
import {appHelp} from "../../../helpers/app";
import {PhoneForm} from './PhoneForm'
import {ValidationCodeForm} from './ValidationCodeForm'

const CustActivate = ({dispatch, lists, Cust, custActivateForm, general, modals, modalType}) => {
    const [closeModal, setCloseModal] = useState(false);

    if (!lists || !lists.CountryList || !Cust)
        return null;

    let custActivateFromRegister = false;
    if (appHelp.validateArr(modals)) {
        if (modals.find(modal => modal === modalsTypes.CUST_ACTIVATE_FROM_REGISTER)) {
            custActivateFromRegister = true;
        }
    }
    const [disableField, setDisabledField] = useState(true);

    useEffect(() => {
        let selectedCountry = null;
        if (custActivateForm && custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY]) {
            //get current country
            selectedCountry = lists.CountryList.find(
                option =>
                    option &&
                    option.phoneCode === "+" + custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY]
            );

        } else if (Cust && Cust.phone) {
            selectedCountry = lists.CountryList.find(
                option =>
                    option.phoneCode === "+" + Cust.phone.countryCode
            );
        }
        if ((!custActivateForm || (!custActivateForm[formsConstants.CUST_ACTIVATE_PHONE] || !custActivateForm[formsConstants.COUNTRY_PLACEHOLDER])) && Cust && Cust.phone && Cust.phone.number) {
            //if form already exist (came from register) dont create new form from Cust
            dispatch(formsActions.updateFieldsValue(formsConstants.CUST_ACTIVATE_FORM, {
                    [formsConstants.CUST_ACTIVATE_PHONE]: Cust.phone.number,
                    [formsConstants.CUST_ACTIVATE_COUNTRY]: selectedCountry.phoneCode,
                    [formsConstants.CUST_ACTIVATE_CODE]: ''
                }
            ))
        }

        if (custActivateFromRegister) {
            dispatch(carmelActions.custActivate());
        }
        //add activation form field to the cust activate form
        return () => {
            //clear form on exit of modal
            dispatch(formsActions.clearFormFields(formsConstants.CUST_ACTIVATE_FORM));
            dispatch(uiActions.resetCustActivate());
        }
    }, []);

    const handleChange = (id, value) => {
        dispatch(formsActions.updateFormFieldValue(
            formsConstants.CUST_ACTIVATE_FORM,
            id,
            value,
            []
        ))
    };

    const sendCustActivate = () => {
        if (general.validationCodeSend) {
            //if there is entered code send it else send general request to get code to phone
            if (custActivateForm[formsConstants.CUST_ACTIVATE_CODE])
                dispatch(carmelActions.custActivate(custActivateForm[formsConstants.CUST_ACTIVATE_CODE], custActivateFromRegister, setCloseModal))
        } else {
            dispatch(carmelActions.custActivate())
        }
    };
    return (
        <ModalWrapper modalType={modalType} title={'Cust Activate'}
                      closeModalIndicator={closeModal}>
            <Fragment>
                <div style={{margin: 0}} className="custom-modal-body modal-full-height-overflow flex-col-space-between"

                >
                    <div>
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem doloribus magnam maiores
                            minus
                            nostrum perferendis qui sed ullam voluptatum? Accusamus doloribus esse harum illo illum
                            magnam
                            nobis
                            officiis ratione sit?
                        </div>
                        <div className={''}>
                            {
                                !general.validationCodeSend ?
                                    <PhoneForm
                                        disableField={disableField}
                                        enableField={() => setDisabledField(false)}
                                        handleChange={handleChange}
                                    />
                                    :
                                    <ValidationCodeForm handleChange={handleChange}/>
                            }
                        </div>
                    </div>
                    <div className="f-row" onClick={sendCustActivate}>
                        <div className="full-width">
                            <input type="submit" value={!general.validationCodeSend ? 'SEND CODE' : 'VALIDATE'}
                                   className="btn color medium full"/>
                        </div>
                    </div>
                </div>
            </Fragment>
        </ModalWrapper>
    )
};


function mapStateToProps({settings, user, forms, ui}) {
    const {lists} = settings;
    const {Cust} = user;
    const {general, modalsUi} = ui;
    const {modals} = modalsUi;
    const custActivateForm = forms[formsConstants.CUST_ACTIVATE_FORM];
    return {
        lists,
        Cust,
        custActivateForm,
        general,
        modals
    }
}

export default injectIntl(connect(mapStateToProps)(CustActivate));


