import React, {Fragment, useState} from 'react'
import {Logo} from '../../components/GeneralComponents'
import Nav from './Nav'
import {connect} from "react-redux";
import CollapseIcon from "./CollapseIcon";


const MainHeader = ({auth}) => {
    const [openNav,setOpenNav] = useState(false);
    return (
        <Fragment>
            <header className="header" role="banner">
                <div className="wrap">
                    <Logo/>
                    <CollapseIcon setOpenNav={setOpenNav} openNav={openNav} loggedIn={auth.loggedIn}/>
                    <Nav openNav={openNav}/>
                </div>
            </header>
        </Fragment>
    )
};


const mapStateToProps = ({authentication}) => {
    const {auth} = authentication;
    return {
        auth
    };
};

export default React.memo(connect(mapStateToProps)(MainHeader));