import React, {useState, useEffect, Fragment} from "react";


export const UseCheckBox = ({col = false, windowVw = window.innerWidth, label = '', id = 'checkbox', congestionFeeDesc = '', description = '', manuallySetCheck = null, inputField = null, setCheckHandle = null, action = null, valid = true, upperTitle = false}) => {
    let [check, setCheck] = useState(false);


    useEffect(() => {
        if (valid) {
            setCheck(id === manuallySetCheck);
            if (id === manuallySetCheck && action) {
                action()
            }
        }
    }, [manuallySetCheck]);
    if (!valid)
        return [false, <div/>];
    const input =
        <Fragment>
            {
                upperTitle &&
                <div className={'full-width'}>
                    <label htmlFor="checkbox" style={{margin: '0', padding: '0'}}>{label}</label>
                </div>
            }

            <div className={`full-width check ${col && windowVw > 680 ? 'flex-col-center' : ''}`}
                 onClick={() => {
                     if (id === 'checkbox')
                         setCheck(!check);
                     else {
                         setCheckHandle(id);
                     }

                 }}

            >

                <div className={'checker'} style={col && windowVw > 680 ? {marginRight: 0, marginBottom: '10px'} : {}}>
            <span className={check ? 'checked' : ''}>
              <input type="checkbox" id="checkbox"/>
            </span>
                </div>
                {
                    !upperTitle &&
                    <div className={`${col && windowVw > 680 ? 'flex-col-center text-max-length' : 'label-container'}`}>
                        <div className={`${col && windowVw > 680 ? 'flex-col-center' : ''}`}>

                            <label htmlFor={id} style={{margin: '0', padding: '0'}}
                                   className={`${col && windowVw > 680 ? 'check-box-label order-2' : ''}`}>{label}</label>
                            {
                                congestionFeeDesc &&

                                <label
                                    className={`congestionFee ${col && windowVw > 680 ? 'check-box-label order-1' : ''}`}
                                    htmlFor={id}
                                    style={{padding: '0'}}>{congestionFeeDesc}</label>
                            }
                        </div>
                        {
                            description &&
                            <Fragment>
                                <label htmlFor={id} style={{margin: '0', padding: '0'}}
                                       className={`descriptionCheckBox ${col && windowVw > 680 ? 'check-box-label order-3' : ''}`}>{description}</label>
                            </Fragment>
                        }
                    </div>
                }

            </div>
        </Fragment>;


    return [check, input];
}
