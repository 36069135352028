import {combineReducers} from 'redux';

import {cust} from './cust.reducer';
import {lists} from './lists';
import {carmelPerks} from "./carmelPerks.reducer";


export const user = combineReducers({
    Cust: cust,
    lists,
    carmelPerks
});
