import React, {useState, useEffect, useRef} from 'react'
import {useDispatch} from "react-redux";
import {addressActions, formsActions} from "../../../../../../store/actions";
import {appHelp} from "../../../../../../helpers";

export const AutoCompleteAirlinePick = ({items, id, formId, form, placeholder, style, focusedField, setFocusOnField, classes, focus}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [text, setText] = useState('');
    const dispatch = useDispatch();
    let inputRef = useRef(null);
    let input = document.getElementById(id);
    if (input) {
        input.onkeydown = function (e) {
            let key = e.keyCode || e.charCode;

            if (key === 8 || key === 46) {
                dispatch(formsActions.updateFormFieldValue(formId, id, '', []));
                setText('')
            }
        };
    }
    useEffect(() => {
        if (!form[id]) {
            setText('');
        }
    }, [form]);

    useEffect(() => {
        if (focus)
            inputRef.current.focus();
    }, [focus]);

    const handleChange = (e) => {
        if (focusedField === id) {
            //if input is selected as alert the clear the alert
            setFocusOnField("");
        }
        let value = e.target.value;
        let validateInput = appHelp.validateInputLettersAndNumbers(value);
        if (!validateInput) {
            return
        }
        let suggestionsFiltered = [];
        if (value.length > 0) {
            suggestionsFiltered = items.sort().filter(v => v.label.toLowerCase().includes(value.toLowerCase()));
        }
        // dispatch(formsActions.)
        setSuggestions(suggestionsFiltered);
        setText(value);
        if (form[id]) {
            dispatch(formsActions.updateFormFieldValue(
                formId,
                id,
                '',
                []
            ))
        }
    };

    const selectSuggestion = (value) => {
        setSuggestions([]);
        setText(value.label);
        dispatch(formsActions.updateFormFieldValue(
            formId,
            id,
            value.value,
            []
        ))
    };

    const renderSuggestions = () => {
        if (suggestions.length === 0)
            return null;
        else {
            return (
                <ul className={`  ${classes}`}>
                    {
                        suggestions.map((item, index) => {
                            return (
                                <li key={index} onClick={() => selectSuggestion(item)}>{item.label}</li>
                            )
                        })
                    }
                </ul>
            )
        }
    };
    return (
        <div className={'autocompleteTextField'}>
            <input type='text' value={text} onChange={handleChange} placeholder={placeholder} style={style}
                   ref={inputRef} id={id}/>
            {renderSuggestions()}
        </div>
    )
};