import React, {Fragment} from "react";
import {useDispatch} from "react-redux";
import logoutIcon from "../../assets/images/logout.png";
import {userActions} from "../../store/user/actions";
import Tooltip from "@material-ui/core/Tooltip";


export default ({setOpenNav, openNav, loggedIn}) => {
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(userActions.logout());
    };
    return (
        <Fragment>
            <div className=" collpase-icon" style={!loggedIn ? {justifyContent: 'flex-end'} : {}}>
                {
                    loggedIn &&
                    <Tooltip title="logout" aria-label="logout" placement={'left'}>
                        <img src={logoutIcon} alt="logout" className='logout-icon' onClick={logout}/>
                    </Tooltip>
                }
                <a href="javascript:void(0);" onClick={() => setOpenNav(!openNav)}>
                    <i className="fa fa-bars"/>
                </a>
            </div>
        </Fragment>
    )
}
