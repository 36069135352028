import axios, {post} from "axios";
import {carmelConstAPIMethods, carmelMethodsConstants, formsConstants, generalConstants} from '../../constants';
import {generalService} from "../general/services";
import {store} from '../index';

export const userService = {
    login,
    facebookLogin,
    logout,
    register,
    passwordRecovery,
    uploadImage,
    loadBasicUserInformation,
    updateUserInfo,
    subscribe,
    getTripList,
    getFutureTripList,
    getPastTripList,
    getCarCashList,
    updatePassword,
    Cust
};


function login() {
    const state = store.getState();
    const {CoreCredentials} = state.authentication;
    const loginForm = state.forms[formsConstants.LOGIN_FORM];
    const registerForm = state.forms[formsConstants.REGISTER_FORM];
    let username = '';
    let password = '';
    if (loginForm) {
        username = loginForm[formsConstants.LOGIN_USERNAME];
        password = loginForm[formsConstants.LOGIN_PASSWORD];
    } else {
        username = registerForm[formsConstants.REGISTER_EMAIL];
        password = registerForm[formsConstants.REGISTER_PASSWORD];
    }

    return generalService.requestSender(
        carmelConstAPIMethods.CUST_LOGIN,
        {
            Credentials: {
                ...CoreCredentials,
                custLoginId: username,
                custPassword: password,
            }
        }
    );
}

function facebookLogin() {
    const state = store.getState();
    const {CoreCredentials, Credentials} = state.authentication;
    const {Cust} = state.user;
    const registerForm = state.forms[formsConstants.REGISTER_FORM];
    //take missing fields from form is arguments in the cust were missing
    if (registerForm && registerForm[formsConstants.REGISTER_PHONE] && registerForm[formsConstants.REGISTER_COUNTRY] && !Cust.phone) {
        Cust.phone = {
            countryCode: registerForm[formsConstants.REGISTER_COUNTRY].id,
            number: registerForm[formsConstants.REGISTER_PHONE],
        }
    }
    if (registerForm && registerForm[formsConstants.REGISTER_EMAIL] && !Cust.emailAddr) {
        Cust.emailAddr = registerForm[formsConstants.REGISTER_EMAIL];
    }
    // const {selectedOptionID} = userActionOptions;
    return generalService.requestSender(
        carmelConstAPIMethods.FACEBOOK_LOGIN,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            "FacebookLogin": {
                "cust": Cust,
            }
        });
}

function logout() {
    // Remove the app Credential state from local storage to avoid auto-login
    localStorage.removeItem('Credentials');
}

function register() {
    const state = store.getState();
    const {CoreCredentials} = state.authentication;
    if (!state.forms[formsConstants.REGISTER_FORM]) {
        return;
    }

    const registerForm = state.forms[formsConstants.REGISTER_FORM];
    const emailAddr = registerForm[formsConstants.REGISTER_EMAIL];
    const firstName = registerForm[formsConstants.REGISTER_FNAME];
    const lastName = registerForm[formsConstants.REGISTER_LNAME];
    const password = registerForm[formsConstants.REGISTER_PASSWORD];
    const passwordCon = registerForm[formsConstants.REGISTER_PASSWORD_CON];
    const country = registerForm[formsConstants.REGISTER_COUNTRY];
    const countryIce = registerForm[formsConstants.REGISTER_COUNTRY_ICE];
    const phoneIce = registerForm[formsConstants.REGISTER_PHONE_ICE];
    const number = registerForm[formsConstants.REGISTER_PHONE];
    // const pictureUrl = state.temp.services.imageAsFormData;

    return generalService.requestSender(
        carmelConstAPIMethods.CUST_REGISTER,
        {
            Credentials: {
                ...CoreCredentials
            },
            Cust: {
                emailAddr,
                firstName,
                lastName,
                password,
                language: country.name,
                phone: {
                    countryCode: country.phoneCode,
                    number,
                },
                phoneIce: {
                    countryCode: countryIce ? countryIce.phoneCode : '',
                    number: phoneIce,
                }
            },
            CustPassword: {
                newPassword1: password,
                newPassword2: passwordCon
            },

        }
    );
}

function updateUserInfo(userUpdatedFields) {
    const state = store.getState();
    const previousUser = state.user.Cust;
    const {Credentials, CoreCredentials} = state.authentication;

    const Cust = {
        ...previousUser,
        ...userUpdatedFields,
    };
    return generalService.requestSender(
        carmelConstAPIMethods.CUST_UPDATE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            Cust
        });
}

function updatePassword(CustPassword) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;

    return generalService.requestSender(
        'CustPassword',
        {
            'Credentials': {
                ...CoreCredentials
            },
            CustPassword
        }
    );
}

function passwordRecovery(custLoginId) {
    const {CoreCredentials} = store.getState().authentication;
    const CustPassword = {
        resetPassword: true,
        custLoginId
    };

    return generalService.requestSender(
        'CustPassword',
        {
            Credentials: {
                ...CoreCredentials
            },
            CustPassword
        }
    );
}

function uploadImage(imageFile) {
    const {CoreCredentials, Credentials} = store.getState().authentication;
    const uniqTime = Date.now();

    const requestOptions = {
        method: 'post',
        url: generalConstants.imageUpload,
        headers: {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            },
            'Content-Type': `multipart/form-data ; boundary=--------------------------${uniqTime}`
        },
        body: imageFile,
    };

    return axios(requestOptions)
        .then(generalService.handleAxiosResponse)
        .then(response => {
            console.log("imageUpload: response", response);
            return response;
        });
}


function loadBasicUserInformation() {
    const {CoreCredentials, Credentials} = store.getState().authentication;
    // build an array of all requests need to make
    return generalService.multipleCallsSender(
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
        }, [
            {
                method: carmelConstAPIMethods.CUST_FOP_LIST,
            },
            {
                method: carmelConstAPIMethods.CUST_ADDRESS_LIST,
            },
            {
                method: carmelConstAPIMethods.CUST_RECENT_TRIP_LIST,
            },
            {
                method: carmelConstAPIMethods.REWARD_PROGRAM_LIST,
            },
            {
                method: carmelConstAPIMethods.REFERRAL_CODE_SUBMIT,
                params: {
                    "AppReferral": {
                        "sendCode": true,
                    }
                }
            }
        ],
        'loadBasicUserInformation'
    );
}

function getTripList() {
    const {CoreCredentials, Credentials} = store.getState().authentication;
    return generalService.multipleCallsSender(
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
        }, [
            {
                method: carmelConstAPIMethods.TRIP_LIST,
                params: {
                    tripListType: "Future"
                }
            },
            {
                method: carmelConstAPIMethods.TRIP_LIST,
                params: {
                    tripListType: "History"
                }
            },
            {
                method: carmelConstAPIMethods.CUST_RECENT_TRIP_LIST
            }
        ],
        'getTripList'
    );
}

function getFutureTripList() {
    const {CoreCredentials, Credentials} = store.getState().authentication;

    return generalService.requestSender(
        carmelConstAPIMethods.TRIP_LIST,
        {
            "preview": false,
            "dtFrom": "",
            "dtTo": "",
            "tripListType": "Future",
            "Credentials": {
                ...Credentials,
                ...CoreCredentials
            }
        }
    )
}

function getCarCashList(update, id) {
    const {CoreCredentials, Credentials} = store.getState().authentication;

    return generalService.requestSender(
        carmelConstAPIMethods.CUST_CAR_CASH_LIST,
        {
            "CustCarCash": {
                "requestedMarket": id,
                "requestCarCash": update,
            },
            "Credentials": {
                ...Credentials,
                ...CoreCredentials
            }
        }
    )
}

function getPastTripList() {
    const {CoreCredentials, Credentials} = store.getState().authentication;

    return generalService.requestSender(
        carmelConstAPIMethods.TRIP_LIST,
        {
            "preview": false,
            "dtFrom": "",
            "dtTo": "",
            "tripListType": "History",
            "Credentials": {
                ...Credentials,
                ...CoreCredentials
            }
        }
    )
}

function subscribe(email) {
    const requestOptions = {
        method: 'POST',
        body: {
            'email': email
        }
    };
    return axios(generalConstants.subscribeEndPoint, requestOptions)
        .then(generalService.handleAxiosResponse)
        .then(response => {
            return response;
        });
}

function Cust() {
    const state = store.getState();
    const {CoreCredentials} = state.authentication;

    return generalService.requestSender(
        carmelConstAPIMethods.CUST,
        {
            Credentials: {
                ...CoreCredentials,
            }
        }
    );
}