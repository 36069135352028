import React from "react";
import {components} from "react-select";

export const CustomDropDownIndicator = props => {
    const customProps = Object.assign(
        {
            className: `briz-drop-down-indicator`
        },
        props
    );
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;

    return <components.DropdownIndicator {...customProps} />;
};