import React from 'react'

export default () => {
    return (
        <div className="testimonials center black">
            <div className="wrap">
                <h6 className="wow fadeInDown"><i className="fa fa-quote-left"/>OUTSTANDING Service, uncompromising
                    technology</h6>
                <p className="wow fadeInUp">"Earn your success based on service to others, not at the expense of others."</p>
                <p className="meta wow fadeInUp">- H. Jackson Brown Jr</p>
            </div>
        </div>
    )
}