import {userConstants, userConstLists} from "../../../../constants";


export function rewards(state = {}, action) {
    switch (action.type) {
        case userConstants.USER_BASIC_INFO_SUCCESS:
            return {
                ...state,
                [userConstLists.REWARD_LIST]: action.results[userConstLists.REWARD_LIST],
                [userConstLists.DISCRIMER]: action.results[userConstLists.DISCRIMER]
            };
        default:
            return state;
    }
}