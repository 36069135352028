import {userConstants} from "../../../constants";


export function carmelPerks(state = {isFirstEntire: true}, action) {
    switch(action.type) {
        case userConstants.FIRST_ENTIRE_FLAG:
            return {
                ...state,
                isFirstEntire: action.isFirstEntire
            };
        default:
            return state;
    }
}
