import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {CreditCardForm} from "./CreditCardForm";
import {AddTip} from "./AddTip";
import IntlMessages from "../../../utils/IntlMessages";

import {userActions, carmelActions} from "../../../store/actions";
import {formsConstants} from "../../../constants/forms.constants";
import {formsActions} from "../../../store/forms/actions";
import {creditCardValidators} from "../../../_validators/creditCard.validators";
import ModalWrapper from "../ModalWrapper";


const CreditCardModal = ({dispatch, creditCardForm, auth, modalType}) => {
    const [closeModal, setCloseModal] = useState(false);

    const submitCard = () => {
        if (auth.loggedIn) {
            if (creditCardForm[formsConstants.CARD_SEQ_NUM]) {
                dispatch(carmelActions.updateCreditCard(setCloseModal));
            } else {
                dispatch(carmelActions.addFop(setCloseModal));
            }
        } else {
            const BreakException = {};
            try {
                if (creditCardForm) {
                    const creditCardFieldsArr = Object.keys(creditCardForm);
                    creditCardFieldsArr.forEach(creditCardField => {
                        if (typeof creditCardForm[creditCardField] === 'string' && !creditCardForm[creditCardField] && creditCardField !== 'onFocus' && creditCardField !== 'errors' && creditCardField !== 'CARD_NUMBER_FOR_DISPLAY') {
                            //not entered fields validator
                            if (creditCardField === formsConstants.CARD_BILLING_STATE) {
                                if (creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE].toLowerCase() === 'us') {
                                    dispatch(
                                        formsActions.setFocusOnField(
                                            formsConstants.CREDIT_CARD,
                                            creditCardField
                                        )
                                    );
                                    dispatch(
                                        formsActions.setFieldErrors(
                                            formsConstants.CREDIT_CARD,
                                            creditCardField,
                                            'Missing field!'
                                        )
                                    );
                                    throw BreakException;
                                }
                            } else {
                                dispatch(
                                    formsActions.setFocusOnField(
                                        formsConstants.CREDIT_CARD,
                                        creditCardField
                                    )
                                );
                                dispatch(
                                    formsActions.setFieldErrors(
                                        formsConstants.CREDIT_CARD,
                                        creditCardField,
                                        'Missing field!'
                                    )
                                );
                                throw BreakException;
                            }
                        }
                    });
                    if (!creditCardValidators.creditCard(creditCardForm[formsConstants.CARD_NUMBER]).isFieldValid) {
                        //not valid credit card
                        dispatch(
                            formsActions.setFocusOnField(
                                formsConstants.CREDIT_CARD,
                                formsConstants.CARD_NUMBER
                            )
                        );
                        dispatch(
                            formsActions.setFieldErrors(
                                formsConstants.CREDIT_CARD,
                                formsConstants.CARD_NUMBER,
                                creditCardValidators.creditCard(creditCardForm[formsConstants.CARD_NUMBER]).message
                            )
                        );
                        throw BreakException;
                    }
                    if (!creditCardValidators.cvvValidator(creditCardForm[formsConstants.CARD_CVV]).isFieldValid) {
                        //not valid cvv card
                        dispatch(
                            formsActions.setFocusOnField(
                                formsConstants.CREDIT_CARD,
                                formsConstants.CARD_CVV
                            )
                        );
                        dispatch(
                            formsActions.setFieldErrors(
                                formsConstants.CREDIT_CARD,
                                formsConstants.CARD_CVV,
                                creditCardValidators.cvvValidator(creditCardForm[formsConstants.CARD_CVV]).message
                            )
                        );
                        throw BreakException;
                    }
                    dispatch(userActions.addGuestFop());
                    setCloseModal(true)
                }
            } catch (e) {
                if (e !== BreakException) {
                    console.log(e)
                }
            }
        }
    };

    const modalCloseAction = () => {
        dispatch(formsActions.clearAllFormFields(formsConstants.CREDIT_CARD));
    };

    return (
        <ModalWrapper modalType={modalType} title={'Credit Card'} modalCloseAction={modalCloseAction}
                      closeModalIndicator={closeModal}>
            <div className="custom-modal-body modal-full-height-overflow">
                <p className="fs_small grey-text text-center ">
                    <IntlMessages id="credit-card-modal.credit-card-details"/>
                </p>
                <p className="fs_xxsmall grey-text text-center">
                    <IntlMessages id="credit-card-modal.credit-card-wont-charge"/>
                </p>
                <CreditCardForm/>
                {
                    auth.loggedIn &&
                    <AddTip/>
                }
                <div className="wrap">
                    <div className="f-row">
                        <input
                            type="submit"
                            value="Save Card"
                            className="btn color medium full"
                            onClick={submitCard}
                        />
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

function mapStateToProps({ui, forms, authentication}) {
    const {modalsUi} = ui;
    const creditCardForm = forms[formsConstants.CREDIT_CARD];
    const {auth} = authentication;
    return {
        modalsUi,
        creditCardForm,
        auth
    };
}

export default injectIntl(connect(mapStateToProps)(CreditCardModal));
