import React, {Fragment, useEffect, useRef} from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Intro} from "./Intro";
import {Service} from "./Service";
import SpecialServices from "./SpecialServices";
import Jumbotron from "./Jumbotron";
import Partners from "./Partners";
import {MainActivity} from "./MainActivity/MainActivity";
import {formsActions} from "../../store/forms/actions";
import {addressConstants, formsConstants} from "../../constants";
import {tripActions} from "../../store/trip/actions";
import {addressActions} from "../../store/address/actions";
import {carmelActions} from "../../store/carmel/actions";

const MainPage = ({searchAddressForm, dispatch, auth, Cust}) => {
    const myRef = null;

    useEffect(() => {
        //on mount clear all trip creation related fields
        dispatch(tripActions.resetTrip());
        dispatch(addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS));
        dispatch(addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS));
        dispatch(addressActions.clearServiceByAddress());
        dispatch(carmelActions.clearServiceByAddressTimeout());


        dispatch(formsActions.updateFieldsValue(formsConstants.SEARCH_FORM, {
            [formsConstants.SEARCH_ADDRESS]: '',
            [formsConstants.DROP_ADDRESS]: ''
        }));
        // add relevant class to the html body 
        document.body.classList.add("home");
        return () => {
            document.body.classList.remove("home");
        }
    }, []);

    return (
        <Fragment>
            <Intro scrollToRefBooking={myRef} loggedIn={auth.loggedIn} name={Cust.firstName}/>
            {
                searchAddressForm &&
                <MainActivity/>
            }
            <Service/>
            <SpecialServices/>
            <Jumbotron/>
            {/* <Partners/> */}
        </Fragment>
    )
};

function mapStateToProps({forms, authentication, user}) {
    const {auth} = authentication;
    const {Cust} = user;
    const searchAddressForm = forms[formsConstants.SEARCH_FORM];
    return ({
        searchAddressForm,
        auth,
        Cust
    })
}

const connectedPage = injectIntl(connect(mapStateToProps)(MainPage));
export {connectedPage as MainPage};