import React, {useRef, useEffect} from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import $ from 'jquery'
import * as luxon from "luxon";
import datetimepicker from 'jquery-datetimepicker'
import {findDOMNode} from 'react-dom'
import {addressConstants} from "../../../constants/address.constants";
import {formsActions} from "../../../store/forms/actions";
import {formsConstants} from "../../../constants/forms.constants";
import {appHelp} from "../../../helpers";
import {tripActions} from "../../../store/trip/actions";

var timeUpdateTimeout = null;

const DateTime = ({trip, dateAndTimeForm, serviceByAddr, dispatch, displayOnly}) => {

    let refDate = useRef(null);


    const resetTimeField = () => {
        const localDateTime = luxon.DateTime.local();
        const localDate = localDateTime.toFormat('yyyy/MM/dd');
        const localTime = localDateTime.toFormat('HH:mm');
        if (refDate) {
            //Init date and time object for the first time
            const el = findDOMNode(refDate.current);
            // DATE & TIME PICKER
            $(el).datetimepicker({
                step: 1,
                minDate: localDate,
                minTime: localTime,
            }).val(`${localDate} ${localTime}`);

        }
    };

    useEffect(() => {
        if (refDate) {
            clearInterval(timeUpdateTimeout);
            if (!timeUpdateTimeout)
                timeUpdateTimeout = setInterval(resetTimeField, 2000);
        }

    }, [refDate]);

    useEffect(() => {
        if (dateAndTimeForm && appHelp.validateObject(trip[addressConstants.PICK_UP_ADDRESS]) ) {
            const [date, time] = [dateAndTimeForm[formsConstants.DATE_FIELD], dateAndTimeForm[formsConstants.TIME_FIELD]];
            //local date to compare dates for time pick minimum value
            const {timeZoneId} = trip[addressConstants.PICK_UP_ADDRESS];
            const localDate = luxon.DateTime.local().setZone(timeZoneId).toFormat('yyyy/MM/dd');

            clearInterval(timeUpdateTimeout);
            timeUpdateTimeout = null;

            if (refDate) {
                //Init date and time object for the first time
                const el = findDOMNode(refDate.current);
                // DATE & TIME PICKER
                $(el).datetimepicker({
                    step: 1,
                    minDate: date > localDate ? localDate : date,
                    minTime: date > localDate ? '00:00' : time,
                    onSelectDate: (e) => {
                        try {
                            //update selected date
                            const date = luxon.DateTime.fromJSDate(e);
                            if (dateAndTimeForm) {
                                dispatch(formsActions.updateFormFieldValue(formsConstants.TIME_FORM, formsConstants.DATE_FIELD, date.toFormat('yyyy/MM/dd'), []));
                                dispatch(
                                    tripActions.dateUpdate(
                                        date.toFormat('yyyy-MM-dd')
                                    )
                                );
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }, onSelectTime: (e) => {
                        try {
                            //update selected time
                            const date = luxon.DateTime.fromJSDate(e);
                            if (dateAndTimeForm) {
                                dispatch(formsActions.updateFormFieldValue(formsConstants.TIME_FORM, formsConstants.TIME_FIELD, date.toFormat('HH:mm'), []));
                                dispatch(
                                    tripActions.timeUpdate(
                                        date.toFormat('HH:mm')
                                    )
                                );
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }).val(`${date} ${time}`);
            }
        } else {
            //reset time form on pickup trip clear
            if (!timeUpdateTimeout)
                timeUpdateTimeout = setInterval(resetTimeField, 2000);
            resetTimeField();
        }

    }, [dateAndTimeForm]);

    const keyPress = (e) => {
        e.preventDefault()
    };
    return (
        <div className="form-group datepicker one-third" id={'order-1'}>
            <label htmlFor="dep-date">Departure date and time</label>
            <input type="text" id="dep-date" ref={refDate} onKeyPress={keyPress}
                   disabled={displayOnly} readOnly/>
        </div>
    )
};

function mapStateToProps({trip, temp, forms}) {
    const {serviceByAddr} = temp.services;
    const dateAndTimeForm = forms[formsConstants.TIME_FORM];
    return ({
        trip,
        serviceByAddr,
        dateAndTimeForm
    })
}

const connectedPage = injectIntl(connect(mapStateToProps)(DateTime));
export {connectedPage as DateTime};