import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import Modal from "react-modal";
import {appHelp} from "../../helpers";
import {uiActions} from "../../store/ui/actions";
import ModalHeader from "./ModalHeader";

Modal.setAppElement("#modal-root");

const ModalWrapper = ({modalsUi, children, modalType, modalCloseAction, dispatch, title, closeModalIndicator, smallModal,headerWithX}) => {
        const [windowVh, setWindowVh] = useState(window.innerHeight * 0.01);

        function windowResize() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            setWindowVh(window.innerHeight * 0.01);
        }

        useEffect(() => {
            window.addEventListener("resize", windowResize);
        }, []);

        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000",
                width: "95%",
                maxWidth: smallModal ? '400px' : "700px",
                height: smallModal ? '' : `${windowVh * 80}px`, // calculating responsively window height
                maxHeight:  smallModal ? '' : `800px`,
                padding: "0"
            }
        };

        const findModal = () => {
            const {modals} = modalsUi;
            return (
                appHelp.validateArr(modals) &&
                modals.find(modal => modal === modalType)
            );
        };


        const isFunction = (functionToCheck) => {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        };

        const closeModal = () => {
            if (isFunction(modalCloseAction)) {
                modalCloseAction();
            }
            dispatch(uiActions.closeModal());
        };

        useEffect(() => {
            if (closeModalIndicator) {
                //when modal set to be closed after and of function
                closeModal();
            }
        }, [closeModalIndicator]);
        return (
            <div style={{zIndex: 10000}}>
                <Modal
                    isOpen={findModal(modalType)}
                    style={customStyles}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    closeTimeoutMS={500}
                >
                    <ModalHeader closeModal={closeModal} title={title} headerWithX={headerWithX}/>
                    {children}
                </Modal>
            </div>
        )
    }
;

function mapStateToProps({ui}) {
    const {modalsUi} = ui;

    return {
        modalsUi
    }
}

export default React.memo(connect(mapStateToProps)(ModalWrapper))