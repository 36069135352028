import {carmelConstAPILists, userConstants, userConstLists} from "../../../../constants";


export function referralList(state = [], action) {
    switch(action.type) {
        case userConstants.USER_BASIC_INFO_SUCCESS:
            return  {
                ...state,
                [userConstLists.REFERRAL_LIST]: action.results[userConstLists.REFERRAL_LIST]
            };
        default:
            return state;
    }
}