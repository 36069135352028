import React from "react";
import {injectIntl} from "react-intl";
import IntlMessages from "../../utils/IntlMessages";


const TripStatusGenerator = ({status, intl}) => {

    switch (status) {
        case "No Show":
            return (
                <span className="badge secondary">
                    <IntlMessages id='trip-details.trip-status.noshow'/>
                </span>
            );
        case "Canceled":
            return (
                <span className="badge warning">
                <IntlMessages id='trip-details.trip-status.canceled'/>
                </span>
            );
        case "Completed":
            return (
                <span className="badge primary">
                <IntlMessages id='trip-details.trip-status.completed'/>
                </span>

            );
        default:
            return (
                <span className="badge secondary">
                    <IntlMessages id='trip-details.trip-status.noshow'/>
                </span>
            )
    }
};
export default injectIntl(TripStatusGenerator);