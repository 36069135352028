export const tripConstants = {
    TIME_UPDATE: 'TIME_UPDATE',
    DATE_UPDATE: 'DATE_UPDATE',

    SET_TRIP_OPTIONS: 'SET_TRIP_OPTIONS',
    ADD_AIRPORT_DETAILS: 'ADD_AIRPORT_DETAILS',
    UPDATE_INFO_CAR: 'UPDATE_INFO_CAR',
    CLEAR_CAR_LOCATION_TIMEOUT: 'CLEAR_CAR_LOCATION_TIMEOUT',
    CLEAR_CAR_LOCATION: 'CLEAR_CAR_LOCATION',
    CLEAR_TRIP_CHARGE: 'CLEAR_TRIP_CHARGE',
    CLEAR_CURRENT_ROUTE_MODE: 'CLEAR_CURRENT_ROUTE_MODE',
    CONTINUE_CAR_LOCATION_INTERVAL: 'CONTINUE_CAR_LOCATION_INTERVAL',
    UPDATE_CAR: 'UPDATE_CAR',
    UPDATE_CAR_CASH_USE: 'UPDATE_CAR_CASH_USE',

    CLEAR_TRIP: "CLEAR_TRIP",

    GET_TRIP_DETAILS_REQUEST: 'GET_TRIP_DETAILS_REQUEST',
    GET_TRIP_DETAILS_SUCCESS: 'GET_TRIP_DETAILS_SUCCESS',
    GET_TRIP_DETAILS_FAILURE: 'GET_TRIP_DETAILS_FAILURE',

    GET_CAR_LOCATION_REQUEST: 'GET_CAR_LOCATION_REQUEST',
    GET_CAR_LOCATION_SUCCESS: 'GET_CAR_LOCATION_SUCCESS',
    GET_CAR_LOCATION_FAILURE: 'GET_CAR_LOCATION_FAILURE',

    GET_ROUTE_DIRECTION_REQUEST: 'GET_ROUTE_DIRECTION_REQUEST',
    GET_ROUTE_DIRECTION_SUCCESS: 'GET_ROUTE_DIRECTION_SUCCESS',
    GET_ROUTE_DIRECTION_FAILURE: 'GET_ROUTE_DIRECTION_FAILURE',

    GET_TRIP_SIGNATURE_REQUEST: 'GET_TRIP_SIGNATURE_REQUEST',
    GET_TRIP_SIGNATURE_SUCCESS: 'GET_TRIP_SIGNATURE_SUCCESS',
    GET_TRIP_SIGNATURE_FAILURE: 'GET_TRIP_SIGNATURE_FAILURE',

    FIRST_TRIP_CHARGE_REQUEST: 'FIRST_TRIP_CHARGE_REQUEST',
    FIRST_TRIP_CHARGE_SUCCESS: 'FIRST_TRIP_CHARGE_SUCCESS',
    FIRST_TRIP_CHARGE_FAILURE: 'FIRST_TRIP_CHARGE_FAILURE',

    SECOND_TRIP_CHARGE_REQUEST: 'SECOND_TRIP_CHARGE_REQUEST',
    SECOND_TRIP_CHARGE_SUCCESS: 'SECOND_TRIP_CHARGE_SUCCESS',
    SECOND_TRIP_CHARGE_FAILURE: 'SECOND_TRIP_CHARGE_FAILURE',

    CLEAR_SIGNATURE: 'CLEAR_SIGNATURE',

    PICK_UP_INSTRUCTIONS_TITLE: 'show-my-car.title.pickup-instructions',
    SHOW_MY_CAR_TITLE: 'show-my-car.title.show-my-car',

    RESET_TRIP: 'RESET_TRIP',
    UPDATE_FARE_PAYMENT_METHOD: 'UPDATE_FARE_PAYMENT_METHOD',
    UPDATE_CAR_CASH_AMOUNT: 'UPDATE_CAR_CASH_AMOUNT',

    UPDATE_INFO_PAYMENT_METHOD: 'UPDATE_INFO_PAYMENT_METHOD',
    UPDATE_CUST_FARE: 'UPDATE_CUST_FARE',
    //Fare
    UPDATE_INFO_FARE: 'UPDATE_INFO_FARE',
    UPDATE_INFO_FOP: 'UPDATE_INFO_FOP',
    DELETE_INFO_FARE: 'DELETE_INFO_FARE',

    FOB_TYPES: 'FOB_TYPES',
    UPDATE_TIP_OPTIONS: 'UPDATE_TIP_OPTIONS',

    // trip payment methods
    PAY_WITH_CASH: "PAY_WITH_CASH",
    PAY_WITH_CREDIT: "PAY_WITH_CREDIT",
    PAY_WITH_HOUSE_ACCOUNT: "PAY_WITH_HOUSE_ACCOUNT",
    UPDATE_FOP: "UPDATE_FOP",

    CLEAR_FOP: 'CLEAR_FOP',

    SET_CURRENT_LOCATION_AS_PICK_UP: 'SET_CURRENT_LOCATION_AS_PICK_UP',
    REPLACE_CURRENT_WITH_PICK_UP: 'REPLACE_CURRENT_WITH_PICK_UP',
    INVALID_HOUSE_ACCOUNT: 'INVALID_HOUSE_ACCOUNT',
    UPDATE_CAR_CASH_AMOUNT_FOR_SECOND_TRIP_PAYMENT: 'UPDATE_CAR_CASH_AMOUNT_FOR_SECOND_TRIP_PAYMENT',
    UPDATE_CAR_POOL:'UPDATE_CAR_POOL'

};

export const carmelMethodsConstants = {
    GET_TRIP_DETAILS: 'Trip',
    CAR_LOCATION: 'CarLocationByTrip',
    CUST_ARRIVED: 'CustArrived',
    SAFETY_PING: 'CarLocationByTrip',
    TRIP_CHARGE: 'TripCharge',
    STATE_LIST: 'StateList',
    FULL_FOP_TYPES_LIST: 'fullFopTypeList',
    COUNTRY_LIST: 'CountryList',
    FOP_TYPE_LIST: 'FopTypeList',
    FOP_TYPE_LIST_FIELD: 'fopTypeList',
};

export const carmelMassagesConstants = {
    OK: 'OK',
};
