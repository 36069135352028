import {carmelConstAPILists} from "./carmel.constants";

export const userConstants = {
    USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
    USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
    USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",

    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    CARMEL_FACEBOOK_LOGIN_REQUEST: "CARMEL_FACEBOOK_LOGIN_REQUEST",
    CARMEL_FACEBOOK_LOGIN_SUCCESS: "CARMEL_FACEBOOK_LOGIN_SUCCESS",
    CARMEL_FACEBOOK_LOGIN_FAILURE: "CARMEL_FACEBOOK_LOGIN_FAILURE",

    UPDATE_USER_INFO_REQUEST: "UPDATE_USER_INFO_REQUEST",
    UPDATE_USER_INFO_SUCCESS: "UPDATE_USER_INFO_SUCCESS",
    UPDATE_USER_INFO_FAILURE: "UPDATE_USER_INFO_FAILURE",

    PASSWORD_RECOVERY_REQUEST: "PASSWORD_RECOVERY_REQUEST",
    PASSWORD_RECOVERY_SUCCESS: "PASSWORD_RECOVERY_SUCCESS",
    PASSWORD_RECOVERY_FAILURE: "PASSWORD_RECOVERY_FAILURE",

    USER_BASIC_INFO_REQUEST: "USER_BASIC_INFO_REQUEST",
    USER_BASIC_INFO_SUCCESS: "USER_BASIC_INFO_SUCCESS",
    USER_BASIC_INFO_FAILURE: "USER_BASIC_INFO_FAILURE",

    LOGOUT: "USERS_LOGOUT",
    REGISTRATION_MISSING_INFO: "REGISTRATION_MISSING_INFO",
    CLEAN_REGISTRATION_MISSING_INFO: "CLEAN_REGISTRATION_MISSING_INFO",
    SAVE_IMAGE_FORM_DATA: "SAVE_IMAGE_FORM_DATA",
    UPDATE_ACCEPT_TERMS_AND_POLICY: "UPDATE_ACCEPT_TERMS_AND_POLICY",
    SUBMITTED_REGISTRATION_FORM: "SUBMITTED_REGISTRATION_FORM",

    CHOSEN_TRIP: "CHOSEN_TRIP",
    UPDATE_CHOSEN_TRIP_OPTIONS: "UPDATE_CHOSEN_TRIP_OPTIONS",
    CHOSEN_ADDRESS: "CHOSEN_ADDRESS",

    CLEAR_CHOSEN_ADDRESSES: "CLEAR_CHOSEN_ADDRESSES",

    CAR_CASH_AVAILABLE: "CAR_CASH_AVAILABLE",
    UPDATE_CURRENT_LOCATION: "UPDATE_CURRENT_LOCATION",
    //Carmel perks action
    FIRST_ENTIRE_FLAG: "FIRST_ENTIRE_FLAG",
    CLEAR_AIRPORT_LIST: "CLEAR_AIRPORT_LIST",
    CLEAR_USER_LISTS: "CLEAR_USER_LISTS"
};

export const userConstLists = {
    TRIP_LIST_FUTURE: `${carmelConstAPILists.TRIP_LIST}Future`,
    TRIP_LIST_HISTORY: `${carmelConstAPILists.TRIP_LIST}History`,
    CUST_FOP_LIST: carmelConstAPILists.CUST_FOP_LIST,
    CUST_ADDRESS_LIST: carmelConstAPILists.CUST_ADDRESS_LIST,
    CUST_RECENT_TRIPS: `${carmelConstAPILists.CUST_RECENT_TRIPS}Recent`,
    REWARD_LIST: "rewardList",
    REFERRAL_LIST: "referallList",
    DISCRIMER: "disclaimer",
    CAR_CASH_DOLLAR_LIST: "custCarDollarsList",
    MARKET_LIST: "marketList",
    TOTAL_CASH_AMOUNT: "totalAmount",
    ADD_GUEST_FOP: 'ADD_GUEST_FOP'
};
