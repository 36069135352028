export const googleMapsConstants = {
    AUTOCOMPLETE_REQUEST: 'AUTOCOMPLETE_REQUEST',
    AUTOCOMPLETE_SUCCESS: 'AUTOCOMPLETE_SUCCESS',
    AUTOCOMPLETE_FAILURE: 'AUTOCOMPLETE_FAILURE',

    GET_PLACE_BY_ID_REQUEST: 'GET_PLACE_BY_ID_REQUEST',
    GET_PLACE_BY_ID_SUCCESS: 'GET_PLACE_BY_ID_SUCCESS',
    GET_PLACE_BY_ID_FAILURE: 'GET_PLACE_BY_ID_FAILURE',

    GET_PLACE_BY_REVERSE_GEO_CODING_REQUEST: 'GET_PLACE_BY_REVERSE_GEO_CODING_REQUEST',
    GET_PLACE_BY_REVERSE_GEO_CODING_SUCCESS: 'GET_PLACE_BY_REVERSE_GEO_CODING_SUCCESS',
    GET_PLACE_BY_REVERSE_GEO_CODING_FAILURE: 'GET_PLACE_BY_REVERSE_GEO_CODING_FAILURE',

    GET_TIME_ZONE_REQUEST: 'GET_TIME_ZONE_REQUEST',
    GET_TIME_ZONE_SUCCESS: 'GET_TIME_ZONE_SUCCESS',
    GET_TIME_ZONE_FAILURE: 'GET_TIME_ZONE_FAILURE',

    CLEAR_AUTOCOMPLETE: 'CLEAR_AUTOCOMPLETE',

    OK: "OK",
    ZERO_RESULTS: "ZERO_RESULTS",
};