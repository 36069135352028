export const currencyInputValidators = {
    inputValidator
};
function inputValidator(value) {
    const digitsBeforeDecimal = parseInt(+value);
    const isFieldValid = `${digitsBeforeDecimal}`.length <= 5;
    const message = isFieldValid  ? '' : 'Input should be up to 5 digits before decimal';
    return {
        isFieldValid,
        message
    }
}
