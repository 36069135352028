import {tripConstants} from "../../../constants";


export function Car(state = {}, action) {
    switch (action.type) {
        case tripConstants.UPDATE_CAR:
            return {
                ...state,
                ...action.car
            };
        default:
            return state;
    }
}