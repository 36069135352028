import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ModalWrapper from "../ModalWrapper";
import IntlMessages from "../../../utils/IntlMessages";
import {formsActions} from "../../../store/forms/actions";
import {formsConstants} from "../../../constants/forms.constants";
import {RateDriverButtons} from './RateDriverButtons'
import {DriverMessageBox} from './DriverMessageBox'
import {carmelActions} from "../../../store/carmel/actions";
import Avatar from '@material-ui/core/Avatar';
import avatarDefault from "../../../assets/images/default_avatar_wide.png";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },

    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        margin: '0 auto'
    },
}));
const RateDriver = ({dispatch, modalType, trip, intl}) => {
    const classes = useStyles();
    const [closeModal, setCloseModal] = useState(false);
    if (!trip || !trip.driver)
        return null;

    const {driver, driverRating} = trip;
    const {pictureUrl, nickName} = driver;


    const sendFeedback = (e) => {
        e.preventDefault();
        dispatch(carmelActions.rateDriver(setCloseModal));
    };

    useEffect(() => {
        dispatch(formsActions.updateFieldsValue(
            formsConstants.DRIVER_FEEDBACK_FORM,
            {
                [formsConstants.DRIVER_FEEDBACK]: '',
                [formsConstants.DRIVER_RATING]: 0
            }
        ))
    }, []);
    return (
        <ModalWrapper modalType={modalType} title={'Rate Your Driver'}
                      closeModalIndicator={closeModal}>

            <div className="custom-modal-body modal-full-height-overflow">
                <form id="rate_driver_form" className='rate_driver_form' type="POST"
                      autoComplete="off">
                    <div className="f-row">
                        <div className='full-width text-center'>
                            <Avatar alt="Remy Sharp"  src={pictureUrl ? pictureUrl : avatarDefault}
                                    className={classes.large}/>
                            <h3 className="driver_name fs_small grey-text">
                                {`${nickName}`}
                            </h3>
                        </div>
                    </div>
                    <div className="f-row">
                        <h4>
                            <IntlMessages id='driver-feedback.driver-rate-title'/>
                        </h4>
                        <RateDriverButtons
                            driverRating={driverRating && driverRating.ratingId ? driverRating.ratingId : 0}
                        />
                    </div>
                    <DriverMessageBox/>
                    <div className="f-row">
                        <input type="submit" value={intl.messages['driver-rate.btn']}
                               className="btn color medium full" onClick={sendFeedback}/>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
};

const mapStateToProps = ({user}) => {
    const {trip} = user.lists.trips;
    return {
        trip
    }
};

export default injectIntl(connect(mapStateToProps)(RateDriver));