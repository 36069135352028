import {combineReducers} from 'redux'

import {ui, authentication, settings, forms, user, temp, googleMaps, trip, interactive} from './reducers';


// Reducers
export const rootReducer = combineReducers({
    authentication,
    forms,
    settings,
    ui,
    user,
    temp,
    googleMaps,
    trip,
    interactive
});
