export const brizServices = [
    {
        title: 'Low Rates', description:"Affordable, competitive rates for every budget.", icon: 'savings'
    }, {
        title: 'Live Support', description:"Contact us via email or chat",  icon: 'lockpad'
    }, {
        title: 'Quality Vehicles', description:"Always clean, late model vehicles for every ride",  icon: 'open-wallet'
    }, {
        title: 'Free Cancellation', description:"Full refund for cancellation made at least 2 hours before your scheduled sedan ride",  icon: 'heart'
    }, {
        title: 'Mobile Applications', description:"Download the phone app for on-the-go connection",  icon: 'magic-trick'
    }, {
        title: '24/7 Services', description:"Service available any day, any time!",  icon: 'call'
    }, {
        title: 'No booking Fees', description:"No booking fees - period!",  icon: 'cup'
    }, {
        title: 'Track your Order', description:"Track your order from reservation to receipt",  icon: 'attach'
    }, {
        title: 'Book for now or for later', description:"Easy to book your ride for now, or reserve it for a later date or time",  icon: 'stars'
    },
];