import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'red'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default ({closeModal, title, headerWithX}) => {
    const classes = useStyles();

    return (
        <div className={classes.re}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" style={{
                        margin: '0',
                        padding: '0'
                    }}>
                        {
                            headerWithX ?
                                <CloseIcon onClick={closeModal}/>
                                :
                                <ArrowBackIosIcon onClick={closeModal}/>
                        }

                    </IconButton>
                    <Typography variant="h6" className={classes.title} style={{
                        margin: '0',
                        padding: '0'
                    }}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}