import {uiConstants} from "../../../constants";

const INIT_STATE = {
    display: false,
    text: ''
};

export function spinner(state = INIT_STATE, action) {

    switch (action.type) {
        case uiConstants.DISPLAY_SPINNER:
            return {
                ...state,
                display: true,
                text: action.text
            };

        case uiConstants.HIDE_SPINNER:
            return {
                ...state,
                display: false,
                text: ''
            };


        default:
            return state;
    }
}