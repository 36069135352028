const domain = process.env.REACT_APP_DOMAIN;
export const generalConstants = {
    API_DOMAIN: domain,
    API_ENDPOINT: `${domain}/JobsWebServ/v2_01/Impl`,
    imageUpload: `${domain}/JobsWebServ/v2_01/CustImage`,
    TOKEN_REFRESH_ENDPOINT: `${domain}/JobsWebServ/oauth2/v1/token`,
    LOGIN_REQUIRED: 'LOGIN_REQUIRED',
    SECRET_KEY: 'af3F2ZsEseZ3wp812ACWe/CEUigKwYuTH22MVaCMuro=',

    // CARMEL_API_KEY: 'db796f5b4de616d5598e18cb42d50c5585016d1733831b9cb2fdc1d1af042727',  // show my car key
    // CARMEL_API_KEY: '702360eb0153881cbf4ca1a88696c699cec875de88032ca3055f1e19c5fdd998', // carmel mobile key
    CARMEL_API_KEY: '4d0564aacce274915387b2d0ade84f8376e9eb82619eac39ac521529b67a8db8', // briz key

    //GOOGLE
    // GOOGLE_API_KEY: 'AIzaSyDBmGEIuP5mG7SVej-mjVCRgBpFuHSFAAg', // Amir Demo API KEY
    // GOOGLE_API_KEY: 'AIzaSyDTfAsW6GjUFomlEGfZPZvi8U91pVNHxYg', // Carmel BRIZ API KEY
    // GOOGLE_API_KEY: 'AIzaSyAxZR1cVUP4WGUGwkBOvd3Y5uKMo5Rinxw', // Carmel BRIZ API KEY
    GOOGLE_API_KEY: 'AIzaSyCm3avZTPHRK2OeXMeiHpcM2Rjsb6oCCTw', // GOOGLE API NEW FROM DOR
    // GOOGLE_API_KEY: 'AIzaSyAxZR1cVUP4WGUGwkBOvd3Y5uKMo5Rinxw', // GOOGLE API open for all
    // GOOGLE_API_KEY: 'AIzaSyCWFYGM7Qp-evx2R5V7XGwPwpcnirMYyHU', // GOOGLE API open for all
    // GOOGLE_API_KEY: 'AIzaSyCxkhs1aBlQF2DpxtjjmvIFTbDwpDAp8UU', // Bonimoo API Key for dev
    GOOGLE_MAIN_URL: "https://maps.googleapis.com/maps/api/",
    GOOGLE_DIRECTION_URL: `directions/`,
    GOOGLE_PREDICTIONS_URL: "place/autocomplete/",
    GOOGLE_PLACE_ID: "place/details/",
    GOOGLE_TIME_ZONE: "timezone/",
    GOOGLE_REVERSE_GEO_CODING: "geocode/",
    //GOOGLE

    TERMS_AND_CONDITIONS_URL: "https://docs.google.com/viewer?url=http://beta.tech.us/projects/web/carmelmobilewebapp/design/CarmelPoints.pdf&embedded=true",
    CORE_CREDENTIALS_INIT: 'CORE_CREDENTIALS_INIT',
    CORE_CREDENTIALS_UPDATE: 'CORE_CREDENTIALS_UPDATE',
    CREDENTIALS_UPDATE: 'CREDENTIALS_UPDATE',

    UPDATE_CURRENT_LOCATION: 'UPDATE_CURRENT_LOCATION',
    ASK_FOR_LOCATION_PERMISSION: 'ASK_FOR_LOCATION_PERMISSION',
    UPDATE_LOCATION_PERMISSION: 'UPDATE_LOCATION_PERMISSION',
    LOCAL_STORAGE_SAVED: 'LOCAL_STORAGE_SAVED',

    // Actions
    GO_BACK_TO_HOME: 'GO_BACK_TO_HOME',
    SAVE_PERK_DETAILS: 'SAVE_PERK_DETAILS',
    CLEAR_PERK_DETAILS: 'CLEAR_PERK_DETAILS',

    // DebugArgs
    OPEN_DEBUG_ARGS_POP_UP: 'OPEN_DEBUG_ARGS_POP_UP',
    CLOSE_DEBUG_ARGS_POP_UP: 'CLOSE_DEBUG_ARGS_POP_UP',
    SET_DEBUG_ARGS: 'SET_DEBUG_ARGS',

    // credentials
    CREDENTIALS_INIT: 'CREDENTIALS_INIT',
    RESET_CREDENTIALS: 'RESET_CREDENTIALS',

    USE_CAR_CASH: 'USE_CAR_CASH',
    SAVE_REQUEST_TO_HISTORY: 'SAVE_REQUEST_TO_HISTORY',
    REQUEST_ID_ON_HOLD: "REQUEST_ID_ON_HOLD",
    CLEAR_REQUEST_ID_ON_HOLD: "CLEAR_REQUEST_ID_ON_HOLD",
    RESEND_FUNC: 'RESEND_FUNC',
    FACEBOOK_CREDENTIALS: 'FACEBOOK_CREDENTIALS',
    CURRENT_CREDIT_CARD: 'CURRENT_CREDIT_CARD',
    FAQ_URL_LINK: "https://www.carmellimo.com/policies/" + 'en' + "/AppFAQ.html",
    PRIVACY_POLICY_URL: "https://www.carmellimo.com/policies/" + 'en' + "/privacy.html",
    TERMS_N_CONDITIONS_URL: "https://www.carmellimo.com/policies/" + 'en' + "/AppTandC.pdf",
    CAR_CASH_LINK: "https://www.carmellimo.com/policies/" + 'en' + "/carcash.html"
};
