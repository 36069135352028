import React, {useState} from 'react';
import defaultFlag from '../../assets/images/flag_default@2x.png'
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default ({list, onCountryPick}) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([1]);

    const [imageStatus, setImageStatus] = useState("loading");

    const handleImageLoaded = () => {
        setImageStatus("loaded")
    };

    const handleImageErrored = () => {
        setImageStatus("failed to load")
    };

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <List dense className={classes.root} style={{
            maxWidth: '100%',
            padding: '0', overFlow: 'auto'
        }}>
            {list.map((country, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                if (country.code && country.name) {
                    return (
                        <ListItem key={index} button onClick={() => onCountryPick(country)}>
                            <ListItemAvatar>
                                <Avatar
                                    src={imageStatus === 'loading' ? defaultFlag : country.flagUrl ? country.flagUrl : defaultFlag}
                                    onLoad={handleImageLoaded}
                                    onError={handleImageErrored}
                                />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={country.name}/>
                            <ListItemSecondaryAction>
                                {country.code}
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                } else {
                    return (
                        <ListItem button style={{
                            marginTop: '4px',
                            marginBottom: '4px',
                            backgroundColor: "#ed6f14",
                            color: 'white',
                            opacity: '1'
                        }} disabled={true}>
                            <ListItemText primary={country.name} style={{fontSize: '18px'}}/>
                        </ListItem>
                    )
                }
            })}
        </List>
    );
}