import React from 'react'
import {connect} from "react-redux";
import FooterNav from "./FooterNav";

const Footer = () => {
    return (
        <footer className="footer black" role="contentinfo">
            <div className="wrap">
                <div className="row">
                    <article className="one-half">
                        <h6>About us</h6>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                            tincidunt ut
                            laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
                            exerci tation
                            nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit
                            amet,
                            consectetuer adipiscing elit, sed diam nonummy.</p>
                    </article>

                    <article className="one-fourth">
                        <h6>Need help?</h6>
                        <p>Contact us via chat or email:</p>
                        <p className="contact-data"><span className="icon icon-themeenergy_chat"/>
                            <a href=""> Chat </a>
                        </p>
                        <p className="contact-data"><span className="icon icon-themeenergy_mail-2"/> <a
                            href="mailto:service@briz.com">service@briz.com</a></p>
                    </article>

                    <article className="one-fourth">
                        <h6>Follow us</h6>
                        <ul className="social">
                            <li><a href="#" title="facebook"><i className="fa fa-fw fa-facebook"/></a></li>
                            <li><a href="#" title="twitter"><i className="fa fa-fw fa-twitter"/></a></li>
                            <li><a href="#" title="linkedin"><i className="fa fa-fw fa-linkedin"/></a></li>
                            <li><a href="#" title="vimeo"><i className="fa fa-fw fa-vimeo"/></a></li>
                        </ul>
                    </article>
                </div>

                <div className="copy">
                    <p>Copyright 2019, Briz Services. All rights reserved. </p>

                    <FooterNav/>
                </div>
            </div>
        </footer>
    )
};


const mapStateToProps = ({}) => {
    return {};
};

export default React.memo(connect(mapStateToProps)(Footer));
