export const userActionsConstants = {
    START_ACTION_REQUIRED: "START_ACTION_REQUIRED",
    END_ACTION_REQUIRED: "END_ACTION_REQUIRED",
    SELECT_ACTION_OPTION: 'SELECT_ACTION_OPTION',

    UPDATE_USER_PHONE: "UPDATE_USER_PHONE",
    UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",

    PHONE: "PHONE",
    EMAIL: "EMAIL",
    USER_ACTION: "USER_ACTION",
    FACEBOOK_LOGIN: "FACEBOOK_LOGIN"
};