import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/es/integration/react'
import {persistStore} from "redux-persist"
import {Router, Route, Switch} from 'react-router-dom';



import {store} from './store';
import {history} from "./helpers";
import App from 'containers/App';
import {generalActions} from "./store/general/actions";



if (
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_NODE_ENV === "stage"
) {
    window.addEventListener("keydown", event => {
        if (event.keyCode === 221 && event.ctrlKey) {
            store.dispatch(generalActions.openDebugArgsPopup());
        }
    });
}


const persistor = persistStore(store);
const MainApp = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router history={history}>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>
);

export default MainApp;
