import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {Link} from "react-router-dom";
import avatarDefault from '../../assets/images/default_avatar_wide.png'
import {urls} from "../../constants";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const ProfilePicture = ({pictureUrl, firstName, lastName}) => {
    const classes = useStyles();
    const [windowVw, setWindowVw] = useState(window.innerWidth);


    function windowResize() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        setWindowVw(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", windowResize);
    }, []);
    return (
        <li style={windowVw > 1050 ? {paddingTop: '22px'} : {}}>
            <Link to={`/${urls.ACCOUNT}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                <div style={{marginRight: '5px'}}>
                    {`${firstName} ${lastName}`}
                </div>
                <Avatar alt="Remy Sharp" src={pictureUrl ? pictureUrl : avatarDefault} className={classes.small}/>
            </Link>
        </li>

    );
};

const mapStateToProps = ({user}) => {
    const {pictureUrl, firstName, lastName} = user.Cust;
    return {
        pictureUrl,
        firstName,
        lastName
    };
};

export default React.memo(connect(mapStateToProps)(ProfilePicture));