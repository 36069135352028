export const facebookConstants = {
    FACEBOOK_LOGIN_REQUEST: "FACEBOOK_LOGIN_REQUEST",
    FACEBOOK_LOGIN_SUCCESS: "FACEBOOK_LOGIN_SUCCESS",
    FACEBOOK_LOGIN_FAILURE: "FACEBOOK_LOGIN_FAILURE",

    FACEBOOK_INFORMATION_REQUEST: "FACEBOOK_INFORMATION_REQUEST",
    FACEBOOK_INFORMATION_SUCCESS: "FACEBOOK_INFORMATION_SUCCESS",
    FACEBOOK_INFORMATION_FAILURE: "FACEBOOK_INFORMATION_FAILURE",

    SDK_LOADED: "FACEBOOK_SDK_LOADED",
};
