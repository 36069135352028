import {interactiveConstants} from "../../../constants";


export function loaders(state = {}, action) {
    switch (action.type) {
        case interactiveConstants.CUST_ARRIVED_REQUEST:
            return {
                CustArrivedLoader: true
            };
        case interactiveConstants.CUST_ARRIVED_SUCCESS:
            return {
                CustArrivedLoader: false
            };
        case interactiveConstants.CUST_ARRIVED_FAILURE:
            return {
                CustArrivedLoader: false
            };
        default:
            return state;
    }
}