import {userConstants, userConstLists} from "../../../../constants";


export function addresses(state = [], action) {
    switch(action.type) {
        case userConstants.USER_BASIC_INFO_SUCCESS:
            return  {
                ...state,
                [userConstLists.CUST_ADDRESS_LIST]:action.results[userConstLists.CUST_ADDRESS_LIST]
            };
        case userConstants.CLEAR_USER_LISTS:
            return  {
                ...state,
                [userConstLists.CUST_ADDRESS_LIST]:{}
            };
        default:
            return state;
    }
}