/* Bonimoo Language Texts

Table of Contents

01.General


*/

module.exports = {
    /* 01.General */
    "general.copyright": "© Bonimoo 2019. All rights Reserved",

    // Buttons
    "trip-details.show-my-car.button": "SHOW MY CAR",
    "trip-details.trip-status.noshow": "NoShow",
    "trip-details.trip-status.canceled": "Canceled",
    "trip-details.trip-status.completed": "Completed",
    "trip-details.extra-charge.button": "ADD TIP & OTHERS",
    "trip-details.pre-pay-trip.button": "PAY FOR RIDE",
    "trip-details.pickup-instruction.button": "PICKUP INSTRUCTIONS",
    "trip-details.cust-arrived.button": "GOT MY LUGGAGE & READY TO GO",
    "show-my-car.safety-ping-title.button": "Safety",
    "show-my-car.safety-ping-action.button": "Ping",
    "show-my-car.call-driver-call.button": "Call",
    "show-my-car.call-driver-driver.button": "Driver",
    "briz-btn.call-now": "Call Driver",
    "trip-payment.submit-payment.button": "PAY",
    "unwrapped-google-play": "Google Play",
    "unwrapped-app-store": "App Store",

    // Top titles
    "show-my-car.title.pickup-instructions": "Pickup Instructions",
    "show-my-car.title.show-my-car": "Show My Car",
    "terminal-select.title.terminal-select": "Set Pickup Terminal",
    "terminal-select.subtitle.terminal-select":
        "Dear Carmel Customer,\nPlease select your pickup terminal from the following list.",
    "trip-details.expedia.title":
        "Carmel Car & Limo Service (via Expedia) Trip Details",
    "trip-details.viator.title":
        "Carmel Car & Limo Service (via Viator) Trip Details",
    "trip-details.carmel.title": "Carmel Car & Limo Service Trip Details",
    "trip-details.default.title": "Carmel Car & Limo Service Trip Details",
    "safety-ping.title": "Safety Ping",
    "terminals.title": "Set Pickup Terminal",
    "rateDriver.title": "Rate Your Driver",
    "profile.title": "Personal Information",
    "referral.title": "Use Referral Code",
    "rates.title": "Rates",
    "our-affiliated-fleet.title": "Our Affiliated Fleet",
    "specials.title": "Specials",
    "group-events.title": "Special Group Events",
    "trip-payment.extra-charge.title": "Tip, Toll & Others",
    "trip-payment.prepay-trip.title": "Pay For Ride",
    "about-us.title": "About Us",

    "trip-details.pickup.label": "Pick Up",
    "trip-details.dropoff.label": "Drop Off",
    "trip-details.date-and-time.label": "Date and time",
    "trip-details.trip-id.title": "Trip No.",
    "trip-details.car-passengers": "Passengers",
    "trip-details.car-luggage": "Luggage",
    "show-my-car.call-us": "CALL NOW",

    "show-my-car.car-details.car": "Car",
    "show-my-car.car-details.company": "Company",
    "safety-ping.greetings": "Have a safe trip",
    "safety-ping.alert.message.strong": "For Safety",
    "safety-ping.alert.message": " - Ping the driver before entering the car",

    // Error Page:
    "layouts.error-title": "Oooops... No data to show",
    "layouts.error-code": "404",
    "layouts.error-description": "No trip signature specified",
    "layouts.error-instructions":
        "Ask your provider for the full URL to get the information you want",

    // unplugged screen
    // fearure list
    "unpllugged.app-info-1-title": "New York City, NY Airport Limousine Service",
    "unpllugged-app-info-1":
        "Carmel Car & Limo delivers the best New York Limousine service. Whether \
        it is an airport transfer or a unique tour of New York City we stand \
        behind our commitment as customer's favorite New York Car & Limo service \
        company. Whether it is your vacation or business travel, Carmel is the \
        way to arrive or depart in style among NYC Airport Limousines without \
        breaking your wallet. In addition, Carmel provides airport transfers for \
        you in more than 350 of world's top cities.",
    "unpllugged-app-info-1-2":
        "Our best of the industry New York Limo service with a affiliated fleet \
        of over 800 late model Sedans, Minivans, Stretch Limousines, SUVs, and \
        SUV super-stretch limos, Large Passenger Vans, offers transportation\
        free of worries. Since 1978, our customer friendly service and our\
        record of dependability make Carmel Car & Limousine your best pick for\
        New York Limousine service. With our unparalleled dedication to our\
        customers, we have succeeded in providing the kind of service we believe\
        you deserve in New York City and around the world- simple, smooth, and\
        affordable..",
    "unpllugged-app-info-1-3":
        "Due to the fact that our record of success is based on delivering\
      first-rate New York car & limo service at affordable rates, Carmel has\
      become the most popular of New York Limousines and its popularity is\
      rapidly growing all over the world.",
    "unpllugged-app-info-1-4":
        "Just sit back and relax while our well trained affiliated chauffeur\
      drives you to your destination in safe and timely manner.",
    "unpllugged.app-info-2-title": "Worldwide Limousine Service",
    "unpllugged-app-info-2":
        "Now you can book premium car or limousine in 350 major cities\
      worldwide. Consistent reliability and personalized attention makes\
      Carmel your best choice for your car & limousine service around the\
      world. Working with special affiliated providers all over world you\
      can find the largest selection of sedans and luxury limousines that\
      are comfortable, clean and well equipped. Our well experienced\
      affiliated drivers are dedicated to driving you in a safe and on time\
      24 hours a day, 7 days a week.",
    "unpllugged-app-info-2-1":
        "As of 2009, Carmel Car and Limousine Service proudly holds the title\
      of being the largest limousine service in the world. Be it airport\
      transfer or special occasion no one gives you better price. Grab your\
      booking right now and avoid sell-out. We'll Be There For You! ",
    "unpllugged-app-info-3-title": " Reliable New York Limousine service",
    "unpllugged-app-info-3":
        "For more than three decades, Carmel Limo New York has been a synonym\
      for Limousine New York, or Limo NY for short. Carmel is still the\
      company of choice when looking for a reliable, experienced New York\
      Limousine service. We built our business one trip at a time, one\
      customer at a time, for now over 36 years, granting us the reputation\
      of the most 'on-time' New York Limousine service. Through the years we\
      developed in two directions. First, we expanded our services, bringing\
      our New York Limousine know-how to all 360 locations we service\
      worldwide. Customers around the world often comment how riding in a\
      Carmel vehicle overseas feels like being in a New York Limo. Second,\
      our worldwide service made us experts in airport transfer.",
    "unpllugged-app-info-3-1":
        "Carmel holds partnerships with more airlines and travel related\
      websites, than any other transportation company in the world. In\
      summary, our story starts with being a small NYC Limousine company,\
      through becoming the New York Limousine Service of choice, sharpening\
      our airport transfer skill to be recognized by all major airlines as\
      the New York Airport Service to partner with, and armed with all of\
      the experience, expand our services worldwide.",
    "unpllugged-app-info-3-2":
        "We wanted to thank our loyal customers for making all this possible,\
        and if you are a first time visitor to our site, we welcome you and\
        hope you will try our service and become a Carmel customer. Nothing\
        beats a Limo New York NY style experience, and no-one does Limos New\
        York like Carmel. So, when you think Limousines New York, think\
        Carmel, everybody else does And, if apps are your 'thing', please look\
        for the Carmel easy to use app, available on ",
    "unwrapped-for-android": "(for Android) and on the",
    "unwrapped-for-i-phone": "(for iPhone).",
    "unwrapped.open-new-window": "Opens a New Window.",
    // Thank You Landing Page
    "thank-you-expedia.title": "Thank you for using Carmel through Expedia.",
    "thank-you-viator.title": "Thank you for using Carmel through Viator.",
    "thank-you-carmel.title": "Thank you for using Carmel.",
    "thank-you-default.title": "Thank you for using Carmel!",
    "thank-you-default.booking-number": "Your booking number is",
    "thank-you-default.btn-book": "Book Another Ride",
    "thank-you-default.btn-trips": "My trips",
    "thank-you-default.btn-post-calendar": "Post to my calendar",
    "thank-you-default.btn-always-post-calendar":
        "Always post your trips in calendar",
    "create-another-trip.carmel.link.title": "Book Another Carmel trip",
    "create-another-trip.carmel.button.title": "Book Another",

    // Location popup
    "ask-location.popup.title": "Location Permission",
    "ask-location.popup.message":
        "This web site use your location to provide its all of its features.",
    "ask-location.popup.button.allow": "OK",
    "ask-location.popup.button.decline": "Decline",

    // Popup General
    "popup.action.button": "OK",
    "popup.action.button-yes": "YES",
    "popup.action.button-no": "NO",
    "popup.action.button-close": "CLOSE",

    // Trip Payment
    "trip-payment.trip.fare.trip-num": "Trip No.",
    "trip-payment.trip.fare.base-fare": "Base Fare",
    "trip-payment.trip.fare.base-fare.already-paid": " (Already Paid)",
    "trip-payment.payment-method.pre-pay.base.trip-update": "BASE FARE",
    "trip-payment.payment-method.pre-pay.all.trip-update": "ALL INCLUSIVE",
    "trip-payment.payment-method.pre-pay.base": "Pay Base Fare only",
    "trip-payment.payment-method.pre-pay.all": "Pay All Inclusive",
    "trip-payment.tip-and-tolls.tip.title": "Tip - Please select tip percentage",
    "trip-payment.tip-and-tolls.tolls.title": "Tolls:",
    "trip-payment.tip-and-tolls.fees.title": "Fees:",
    "trip-payment.tip-and-tolls.tip.input.other": "Other:",
    "trip-payment.tip-and-tolls.tip.input.other.placeholder": "Tip Amount",
    "trip-payment.extra-charge.tolls.title":
        "Enter tolls amount (if any) - Please show it to the driver",
    "trip-payment.extra-charge.misc.title": "Enter misc amount if applicable",

    // Credit card form
    "credit-card-form.title": "CREDIT CARD DETAILS",
    "credit-card-form.header.notice":
        "Your credit card won't be charged until you book your trip",
    "credit-card-form.expiration-date.title": "EXPIRATION DATE",
    "credit-card-form.billing-address.title": "BILLING ADDRESS",
    "credit-card.card-number.placeholder": "CREDIT CARD NUMBER",
    "credit-card.holder-name.placeholder": "HOLDER NAME",
    "credit-card.month.placeholder": "MONTH",
    "credit-card.year.placeholder": "YEAR",
    "credit-card.cvv.placeholder": "CVV",

    // Trip Creation

    "trip-creation.payment.button.credit-card": "CREDIT CARD",
    "trip-creation.payment.button.pay-cash": "PAY IN CAR",
    "trip-creation.payment.pay-cash": "You can pay with cash in the car",
    "trip-creation.payment.button.cash": "CASH",
    "trip-creation.payment.button.swipe": "SWIPE CARD IN THE CAR",
    "trip-creation.payment.swipe-description":
        "You can swipe your credit card in the car",
    "trip-creation.payment.button.house-account": "HOUSE ACCOUNT",
    "trip-creation.payment.button.cash-or-swipe": "CASH OR SWIPE CARD IN CAR",
    "trip-creation.payment.button.pay-with-account": "PAY WITH ACCOUNT",
    "trip-creation.payment.button.pay-with-account-or-credit-card":
        "You can pay with cash, or use your credit card in the car",
    "car-book.change-credit-card-btn": "CHANGE CARD",
    "car-book.change-car-or-swipe-btn": "CASH OR SWIPE CARD IN THE CAR",

    // trips display page
    "my-trips.navbar.future-trips": "FUTURE TRIPS",
    "my-trips.navbar.past-trips": "PAST TRIPS",
    "my-trips.trips-no-trips-future": "No future trips",
    "my-trips.trips-no-trips-past": "No past trips",
    "my-trips.trips.trip-completed": "Completed",
    "my-trips.trips.trip-canceled": "CANCELED",
    "my-trips.trips.trip-btn-show-car": "SHOW MY CAR",
    "my-trips.trips.trip-btn-add-tip": "ADD TIP & OTHERS",
    "my-trips.trips.trip-btn-cancel": "CANCEL TRIP",
    "my-trips.trips.trip-btn-instructions": "PICK UP INSTRUCTIONS",
    "my-trips.trips.trip-btn-instructions-thank-you": "INSTRUCTIONS",
    "my-trips.trips.trip-btn-ready-to-go": "READY TO GO",
    "my-trips.trips.trip-btn-ready-to-go-luggage": "GOT MY LUGGAGE & READY TO GO",
    "my-trips.trips.trip-btn-driver-rating": "RATE DRIVER",
    "my-trips.trips.trip-btn-prepay": "PAY FOR RIDE",

    //    trips display modal
    "trips-modal.trip-display.trip-no": "Trip No.",
    "trips-modal.after-pay":
        "After paying the ride using cash or credit card, your receipt will be emailed to you.",
    "trips-modal.save-to-favorite": "SAVE AS FAVORITE",
    "trips-modal.basic-desc-people": "People",
    "trips-modal.basic-desc-luggage": "Luggage",
    "trip-details.driver-feedback.button": "PROVIDE TRIP FEEDBACK",

    //save to favorite modal
    "save-to-favorite-modal.title-address": "SAVE ADDRESS TO FAVORITE LIST",
    "save-to-favorite-modal.title-address-edit": "EDIT FAVORITE ADDRESS",
    "save-to-favorite-modal.title-trip": "SAVE TRIP TO FAVORITE LIST",
    "save-to-favorite-modal.title-trip-edit": "EDIT FAVORITE TRIP",
    "save-to-favorite-modal.btn-save": "Save",
    "save-to-favorite-modal.btn-cancel": "Cancel",
    "save-to-favorite-modal.nickname-will-represent":
        "This nickname will be presented whenever you use this address - You can also save this address without a nickname",

    // air line  modal
    "airline-modal.airline-list.select": "Click to Select",
    "airline-modal.airline-list.not-found": "Airports not found",
    "airline-modal.airline-list.try-again": "Try to search again",

    // unwrapped screen
    "unwrapped-screen-title": "Quality service at unbeatable prices",
    "unwrapped-screen-get-started": "Get Started",
    "unwrapped-screen.feature-display.quote":
        "Get a quote, Book for now or Book for later",
    "unwrapped-screen.buttons.register": "Sign Up",
    "unwrapped-screen.buttons.login": "Sign In",
    "unwrapped-screen.buttons.facebook-login": "Login",
    "unwrapped-screen.feature-display.earn-air-mirles": "Earn Airline Miles",
    "unwrapped-screen.feature-display.earn-carnel-points": "Earn Carmel Points",
    "unwrapped-screen.feature-display.enjoy-perks": "Enjoy Carmel Perks",
    "unwrapped-screen.feature-display.new": "NEW",

    //sign in modal
    //sign up modal
    "signin-modal.title": "Sign In",
    "signin-modal.confirm-text": "By signing in, you agree to these",
    "signin-modal.terms-and-services": "Terms of Service",
    "sign-in.trouble-sign-in": "Trouble Signing In?",
    "sign-in.email-us": "EMAIL US",
    "sign-in.call-us": " CALL US",
    "signup-modal-signup-btn": "Sign Up",
    "signup-modal-change-image": "Change Image",
    "signup-modal-primary-mobile": "PRIMARY MOBILE",
    "signup-modal-ice-mobile": "ICE MOBILE (In case of emergency - optional)",
    "signup-modal-terms": "By signing up, you agree to these",
    "signup-modal-terms-and-services": "Terms of Service",
    "signup-modal-primary-password": "ACCOUNT PASSWORD",
    "signup-form.password-recovery-instructions":
        "( Password must be at least 8 characters long and contain at least one lowercase, one uppercase, one digit. )",

    //forgot password modal
    "forgot-password.description":
        "We will send you an email to this address - Please make sure to  provide the correct email account.",
    "forgot-password.title": "PASSWORD RECOVERY",
    "forgot-password.btn": "Recover",
    "forgot-password.btn-recover-by-mail": "Recover by email",
    "forgot-password.cancel": "Cancel",
    "forgot-password.close": "Close",

    //sidebar
    "sidebar-items.home": "Home",
    "sidebar-items.my-trips": "My trips",
    "sidebar-items.payment-info": "Payment Information",
    "sidebar-items.perks": "Carmel Perks",
    "sidebar-items.referral": "Use Referral Code",
    "sidebar-items.car-cash": "Car Cash",
    "sidebar-items.points": "Carmel Points",
    "sidebar-items.reward": "Reward Program",
    "sidebar-items.faq": "FAQ",
    "sidebar-items.about": "About",
    "sidebar-items.general-info.rates": "Rates",
    "sidebar-items.general-info.our-fleet": "Our Affiliated Fleet",
    "sidebar-items.general-info.specials": "Specials",
    "sidebar-items.general-info.group-events": "Special Group Events",

    //pay for ride component
    "pay-for-ride.add-new-credit": "Add New Credit Card",
    "pay-for-ride.use-carmel-cash": "Use Carmel Car Cash:",

    //main header
    "main-header.my-trips": "My Trips",
    "pay-for-ride.header": "Pay For Ride",
    "tip-and-tolls.header": "Tip, Toll & Others",
    "pay-for-ride.show-my-ride": "Show Me My Car",
    "pay-for-ride.pickup-instructions": "Pickup Instructions",
    "payment-header.payment-info": "Payments Information",
    "payment-header.change-payment-info": "Select/Add Credit Card",
    "reward-header": "Reward Program",
    "carmel-cash-header": "My Car Cash",
    "perks-header": "Carmel Perks",
    "faq-header": "Frequently Asked Questions",
    "about-header": "About Us",
    "points-header": "Carmel Points",
    "spread-header": "My Car Cash",
    "main-header.vehicles-rates-payment": "Vehicles, Rates & Payment",
    "credit-card-modal.credit-card-details": "CREDIT CARD DETAILS",
    "credit-card-modal.add-credit": "Add Credit Card",
    "credit-card-modal.edit-credit": "Edit Credit Card",
    "credit-card-modal.credit-card-wont-charge":
        "Your credit card won't be charged until you book your trip",
    "credit-card-modal.credit-card-save": "Save Card",
    "credit-card-modal.credit-card-prepay.title": "AUTO CHARGE",
    "credit-card-modal.credit-card-prepay.all-inclusive.percentage-title":
        "Please select default tip percentage",
    "credit-card-modal.credit-card-prepay.base-prepay": "BASE FARE",
    "credit-card-modal.credit-card-prepay.all-inclusive-prepay": "ALL INCLUSIVE",
    "credit-card-modal.credit-card-prepay.all-inclusive-prepay-description":
        "I wish to pay the base fare + tolls + fees(if any) & gratuities percentage of:",
    "credit-card-modal.credit-card-prepay.description":
        "I will pay tolls, gratuities & fees at the end of the ride . credit-card-modal.credit-card-prepay.description: The Auto-charge feature allows you to book your reservations without having to key in your payment information every time you reserve a vehicle.",

    // payments page
    "payments.add-credit-card": "+ ADD CREDIT CARD",
    "payments.edit-credit-card": "Edit",
    "payments.remove-credit-card": "Remove",

    //carmel reward page
    "carmel-reward.btn-redeem": "Redeem Point",
    "carmel-reward.btn-remove": "Remove Program",
    "carmel-reward.btn-change": "Change Program",
    "carmel-reward.carmel-points": "Carmel Points:",
    // carmel cash page
    "carmel-cash.top.total-car-cash": "Total Car Cash",
    "carmel-cash.top.total-car-cash-title": "CARMEL $6X6 CAR CASH",
    "carmel-cash.bottom.btn-open": "Request Car Cash",
    "carmel-cash.bottom.btn-close": "Close Request List",

    // driver rating page
    "driver-rate.btn": "SEND",

    //booking page
    "booking.fields-title-pickup": "PICK-UP FROM",
    "booking.fields-title-dropOff": "DROP-OFF AT",
    "booking.fields-title-clock": "PICK UP DATE & TIME ",
    "booking.fields-go-btn": "GO",

    //search address modal
    "search-address.modal.search-title": "Type address and start to Carmel it!",
    "search-address.modal.search-title-fail": "Sorry, we didn't find a match",
    "search-address.modal.search-result": "Search Results",
    "popup.title": "Attention",
    // select airport from list modal
    "select-airport-modal.title-drop": "Select Airport for Drop-off",
    "select-airport-modal.message-drop":
        "Currently we are only providing service to/from an airport in the selected drop-off location. Local service will be added shortly.",
    "select-airport-modal.message-pick":
        "Currently we are only providing service to/from an airport in the selected pick-up location. Local service will be added shortly.",
    "select-airport-modal.title": "Select Airport",

    // car book page
    "car-book-select-car": "SELECT A CAR",
    "car-book.select-payment": "Select Payment Method",
    "car-book.car-not-availble": "Not available for the time requested",

    //profile-page
    "profile-page.title": "",
    //profile-page password recovery
    "profile-page-password-recovery.header": "Personal Information",

    //referral page
    "referral-page.input-referral-code.placeholder": "Enter Referral Code",
    "referral-page.submit-btn-text": "Submit Code",

    //rates page
    "general-info.rates-page.title": "Rates",

    //MARK: - Loaders
    "spinner.sending_Loader": "Sending..",
    "spinner.just_A_Moment_Loader": "Just a Moment..",
    "spinner.getting_First_Loader": "Getting First Time Info..",
    "spinner.sending_Your_Regards_Loader": "Sending Your Regards..",
    "spinner.removing_Prog_Loader": "Removing Reward Program..",
    "spinner.changing_Prog_Loader": "Changing Reward Program..",
    "spinner.performing_Loader": "Performing..",
    "spinner.change_Pass_Loader": "Changing your password..",
    "spinner.updating_Your_Info_Loader": "Updating Your Info..",
    "spinner.requesting_Car_Cash_Loader": "Requesting Car Cash..",
    "spinner.adding_Card_Loader": "Adding Card..",
    "spinner.saving_Card_Loader": "Saving Card..",
    "spinner.downloading_Content_Loader": "Downloading Content..",
    "spinner.reserving_Your_Car_Loader": "Reserving your car..",
    "spinner.getting_Insturctions_Loader": "Getting Instructions..",
    "spinner.looking_For_Your_Car_Loader": "Looking for your car..",
    "spinner.updating_Info_Loader": "Updating Info..",
    "spinner.loading_Trip_Info_Loader": "Loading Trip Info..",
    "spinner.checking_Policy_Loader": "Checking Policy..",
    "spinner.loading_Payment_Loader": "Loading Payment Info..",
    "spinner.checking_Trip_Loader": "Checking Trip Info..",
    "spinner.cancel_Trip_Loader": "Canceling Trip..",
    "spinner.saving_Loader": "Saving..",
    "spinner.paying_Tip_&_Toll_Loader": "Paying Your Tip & Tolls..",
    "spinner.paying_Trip_Loader": "Paying Your Trip..",
    "spinner.sending_Your_Rating": "Sending your rating..",
    "spinner.retrying": "Retrying..",
    "spinner.removing_card": "Removing Card..",
    "spinner.loading_Just_a_Moment_loader": "Loading, Just a Moment..",

    //driver feedback
    "driver-feedback.text-box-title": 'What did you like or dislike?',
    "driver-feedback.driver-rate-title": 'How was your experience?',

    'field-required-message': 'Field is required'
};
