import {carmelConstantsActions, userConstLists} from "../../../../constants";

export function carCash(state = {}, action) {
    switch (action.type) {
        case carmelConstantsActions.CAR_CASH_SUCCESS:
            return {
                ...state,
                [userConstLists.CAR_CASH_DOLLAR_LIST]: action.payload[userConstLists.CAR_CASH_DOLLAR_LIST],
                [userConstLists.TOTAL_CASH_AMOUNT]: action.payload[userConstLists.TOTAL_CASH_AMOUNT],
                [userConstLists.MARKET_LIST]: action.payload[userConstLists.MARKET_LIST],
            };
        case carmelConstantsActions.CAR_CASH_FAILURE:
            return {
                ...state,
                error:action.error
            };
        default:
            return state;
    }
}