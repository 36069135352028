import React, {Component} from 'react';
import {connect} from 'react-redux'
import {injectIntl} from "react-intl";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import {generalActions} from "../store/actions";


class DebugArgsPopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: ''
        }
    }

    closePopUp = (e) => {
        e.preventDefault();
        this.props.dispatch(generalActions.closeDebugArgsPopup())
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.dispatch(generalActions.setDebugArgs(this.state.input));
        this.props.dispatch(generalActions.closeDebugArgsPopup());
    };

    handleChange = (event, fieldName) => {
        this.setState({
            [fieldName]: event.target.value
        })
    };

    render() {
        const {popup} = this.props;

        return (
            <Modal isOpen={popup.debugArgs} className='briz-pop-up' style={{
                zIndex: '100000000',
                position: 'fixed',
                width: '100%',
                top: '30%'
            }}>
                <div className='modal content one-half'>
                    <div className={'f-row'}>
                        <h1 style={{color: 'white'}}>Debug Args</h1>
                    </div>
                    <div className={'f-row'}>
                        <div className='full-width'>
                            <Input value={this.state.input} onChange={e => this.handleChange(e, 'input')}
                                   type={"text"}/>
                        </div>
                    </div>
                    <div className={'f-row'}>
                        <div className='one-half'>
                            <input value={'Cancel'} type={'submit'} className={'btn red full medium'} color={'danger'}
                                   onClick={this.closePopUp}/>
                        </div>
                        <div className='one-half'>
                            <input value={'SET'} type={'submit'} className={'btn color full medium'} color={'success'}
                                   onClick={this.handleSubmit}/>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({ui}) => {
    const {popup} = ui;
    return {
        popup,
    }
};

const connectedPage = injectIntl(connect(mapStateToProps)(DebugArgsPopUp));
export {connectedPage as DebugArgsPopUp};
