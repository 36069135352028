import React, {Component} from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import {connect} from 'react-redux'
// styled
import StyledModal from "./Modal.css";
import {uiActions} from "../store/ui/actions";
import IntlMessages from "../utils/IntlMessages";
import {appHelp} from "../helpers";
import {injectIntl} from "react-intl";

const modalRoot = document.getElementById("modal-root");

class Modal extends Component {
    static defaultProps = {
        id: "",
        modalClass: "",
        modalSize: "md"
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        modalClass: PropTypes.string,
        modalSize: PropTypes.string
    };

    state = {fadeType: null};

    background = React.createRef();

    componentDidMount() {
        window.addEventListener("keydown", this.onEscKeyDown, false);
        setTimeout(() => this.setState({fadeType: "in"}), 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isOpen && prevProps.isOpen) {
            this.setState({fadeType: "out"});
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onEscKeyDown, false);
    }

    transitionEnd = e => {
        if (e.propertyName !== "opacity" || this.state.fadeType === "in") return;

        if (this.state.fadeType === "out") {
            this.props.dispatch(uiActions.hidePopUp());
            this.props.onClose();
        }
    };

    onEscKeyDown = e => {
        if (e.key !== "Escape") return;
        this.setState({fadeType: "out"});
    };

    handleClick = e => {
        e.preventDefault();
        this.setState({fadeType: "out"});
    };

    handleAction = (action) => {
        this.setState({fadeType: "out"});
        action();
    };

    render() {
        const {popup, intl} = this.props;
        let buttons = null;
        if (popup.buttons) {
            if (Array.isArray(popup.buttons)) {
                buttons = popup.buttons.map((btn, index) => {
                    return (
                        <div key={index}
                             className={'full-width'}
                        >
                            <input type={'submit'} key={btn.messageId} value={btn.messageId}
                                   className={`btn ${btn.color === 'red' ? 'red' : 'grey'} medium full`}
                                   onClick={() => this.handleAction(btn.action)}
                            />
                        </div>

                    )
                })
            } else {
                buttons =
                    <input type={'submit'} className='btn grey medium full'
                           style={{color: popup.buttons.color}}
                           onClick={() => this.handleAction(popup.buttons.action)}
                           value={popup.buttons.messageId}
                    />
            }
        } else if (popup.inputs) {
            if (appHelp.validateArr(popup.inputs)) {
                buttons = popup.inputs.map((btn, index) => {
                    return (
                        <div className='full-width'>
                            <input key={index}
                                   type="submit" value={btn.title}
                                   className={`btn ${btn.classes} medium full`}
                                   onClick={() => this.handleAction(btn.action)}
                            />
                        </div>
                    )
                })
            }
        } else if (popup.action) {
            buttons =
                <input type={'submit'} className='btn color medium full'
                       value={intl.messages['popup.action.button-close']}
                       onClick={() => this.handleAction(popup.action)}/>
        }

        return ReactDom.createPortal(
            <StyledModal
                id={this.props.id}
                className={`wrapper ${"size-" + this.props.modalSize} fade-${
                    this.state.fadeType
                    } ${this.props.modalClass}`}
                role="dialog"
                modalSize={this.props.modalSize}
                onTransitionEnd={this.transitionEnd}
            >
                <div className="box-dialog">
                    <div id="popup1" className="overlay show text-left">
                        <div className="popup">
                            <h2>{popup.title}</h2>
                            <button className="close" onClick={(e) => {
                                //if actions exist take the last action and fire it when user clicks on X
                                if (popup.action)
                                    this.handleAction(popup.action);
                                else if (appHelp.validateArr(popup.buttons))
                                    this.handleAction(popup.buttons[popup.buttons.length - 1].action);
                                else if (appHelp.validateArr(popup.inputs))
                                    this.handleAction(popup.inputs[popup.inputs.length - 1].action);
                                else
                                    this.handleClick(e) //close popup
                            }
                            }>&times;</button>
                            <div className="content">
                                {popup.message}
                                {
                                    buttons &&
                                    <div className="pop-up-footer">
                                        {buttons}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </StyledModal>,
            modalRoot
        );
    }
}

const mapStateToProps = ({ui}) => {
    const {popup} = ui;
    return {
        popup,
    }
};


export default injectIntl(connect(mapStateToProps)(Modal));
