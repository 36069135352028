import React, {Fragment, useState} from 'react';

const Quantity = () => {
    const [value, setValue] = useState(1);


    const increment = (e) => {
        e.preventDefault();
        setValue(prevValue =>  prevValue < 11 ? prevValue + 1 : 11);
    };

    const decrement = (e) => {
        e.preventDefault();
        setValue(prevValue => prevValue > 1 ? --prevValue : 1);
    };

    return (
        <Fragment>
            <div className="quantity-input ">
                <button className="quantity-input__modifier quantity-input__modifier--left" onClick={decrement}>
                    &mdash;
                </button>
                <input type="text" id="people" className='text-center' min="1" value={value} max={10} readOnly/>
                <button className="quantity-input__modifier quantity-input__modifier--right" onClick={increment}>
                    &#xff0b;
                </button>
            </div>
        </Fragment>
    );
};

export default Quantity;