import {uiConstants} from "../../../constants";


export function toggle(state = {}, action) {

    switch (action.type) {
        case uiConstants.CAR_TOGGLE:
            return {
                ...state,
                car: action.car,
                toggle: !state.toggle
            };
        case uiConstants.CLEAR_CAR_TOGGLE:
            return {
                ...state,
                toggle: false,
                car: -1
            };

        case uiConstants.AIRPORT_SELECT:
            return {
                ...state,
                airportToggle: true
            };
        case uiConstants.CLOST_AIRPORT_SELECT:
            return {
                ...state,
                airportToggle: false
            };

        default:
            return state;
    }
}