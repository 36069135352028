
export const registerValidators = {
    userBasicAuth,
    email,
    phone,
    password,
};


function password(value) {
    const validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const isFieldValid = validator.test(value);
    const message = isFieldValid  ? '' : 'Password must have Minimum eight characters, at least one uppercase letter, one lowercase letter and one number ';

    return {
        isFieldValid,
        message
    }
}


function phone(value) {
    const validator = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const isFieldValid = validator.test(value);
    const message = isFieldValid  ? '' : 'Phone number not valid ';

    return {
        isFieldValid,
        message
    }
}

function email(value) {
    const validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isFieldValid = validator.test(value);
    const message = isFieldValid  ? '' : 'Email not valid ';
    return {
        isFieldValid,
        message
    }
}

function userBasicAuth(value) {
    // accept only digits
    const isFieldValid = /^[a-zA-Z]+$/.test(value);
    const message = isFieldValid  ? '' : 'Not valid input';
    return {
        isFieldValid,
        message
    }
}
