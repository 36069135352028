import {generalConstants, userConstants, carmelConstAPI, settingsConstants} from "../../constants";


export function credentials(state = {}, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                custAuthType: carmelConstAPI.authTypeGuest,
            };
        case userConstants.CARMEL_FACEBOOK_LOGIN_SUCCESS:
            return {
                ...state,
                custAuthType: 'F',
            };
        case generalConstants.CREDENTIALS_INIT:
            return action.Credentials;
        case generalConstants.RESET_CREDENTIALS:
            if (state.facebookAuth) {
                delete state.facebookAuth;
            }
            if (state.custLoginId) {
                delete state.custLoginId;

            }
            if (state.custPassword) {
                delete state.custPassword;
            }
            return {
                ...state,
                custAuthType: carmelConstAPI.authTypeGuest,
                appData: {
                    appSourceType: generalConstants.appSourceType,
                    deviceAlertId: "",
                    osVersion: window.navigator.platform,
                    platform: generalConstants.platform,
                    timeout: generalConstants.timeout,
                    timeoutCode: generalConstants.timeoutCode,
                    version: generalConstants.version,
                    // If JS will not be able to generate a browser fingerprint the ID will be "0"
                    deviceUniqueId: "0"
                }
            };
        // case generalConstants.CREDENTIALS_UPDATE:
        //     // This allows us to update details nested in appData
        //     return {
        //         ...state,
        //         ...action.Credentials,
        //         appData: {
        //             ...state.appData,
        //             ...action.Credentials.appData
        //         }
        //     };
        case settingsConstants.CHANGE_LOCALE:
            return {
                ...state,
                appData: {
                    ...state.appData,
                    language: action.payload
                }
            };

        case generalConstants.CREDENTIALS_UPDATE:
            // This allows us to update details nested in appData
            return {
                ...state,
                ...action.CredentialsUpdate,
            };
        case generalConstants.FACEBOOK_CREDENTIALS:
            // This allows us to update details nested in appData
            return {
                ...state,
                ...action.CredentialsUpdate
            };


        default:
            return state;

    }
}