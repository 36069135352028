import {generalConstants, settingsConstants} from "../../constants";


export function CoreCredentials(state = {}, action) {
    switch (action.type) {
        case generalConstants.CORE_CREDENTIALS_INIT:
            return action.Credentials;
        case generalConstants.CORE_CREDENTIALS_UPDATE:
            // This allows us to update details nested in appData
            return {
                ...state,
                ...action.CredentialsUpdate,
                appData: {
                    ...state.appData,
                    ...action.CredentialsUpdate.appData
                }
            };
        case settingsConstants.CHANGE_LOCALE:
            return {
                ...state,
                appData: {
                    ...state.appData,
                    language: action.payload
                }
            };
        case generalConstants.SET_DEBUG_ARGS:
            return {
                ...state,
                debugArgs: action.input
            };
        default:
            return state;
    }
}