import React from "react";

export const formsConstants = {
    UPDATE_FIELDS_VALUE: 'UPDATE_FIELDS_VALUE',
    UPDATE_FORM_FIELD_VALUE: 'UPDATE_FORM_FIELD_VALUE',
    CLEAR_FORM_FIELDS: 'CLEAR_FORM_FIELDS',
    SET_FORM_FIELD_ERRORS: 'SET_FORM_FIELD_ERRORS',
    SET_FIELD_IS_VALID: 'SET_FIELD_IS_VALID',
    CLEAR_ALL_FORM_FIELDS: 'CLEAR_ALL_FORM_FIELDS',
    SET_FOCUS_ON_FIELD: 'SET_FOCUS_ON_FIELD',
    CLEAR_FOCUS_ON_FIELD: 'CLEAR_FOCUS_ON_FIELD',

    //recovery modal
    RECOVER_PASSWORD_FORM: 'recoverPassword',
    RECOVERY_EMAIL: 'recoveryMail',

    // Forms
    //////////////

    // Credit Card
    //////////////

    // Form Name
    CREDIT_CARD: 'creditCard',

    //// Form Fields IDs:
    CARD_NUMBER: 'cardNumber',
    CARD_EXPIRATION_DATE_MONTH: 'cardExpMonth',
    CARD_EXPIRATION_DATE_YEAR: 'cardExpYear',
    CARD_HOLDER_NAME: 'cardHolder',
    CARD_CVV: 'cardCvvCode',
    CARD_BILLING_ADDRESS: 'billingAddr',
    CARD_BILLING_CITY: 'billingCity',
    CARD_BILLING_STATE: 'billingState',
    CARD_BILLING_ZIP: 'billingZip',
    CARD_BILLING_COUNTRY_CODE: 'billingCountryCode',
    CARD_SEQ_NUM: 'cardSeq',
    CARD_PREPAY_METHOD: 'prepayType',
    TIP_PERCENT: 'gratuityPct',
    CARD_ID: 'fopId',
    FOP_CODE: 'fopCode',

    ////Fields placeholders
    CARD_NUMBER_PLACEHOLDER: 'CREDIT CARD NUMBER',
    HOLDER_NAME_PLACEHOLDER: 'HOLDER NAME',
    MONTH_PLACEHOLDER: 'MONTH',
    YEAR_PLACEHOLDER: 'YEAR',
    CVV_PLACEHOLDER: 'CVV',
    ADDRESS_PLACEHOLDER: 'ADDRESS',
    CITY_PLACEHOLDER: 'CITY',
    STATE_PLACEHOLDER: 'STATE',
    COUNTRY_PLACEHOLDER: 'COUNTRY',
    ZIP_CODE_PLACEHOLDER: 'ZIP CODE',
    CARD_NUMBER_FOR_DISPLAY: 'CARD_NUMBER_FOR_DISPLAY',

    // Trip Payment - Prepay
    //// Form Name
    PAYMENT_FORM: 'tripPayment',
    //// Form Fields:
    PAYMENT_METHOD: 'method',
    PAYMENT_TIP: 'tip',

    // Trip Payment - Extra Charge
    //// Form Name
    EXTRA_CHARGER_FORM: 'extraCharge',
    //// Form Fields:
    EXTRA_CHARGER_TOLLS: 'tolls',
    EXTRA_CHARGER_MISC: 'misc',
    EXTRA_CHARGER_TIP: 'tip',

    // Other Constants
    OTHER_TIP: 'other',


    // App Login
    //// Form Name
    LOGIN_FORM: 'carmelLogin',
    //// Form Fields:
    LOGIN_USERNAME: 'custLoginId',
    LOGIN_PASSWORD: 'custPassword',

    //// Form Name
    HOUSE_USER_FORM: 'houseLogin',
    //// Form Fields:
    HOUSE_USERNAME: 'accountId',
    HOUSE_PASSWORD: 'accountPassword',

    // Register Login
    //// Form Name
    REGISTER_FORM: 'carmelRegister',
    //// Form Fields:
    REGISTER_FNAME: 'FirstName',
    REGISTER_LNAME: 'LastName',
    REGISTER_EMAIL: 'emailAddr',
    REGISTER_PASSWORD: 'newPassword1',
    REGISTER_PASSWORD_CON: 'newPassword2',
    REGISTER_COUNTRY: 'Country',
    REGISTER_COUNTRY_ICE: 'IceCountry',
    REGISTER_PHONE: 'Phone',
    REGISTER_PHONE_ICE: 'PhoneEmergency',


    // Airline
    AIRLINE_FORM: 'airline',
    AIRLINE_NAME: 'airlineCode',
    FLIGHT_NUMBER: 'flightNumber',
    ARRIVE_CITY: 'arrivingFrom',

    //Address
    SEARCH_FORM: 'searchForm',
    SEARCH_ADDRESS: 'searchAddress',
    DROP_ADDRESS: 'dropAddress',

    //Booking time form
    TIME_FORM: 'timeForm',
    DATE_FIELD: 'booking_date',
    TIME_FIELD: 'booking_time',
    UTC_DATE_FIELD: 'booking_utc_date',
    UTC_TIME_FIELD: 'booking_utc_time',
    AMPM_FIELD: 'booking_ampm',

    //favorite nickname form
    FAVORITE_NICKNAME_FORM: 'nicknameForm',
    TRIP_NICKNAME: 'tripNickName',

    //reward program form
    REWARD_FORM: 'rewardForm',
    REWARD_ID: 'rewardId',
    REWARD_NUMBER: 'rewardNumber',

    CAR_CASH_MARKETS_FORM: 'carCashMarkets',
    MARKET_NAME: 'marketName',

    //cust activate form
    CUST_ACTIVATE_FORM: 'custActivateFrom',
    CUST_ACTIVATE_PHONE: 'custActivatePhone',
    CUST_ACTIVATE_COUNTRY: 'custActivateCountry',
    CUST_ACTIVATE_CODE: 'custActivateCode',

    //driver message box
    DRIVER_FEEDBACK_FORM: 'driverFeedbackForm',
    DRIVER_FEEDBACK: 'ratingMessage',
    DRIVER_RATING: 'ratingId'
};

export const loginForm = [
    {
        label: 'Your username',
        type: 'text',
        formConst: formsConstants.LOGIN_USERNAME
    },
    {
        label: 'Your password',
        type: 'password',
        formConst: formsConstants.LOGIN_PASSWORD
    }
];
export const registerForm = [
    {
        label: 'Your name ',
        type: 'text',
        formConst: formsConstants.REGISTER_FNAME
    }, {
        label: 'Your last name',
        type: 'text',
        formConst: formsConstants.REGISTER_LNAME
    }, {
        label: 'Your email address',
        type: 'text',
        formConst: formsConstants.REGISTER_EMAIL
    }, {
        label: 'Your Country',
        type: 'text',
        formConst: formsConstants.REGISTER_COUNTRY
    }, {
        label: 'Your phone number',
        guestLabel: 'Number (Cell Phone Preferred)',
        type: 'text',
        formConst: formsConstants.REGISTER_PHONE
    }, {
        label: 'Your Ice Country',
        guestLabel: "Country At Pick-up",
        type: 'text',
        formConst: formsConstants.REGISTER_COUNTRY_ICE
    }, {
        label: 'Your Ice phone number',
        guestLabel: "Number At Pick-up (If Different)",
        type: 'text',
        formConst: formsConstants.REGISTER_PHONE_ICE
    },
    {
        label: 'Your password',
        type: 'password',
        formConst: formsConstants.REGISTER_PASSWORD
    }, {
        label: 'Repeat password',
        type: 'password',
        formConst: formsConstants.REGISTER_PASSWORD_CON
    }
];
export const houseFormContent = [
    {
        type: "email",
        label: "Account Number",
        formConst: formsConstants.HOUSE_USERNAME
    },
    {
        type: "password",
        label: "Password",
        formConst: formsConstants.HOUSE_PASSWORD
    }
];