import {generalService} from '../general/services';
import {carmelMethodsConstants} from "../../constants";
import {store} from '../index';

export const interactiveServices = {
    custArrived,
    safetyPing,
};


function custArrived(verifyTerminal, updateTerminal) {
    const state = store.getState();
    const {CustArrived} = state.interactive;
    const {CoreCredentials, Credentials} = state.authentication;
    return generalService.requestSender(
        carmelMethodsConstants.CUST_ARRIVED,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            'CustArrived': {
                ...CustArrived,
                verifyTerminal,
                updateTerminal
            },
        },
    )
}

function safetyPing() {
    const state = store.getState();

    const {tripId} = state.user.lists.trips.trip;
    const {CoreCredentials, Credentials} = state.authentication;
    return generalService.requestSender(
        carmelMethodsConstants.SAFETY_PING,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            },
            safetyPing: true,
            tripId,
        }
    )
}