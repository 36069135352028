export const defaultConstants = {
    defaultStartPath: '/',
    defaultLocale: 'en',
    defaultMenuType: 'menu-sub-hidden', //'menu-default', 'menu-hidden'
    subHiddenBreakpoint: 440,
    menuHiddenBreakpoint: 768,
    searchPath: "/app/gogo/start",
    location: '40.731412,-73.996974',
    CARMEL_PHONE_NUMBER: '1-866-666-6666',
};


export const localeOptions = [
    {id: 'en', name: 'English'},
];
