import React, {Fragment, useEffect} from "react";
import lottie from "lottie-web";
import spinnerJson from '../../assets/animations/spinner'
import '../../assets/css/sass/_global.scss'
import {useSelector} from "react-redux";
import IntlMessages from "../../utils/IntlMessages";

export const Spinner = (props) => {
    const {intl} = props;
    let spinner = null;
    const spinnerState = useSelector(state => state.ui.spinner);

    useEffect(() => {
        lottie.loadAnimation({
            container: spinner,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: spinnerJson
        });
    }, []);

    return (
        <Fragment>
            {
                spinnerState.display ?
                    <div className="modal-backdrop"/>
                    :
                    null
            }
            <div className="lottie-spinner" style={{display: spinnerState.display ? 'block' : 'none'}}>
                <div className="container spinner-container">
                    <div className='spinner-item' ref={ref => spinner = ref}/>
                    <p className='spinner-text'>
                        {
                            spinnerState.text &&
                            <IntlMessages id={spinnerState.text}/>
                        }
                    </p>
                </div>
            </div>
        </Fragment>
    );
};