export const creditCardValidators = {
    cvcFormat,
    creditCard,
    cvvValidator
};

function cvcFormat(value) {

}

// Takes the form field value and returns true on valid number
function creditCard(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm
    let nCheck = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    // I have added the '&& nCheck !== 0' part to make sure
    // an empty card number value is not valid!

    const isFieldValid = (nCheck % 10) === 0 && nCheck !== 0;
    let message = '';

    if (isFieldValid) {
        message = '';
    } else {
        message = 'Credit Card not valid';
    }

    return {
        isFieldValid,
        message
    }
}

function cvvValidator(value) {
    // accept only digits
    const isFieldValid = /[0-9]+/.test(value);
    const message = isFieldValid  ? '' : 'CVV not valid';
    return {
        isFieldValid,
        message
    }
}
