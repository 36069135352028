import React, {Fragment} from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {formsConstants} from "../../../constants";
import {carmelActions, uiActions} from "../../../store/actions";

const ValidationCodeForm = ({dispatch, custActivateForm, handleChange}) => {
    if (!custActivateForm)
        return null;

    let inputRef = null;

    const resendCode = () => {
        dispatch(carmelActions.custActivate())
    };

    const changePhoneNumber = () => {
        dispatch(uiActions.resetCustActivate());
    };

    return (
        <Fragment>
            <div className="form-group f-row text-left" style={{zIndex: "100"}}>
                <div className={"full-width"}>
                    <label htmlFor="username">Enter Validation Code</label>
                    <input
                        type="text"
                        id={formsConstants.CUST_ACTIVATE_CODE}
                        value={custActivateForm[formsConstants.CUST_ACTIVATE_CODE]}
                        ref={ref => inputRef = ref}
                        onChange={event =>
                            handleChange(event.target.id, event.target.value)
                        }
                    />
                </div>
                <div className='full-width' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span className='span-as-link' onClick={changePhoneNumber}>
                            Change Phone Number
                    </span>
                    <span className='span-as-link ' onClick={resendCode}>
                            Resend Code
                    </span>
                </div>
            </div>
        </Fragment>
    )
};

function mapStateToProps({settings, forms}) {
    const {lists} = settings;
    const custActivateForm = forms[formsConstants.CUST_ACTIVATE_FORM];
    return {
        lists,
        custActivateForm
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(ValidationCodeForm));
export {connectedPage as ValidationCodeForm};
