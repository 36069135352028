import React, {Component} from "react";
import {connect} from 'react-redux';
import {injectIntl} from "react-intl";
import {carmelConstAPI, formsConstants} from "../../../constants";
import {formsActions, tripPaymentActions} from "../../../store/actions";
import IntlMessage from "utils/IntlMessages";

class ChooseTipMethod extends Component {
    constructor(props) {
        super(props);

        this.togglePracent = React.createRef();
        this.toggle = React.createRef();
    }

    componentDidMount() {
        const {creditCardForm} = this.props;
        this.toggle.current.classList.remove("cc_fare_checked");
        this.togglePracent.current.classList.remove("cc_fare_checked");
        if (creditCardForm && creditCardForm.autoCharge) {
            if (creditCardForm[formsConstants.CARD_PREPAY_METHOD] === carmelConstAPI.FARE_ALL_INCLUSIVE)
                this.selectInclusive();
            else
                this.selectBaseFare();
        }
    }

    selectBaseFare = () => {
        const {creditCardForm, dispatch} = this.props;
        if (!this.togglePracent.current.classList.contains('cc_fare_checked')) {
            this.toggle.current.classList.remove("cc_fare_checked");
            this.togglePracent.current.classList.add("cc_fare_checked");
            this.props.displayer('none');

            if (creditCardForm) {
                dispatch(formsActions.updateFieldsValue(
                    formsConstants.CREDIT_CARD,
                    {
                        [formsConstants.CARD_PREPAY_METHOD]: 'BASE',
                    }
                ));
                dispatch(tripPaymentActions.selectPrepayMethod('BASE'))
            }
        } else {
            this.togglePracent.current.classList.remove("cc_fare_checked");
            this.props.displayer('none');

            if (creditCardForm) {
                dispatch(formsActions.updateFieldsValue(
                    formsConstants.CREDIT_CARD,
                    {
                        [formsConstants.CARD_PREPAY_METHOD]: 'DFLT',
                    }
                ));
                dispatch(tripPaymentActions.selectPrepayMethod('DFLT'))
            }
        }
    };

    selectInclusive = () => {
        const {creditCardForm, dispatch} = this.props;
        if (!this.toggle.current.classList.contains('cc_fare_checked')) {
            this.toggle.current.classList.add("cc_fare_checked");
            this.togglePracent.current.classList.remove("cc_fare_checked");
            this.props.displayer('block');

            if (creditCardForm) {
                dispatch(formsActions.updateFieldsValue(
                    formsConstants.CREDIT_CARD,
                    {
                        [formsConstants.CARD_PREPAY_METHOD]: 'AI',
                    }
                ));
                dispatch(tripPaymentActions.selectPrepayMethod('AI'))
            }
        } else {
            this.toggle.current.classList.remove("cc_fare_checked");
            this.props.displayer('none');

            if (creditCardForm) {
                dispatch(formsActions.updateFieldsValue(
                    formsConstants.CREDIT_CARD,
                    {
                        [formsConstants.CARD_PREPAY_METHOD]: 'DFLT',
                    }
                ));
                dispatch(tripPaymentActions.selectPrepayMethod('DFLT'))
            }
        }

    };

    render() {
        const {lists} = this.props;
        if (!lists || !lists.fullFopTypeList)
            return null;
        const {fareBasicDesc, fareAllInclusiveDesc} = lists.fullFopTypeList;
        if (!fareBasicDesc || !fareAllInclusiveDesc)
            return null;
        return (
            <div className="autoCharge-checkboxes-container">
                <div className="cc_fare text-center  " ref={this.togglePracent}
                     onClick={this.selectBaseFare}>
                    <div>
                        <span className="empty_icon"/>
                        <input type="radio" name="cc_fare_input"/>
                        <p className="grey-text">
                            BASE FARE
                        </p>
                        <p className="fs_xxsmall">
                            {fareBasicDesc}
                        </p>
                    </div>
                </div>
                <div className="cc_fare text-center" ref={this.toggle}
                     onClick={this.selectInclusive}>
                    <div>
                        <span className="empty_icon"/>
                        <input type="radio" name="cc_fare_input"/>
                        <p className="grey-text">ALL INCLUSIVE</p>
                        <p className="fs_xxsmall">
                            {fareAllInclusiveDesc}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({forms, settings}) {
    const creditCardForm = forms[formsConstants.CREDIT_CARD];
    const {lists} = settings;

    return {
        creditCardForm,
        lists
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(ChooseTipMethod));
export {connectedPage as ChooseTipMethod};

