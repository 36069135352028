import React, {useState, useEffect, Fragment} from 'react'
import IntlMessages from '../../utils/IntlMessages'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {modalsTypes, tripPaymentConstants, urls} from "../../constants";
import {interactiveActions, tripActions, uiActions, userActions} from "../../store/actions";
import {appHelp} from "../../helpers";

const TripOptionsBtns = ({trip, dispatch, tripOptions, unplugged, buttonDiraction, thankyouScreen, page, intl}) => {
    const cancelTrip = (e) => {
        //stops the activations of display trip function
        e.stopPropagation();
        dispatch(tripActions.cancelTrip(trip));
    };


    const extraCharge = (e) => {
        //stops the activations of display trip function
        e.stopPropagation();
        // update selected trip
        dispatch(userActions.chooseTrip(trip));
        // first trip charge
        dispatch(tripActions.firstTripCharge(tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE));
    };

    const getDriverLocation = (e) => {
        //stops the activations of display trip function
        e.stopPropagation();
        dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR));
        // dispatch(userActions.chooseTrip(trip));
        //before entering the page check if response if ok
        // dispatch(uiActions.displaySpinner('spinner.looking_For_Your_Car_Loader'));
        // dispatch(tripActions.getCarLocation(trip.tripId, () => {
        //     console.log('show my car denied')
        // }, () => dispatch(uiActions.showModal(modalsConstants.modalsTypes.SHOW_MY_CAR))));

    };

    const payForRide = (e) => {
        //stops the activations of display trip function
        e.stopPropagation();
        // update selected trip
        dispatch(userActions.chooseTrip(trip));
        // first trip charge
        dispatch(tripActions.firstTripCharge(tripPaymentConstants.CHARGE_TYPE_PREPAY));

    };


    const setCustArrived = (e) => {
        e.stopPropagation();
        dispatch(userActions.chooseTrip(trip));
        dispatch(interactiveActions.custArrivedUpdateTripId(trip.tripId, tripOptions.includes("VerifyTerminal")));
        //send cust arrived with check if VerifyTerminal exist
        dispatch(interactiveActions.custArrived(tripOptions.includes("VerifyTerminal")));
    };

    const pickupInstructions = (e) => {
        e.stopPropagation();
        dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR));
        // dispatch(userActions.chooseTrip(trip));
        //before entering the page check if response if ok
        // dispatch(uiActions.displaySpinner('spinner.getting_Insturctions_Loader'));
        // dispatch(tripActions.getCarLocation(trip.tripId, () => {
        //     console.log('show my car denied')
        // }, () => dispatch(uiActions.showModal(modalsConstants.modalsTypes.PICK_UP_INSTRUCTIONS))));
    };


    if (!appHelp.validateArr(tripOptions))
        return null;
    let buttons = tripOptions.map((option, index) => {
        let btnClass = `btn grey medium full`;
        let dangerButton = `btn color medium full`;
        let inputContainer = null;
        switch (option) {
            case "CarLocation":
                inputContainer = (
                    <input type='submit' value={intl.messages['my-trips.trips.trip-btn-show-car']} className={btnClass}
                           key={6}
                           onClick={getDriverLocation}/>
                );
                break;
            case "ExtraCharge":
                inputContainer = (
                    <input type='submit' value={intl.messages['my-trips.trips.trip-btn-add-tip']} className={btnClass}
                           key={5}
                           onClick={extraCharge}/>

                );
                break;
            // case "RecentTrip":
            //     if (buttonDiraction !== 'row') {
            //         return (
            //             <button key={3} className={btnClass} onClick={() => {
            //                 dispatch(uiActions.showModal(modalsTypes.SAVE_FAVORITE))
            //             }}>
            //                 <IntlMessages id="trips-modal.save-to-favorite"/>
            //             </button>
            //         );
            //     }
            //     break;

            case "PickUpInstructions":
                inputContainer = (
                    <input type='submit'
                           value={intl.messages[thankyouScreen ? 'my-trips.trips.trip-btn-instructions-thank-you' : 'my-trips.trips.trip-btn-instructions']}
                           className={btnClass} key={7}
                           onClick={pickupInstructions}>
                    </input>
                );
                break;
            case "CustArrived":
                inputContainer = (
                    <input type='submit'
                           value={intl.messages[thankyouScreen ? 'my-trips.trips.trip-btn-ready-to-go-luggage' : 'my-trips.trips.trip-btn-ready-to-go']}
                           className={btnClass} key={9}
                           onClick={setCustArrived}/>
                );

                break;
            case "PrepayTrip":
                inputContainer = (
                    <input type='submit' value={intl.messages['my-trips.trips.trip-btn-prepay']} className={btnClass}
                           key={4}
                           onClick={payForRide}/>
                );
                break;
            case "CancelTrip":
                inputContainer = (
                    <input type='submit' value={intl.messages['my-trips.trips.trip-btn-cancel']}
                           className={dangerButton}
                           key={1} onClick={cancelTrip}/>
                );
                break;
            default:
                return null;
        }
        return (
            inputContainer
        )
    });
    //clear all null buttons
    buttons = buttons.filter(Boolean);


    if (trip.feedbackLink && buttonDiraction !== 'row') {
        buttons.push(
            <a key={2} href={trip.feedbackLink} target={'_blank'}
               className={'btn btn-block btn-default primary_btn p-2  mt-1 '}>
                <IntlMessages id={'trip-details.driver-feedback.button'}/>
            </a>
        );
    }

    //sort the array by key number
    buttons = buttons.sort((a, b) => a.key - b.key).reverse();

    return (
        buttons.map((btn, index) => {
            return (
                <div key={index} style={{paddingBottom: '0'}}
                     className={`${buttons.length > 2 ? 'one-third-full-button' : buttons.length === 2 ? 'one-half-full-button' : 'full-width'}`}>
                    {btn}
                </div>
            )
        })
    )
};

function mapStateToProps({ui}) {
    const {unplugged} = ui;
    const {currentUrl} = ui.page;
    return ({
        unplugged,
        page: currentUrl
    })
}

const connectedPage = injectIntl(connect(mapStateToProps)(TripOptionsBtns));
export {connectedPage as TripOptionsBtns};
