import React, {Component, Fragment} from "react";
import {connect} from 'react-redux';
import {injectIntl} from "react-intl";
import Cleave from "cleave.js/react";

import {formsActions, uiActions} from "../../../store/actions";
import {formsConstants, modalsTypes} from "../../../constants";
import {creditCardValidators} from "../../../_validators";
import IntlMessages from "../../../utils/IntlMessages";
import Select from "react-select";
import {appHelp} from "../../../helpers";
import {CustomDropDownIndicator} from "../../CustomSelectComps";
import flag from "../../../assets/images/americanada.png";
import defaultFlag from "../../../assets/images/flag_default@2x.png";

class CreditCardForm extends Component {

    constructor(props) {
        super(props);

        const {dispatch, creditCardForm} = this.props;
        this.fields = [];
        if (!creditCardForm) {
            dispatch(formsActions.updateFieldsValue(
                formsConstants.CREDIT_CARD,
                {
                    [formsConstants.CARD_NUMBER]: '',
                    [formsConstants.CARD_EXPIRATION_DATE_YEAR]: '',
                    [formsConstants.CARD_EXPIRATION_DATE_MONTH]: '',
                    [formsConstants.CARD_HOLDER_NAME]: '',
                    [formsConstants.CARD_CVV]: '',

                    [formsConstants.CARD_BILLING_ADDRESS]: '',
                    [formsConstants.CARD_BILLING_CITY]: '',
                    [formsConstants.CARD_BILLING_STATE]: '',
                    [formsConstants.CARD_BILLING_ZIP]: '',
                    [formsConstants.CARD_BILLING_COUNTRY_CODE]: 'US',
                }));
        }
        this.state = {
            currentId: '',
            imageStatus: "loading"
        }
    }


    handleImageLoaded() {
        this.setState({imageStatus: "loaded"});
    }

    handleImageErrored() {
        this.setState({imageStatus: "failed to load"});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {creditCardForm} = this.props;
        if (creditCardForm && creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE]) {
            if (prevProps.creditCardForm && prevProps.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE]) {
                if (prevProps.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE] !== creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE]) {
                    if (this.state.imageStatus !== 'loading') // on image change set image status to loading until in loader again
                        this.setState({imageStatus: 'loading'})
                }
            }
        }
    }

    customStyles = {
        control: (base, state) => ({
            ...base,
            borderRadius: '0',
            border: 'none',
            boxShadow: 'none',
            fontSize: '14px',
            height: '47px',
            background: 'none'
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: 'rgba(126,126,126,0.7)',
            }
        },
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'left',
        }),
    };

    customNumberInputStyles = {
        control: (base, state) => ({
            ...base,
            borderRadius: '0',
            border: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            height: '47px',
            background: 'none'
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: 'rgba(126,126,126,0.7)',
            }
        },
    };

    handleChange = (id, value, validators) => {
        const {dispatch} = this.props;
        let validInputs = appHelp.validateInputLettersAndNumbers(value);
        if (!validInputs && id !== formsConstants.CARD_HOLDER_NAME) {
            return;
        } else {
            value = value.replace(/[^a-z 0-9]/gi, '');
        }


        if (id === formsConstants.CARD_BILLING_COUNTRY_CODE
            && value !== 'US'
        ) {
            dispatch(formsActions.updateFormFieldValue(
                formsConstants.CREDIT_CARD,
                formsConstants.CARD_BILLING_STATE,
                '',
                []
                )
            );
        }

        if (id === formsConstants.CARD_BILLING_STATE
            && value !== ''
        ) {
            dispatch(formsActions.updateFormFieldValue(
                formsConstants.CREDIT_CARD,
                formsConstants.CARD_BILLING_COUNTRY_CODE,
                'US',
                []
                )
            );
        }
        if (id === formsConstants.CARD_NUMBER || id === formsConstants.CARD_CVV) {
            validInputs = appHelp.validateInputNumbers(value);
            if (!validInputs)
                return;
        }
        dispatch(formsActions.updateFormFieldValue(
            formsConstants.CREDIT_CARD,
            id,
            value,
            validators
            )
        );
    };

    fieldInputSwitch = (field, creditCardForm) => {
        switch (field.type) {
            case 'select':
                return (
                    <div className={'form-group common_selectfield'}>
                        <Select
                            components={{
                                DropdownIndicator: CustomDropDownIndicator
                            }}
                            options={field.options}
                            menuPlacement="auto"
                            className={`form-control-select
                                        ${creditCardForm.errors && creditCardForm.errors[field.id] ? 'border-danger' : ''}`}
                            name={field.id}
                            id={field.name}
                            ref={(input) => {
                                this.fields[field.id] = input
                            }}
                            placeholder={field.placeholder}
                            isDisabled={field.isDisabled}
                            value={field.value}
                            styles={this.customStyles}
                            onChange={
                                (e) => this.handleChange(field.id, e.value, field.validators)
                            }
                        />
                        {
                            creditCardForm.errors && creditCardForm.errors[field.id] &&
                            creditCardForm.errors[field.id].map(errorMessage => (
                                <small className='input-help-text form-text text-left alert-text'>
                                    {errorMessage}
                                </small>
                            ))
                        }
                    </div>
                );
            case 'countryCode':
                return (
                    <Fragment>
                        <div className="country_code_sec_payments"
                             onClick={() => this.props.dispatch(uiActions.showModal(modalsTypes.CREDIT_CARD_COUNTRIES))}
                        >
                            <div className='flag-image-wrapper'>
                                <img
                                    src={this.imageStatus === 'loading' ? defaultFlag : field.value && field.value.flagUrl ? field.value.flagUrl : flag}
                                    onLoad={this.handleImageLoaded.bind(this)}
                                    onError={this.handleImageErrored.bind(this)}
                                    alt="country_flag"
                                    className="c_flag"
                                />
                                <span
                                    className="c_code ml-2">{field.value && field.value.name ? field.value.name : 'United States'}</span>
                            </div>
                            <div className=''>
                                <span
                                    className="c_code">{field.value && field.value.code ? field.value.code : 'US'}</span>
                            </div>
                        </div>
                    </Fragment>
                );
            default:
                return (
                    <Fragment>
                        <input type={field.type}
                               id={field.id} name={field.name}
                               ref={(input) => {
                                   this.fields[field.id] = input
                               }}
                               maxLength={field.maxLength}
                               className={`form-control
                                           ${creditCardForm.errors && creditCardForm.errors[field.id] ? 'border-danger' : ''}`}
                               placeholder={field.placeholder}
                               onChange={
                                   (e) => this.handleChange(e.target.id, e.target.value, field.validators)
                               }
                               value={creditCardForm[field.id]}
                        />
                        {
                            creditCardForm.errors && creditCardForm.errors[field.id] &&
                            creditCardForm.errors[field.id].map(errorMessage => (
                                <small className='input-help-text form-text text-left alert-text'>
                                    {errorMessage}
                                </small>
                            ))
                        }
                    </Fragment>
                )
        }
    };
    focusCreditCard = (e) => {
        const {dispatch, creditCardForm} = this.props;
        if (e.target.id === formsConstants.CARD_NUMBER && creditCardForm[formsConstants.CARD_NUMBER_FOR_DISPLAY]) {
            //when credit card field clicked and CARD_NUMBER_FOR_DISPLAY clear all related fields
            dispatch(formsActions.updateFormFieldValue(
                formsConstants.CREDIT_CARD,
                formsConstants.CARD_NUMBER_FOR_DISPLAY,
                '',
                []
            ));
            dispatch(formsActions.updateFormFieldValue(
                formsConstants.CREDIT_CARD,
                formsConstants.CARD_NUMBER,
                '',
                []
            ));
        }
    };

    render() {
        const {props} = this;
        const {dispatch, creditCardForm, lists} = props;
        if (creditCardForm && creditCardForm.onFocus) {
            if (this.fields[creditCardForm.onFocus]) {
                this.fields[creditCardForm.onFocus].focus();
            }
            dispatch(formsActions.clearFocusOnField(formsConstants.CREDIT_CARD))
        }

        if (!creditCardForm || !lists || !lists.CountryList || !lists.stateList) {
            return null;
        }

        let stateValue = lists.stateList.filter(field => field.code === props.creditCardForm[formsConstants.CARD_BILLING_STATE])[0];
        if (props.creditCardForm && props.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE] !== "US" && props.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE] !== '') {
            if (creditCardForm[formsConstants.CARD_BILLING_STATE]) {
                //if chosen county is not us clear the billing state if exist
                dispatch(formsActions.updateFormFieldValue(
                    formsConstants.CREDIT_CARD,
                    formsConstants.CARD_BILLING_STATE,
                    '',
                    []
                ))
            }
            //clear the pick state
            stateValue = null;
        }
        this.forms = {
            longFields: {
                width: '100%',
                headline: '',
                fields: [
                    {
                        id: formsConstants.CARD_NUMBER,
                        name: formsConstants.CARD_NUMBER,
                        placeholder: creditCardForm[formsConstants.CARD_NUMBER_FOR_DISPLAY] ? creditCardForm[formsConstants.CARD_NUMBER_FOR_DISPLAY] : "CREDIT CARD NUMBER", //CARD_NUMBER_FOR_DISPLAY is the credit card number with musk
                        validators: [creditCardValidators.creditCard],
                        type: 'tel',
                        col: 'full-width-credit-card',
                        maxLength: 20,
                        options: {
                            creditCard: true,
                            numericOnly: true,
                            delimiter: '-'
                        }
                    },
                    {
                        id: formsConstants.CARD_HOLDER_NAME,
                        name: formsConstants.CARD_HOLDER_NAME,
                        placeholder: "HOLDER NAME",
                        validators: [],
                        type: 'text',
                        col: 'full-width-credit-card',
                        maxLength: 50,
                        options: {
                            delimiter: '',
                            blocks: [9999999]
                        }
                    }
                ]
            },
            expirationDate: {
                width: "67%",
                headline: '',
                fields: [
                    {
                        id: formsConstants.CARD_EXPIRATION_DATE_MONTH,
                        name: formsConstants.CARD_EXPIRATION_DATE_MONTH,
                        placeholder: formsConstants.MONTH_PLACEHOLDER,
                        validators: [],
                        options: [...Array(12).keys()].map(month => {
                            const monthString = (month + 1).toString();

                            return {label: monthString, value: monthString < 10 ? `0${monthString}` : monthString}
                        })
                    },
                    {
                        id: formsConstants.CARD_EXPIRATION_DATE_YEAR,
                        name: formsConstants.CARD_EXPIRATION_DATE_YEAR,
                        placeholder: formsConstants.YEAR_PLACEHOLDER,
                        validators: [],
                        options: appHelp.getNextYearsList(30)
                    }
                ]
            },
            cvv: {
                width: "33%",
                headline: '',
                fields: [
                    {
                        id: formsConstants.CARD_CVV,
                        name: formsConstants.CARD_CVV,
                        type: 'number',
                        placeholder: "CVV",
                        validators: [creditCardValidators.cvvValidator],
                        mask: '9999',
                        options: {
                            blocks: [4],
                            numericOnly: true
                        }
                    }
                ]
            },
            address: {
                width: "100%",
                headline: '',
                fields: [
                    {
                        id: formsConstants.CARD_BILLING_ADDRESS,
                        name: formsConstants.CARD_BILLING_ADDRESS,
                        type: 'text',
                        maxLength: 100,
                        placeholder: formsConstants.ADDRESS_PLACEHOLDER,
                        validators: [],
                        options: {},
                        columnWidth: 'full-width-credit-card',
                    },
                    {
                        id: formsConstants.CARD_BILLING_CITY,
                        name: formsConstants.CARD_BILLING_CITY,
                        type: 'text',
                        maxLength: 100,
                        placeholder: formsConstants.CITY_PLACEHOLDER,
                        validators: [],
                        options: {},
                        columnWidth: 'full-width-credit-card',
                    }, {
                        id: formsConstants.CARD_BILLING_STATE,
                        name: formsConstants.CARD_BILLING_STATE,
                        type: 'select',
                        maxLength: 100,
                        placeholder: formsConstants.STATE_PLACEHOLDER,
                        validators: [],
                        options: props.lists.stateList.map(country => ({
                            label: country.name,
                            value: country.code,
                            className: 'option-list-class'
                        })),
                        columnWidth: 'one-half-credit-card',
                        removePadding: true,
                        value: stateValue
                            ? {label: stateValue.name, value: stateValue.code}
                            : '',
                        isDisabled: props.creditCardForm && props.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE] !== "US" && props.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE] !== '',
                    },

                    {
                        id: formsConstants.CARD_BILLING_ZIP,
                        name: formsConstants.CARD_BILLING_ZIP,
                        type: 'text',
                        maxLength: 100,
                        placeholder: formsConstants.ZIP_CODE_PLACEHOLDER,
                        validators: [],
                        options: {
                            blocks: [20],
                            numericOnly: true
                        },
                        columnWidth: 'one-half-credit-card',
                    },
                    {
                        id: formsConstants.CARD_BILLING_COUNTRY_CODE,
                        name: formsConstants.CARD_BILLING_COUNTRY_CODE,
                        type: 'countryCode',
                        maxLength: 100,
                        placeholder: formsConstants.COUNTRY_PLACEHOLDER,
                        validators: [],

                        columnWidth: 'full-width-credit-card',
                        value: lists.CountryList.find(option => option.code === props.creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE])
                    },
                ]
            }
        };

        return (
            <div className='credit-card-form  wrap'>
                <div className='form-container row'>
                    <form className="text-center white_form"
                          id="credit_card_form"
                          name="credit_card_form"
                          noValidate="novalidate">
                        {
                            this.forms.longFields.fields.map(field => (
                                <div key={field.id} className={`${field.col}`}>
                                    <div className="form-group scan_sec gray-place-holder">
                                        <Cleave
                                            className={`form-control
                                                        ${creditCardForm.errors && creditCardForm.errors[field.id] ? 'border-danger' : ''}
                                                        ${field.id === formsConstants.CARD_NUMBER && creditCardForm && creditCardForm.isValid && creditCardForm.isValid[field.id] ? 'border-success' : ''}`}
                                            id={field.id}
                                            type={field.type}
                                            ref={(input) => {
                                                if (input) {
                                                    this.fields[field.id] = input.element
                                                }
                                            }}
                                            onFocus={this.focusCreditCard}
                                            value={creditCardForm[formsConstants.CARD_NUMBER_FOR_DISPLAY] && field.id === formsConstants.CARD_NUMBER ? '' : creditCardForm[field.id]} //when credit card for display exist hide the original credit card value
                                            name={field.id}
                                            maxLength={field.maxLength}
                                            options={field.options}
                                            onChange={
                                                (e) => this.handleChange(e.target.id, e.target.rawValue, field.validators)
                                            }
                                            placeholder={field.placeholder}/>
                                        {
                                            creditCardForm.errors && (creditCardForm.errors[field.id]) &&
                                            creditCardForm.errors[field.id].map(errorMessage => (
                                                <small className='input-help-text form-text text-left alert-text'>
                                                    {errorMessage}
                                                </small>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <div className="full-width-credit-card">
                            <p className="fs_small grey-text  "><IntlMessages
                                id={'credit-card-form.expiration-date.title'}/></p>
                            <div className="row">
                                {
                                    this.forms.expirationDate.fields.map(field => {
                                        return (
                                            <div key={field.id} className="one-third-credit-card">
                                                <div className="form-group common_selectfield"
                                                     style={{zIndex: this.state.currentId === field.id ? 1000 : 5}}>
                                                    <Select
                                                        components={{
                                                            DropdownIndicator: CustomDropDownIndicator
                                                        }}
                                                        onFocus={() => {
                                                            this.setState({currentId: field.id});
                                                        }}
                                                        id={field.id}
                                                        ref={(input) => {
                                                            this.fields[field.id] = input
                                                        }}
                                                        menuPlacement="auto"
                                                        placeholder={field.placeholder}
                                                        className={`form-control-select
                                                                    ${creditCardForm.errors && creditCardForm.errors[field.id] ? 'border-danger' : ''}`}
                                                        name={field.id}
                                                        options={field.options}
                                                        stayOpen={true}
                                                        onChange={
                                                            (e) => this.handleChange(field.id, e.value, field.validators)
                                                        }
                                                        isSearchable={false}
                                                        maxLength={field.maxLength}
                                                        styles={this.customNumberInputStyles}
                                                        value={field.options.filter(option => option.label === creditCardForm[field.id] || option.value === creditCardForm[field.id])}
                                                    />
                                                    {
                                                        creditCardForm.errors && creditCardForm.errors[field.id] &&
                                                        creditCardForm.errors[field.id].map(errorMessage => (
                                                            <small
                                                                className='input-help-text form-text text-left alert-text'>
                                                                {errorMessage}
                                                            </small>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                {
                                    this.forms.cvv.fields.map(field => {
                                        return (
                                            <div key={field.id} className="one-third-credit-card">
                                                <div className="form-group grayPlaceHolder">
                                                    <input
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        // mask={field.mask}
                                                        // maskPlaceholder={''}
                                                        ref={(input) => {
                                                            this.fields[field.id] = input
                                                        }}
                                                        className={`form-control text-center
                                                                    ${creditCardForm.errors && creditCardForm.errors[field.id] ? 'border-danger' : ''}`}
                                                        placeholder={field.placeholder}
                                                        // options={{
                                                        //     ...field.options,
                                                        //     className: "form-control text-center"
                                                        // }}
                                                        onChange={
                                                            (e) => this.handleChange(e.target.id, e.target.value, field.validators)
                                                        }
                                                    />
                                                    {
                                                        creditCardForm.errors && creditCardForm.errors[field.id] &&
                                                        creditCardForm.errors[field.id].map(errorMessage => (
                                                            <small
                                                                className='input-help-text form-text text-left alert-text'>
                                                                {errorMessage}
                                                            </small>
                                                        ))
                                                    }
                                                </div>
                                            </div>)
                                    })
                                }

                            </div>
                        </div>

                        <p className="fs_small grey-text full-width-credit-card">
                            <IntlMessages id={'credit-card-form.billing-address.title'}/>
                        </p>
                        {
                            this.forms.address.fields.map(field => {
                                return <div key={field.id}
                                            onFocus={() => {
                                                this.setState({currentId: field.id});
                                            }}
                                            className={`form-group ${field.columnWidth} pr-0 ${field.type === 'select' || field.type === 'countryCode' ? '' : ''} ${field.id === formsConstants.CARD_BILLING_ZIP ? "pr-0" : ''}`}
                                            style={{zIndex: field.id === formsConstants.CARD_BILLING_STATE ? this.state.currentId === field.id ? 1000 : 5 : 1}}
                                >
                                    {
                                        this.fieldInputSwitch(field, creditCardForm)
                                    }
                                </div>
                            })
                        }
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({forms, settings}) {
    const creditCardForm = forms[formsConstants.CREDIT_CARD];
    const {lists} = settings;

    return {
        creditCardForm,
        lists,
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(CreditCardForm));
export {connectedPage as CreditCardForm};