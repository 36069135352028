import {carmelConstants, defaultConstants, generalConstants} from "../../constants";
import {googleMapsConstants} from './googleMaps.constants'
import axios from "axios";
import {generalService} from "../general/services";

export const googleMapsService = {
    getAutocomplete,
    getPlaceById,
    getPlaceByReverseGeoCoding,
    getTimeZone,
};

function getAutocomplete(input, location = defaultConstants.location) {
    const URL = `https://ride-api-dev.briz.com/api/autocomplete?input=${input}&location=${location}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    return requestSender(URL, requestOptions)
}

function getPlaceById(placeID) {
    const baseURL = 'https://briz.com/api/';
    const action = 'details';
    const params = `placeid=${placeID}`;
    const URL = `${baseURL}${action}?${params}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    return requestSender(URL, requestOptions)
}

function requestSender(URL, requestOptions) {
    console.log(URL, " Request: ", requestOptions);

    return axios(URL, requestOptions)
        .then(generalService.handleAxiosResponse)
        .then(response => {

            console.log(URL, " Response: ", response);

            if (response.status !== googleMapsConstants.OK) {
                return Promise.reject({
                    ...response,
                    // Todo - Add this string into the Intl string file
                    error_message: "Could not recognize your address"
                });
            }

            return response
        })
}

function getPlaceByReverseGeoCoding(placeLatLong) {
    const params = {latlng: placeLatLong};
    const URL = `${generalConstants.GOOGLE_MAIN_URL}${generalConstants.GOOGLE_REVERSE_GEO_CODING}`;

    return googleRequestSender(URL, params)
}

function getTimeZone(location) {
    const targetDate = new Date();// Current date/time of user computer
    const timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60; // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC
    const params = {
        location: `${location.latitude},${location.longitude}`,
        timestamp: timestamp
    };
    const URL = `${generalConstants.GOOGLE_MAIN_URL}${generalConstants.GOOGLE_TIME_ZONE}`;

    return googleRequestSender(URL, params)
}

function googleRequestSender(URL, params) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            ...params,
            key: generalConstants.GOOGLE_API_KEY,
            language: 'en'
        }
    };
    console.log(URL, " Request: ", requestOptions);
    return axios.get(`${URL}json`, requestOptions)
        .then(generalService.handleAxiosResponse)
        .then(response => {
            console.log(URL, " Response: ", response);

            if (response.status !== googleMapsConstants.OK) {
                return Promise.reject({
                    ...response,
                    // Todo - Add this string into the Intl string file
                    error_message: "Could not recognize your address"
                });
            }

            return response
        })
}
