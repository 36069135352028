import {carmelConstantsActions, uiConstants} from "../../../constants";

const INIT_STATE = {
    sideMenuOpen: false,
    buttonsDisable: false,
    openDropField: false,
    openTimeField: false,
    validationCodeSend: false
};

export function general(state = INIT_STATE, action) {
    switch (action.type) {
        case uiConstants.TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuOpen: action.sideMenuOpen,
            };

        case uiConstants.DISABLE_BUTTONS:
            return {
                ...state,
                buttonsDisable: true,
            };
        case uiConstants.UNABLE_BUTTONS:
            return {
                ...state,
                buttonsDisable: false,
            };
        case uiConstants.OPEN_DROP_FIELD:
            return {
                ...state,
                openDropField: true,
            };

        case uiConstants.CLOSE_DROP_FIELD:
            return {
                ...state,
                openDropField: false,
            };
        case uiConstants.OPEN_TIME_FIELD:
            return {
                ...state,
                openTimeField: true,
            };
        case uiConstants.CLOSE_TIME_FIELD:
            return {
                ...state,
                openTimeField: false,
            };
        case carmelConstantsActions.CUST_ACTIVATE_VALIDATION_CODE_SUCCESS:
            return {
                ...state,
                validationCodeSend: true,
            };
        case uiConstants.RESET_CUST_ACTIVATE:
            return {
                ...state,
                validationCodeSend: false,
            };
        case uiConstants.SET_DRIVER_PICKED_RATING_TO_DISPLAY:
            return {
                ...state,
                driverRatingDisplay : {
                    tripId: action.tripId,
                    driverRating: action.driverRating
                }

            };

        default:
            return state;
    }
}