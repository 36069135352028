import {uiConstants, addressConstants} from '../../constants';
import {ActionTypes} from './types'

export const uiActions = {
    showPopUp,
    hidePopUp,
    setPopUpTitle,
    setPopUpMessage,
    setPopUpButtons,
    setPopUpInputs,
    createBasicPopup,
    currentPage,
    updateAddressDescription,
    clearAddressDescription,
    hideSpinner,
    displaySpinner,
    showAirportSelect,
    closeAirportSelect,
    showModal,
    closeModal,
    removeModal,
    setPopUpAction,
    clearModalsArr,
    currentSwiperCard,
    resetCustActivate,
    setRateDriverDisplay
};


function showPopUp() {
    return {
        type: uiConstants.POP_UP_SHOW
    }
}

export function currentPage(page) {
    return {
        type: ActionTypes.CURRENT_PAGE,
        page
    }
}

function hidePopUp(input) {
    return {
        type: uiConstants.POP_UP_HIDE,
        input
    }
}

function setPopUpTitle(title) {
    return {
        type: uiConstants.POP_UP_SET_TITLE,
        title
    }
}

function setPopUpMessage(message) {
    return {
        type: uiConstants.POP_UP_SET_MASSAGE,
        message
    }
}

function setPopUpMode(input) {
    return {
        type: uiConstants.POP_UP_SET_MODE,
        input
    }
}

function displaySpinner(text) {
    return {
        type: uiConstants.DISPLAY_SPINNER,
        text
    }
}

function hideSpinner() {
    return {
        type: uiConstants.HIDE_SPINNER,
    }
}


function createBasicPopup(title, message) {
    return dispatch => {
        if (!title && !message) {
            return;
        }
        try {
            dispatch(setPopUpTitle((title ? title : "Attention")));
            dispatch(setPopUpMessage((message)));
            dispatch(showPopUp());
        } catch (e) {
            return {
                type: uiConstants.CREATE_BASIC_POP_UP_FAIL
            }
        }
    }
}
function setPopUpAction(action) {
    return {
        type: uiConstants.POP_UP_SET_ACTION,
        action
    }
}

function setPopUpButtons(input) {
    return {
        type: uiConstants.POP_UP_BUTTONS,
        input
    }
}function setPopUpInputs(input) {
    return {
        type: uiConstants.POP_UP_INPUTS,
        input
    }
}

function updateAddressDescription(addressDescription, addressType) {
    return {
        type: addressConstants.UPDATE_ADDRESS_DESCRIPTION,
        addressDescription,
        addressType
    }
}

function clearAddressDescription(addressType) {
    return {
        type: addressConstants.CLEAR_ADDRESS_DESCRIPTION,
        addressType
    }
}

function showAirportSelect() {
    return dispatch => {
        //disable the buttons of the main map page
        // dispatch(uiActions.disableButtons());
        dispatch(showAirportForm())

    };

    function showAirportForm() {
        return {
            type: uiConstants.AIRPORT_SELECT,
        }
    }
}

function closeAirportSelect() {
    return dispatch => {
        //enable the buttons of the main map page
        // dispatch(uiActions.enableButtons());
        dispatch(closeAirportForm())
    };

    function closeAirportForm() {
        return {
            type: uiConstants.CLOST_AIRPORT_SELECT,
        }
    }
}

function showModal(modal) {
    document.body.classList.add('modal-open');
    return {
        type: uiConstants.SHOW_MODAL,
        modal
    }
}

function closeModal() {
    return {
        type: uiConstants.CLOSE_MODAL,
    }
}

function removeModal(modal) {
    return {
        type: uiConstants.REMOVE_MODAL,
        modal
    }
}

function clearModalsArr(modal) {
    return {
        type: uiConstants.ClEAR_MODALS,
    }
}

function currentSwiperCard(currentCard) {
    return {
        type: uiConstants.CURRENT_CARD,
        currentCard
    }
}

function resetCustActivate() {
    return {
        type: uiConstants.RESET_CUST_ACTIVATE
    }
}

function setRateDriverDisplay(tripId,driverRating) {
    return {
        type: uiConstants.SET_DRIVER_PICKED_RATING_TO_DISPLAY,
        tripId,
        driverRating
    }
}