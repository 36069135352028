import {uiConstants, urls} from "../../../constants";
import {ActionTypes} from "../types";

const INIT_STATE = {
    currentUrl: urls.MAIN_PAGE,
    currentCard: -1
};

export function pageUi(state = INIT_STATE, action) {

    switch (action.type) {
        case ActionTypes.CURRENT_PAGE:
            return {
                ...state,
                currentUrl: action.page
            };
        case uiConstants.CURRENT_CARD:
            return {
                ...state,
                currentCard: action.currentCard
            };

        default:
            return state;
    }
}
