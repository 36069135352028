import {tripConstants, uiConstants} from "../../../constants";

export function Fop(state = {}, action) {
    switch (action.type) {
        case tripConstants.UPDATE_INFO_FOP:
            return {
                ...state,
                ...action.fop
            };
        case tripConstants.UPDATE_INFO_PAYMENT_METHOD:
            return {
                ...action.paymentMethod,
                fopCode: action.paymentMethod.fopCode,
                fopTypeCode: action.paymentMethod.fopTypeCode,
            };
        case tripConstants.UPDATE_FARE_PAYMENT_METHOD:
            return {
                ...state,
                prepayType: action.method
            };

        case tripConstants.UPDATE_FOP:
            return {
                ...state,
                ...action.fop
            };

        case uiConstants.CLEAR_SELECTED_PRICE:
            return {};

        case uiConstants.CLEAR_FOP:
            return {};
        default:
            return state;
    }
}