import {formsConstants, tripPaymentConstants} from "../../constants";
import {formsActions} from "../actions";

export const tripPaymentActions = {
    selectPrepayMethod,
    setChargeType,
    clearTripChargeData,
};

function selectPrepayMethod(prepayType) {
    return dispatch => {
        dispatch(updateFareTotal(prepayType));

        dispatch(formsActions.updateFieldsValue(formsConstants.PAYMENT_FORM, {
            [formsConstants.PAYMENT_METHOD]: prepayType
        }));

        return {
            type: tripPaymentConstants.UPDATE_PREPAY_TYPE,
            prepayType
        }
    };
}

function updateFareTotal(prepayType) {
    return {
        type: tripPaymentConstants.UPDATE_FARE_TOTAL
    }
}



function setChargeType(chargeType) {
    return {
        type: tripPaymentConstants.SET_CHARGE_TYPE,
        chargeType
    }
}



function clearTripChargeData() {
    return {
        type: tripPaymentConstants.CLEAR_TRIP_CHARGE
    }
}
