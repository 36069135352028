import {formsConstants} from "../../constants";
import {tripConstants} from "../../constants/trip.constants";
import {userConstants} from "../../constants/user.constants";
import {uiConstants} from "../../constants/";


export function forms(state = {invalidHouseAccountFields: '', required: {}}, action) {
    switch (action.type) {
        case formsConstants.UPDATE_FIELDS_VALUE:
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    ...action.fields_to_update
                }
            };
        case formsConstants.CLEAR_FORM_FIELDS:
            return {
                ...state,
                [action.form]: {
                    [action.fields_to_clear]: ''
                }
            };
        case formsConstants.SET_FORM_FIELD_ERRORS:
            if (state[action.form] && state[action.form].isValid) {
                delete state[action.form].isValid[action.fieldName];
            }

            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    errors: {
                        ...state[action.form].errors,
                        [action.fieldName]: [action.errors]
                    }
                }
            };
        case formsConstants.CLEAR_ALL_FORM_FIELDS:
            delete state[action.form.form];
            delete state[action.form];
            return {
                ...state,
            };
        case formsConstants.SET_FIELD_IS_VALID:
            if (state[action.form] && state[action.form].errors) {
                delete state[action.form].errors[action.fieldName];
            }

            const previousIsValid = state[action.form] ? state[action.form].isValid || {} : {};

            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    isValid: {
                        ...previousIsValid,
                        [action.fieldName]: true
                    }
                }
            };
        case formsConstants.SET_FOCUS_ON_FIELD:
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    onFocus: action.fieldName
                }
            };
        case formsConstants.CLEAR_FOCUS_ON_FIELD:
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    onFocus: '',
                }
            };
        case tripConstants.INVALID_HOUSE_ACCOUNT:
            return {
                ...state,
                invalidHouseAccountFields: action.message
            };

        case userConstants.REGISTRATION_MISSING_INFO:
            return {
                ...state,
                required: action.result
            };
        case uiConstants.CLEAR_MISSING_INFO:
            return {
                ...state,
                required: {}
            };
        case userConstants.SUBMITTED_REGISTRATION_FORM:
            return {
                ...state,
                isSubmittedRegistrationForm: action.isSubmittedRegistrationForm
            };
        default:
            return state;
    }
}
