import React, {useEffect} from "react";
import {connect} from 'react-redux';

import {AirLineForm} from "./AirLineForm";
import {formsActions, uiActions} from "../../../../store/actions";
import {formsConstants} from "../../../../constants";
import {findDOMNode} from "react-dom";
import $ from "jquery";

const AirLineInfo = ({airportToggle, dispatch, airportFormErrors}) => {
    let displayRef = null;
    useEffect(() => {
        dispatch(formsActions.updateFieldsValue(
            formsConstants.AIRLINE_FORM,
            {
                [formsConstants.AIRLINE_NAME]: '',
                [formsConstants.FLIGHT_NUMBER]: '',
                [formsConstants.ARRIVE_CITY]: '',
            }
        ))
    }, []);
    useEffect(() => {
        const el = findDOMNode(displayRef);
        if (airportToggle) {
            //hide route
            // dispatch(carLocationActions.hideRoute());
            //close time field
            //reset form
            dispatch(formsActions.updateFieldsValue(
                formsConstants.AIRLINE_FORM,
                {
                    [formsConstants.AIRLINE_NAME]: '',
                    [formsConstants.FLIGHT_NUMBER]: '',
                    [formsConstants.ARRIVE_CITY]: '',
                }
            ));
            // slide animation
            $(el).slideToggle("slow", function () {
                $(el).css({'height': '100%', 'width': '100%', 'opacity': '1'})
            })

        } else {
            dispatch(formsActions.clearAllFormFields(formsConstants.AIRLINE_FORM));
            $(el).hide(500)
        }
    }, [airportToggle]);


    return (
        <div className="f-row" ref={ref => displayRef = ref}
             style={{display: 'block', height: '100% !important', width: '100% !important'}} id={'order-3'}>
            <AirLineForm airportFormErrors={airportFormErrors}/>
        </div>
    )
};

function mapStateToProps({ui}) {
    const {airportToggle} = ui.toggle;
    return ({
        airportToggle
    })
}

const connectedPage = React.memo(connect(mapStateToProps)(AirLineInfo));
export {connectedPage as AirLineInfo};