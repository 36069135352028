import React from 'react'
import $ from 'jquery'

export const Intro = ({loggedIn, name}) => {
    const scrollTo = (id) => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(id).offset().top - 80 // scroll to ellement minus nav bar height (79px)
        }, 1000);
    };
    return (
        <div className="intro">
            <div className="wrap">
                <div className="textwidget">
                    <h1 className="wow fadeInDown">
                       {loggedIn && name? `Hello ${name}!`: 'Need a ride?'}
                    </h1>
                    <h2 className="wow fadeInUp">You've come to the right place.</h2>
                    <div className="actions">
                        <button title="Our services" className="btn large white wow fadeInLeft anchor"
                           onClick={() => scrollTo('#services')}>
                            Our services
                        </button>
                        <button title="Make a booking"
                           className="btn large color wow fadeInRight anchor" onClick={() => scrollTo('#booking')}>
                            FIND A RIDE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}