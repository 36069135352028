import {combineReducers} from 'redux';

import {credentials} from './credentials.reducer';
import {facebookLogin} from './facebookLogin.reducer';
import {CoreCredentials} from './coreCredentials.reducer';
import {authentication as auth} from './authentication.reducer';



export const authentication = combineReducers({
    Credentials: credentials,
    facebookLogin,
    CoreCredentials,
    auth
});
