import React, {useEffect, useState} from 'react'
import {appHelp} from "../../helpers/app";
import {modalsTypes} from "../../constants/ui/modals.constants";
import CreditCardModal from './CreditCardModal'
import CountryListModal from './CountryListModal'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import PasswordRecoveyModal from "./PasswordRecoverModal";
import TerminalList from "./TermialList";
import ShowMyCar from "./ShowMyCar";
import RateDriver from "./RateDriver";
import TripDetailsModal from "./TripDetailsModal";
import CustActivate from "./CustActivate";
import IFrameLoader from "./IFrameLoader";

const ModalCollector = ({modalsUi}) => {
    const [modalsComponents, setModals] = useState([]);

    useEffect(() => {
        const {modals} = modalsUi;
        let modalsCollector = [];

        if (appHelp.validateArr(modals)) {
            //collect all modals that currency open for display
            modals.forEach((modal) => {
                switch (modal) {
                    case modalsTypes.CREDIT_CARD:
                        modalsCollector = [...modalsCollector, <CreditCardModal modalType={modal}/>];
                        break;
                    case modalsTypes.COUNTRIES:
                    case modalsTypes.CREDIT_CARD_COUNTRIES:
                    case modalsTypes.COUNTRIES_ICE:
                        modalsCollector = [...modalsCollector, <CountryListModal modalType={modal}/>];
                        break;
                    case modalsTypes.PASSWORD_RECOVERY:
                    case modalsTypes.PASSWORD_RECOVERY_BY_MAIL:
                        modalsCollector = [...modalsCollector, <PasswordRecoveyModal modalType={modal}/>];
                        break;
                    case modalsTypes.TERMINAL_PICK:
                        modalsCollector = [...modalsCollector, <TerminalList modalType={modal}/>];
                        break;
                    case modalsTypes.SHOW_MY_CAR:
                        modalsCollector = [...modalsCollector, <ShowMyCar modalType={modal}/>];
                        break;
                    case modalsTypes.TRIP_DETAILS:
                        modalsCollector = [...modalsCollector, <TripDetailsModal modalType={modal}/>];
                        break;
                    case modalsTypes.RATE_DRIVER:
                        modalsCollector = [...modalsCollector, <RateDriver modalType={modal}/>];
                        break;
                    case modalsTypes.TERMS_AND_CONDITIONS:
                    case modalsTypes.TERMS_MODAL:
                    case modalsTypes.PRIVACY_MODAL:
                        modalsCollector = [...modalsCollector, <IFrameLoader modalType={modal}/>];
                        break;
                    case modalsTypes.CUST_ACTIVATE:
                    case modalsTypes.CUST_ACTIVATE_FROM_REGISTER:
                        modalsCollector = [...modalsCollector, <CustActivate modalType={modal}/>];
                        break;
                    default:
                        modalsCollector = [...modalsCollector];

                }
            })
        }
        if (modalsComponents.length > modalsCollector.length) {
            //set timeout for closing animation effect
            setTimeout(() => {
                setModals(modalsCollector)
            }, 500)
        } else {
            setModals(modalsCollector)
        }

    }, [modalsUi]);

    if (modalsComponents.length > 0)
        return (
            [...modalsComponents]
        );
    else
        return null;
};

function mapStateToProps({ui}) {
    const {modalsUi} = ui;
    return {
        modalsUi,
    };
}

export default React.memo(injectIntl(connect(mapStateToProps)(ModalCollector)));