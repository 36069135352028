import React, {Component} from "react";
import {connect} from 'react-redux';
import {injectIntl} from "react-intl";
import {formsActions, tripActions} from "../../../store/actions";
import {formsConstants} from "../../../constants";
import IntlMessage from "../../../utils/IntlMessages";


class ChooseTipPracent extends Component {
    constructor(props) {
        super(props);

        this.smallPresent = React.createRef();
        this.medPresent = React.createRef();
        this.bigPresent = React.createRef();
    }

    componentDidMount() {
        const {fullFopTypeList, creditCardForm} = this.props;
        if (creditCardForm && creditCardForm[formsConstants.TIP_PERCENT]) {
            if (creditCardForm[formsConstants.TIP_PERCENT] === 0.15) {
                this.selectSmall();
            } else if (creditCardForm[formsConstants.TIP_PERCENT] === 0.20) {
                this.selectMed();
            } else {
                this.selectBig();
            }
        } else if (fullFopTypeList && fullFopTypeList.hasOwnProperty('tipOptionDefaultSeq')) {
            if (fullFopTypeList.tipOptionDefaultSeq === 1) {
                this.selectSmall();
            } else if (fullFopTypeList.tipOptionDefaultSeq === 2) {
                this.selectMed();
            } else {
                this.selectBig();
            }
        } else {
            this.selectMed();
        }
    }

    selectSmall = () => {
        this.smallPresent.current.classList.add('active');
        this.medPresent.current.classList.remove('active');
        this.bigPresent.current.classList.remove('active');
        this.updateTip(0.15);
    };
    selectMed = () => {
        this.smallPresent.current.classList.remove('active');
        this.medPresent.current.classList.add('active');
        this.bigPresent.current.classList.remove('active');
        this.updateTip(0.20);
    };
    selectBig = () => {
        this.smallPresent.current.classList.remove('active');
        this.medPresent.current.classList.remove('active');
        this.bigPresent.current.classList.add('active');
        this.updateTip(0.25);
    };

    updateTip = (tipPercent) => {
        const {dispatch, creditCardForm} = this.props;
        if (creditCardForm) {
            dispatch(formsActions.updateFieldsValue(
                formsConstants.CREDIT_CARD,
                {
                    [formsConstants.TIP_PERCENT]: tipPercent,
                }
            ))
        }
    };

    render() {
        return (
            <div className="row">
                <div className="full-width">
                    <div className="cc_tip_div" style={{display: this.props.display}}>
                        <p>
                            <IntlMessage id='credit-card-modal.credit-card-prepay.all-inclusive.percentage-title'/>
                        </p>
                        <div className="btn-group cc_btn_group" data-toggle="buttons">
                            <a className="btn one-third-credit-card" ref={this.smallPresent} onClick={this.selectSmall}>
                                <input type="radio" name="tip_percentage" className={'one-third-credit-card'}/>
                                15%
                            </a>
                            <a className="btn one-third-credit-card" ref={this.medPresent} onClick={this.selectMed}>
                                <input type="radio" name="tip_percentage" className={'one-third-credit-card'}/>
                                20%
                            </a>
                            <a className="btn active one-third-credit-card" ref={this.bigPresent} onClick={this.selectBig}>
                                <input type="radio" name="tip_percentage" />
                                25%
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({temp, forms, settings}) {
    const {tipOptions} = temp.objects;
    const creditCardForm = forms[formsConstants.CREDIT_CARD];
    const {fullFopTypeList} = settings.lists;
    return {
        tipOptions,
        creditCardForm,
        fullFopTypeList
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(ChooseTipPracent));
export {connectedPage as ChooseTipPracent};