export const addressConstants = {
    // Action names
    PARSE_SELECTED_ADDRESS_BY_ID_PARSING: 'PARSE_SELECTED_ADDRESS_BY_ID_PARSING',
    PARSE_SELECTED_ADDRESS_BY_ID_SUCCESS: 'PARSE_SELECTED_ADDRESS_BY_ID_SUCCESS',
    PARSE_SELECTED_ADDRESS_BY_ID_FAILURE: 'PARSE_SELECTED_ADDRESS_BY_ID_FAILURE',

    PARSE_ADDRESS_COMPONENT_BY_ID_PARSING: 'PARSE_ADDRESS_COMPONENT_BY_ID_PARSING',
    PARSE_ADDRESS_COMPONENT_BY_ID_SUCCESS: 'PARSE_ADDRESS_COMPONENT_BY_ID_SUCCESS',
    PARSE_ADDRESS_COMPONENT_BY_ID_FAILURE: 'PARSE_ADDRESS_COMPONENT_BY_ID_FAILURE',

    PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_PARSING: 'PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_PARSING',
    PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_SUCCESS: 'PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_SUCCESS',
    PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_FAILURE: 'PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_FAILURE',

    PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_PARSING: 'PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_PARSING',
    PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_SUCCESS: 'PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_SUCCESS',
    PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_FAILURE: 'PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_FAILURE',

    PARSE_ADDRESS_COMPONENT_SUCCESS: 'PARSE_SELECTED_ADDRESS_SUCCESS',
    PARSE_ADDRESS_COMPONENT_FAILURE: 'PARSE_SELECTED_ADDRESS_FAILURE',

    SET_ADDRESS_OBJECT: 'SET_ADDRESS_OBJECT',
    PICK_UP_ADDRESS_CHANGED: 'PICK_UP_ADDRESS_CHANGED',
    CLEAR_ADDRESS: 'CLEAR_ADDRESS',
    DETECT_CURRENT_LOCATION: 'DETECT_CURRENT_LOCATION',
    CLEAR_SERVICE_BY_ADDRESS: 'CLEAR_SERVICE_BY_ADDRESS',
    UPDATE_ADDRESS: 'UPDATE_ADDRESS',

    UPDATE_ADDRESS_DESCRIPTION: 'UPDATE_ADDRESS_DESCRIPTION',
    CLEAR_ADDRESS_DESCRIPTION: 'CLEAR_ADDRESS_DESCRIPTION',
    UPDATE_REGISTRATION_FORM: 'UPDATE_REGISTRATION_FORM',

    UPDATE_FOP_FORM: 'UPDATE_FOP_FORM',
    UPDATE_FOP_CARD_SEQUENCE: 'UPDATE_FOP_CARD_SEQUENCE',

    UPDATE_SELECTED_PRICE: 'UPDATE_SELECTED_PRICE',
    UPDATE_SELECTED_TIP: 'UPDATE_SELECTED_TIP',

    CLEAR_SELECTED_PRICE: 'CLEAR_SELECTED_PRICE',

    ADD_ADDRESS_PREDICTION: 'ADD_ADDRESS_PREDICTION',
    CLEAR_ADDRESS_PREDICTION: 'CLEAR_ADDRESS_PREDICTION',

    // Names
    PICK_UP_ADDRESS: 'addrPu',
    DROP_OFF_ADDRESS: 'addrDo',
    CURRENT_LOCATION_ADDRESS: 'currentLocation',

    CONTINUE_ADDRESS_PROGRESS: 'CONTINUE_ADDRESS_PROGRESS',
    DROP_OFF_ON_HOLD: 'DROP_OFF_ON_HOLD',
    CLEAR_DROP_OFF_ON_HOLD: 'CLEAR_DROP_OFF_ON_HOLD',

    CLEAR_TRIP_FIELDS: 'CLEAR_TRIP_FIELDS',
    AIRPORT_ADDRESS_PICK: 'AIRPORT_ADDRESS_PICK'

};