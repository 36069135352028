import {tripConstants} from "../../../constants";


export function Fare(state = {}, action) {
    switch (action.type) {
        case tripConstants.UPDATE_INFO_FARE:
            return {
                ...state,
                ...action.custFare
            };
        case tripConstants.UPDATE_FARE_PAYMENT_METHOD:
            return action.fare;
        case tripConstants.UPDATE_CAR_CASH_AMOUNT:

            if (action.useCarCash) {
                state = {
                    ...state,
                    carCashAmt: action.carCashAmt,
                };
            } else {
                delete state.carCashAmt
            }
            return state;

        default:
            return state;
    }
}