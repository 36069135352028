import {formsConstants, generalConstants} from "../../constants";

export const generalActions = {
    currentCreditCard,
    resendFunctionAfterCustActive,
    openDebugArgsPopup,
    closeDebugArgsPopup,
    setDebugArgs
};

function currentCreditCard(cardSeq) {
    return {
        type: generalConstants.CURRENT_CREDIT_CARD,
        cardSeq
    }
}

function resendFunctionAfterCustActive(resendFunc) {
    return {
        type: generalConstants.RESEND_FUNC,
        resendFunc
    }
}

function openDebugArgsPopup() {
    return {
        type: generalConstants.OPEN_DEBUG_ARGS_POP_UP
    }
}

function closeDebugArgsPopup() {
    return {
        type: generalConstants.CLOSE_DEBUG_ARGS_POP_UP
    }
}

function setDebugArgs(input) {
    return {
        type: generalConstants.SET_DEBUG_ARGS,
        input
    }
}
