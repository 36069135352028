import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"
import {createLogger} from 'redux-logger';
import {rootReducer} from './root';

const loggerMiddleware = createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfiq = {
    key: 'root',
    storage: storage,
    blacklist: ['ui', 'forms', 'temp', 'trip', 'googleMaps', 'user'],

};

const persistedReducer = persistReducer(persistConfiq, rootReducer);

export const store = (process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'stage')
    ? createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(thunk, loggerMiddleware))
    )
    : createStore(
        persistedReducer,
        applyMiddleware(thunk)
    );



