import {
    addressConstants,
    carmelConstantsActions,
    carmelObjectNames,
    tripConstants,
    uiConstants
} from "../../../constants";
import {googleMapsConstants} from '../../googleMaps/googleMaps.constants'
import {Car} from './Car.reducer';
import {Fare} from './Fare.reducer';
import {Fop} from './Fop.reducer';


export const tripFields = (state, action) => {
    return {
        car: Car(state.car, action),
        fare: Fare(state.fare, action),
        fop: Fop(state.fop, action),
    }
};

export function trip(state = {}, action) {
    switch (action.type) {
        // ACTIONS RELATED TO ADDRESS
        case addressConstants.UPDATE_ADDRESS:
            return {
                ...state,
                [action.addressType]: action.address,
            };
        case addressConstants.CLEAR_ADDRESS:
            delete state[action.addressType];

            return {
                ...state,
            };
        case uiConstants.CLEAR_TRIP:
            return {
                ...state,
                [action.tripType]: {}
            };
        case addressConstants.PICK_UP_ADDRESS_CHANGED:
            return {
                ...state,
                [addressConstants.DROP_OFF_ADDRESS]: {}
            };
        case addressConstants.PARSE_SELECTED_ADDRESS_BY_ID_FAILURE:
            return {
                ...state,
                [action.addressType]: {}
            };

        case addressConstants.PARSE_SELECTED_ADDRESS_BY_ID_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    ...action.result
                }
            };
        case googleMapsConstants.GET_TIME_ZONE_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    timeZoneId: action.timeZone
                }
            };
        case addressConstants.PARSE_ADDRESS_COMPONENT_BY_ID_FAILURE:
            return {
                ...state,
                [action.addressType]: {}
            };
        case addressConstants.PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_FAILURE:
            return {
                ...state,
                [action.addressType]: {}
            };
        case addressConstants.PARSE_ADDRESS_COMPONENT_BY_ID_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    ...action.result
                }
            };
        case addressConstants.PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    ...action.result
                }
            };
        case carmelConstantsActions.SERVICE_BY_ADDRESS_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    ...action.serviceByAddress.addr,
                    addrPriceSupported: action.serviceByAddress.addrPriceSupported,
                },
                // serviceByAddress: action.serviceByAddress
            };
        case carmelConstantsActions.ADDRESS_GENERATION_FAILURE:
            return {
                ...state,
                serviceByAddr: false
            };
        //    After "get place by id" Google API call set the object onto trip metaData
        case googleMapsConstants.GET_PLACE_BY_ID_SUCCESS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    metaData: JSON.stringify(action.googlePlacesObj)
                }
            };
        // Replace\Overwrite address with selected object from list
        case addressConstants.SET_ADDRESS_OBJECT:
            return {
                ...state,
                [action.addressType]: action.fromAirportAll
            };
        case tripConstants.DATE_UPDATE:
            return {
                ...state,
                [carmelObjectNames.TRIP_DATE]: action.date
            };
        case tripConstants.TIME_UPDATE:
            return {
                ...state,
                [carmelObjectNames.TRIP_TIME]: action.time
            };

        case tripConstants.RESET_TRIP:
            return {
                ...tripFields({}, action)
            };
        case tripConstants.ADD_AIRPORT_DETAILS:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    ...action.airportDetails
                }
            };

        case tripConstants.SET_CURRENT_LOCATION_AS_PICK_UP:
            return {
                ...state,
                [addressConstants.PICK_UP_ADDRESS]: {...state[addressConstants.CURRENT_LOCATION_ADDRESS]},
                updatePickup: false
            };
        case tripConstants.REPLACE_CURRENT_WITH_PICK_UP:
            return {
                ...state,
                updatePickup: true
            };
        case tripConstants.UPDATE_CAR_POOL:
            return {
                ...state,
                carPoolAllowed: action.carPoolAllowed ? action.carPoolAllowed : false
            };
        case addressConstants.CLEAR_TRIP_FIELDS:
            return {
                ...tripFields({}, action)
            };
        case tripConstants.DELETE_INFO_FARE:
            return {
                ...state,
                fare: {}
            };

        case
        tripConstants.UPDATE_CAR_CASH_USE
        :
            return {
                ...state,
                useCarCash: !state.useCarCash

            };

        default:
            return {
                ...state,
                ...tripFields(state, action)
            };
    }
}