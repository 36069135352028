import React, {Fragment, useState} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ModalWrapper from "../ModalWrapper";
import TripDetailsHeader from "./TripDetailsHeader";
import SingleTrip from "../../MyTrips/TripsContainer/SingleTrip";
import TripDetailsBody from "./TripDetailsBody";
import {uiActions} from "../../../store/ui/actions";
import {modalsTypes} from "../../../constants/ui/modals.constants";
import about from "../../../assets/images/about.png";

const TripDetailsModal = ({dispatch, modalType, trips, intl}) => {
    const [closeModal, setCloseModal] = useState(false);
    const saveToFavorite = () => {
        dispatch(uiActions.showModal(modalsTypes.SAVE_FAVORITE))
    };
    if (!trips || !trips.trip)
        return null;
    return (
        <ModalWrapper modalType={modalType} title={'Trip details'}
                      closeModalIndicator={closeModal} headerWithX={true}>
            <div className="modal-full-height-overflow">
                <TripDetailsHeader trip={trips.trip}/>
                <div className='f-row'>
                    <SingleTrip trip={trips.trip} hideTripOptions={true}/>
                </div>
                <article className='result no-shadow f-row'>
                    <TripDetailsBody trip={trips.trip}/>
                </article>
                <div className='f-row'>
                    {
                        trips.trip.displayTextList && trips.trip.displayTextList[0] &&
                        <Fragment>
                            <hr/>
                            <div className='text-image-row'>
                                <img src={about}/>
                                <span className=' text-left'>
                        {trips.trip.displayTextList[0].text}
                        </span>
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </ModalWrapper>
    )
};

function mapStateToProps({user}) {
    const {trips} = user.lists;
    return {
        trips
    }
}

export default injectIntl(connect(mapStateToProps)(TripDetailsModal));