import {combineReducers} from 'redux';

import {trips} from './trips.reducer';
import {addresses} from './addresses.reducer';
import {carCash} from './carCash.reducer';
import {fop} from './fop.reducer';
import {rewards} from './rewards.reducer';
import {referralList as referral} from './referralList.reducer';


export const lists = combineReducers({
    trips,
    addresses,
    carCash,
    fop,
    rewards,
    referral
});