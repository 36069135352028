export const tripPaymentConstants = {
    // Actions
    UPDATE_PREPAY_TYPE: 'UPDATE_PREPAY_TYPE',
    UPDATE_FARE_TOTAL: 'UPDATE_FARE_TOTAL',
    SET_CHARGE_TYPE: 'SET_CHARGE_TYPE',
    CLEAR_TRIP_CHARGE: 'CLEAR_TRIP_CHARGE',

    // Constant Names
    PAYMENT_METHOD_ALL_INCLUSIVE: 'AI',
    PAYMENT_METHOD_BASE_FARE: 'BASE',
    PAYMENT_METHOD_DEFAULT: 'DFLT',

    CHARGE_TYPE_PREPAY: 'PrepayRide',
    CHARGE_TYPE_EXTRA_CHARGE: 'ExtraCharge',

    //Placeholders
    TOLLS_AMOUNT_PLACEHOLDER: 'Tolls Amount',
    MISC_AMOUNT_PLACEHOLDER: 'Misc Amount'
};

export const fopCardTypeRegex = {
    AE: /^3[47][0-9]{5,}$/,
    VI: /^4[0-9]{6,}([0-9]{3})?$/,
    MC: /^(5[1-5][0-9]{4}|677189)[0-9]{5,}$/,
    DC: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    DS: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))[0-9]{0,10})|((5067)|(4576)|(4011))[0-9]{0,12})$/,
    Hipercard: /^(606282|3841)[0-9]{5,}$/,
    UnionPay: /^(62|88)[0-9]{5,}$/,
};
