import {facebookConstants,  userConstants} from "../../../constants";


export function cust(state = {}, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                ...action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.user,
                loggedIn: true
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false
            };
        case userConstants.UPDATE_USER_INFO_SUCCESS:
            return {
                ...action.user
            };
        case facebookConstants.FACEBOOK_LOGIN_SUCCESS:
            return {
                ...state,
                ...action.facebookUser.info
            };

        case userConstants.UPDATE_ACCEPT_TERMS_AND_POLICY:
            return {
                ...state,
                isTermsAndPrivacyAccepted: action.isTermsAndPrivacyAccepted
            };

        case userConstants.LOGOUT:
            // delete state.cust
            return {
                ...state,
                // cust: {}
            }
        default:
            return state;
    }
}