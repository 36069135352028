import {modalsTypes, uiConstants} from "../../../constants";
// Modals UI Action
//////////////////////
const INIT_STATE = {
    modals: []
};

export function modalsUi(state = INIT_STATE, action) {
    switch (action.type) {
        case uiConstants.SHOW_MODAL:
            if (state.modals[state.modals.length - 1] === action.modal) {
                return {
                    ...state
                }
            }
            return {
                ...state,
                modals: [...state.modals, action.modal]
            };

        case uiConstants.CLOSE_MODAL:
            return {
                ...state,
                modals: [
                    ...state.modals.slice(0, state.modals.length - 1),
                    ...state.modals.slice(state.modals.length - 1 + 1)
                ]
            };
        case uiConstants.REMOVE_MODAL:
            return {
                ...state,
                modals: state.modals.filter(modal => modal.modal !== action.modal)
            };

        case uiConstants.ClEAR_MODALS:
            return {
                ...state,
                modals: []
            };
        case uiConstants.SHOW_TRIP_DETAILS:
            return {
                ...state,
                hideTripDetails: false
            };
        case uiConstants.HIDE_TRIP_DETAILS:
            return {
                ...state,
                hideTripDetails: true
            };

        default:
            return state;
    }
}
