import React from "react";

export const urls = {
    MAIN_PAGE: '/',
    DESTINATIONS: 'destination',
    DESTINATIONS_SINGLE: 'destination-single',
    DESTINATIONS_MICRO: 'destination-micro',
    MY_TRIPS: 'my-trips',
    PERKS: 'pekrs',
    REFFERAL_CODE: 'refferal-code',
    TRIP_UPDATE: 'trip-update',
    ABOUT: 'about',
    SERVICES: 'services',
    FAQ: 'faq',
    LOGIN: 'login',
    REGISTER: 'register',
    ACCOUNT: 'account',
    TERMS: 'terms',
    HELP: 'help',
    PARTNERS: 'partners',
    THANK_YOU: 'THANK_YOU',
    PAY_FOR_RIDE: 'pay-for-ride',
    TIP_AND_TOLLS: 'tip-and-tolls',
    REWARD_PROGRAM: 'reward-program',
    CONTACT: 'contanct',
    SUPPORT: 'support'
};

export const navigation = [
    {
        url: urls.MAIN_PAGE,
        title: 'Home',
    },

    {
        url: urls.MY_TRIPS,
        title: 'My trips',
        loggedIn: true,
    },

    {
        title: 'Benefits',
        nested: [
            {
                url: urls.REFFERAL_CODE,
                title: 'Use Referral Code',
            },
            {
                url: urls.REWARD_PROGRAM,
                title: 'Reward Program',
            },
        ],
        loggedIn: true,
    },

    {
        url: urls.ACCOUNT,
        title: 'account',
        loggedIn: true
    },
    {
        title: 'Log out',
        loggedIn: true
    },
    {
        url: urls.LOGIN,
        title: 'Login',
        loggedIn: false
    },
    {
        url: urls.REGISTER,
        title: urls.REGISTER,
        loggedIn: false
    }, {
        url: urls.SUPPORT,
        title: 'support',
        class: 'main-color-text'
    },
];

export const footerNavigation = [
    {
        url: urls.MAIN_PAGE,
        title: 'Home'
    },
    {
        url: urls.ABOUT,
        title: 'About us'
    },
    {
        url: urls.SUPPORT,
        title: 'Support'
    },
    {
        url: urls.TERMS,
        title: 'Terms of use'
    },
    {
        url: urls.PARTNERS,
        title: 'For partners'
    },
];