import {interactiveConstants} from "../../../constants";


export function CustArrived(state = {}, action) {
    switch (action.type) {
        // CustArrived Actions
        case interactiveConstants.CUST_ARRIVED_SET_TRIP_ID:
            return {
                tripId: action.tripId,
                markCustArrived: action.markCustArrived,
            };
        case interactiveConstants.CUST_ARRIVED_VERIFY_TERMINAL:
            return {
                ...state,
                markCustArrived: true,
                verifyTerminal: true
            };
        case interactiveConstants.CUST_ARRIVED_SET_TERMINAL_LIST:
            return {
                ...state,
                terminalList: action.terminalList
            };
        case interactiveConstants.CUST_ARRIVED_UPDATE_SELECTED_TERMINAL:
            return {
                ...state,
                terminalId: action.terminalId,
            };
        default:
            return state;
    }
}