import React from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import ContentCard from "./ContentCard";

const TripDetailsHeader = ({general, trip}) => {
    const {driver, car, fare, displayTextList} = trip;
    if (!car || !fare || !displayTextList) {
        return null
    }
    return (
        <div className="f-row" style={{padding: 0}}>
            <div className='flex-row-space-between'>
                <ContentCard contentWidth={driver ? 'one-half-trip-details-image' : 'full-width'}
                             imageUrl={car.carClassPictureUrl}
                             driver={false} title={car.carClassDesc} subTitle={`$ ${(fare.total).toFixed(2)}`}/>
                {
                    driver &&
                    <ContentCard contentWidth={'one-half-trip-details-image'} imageUrl={driver.pictureUrl} driver={true}
                                 title={`${driver.firstName} ${driver.lastName}`}/>
                }
            </div>
            <div className='full-width text-left text-max-length' style={{paddingBottom: 0}}>
                {fare.displayTextList[0].text}
                <hr/>
            </div>
        </div>
    )
}

function mapStateToProps({ui, user}) {
    const {lists} = user;
    const {general} = ui;
    return {
        lists,
        general
    }
}

export default injectIntl(connect(mapStateToProps)(TripDetailsHeader));