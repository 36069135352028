import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ModalWrapper from "./ModalWrapper";

const ShowMyCar = ({dispatch, modalType}) => {
    const [closeModal, setCloseModal] = useState(false);
    const [os, setOs] = useState(null);

    const getOS = () => {
        const userAgent = navigator.userAgent;
        const isIphone = /iPad|iPhone|iPod/.test(userAgent);
        const isAndroid = /(android)/i.test(userAgent);
        return isAndroid ? 'Android' : isIphone ? 'iOS' : 'Other';
    };

    useEffect(() => {
        setOs(getOS());
    }, []);

    return (
        <ModalWrapper modalType={modalType} title={'Show My Car'}
                      closeModalIndicator={closeModal} smallModal={true}>

            <div className="custom-modal-body " style={{marginBottom:'40px'}}>
                <h1 className="card-title text-center top-title">
                    For show my car service please download our app in
                    {
                        (os === 'iOS' || (os === 'Other' || !os)) &&
                        <b className="app-store-name span-as-link"
                           onClick={() => window.open('https://itunes.apple.com/us/app/carmel-car-taxi-limo/id573578466', 'mywindow')}
                        > <br/> App Store
                        </b>
                    }
                    {
                        (os === 'Other' ||  !os) &&
                        <span> or</span>
                    }
                    {
                        (os === 'Android' || (os === 'Other' || !os)) &&
                        <b className="app-store-name span-as-link"
                           onClick={() => window.open('https://play.google.com/store/apps/details?id=com.carmellimo.limousine', 'mywindow')}
                        > Play Store
                        </b>
                    }

                </h1>
            </div>

        </ModalWrapper>
    )
};

function mapStateToProps({}) {
    return {}
}

export default injectIntl(connect(mapStateToProps)(ShowMyCar));