import React, {Fragment} from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {formsActions, uiActions} from "../../../store/actions";
import {formsConstants, modalsTypes} from "../../../constants";
import flag from "../../../assets/images/americanada.png";
import IntlMessages from "../../../utils/IntlMessages";

const PhoneForm = ({dispatch, lists, custActivateForm, disableField, enableField, handleChange}) => {
    if (!custActivateForm) {
        return null;
    }
    let inputRef = null;

    let selectedCountry = null;
    //find cust country in country list for display
    if (custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY]) {
        selectedCountry = lists.CountryList.find(
            option =>
                option &&
                option.phoneCode === custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY]
        );
        if (!selectedCountry)
            selectedCountry = lists.CountryList.find(
                option =>
                    option.phoneCode ===
                    custActivateForm[formsConstants.CUST_ACTIVATE_COUNTRY].phoneCode
            );
    }

    return (
        <Fragment>
            <div className="form-group text-left" style={{zIndex: "100"}}>
                <div className="f-row">
                    <div className={"full-width"}>
                        <label htmlFor="username">Your Country</label>
                        <div className="country_code_sec_payments"
                             onClick={() => {
                                 dispatch(uiActions.showModal(modalsTypes.COUNTRIES));
                             }}
                        >
                            <div className='flag-image-wrapper'>
                                <img
                                    src={selectedCountry && selectedCountry.flagUrl ? selectedCountry.flagUrl : flag}
                                    alt="country_flag"
                                    className="c_flag"
                                />
                                <span
                                    className="c_code ml-2">{selectedCountry && selectedCountry.name ? selectedCountry.name : 'United States'}</span>
                            </div>
                            <div className='' style={{paddingRight: '15px'}}>
                                <span
                                    className="c_code">{selectedCountry.value && selectedCountry.code ? selectedCountry.code : 'US'}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="f-row">
                    <div className={"full-width"}>
                        <label htmlFor="username">Your Phone Number</label>
                        <input
                            type="text"
                            id={formsConstants.CUST_ACTIVATE_PHONE}
                            name="signup_mobile"
                            maxLength="15"
                            className="form-control grayOnFocus"
                            style={{color: !disableField ? '' : 'darkgray'}}
                            placeholder="NUMBER"
                            value={custActivateForm[formsConstants.CUST_ACTIVATE_PHONE]}
                            ref={ref => inputRef = ref}
                            disabled={disableField}
                            onChange={event =>
                                handleChange(event.target.id, event.target.value)
                            }
                        />
                        {
                            disableField &&
                            <div className='w-100  text-right'>
                            <span className='span-as-link' onClick={enableField}>
                                Change Phone Number
                            </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapStateToProps({settings, forms}) {
    const {lists} = settings;
    const custActivateForm = forms[formsConstants.CUST_ACTIVATE_FORM];
    return {
        lists,
        custActivateForm
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(PhoneForm));
export {connectedPage as PhoneForm};
