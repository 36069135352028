import React, {Fragment, useEffect, useState} from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {AutoCompleteSelectField} from "../../GeneralComponents/AutoCompleteSelectField";
import {addressConstants, formsConstants} from "../../../constants";
import {googleMapsActions, addressActions, carmelActions, uiActions, formsActions} from "../../../store/actions";
import {appHelp} from "../../../helpers";
import IntlMessages from "../../../utils/IntlMessages";

const combineAirports = (all, airportListService) => {
    const combinedAirports = all.map((airport, index) => {
        let airportCombine = {...airport};
        const airportFromAllList = airportListService.find(airportAll => airportAll.airportCode === airport.airportCode);
        if (airportFromAllList) {
            airportCombine.latitude = airportFromAllList.latitude;
            airportCombine.longtitude = airportFromAllList.longtitude;

            airportCombine.airportDetails = airportCombine.airportName ||
                <IntlMessages key={index} id="airline-modal.airline-list.try-again"/>;

            if (airportFromAllList.airportName)
                airportCombine.airportName = airportFromAllList.airportName;

            return airportCombine;
        }
    });
    return combinedAirports;
};

const SearchAddressSelectInput = ({searchAddressForm, label, formConst, autocomplete, lists, dispatch, trip, tripDetails, displayOnly, tripFieldError}) => {
    const [options, setOptions] = useState([]);

    //current input address type
    const addressType = formConst === formsConstants.SEARCH_ADDRESS ? addressConstants.PICK_UP_ADDRESS : addressConstants.DROP_OFF_ADDRESS;

    //create full airport list to add results to search
    let airportList = [];
    if (lists && appHelp.validateArr(lists.airportListAll)) {
        //air port lists
        const {airportListAll, airportListService} = lists;
        airportList = combineAirports(airportListService, airportListAll);
    }

    useEffect(() => {
        if (autocomplete[addressType] && appHelp.validateArr(autocomplete[addressType].predictions)) {
            let predictions = autocomplete[addressType].predictions.map(prediction => {
                //create prediction list to display
                return {
                    label: prediction.description,
                    value: prediction.place_id
                }
            });
            setOptions(predictions);
        }
    }, [autocomplete[addressType].predictions]);


    const chooseAddress = (address) => {
        dispatch(uiActions.displaySpinner());
        dispatch(googleMapsActions.clearAutocomplete(addressType));
        dispatch(addressActions.clearPrediction(addressType));
        if (trip && trip[addressType] && Object.keys(trip[addressType]).length > 0) {
            //clear previous address if exist
            dispatch(carmelActions.clearServiceByAddressTimeout());
            dispatch(addressActions.clearServiceByAddress());
            dispatch(addressActions.clearAddress(addressType));
        }

        if (address.airport) {
            // Set the airport to the address type in the Trip object
            dispatch(addressActions.airportSelected(address, addressType));

        } else {
            // Get place by Id - will update the Trip address PickUp or DropOff
            dispatch(googleMapsActions.getPlaceById(address.value, addressType));
        }

        dispatch(formsActions.updateFieldsValue(
            formsConstants.SEARCH_FORM,
            {
                [formConst]: '',
            }));

    };

    return (
        <div className="form-group select one-third"
             id={addressType === addressConstants.DROP_OFF_ADDRESS ? 'order-4' : 'order-2'}>
            <label>{label}</label>
            <AutoCompleteSelectField
                items={options}
                setFocusOnField={appHelp.validateArr(tripFieldError) && tripFieldError.includes(addressType)}
                displayOnly={displayOnly}
                formId={formsConstants.SEARCH_FORM}
                addressType={addressType}
                id={formConst} form={searchAddressForm}
                placeholder={addressType === addressConstants.PICK_UP_ADDRESS ? 'La Guardia (LGA)' : '123 5th Ave, Manhattan'}
                airportList={airportList}
                handleClick={chooseAddress}
                tripFieldError={appHelp.validateArr(tripFieldError) && tripFieldError.includes(addressType)}
                addressDescription={tripDetails[addressType].addressDescription}
            />
            {
                appHelp.validateArr(tripFieldError) && tripFieldError.includes(addressType) &&
                <label style={{marginTop: '5px'}}> <IntlMessages id='field-required-message'/></label>
            }
        </div>

    )
};

function mapStateToProps({forms, googleMaps, settings, trip, ui}) {
    const {autocomplete} = googleMaps;
    const {lists} = settings;
    const {tripDetails} = ui;
    const searchAddressForm = forms[formsConstants.SEARCH_FORM];
    return ({
        searchAddressForm,
        autocomplete,
        lists,
        trip,
        tripDetails
    })
}

const connectedPage = injectIntl(connect(mapStateToProps)(SearchAddressSelectInput));
export {connectedPage as SearchAddressSelectInput};