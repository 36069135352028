import React, {Fragment, useState} from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {DateTime} from "./DateTime";
import {SearchAddressSelectInput} from "./SearchAddressSelectInput";
import {addressConstants, formsConstants, urls} from "../../../constants";
import {AirLineInfo} from './AirlinePicker/AirLineInfo'
import {appHelp, history} from '../../../helpers'
import {tripActions} from "../../../store/trip/actions";
import {uiActions} from "../../../store/ui/actions";
import {formsActions} from "../../../store/forms/actions";
import {addressActions} from "../../../store/address/actions";
import Quantity from "../../GeneralComponents/NumberPickInput";


const addressSearchForms = [
    {
        label: 'Pick up location',
        formConst: formsConstants.SEARCH_ADDRESS
    }, {
        label: 'Drop off location',
        formConst: formsConstants.DROP_ADDRESS
    },
];
const MainActivity = ({myRef, displayOnly = false, trip, airlineForm, dispatch}) => {
        const [tripFieldError, setTripFieldsError] = useState([]);
        const [airportFormErrors, setAirportFormErrors] = useState([]);

        const continueTripCreation = (e) => {
            e.preventDefault();
            errorsCollector();
        };

        const updateAirportDetailsAndContinue = () => {
            dispatch(tripActions.updateAirportDetails(airlineForm));
            if (trip.updatePickup) {
                //update the pickup form of trip when current location in airport
                dispatch(tripActions.setCurrentLocationAsPickUp());
            }
            dispatch(uiActions.closeAirportSelect());
            setTimeout(() => {
                dispatch(formsActions.clearAllFormFields(formsConstants.AIRLINE_FORM))
            }, 1000);
            dispatch(addressActions.clearAirlineList());
            history.push(`/${urls.TRIP_UPDATE}`);
        };

        const errorsCollector = () => {
            const tripFieldsError = [];
            const airportErrors = [];
            if (!appHelp.validateObject(trip[addressConstants.PICK_UP_ADDRESS])) {
                //if pick up not entered
                tripFieldsError.push(addressConstants.PICK_UP_ADDRESS)
            } else if (trip[addressConstants.PICK_UP_ADDRESS].airport) {
                //if pick up entered and is an airport
                //check for empty airport fields
                const airportFieldsArr = Object.keys(airlineForm);

                airportFieldsArr.forEach(airportField => {
                    if (typeof airlineForm[airportField] === 'string' && !airlineForm[airportField] && airportField !== 'onFocus' && airportField !== 'errors') {
                        //not entered fields validator
                        airportErrors.push(airportField)
                    }
                });


            }
            if (!appHelp.validateObject(trip[addressConstants.DROP_OFF_ADDRESS])) {
                //if drop off not entered
                tripFieldsError.push(addressConstants.DROP_OFF_ADDRESS)
            }

            if (airportErrors.length === 0 && tripFieldsError.length === 0) {
                // when no errors continue proggress
                if (trip[addressConstants.PICK_UP_ADDRESS].airport)
                    updateAirportDetailsAndContinue();
                else
                    history.push(`/${urls.TRIP_UPDATE}`);
            } else {
                // set the errors
                setTripFieldsError(tripFieldsError);
                setAirportFormErrors(airportErrors);
            }


        };

        return (
            <Fragment>
                <div className="advanced-search color" id="booking">
                    <div className="wrap">
                        <form role="form" method="post" className='' autoComplete="off">
                            <div className="f-row main-activity-trip-pick-form">
                                <DateTime displayOnly={displayOnly}/>
                                {
                                    addressSearchForms.map(form => {
                                        return (
                                            <SearchAddressSelectInput label={form.label} formConst={form.formConst}
                                                                      key={form.formConst}
                                                                      displayOnly={displayOnly}
                                                                      tripFieldError={tripFieldError}/>
                                        )
                                    })
                                }
                                <AirLineInfo airportFormErrors={airportFormErrors}/>
                            </div>
                            {
                                !displayOnly &&
                                <Fragment>
                                    <div/>
                                    < div className="f-row" ref={ref => myRef = ref}>
                                        <div className="form-group spinner how-many-people-input-field">
                                            <div className=''>
                                                <label htmlFor="people" className='text-max-length'>How many
                                                    people <small>(including
                                                        children)</small>?</label>
                                            </div>
                                            <Quantity/>
                                        </div>

                                        <div className="form-group right">
                                            <button type="submit" className="btn large black"
                                                    onClick={continueTripCreation}>FIND A RIDE
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </form>
                    </div>
                </div>

            </Fragment>
        )
    }
;

function mapStateToProps({trip, forms}) {
    const airlineForm = forms[formsConstants.AIRLINE_FORM];
    return ({
        trip,
        airlineForm
    })
}

const connectedPage = injectIntl(connect(mapStateToProps)(MainActivity));
export {connectedPage as MainActivity};