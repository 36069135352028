import axios from 'axios';
import {appHelp} from '../../helpers'
import {store} from '../index'
import {
    carmelConstAPILists,
    carmelConstAPIMethods,
    fopCardTypeRegex,
    generalConstants,
    userConstLists,
} from "../../constants";

export const generalService = {
    requestSender,
    handleAxiosResponse,
    multipleCallsSender,
    requestRetry,
    assignValueToObjectPath,
    determineFieldValue,
    getListObject,

    loadBasicInfo,
    creditCardForDisplay,
};


function requestCreator(method,
                        params,
                        httpMethod = "POST",
                        httpHeaders = {
                            'Content-Type': 'application/json'
                        }) {

    const id = generateRequestId(method);
    return {
        requestOptions: {
            method: httpMethod,
            headers: httpHeaders,
            data: JSON.stringify({
                calls: [
                    {
                        method: method,
                        params: params,
                        id
                    }
                ]
            })
        },
        id
    };
}

function multipleCallsCreator(sharedParams = {},
                              calls = [],
                              httpMethod = "POST",
                              httpHeaders = {'Content-Type': 'application/json'}) {

    calls = calls.map(call => {
        call.id = generateRequestId(call.method);
        return call;
    });

    return {
        requestOptions: {
            method: httpMethod,
            headers: httpHeaders,
            data: JSON.stringify({
                sharedParams,
                calls
            })
        },
        id: calls[0].id
    };
}

function loadBasicInfo() {
    const {Credentials} = store.getState().authentication;

    // The credentials object without any cust login details.
    // The API not working with those fields full.
    const sharedParams = {
        Credentials: {
            ...Credentials,
            custLoginId: "",
            custPassword: ""
        }
    };

    const airportListService = {
        method: carmelConstAPIMethods.AIRPORT_LIST,
        params: {
            airportListType: 'Service'
        }
    };
    const airportListAll = {
        method: carmelConstAPIMethods.AIRPORT_LIST,
        params: {
            airportListType: 'All'
        }
    };
    const countryList = {
        method: carmelConstAPIMethods.COUNTRY_LIST
    };
    const fopTypeList = {
        method: carmelConstAPIMethods.FOP_TYPE_LIST
    };

    // Gathering all lists required for loading the app.
    return generalService.multipleCallsSender(
        sharedParams,
        [
            airportListService,
            airportListAll,
            countryList,
            fopTypeList
        ],
        'loadBasicInfo'
    );
}

function handleAxiosResponse(response) {
    if (response.status !== 200) {
        // const error = (data && data.message) || response.statusText;
        const error = response.statusText;
        return Promise.reject(error);
    } else {
        return response.data
    }
}

function getListObject(list, objectUniqCode) {
    const lists = store.getState().settings.lists;

    // Filter through the list specified to find a single object
    const results = lists[list].filter(target => {
        return target[carmelConstAPILists.objectCode[list]] === objectUniqCode
    });

    // Return the first (and only) object of the results.
    return results[0]
}

function assignValueToObjectPath(pathAsArray, state, object = {}) {
    // This function takes an array of [...pathArray, value].
    // The first N values of the array are the path you want to assign in the object.
    // The las value of the array is the value you want to be assign to the end of the path.

    // Keep the existing data in the state
    if (pathAsArray.length > 2) {
        if (typeof state[pathAsArray[0]] === 'object') {
            //'Get existing data from state
            state = state[pathAsArray[0]];
        } else if (!state[pathAsArray[0]]) {
            // Path not existing, pass empty object
            state = {};
        }
    }

    // This is for the last iteration assign the value to the end of the path
    if (pathAsArray.length === 2) {
        if (pathAsArray[0] !== "") {
            object[pathAsArray[0]] = pathAsArray[1];
            return {
                ...state,
                ...object
            };
        } else {
            return pathAsArray[1];
        }
    }
    // This part reduces the array and get back the nested object,
    // assigning the nested object in the first value of the array.
    else {
        object[pathAsArray[0]] = generalService.assignValueToObjectPath(pathAsArray.splice(1), state);
        return object;
    }
}

function determineFieldValue(pathAsArray, state, value = undefined) {
    if (value) return value;

    if (typeof state[pathAsArray[0]] === 'object') {
        if (pathAsArray.length > 1) {
            // Continue to the object's next level
            return generalService.determineFieldValue(pathAsArray.splice(1), state[pathAsArray[0]]);
        } else {
            return state[pathAsArray[0]];
        }
    } else if (state[pathAsArray[0]] === undefined) {
        // Path not existing, pass empty string
        return '';
    } else {
        // Return the value at the end of the path
        // *** (This mey return a value at the middle of the path) ***
        return state[pathAsArray[0]];
    }
}

function creditCarFormatter(creditCardNumber) {
    // Remove all non numerical characters from credit card string
    creditCardNumber = creditCardNumber.replace(/[^0-9]/gi, '');

    const cardNumber = creditCardNumber;
    const displayCardNumber = generalService.creditCardForDisplay(creditCardNumber);
    const valid = valid_credit_card(creditCardNumber);
    const cardSeq = determineCardSequence(store.getState().user.lists.fop[userConstLists.CUST_FOP_LIST]);

    // Loop through cardTypeRegex to find a match to the credit card number
    const cardTypeResult = Object.keys(fopCardTypeRegex).filter(cardType => (
            creditCardNumber.match(fopCardTypeRegex[cardType])
        )
    );

    let fopType;

    if (cardTypeResult.length !== 0) {
        fopType = generalService.getListObject(carmelConstAPILists.FOP_TYPE_LIST, cardTypeResult[0]);
    } else {
        fopType = {
            fopCode: '',
        }
    }

    return {
        fopCode: fopType.fopCode,
        cardNumber,
        displayCardNumber,
        valid,
        cardSeq
    }
}


function creditCardForDisplay(creditCardNumber) {
    let results = '';
    for (const index in creditCardNumber) {
        if (index % 4 === 0 && index !== '0') {
            results += '-'
        }

        // This is just to make sure the app will not crash
        if (creditCardNumber.hasOwnProperty(index)) {
            results += creditCardNumber[index]
        }
    }
    return results
}

// Takes the form field value and returns true on valid number
function valid_credit_card(value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    // I have added the '&& nCheck !== 0' part to make sure
    // an empty card number value is not valid!
    return (nCheck % 10) === 0 && nCheck !== 0;
}

function determineCardSequence(fopList) {
    if (fopList.length === 0) {
        return 1;
    } else if (fopList[0].cardSeq === 1) {
        return 2;
    } else {
        return 1;
    }
}

function concealCardNumber(cardNumber) {
    const displayCardNumber = creditCardForDisplay(cardNumber);

    const cardNumberAsArray = displayCardNumber.split('-');
    const digitsRegex = /\d/gui;
    let result = '';

    for (const index in cardNumberAsArray) {
        if (index < cardNumberAsArray.length - 1) {
            result += cardNumberAsArray[index].replace(digitsRegex, "X");
            result += '-';
        } else {
            result += cardNumberAsArray[index];
        }
    }
    return result;
}

function multipleCallsSender(sharedParams, calls, methodName = 'Not Specified Method') {
    const {requestOptions, id} = multipleCallsCreator(sharedParams, calls);
    appHelp.print([`${methodName} - request:`, requestOptions]);
    return axios(
        generalConstants.API_ENDPOINT,
        requestOptions
    )
        .then(generalService.handleAxiosResponse)
        .then(response => {
            appHelp.print([`${methodName}: response`, response]);
            return response;
        });
}

function requestSender(method, params) {
    const {requestOptions, id} = requestCreator(method, params);
    console.log(`${method}: requestOptions`, requestOptions);
    return axios(
        generalConstants.API_ENDPOINT,
        requestOptions
    )
        .then(generalService.handleAxiosResponse)
        .then(response => {
            console.log(`${method}: response`, response.results[0].data);
            console.log(`${method}: response`, JSON.stringify(response.results[0].data));
            return response.results[0].data;
        });
}


function generateRequestId() {
    return `${Math.round(Math.random() * 10000000)}`
}


function requestRetry(requestOptions, method) {
    return axios(
        generalConstants.API_ENDPOINT,
        requestOptions
    )
        .then(generalService.handleAxiosResponse)
        .then(response => {
            console.log(`${method}: response`, response.results[0].data);
            return response.results[0].data;
        });
}