import React from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const TripDetailsBody = ({trip}) => {
    const {car} = trip;
    const {maxPassengers, maxLuggage, maxWeelchairs} = car;
    return (
        <ul style={{height: 0}}>
            <li>
                <span className="icon icon-themeenergy_user-3"/>
                <p>Max <strong>{maxPassengers} people</strong> <br/>per vehicle</p>
            </li>
            <li className='no-shadow'>
                <span className="icon icon-themeenergy_travel-bag"/>
                <p>Max <strong>{maxLuggage} suitcases</strong> <br/>per vehicle</p>
            </li>
            <li>
                <span className="icon icon-themeenergy_patient-wheelchair"/>
                <p>Max <strong>{maxWeelchairs} wheelchair</strong> <br/>per vehicle</p>
            </li>
        </ul>
    )
};

function mapStateToProps({ui, user}) {
    const {lists} = user;
    const {general} = ui;
    return {
        lists,
        general
    }
}

export default injectIntl(connect(mapStateToProps)(TripDetailsBody))