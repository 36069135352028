import {combineReducers} from 'redux';

import {intervals} from './intervals.reducer';
import {services} from './services.reducer';
import {objects} from './objects.reducer';
import {loaders} from './loaders.reducer';
import {lists} from './lists.reducer';
import {requestHistory} from './requestHistory.reducer';

export const temp = combineReducers({
    intervals,
    services,
    objects,
    loaders,
    lists,
    requestHistory
});
