import {
    carmelConstantsActions,
    carmelConstAPILists,
    userConstants,
    userConstLists
} from "../../../../constants";

export function trips(state = {}, action) {
    switch (action.type) {
        case carmelConstantsActions.TRIP_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case carmelConstantsActions.TRIP_LIST_SUCCESS:
            return {
                loading: false,
                list: action.list
            };

        case carmelConstantsActions.TRIP_LIST_FETCH_SUCCESS:
            return {
                ...state,
                [userConstLists.TRIP_LIST_FUTURE]:
                    action.lists[userConstLists.TRIP_LIST_FUTURE],
                [userConstLists.TRIP_LIST_HISTORY]:
                    action.lists[userConstLists.TRIP_LIST_HISTORY],
                [userConstLists.CUST_RECENT_TRIPS]:
                    action.lists[userConstLists.CUST_RECENT_TRIPS]
            };
        case userConstants.CLEAR_USER_LISTS:
            return {
                ...state,
                [userConstLists.TRIP_LIST_FUTURE]: {},
                [userConstLists.TRIP_LIST_HISTORY]: {},
                [userConstLists.CUST_RECENT_TRIPS]: {}
            };
        case carmelConstantsActions.FUTURE_TRIP_LIST_SUCCESS:
            return {
                ...state,
                [userConstLists.TRIP_LIST_FUTURE]: action.lists
                // [userConstLists.CUST_RECENT_TRIPS]: state[userConstLists.CUST_RECENT_TRIPS]
            };

        case carmelConstantsActions.PAST_TRIP_LIST_SUCCESS:
            return {
                ...state,
                [userConstLists.TRIP_LIST_HISTORY]: action.lists
            };

        case carmelConstantsActions.TRIP_LIST_FAILURE:
            return {
                ...state
            };
        case userConstants.USER_BASIC_INFO_SUCCESS:
            return {
                ...state,
                [userConstLists.TRIP_LIST_FUTURE]:
                    action.results[userConstLists.TRIP_LIST_FUTURE],
                [userConstLists.CUST_RECENT_TRIPS]:
                    action.results[userConstLists.CUST_RECENT_TRIPS]
            };

        case userConstants.CHOSEN_TRIP:
            return {
                ...state,
                trip: action.trip
            };
        case userConstants.UPDATE_CHOSEN_TRIP_OPTIONS:
            return {
                ...state,
                trip: {
                    ...state['trip'],
                    tripOptions: action.tripOptions
                }

            };
        case
        userConstants.CHOSEN_ADDRESS
        :
            return {
                ...state,
                address: action.address
            };

        case
        userConstants.CLEAR_CHOSEN_ADDRESSES
        :
            return {
                ...state,
                trip: {},
                address: {}
            };

        case
        carmelConstantsActions.NICKNAME_UPDATE_REQUEST
        :
            delete state.error;
            return {
                ...state,
                nicknameUpdate: false
            };
        case
        carmelConstantsActions.NICKNAME_UPDATE_SUCCESS
        :
            return {
                ...state,
                nicknameUpdate: true
            };

        case
        carmelConstAPILists.SERVICE_BY_ADDRESS_AIR_PORT_LIST
        :
            return {
                ...state,
                serviceByAddrAirPorts: action.airportList
            };
        case
        userConstants.CLEAR_AIRPORT_LIST
        :
            return {
                ...state,
                serviceByAddrAirPorts: []
            };

        case
        carmelConstantsActions.NICKNAME_UPDATE_FAILURE
        :
            return {
                ...state,
                nicknameUpdate: false,
                error: action.error
            };
        case
        carmelConstantsActions.DELETE_ADDRESS_REQUEST
        :
            return {
                ...state,
                deleteAddress: {}
            };
        case
        carmelConstantsActions.DELETE_ADDRESS_SUCCESS
        :
            delete state.deleteAddress;
            return {
                ...state
            };
        case
        carmelConstantsActions.DELETE_ADDRESS_FAILURE
        :
            return {
                ...state,
                deleteAddress: action.error
            };

        default:
            return state;
    }
}
