import React, {Fragment} from 'react'
import private_transfer from '../../../assets/images/uploads/private_transfers.jpg'
import bus_transfers from '../../../assets/images/uploads/bus_transfers.jpg'
import shuttle_transfers from '../../../assets/images/uploads/shuttle_transfers.jpg'
import helicopter_transfers from '../../../assets/images/uploads/helicopter_transfers.jpg'

export default () => {
    return (
        <Fragment>
            <div className="services boxed white" id="services">
                <article className="one-fourth wow fadeIn">
                    <figure className="featured-image">
                        <img src={private_transfer} alt="" style={{backgroundSize:'cover', width:'100%', height:'100%', display:'flex'}}/>
                        <div className="overlay">
                            <a href="javascript:void(0)" className="expand">+</a>
                        </div>
                    </figure>
                    <div className="details">
                        <h4>
                            <a href="javascript:void(0)">Private transfers</a>
                        </h4>
                        <p>No sharing, no waiting. Book a car & driver for your sole use.</p>
                        <a className="more" title="Read more" href="javascript:void(0)">Read more</a>
                    </div>
                </article>

                <article className="one-fourth wow fadeIn" data-wow-delay=".2s">
                    <figure className="featured-image">
                        <img src={bus_transfers} alt="" style={{backgroundSize:'cover', width:'100%', height:'100%', display:'flex'}}/>
                        <div className="overlay">
                            <a href="javascript:void(0)" className="expand">+</a>
                        </div>
                    </figure>
                    <div className="details">
                        <h4><a href="javascript:void(0)">Bus transfers</a></h4>
                        <p>Buses available to accommodate any size group.</p>
                        <a className="more" title="Read more" href="javascript:void(0)">Read more</a>
                    </div>
                </article>

                <article className="one-fourth wow fadeIn" data-wow-delay=".4s">
                    <figure className="featured-image">
                        <img src={shuttle_transfers} alt="" style={{backgroundSize:'cover', width:'100%', height:'100%', display:'flex'}}/>
                        <div className="overlay">
                            <a href="javascript:void(0)" className="expand">+</a>
                        </div>
                    </figure>
                    <div className="details">
                        <h4><a href="javascript:void(0)">Shuttle transfers</a></h4>
                        <p>Frequent airport to hotel transfers available.</p>
                        <a className="more" title="Read more" href="javascript:void(0)">Read more</a>
                    </div>
                </article>

                <article className="one-fourth wow fadeIn" data-wow-delay=".6s">
                    <figure className="featured-image">
                        <img src={helicopter_transfers} alt="" style={{backgroundSize:'cover', width:'100%', height:'100%', display:'flex'}}/>
                        <div className="overlay">
                            <a href="javascript:void(0)" className="expand">+</a>
                        </div>
                    </figure>
                    <div className="details">
                        <h4><a href="javascript:void(0)">Helicopter transfers</a></h4>
                        <p>Skip the traffic. Arrive quickly & safely.</p>
                        <a className="more" title="Read more" href="javascript:void(0)">Read more</a>
                    </div>
                </article>
            </div>
        </Fragment>
    )
}