/*global FB*/
import {store} from '../index'
import {carmelConstAPI, facebookConstants} from '../../constants';

let isFacebookSDKLoaded;

export const facebookService = {
    login,
    logout,
    getUserInformation,
    getLoginStatus,
};

function login(callback) {
    FB.login(callback, {scope: 'email'});
}

function logout() {
    FB.logout(function (response) {
        console.log('facebook logout success')
    });
}

function getUserInformation(callback, auth) {
    try {
        facebookLoader();
        FB.api("/me", "get",
            {
                // appsecret_proof: CryptoJS.HmacSHA256(auth.authResponse.accessToken, carmelConstAPI.facebookSecret).toString(CryptoJS.enc.Hex),
                fields: ["last_name", "first_name", "email", "id,picture.type(large)"]
            },
            callback
        );
    } catch (e) {
        console.log(e)
    }
}


// This function is called when someone finishes with the Login
// Button.
function getLoginStatus(callback) {
    if (callback) {
        FB.getLoginStatus(callback)
    } else {
        FB.getLoginStatus(function (response) {
            console.log(response);
        });
    }
}


function facebookLoader() {
    if (!isFacebookSDKLoaded) {
        window.fbAsyncInit();
    }
}

// This code is for importing the JavaScript Facebook SDK to our project
// Initialize the Facebook SDK
window.fbAsyncInit = function () {
    FB.init({
        appId: carmelConstAPI.facebookAppKey,
        autoLogAppEvents: true,
        // appsecret_proof:CryptoJS.HmacSHA256('sha256', $access_token, $app_secret) ,
        // enable cookies to allow the server to access the session
        cookie: true,
        // Use facebook SDK version 3.2
        version: 'v5.0'
    });
    FB.getLoginStatus(function (response) {
        store.dispatch(facebookLoaded(response));
    });
};

// Load the SDK asynchronously
(function (d, s, id) {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/all.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

function facebookLoaded(status) {
    return {type: facebookConstants.SDK_LOADED, status, loadedSDK: true}
}