import {
    carmelMassageCodes,
    carmelMethodsConstants,
    carmelConstAPILists,
    carmelConstants,
    addressConstants,
    formsConstants,
    tempConstants,
    carmelConstantsActions,
    defaultConstants,
    urls, modalsTypes
} from "../../constants";

import {appHelp, history} from '../../helpers'
import {carmelServices} from './service'
import {store} from '../index'
import {
    uiActions,
    addressActions,
    formsActions,
    tripActions,
    userActions,
    generalActions,
    generalAuthActions
} from "../actions";
import * as luxon from "luxon";
import {fopConstants, paymentTypes} from "../../constants/fop.constants";
import {generalService} from "../general/services";


export const carmelActions = {
    errorHandling,
    loadBasicInfo,
    serviceByAddr,
    clearServiceByAddressTimeout,
    getAirlineList,
    getPriceList,
    tripUpdate,
    addFop,
    updateCreditCard,
    deleteFop,
    clearPriceList,
    custActivate,
    rateDriver,
    submitReferralCode,
    refreshToken
};
let pickTimeout = null;

function serviceByAddr(
    addressType,
    validDistance = true,
    currentLocation = null,
    addrToDisplay = null,
    addressValidationNeeded = true,
    fromTimeout = false
) {
    return (dispatch, getState) => {
        dispatch(request());
        carmelServices
            .serviceByAddr(addressType, currentLocation)
            .then(response => {
                // Keep error handling on top to
                dispatch(
                    errorHandling(response, false, () =>
                        dispatch(
                            serviceByAddr(
                                addressType,
                                validDistance,
                                currentLocation,
                                addrToDisplay,
                                addressValidationNeeded
                            )
                        )
                    )
                );
                if(response.result.code === carmelMassageCodes.CUST_ACTIVATE)
                    return;
                if (
                    (!response.hasOwnProperty("addrVerifyRequired") ||
                        response.addrVerifyRequired) &&
                    !response.addr.airport &&
                    addressValidationNeeded
                ) {
                    // Stop serviceByAddress process if the pickup address is not valid
                    if (
                        addrToDisplay &&
                        appHelp.isAddressInvalid(addrToDisplay, addressType)
                    ) {
                        //if address not valid stop progress
                        dispatch(
                            uiActions.setPopUpTitle(
                                `${
                                    addressType === addressConstants.PICK_UP_ADDRESS
                                        ? "Pick-up"
                                        : "Drop-off"
                                    } Address Required`
                            )
                        );
                        dispatch(
                            uiActions.setPopUpMessage(
                                "The selected address is not accurate or includes a range of building numbers. Please enter an exact address."
                            )
                        );
                        dispatch(uiActions.showPopUp());
                        dispatch(addressActions.clearAddress(addressType));

                        dispatch(failure(response));
                        return;
                    }
                }

                const state = getState();
                const trip = state.trip;
                const forms = state.forms;
                const lists = state.temp.lists;
                const objects = state.temp.objects;

                if (response.result.code === carmelMassageCodes.OK) {
                    if (objects.dropOffAddressOnHold)
                        dispatch(addressActions.parseDropOffOnHold());
                    if (addressType === addressConstants.CURRENT_LOCATION_ADDRESS) {
                        //if service by address is from current location and there is all ready pickup trip stop prograsion
                        if (
                            appHelp.validateObject(trip[addressConstants.PICK_UP_ADDRESS])
                        ) {
                            return;
                        }
                    }

                    //all attributes from the response
                    const {airportList, addr} = response;
                    if (
                        (addressType === addressConstants.DROP_OFF_ADDRESS &&
                            (!trip[addressConstants.PICK_UP_ADDRESS] ||
                                Object.keys(trip[addressConstants.PICK_UP_ADDRESS]).length ===
                                0)) ||
                        addressType === addressConstants.PICK_UP_ADDRESS ||
                        addressType === addressConstants.CURRENT_LOCATION_ADDRESS
                    ) {
                        // if address is pick up or if drop off then check if pick up exist
                        //save closes airports to my user lists for later uses
                        if (airportList && airportList.airportList) {
                            dispatch(
                                saveAirportListFromServiceByAddr(airportList.airportList)
                            );
                        }
                    }

                    // Update the Service object in the Temp redux object.
                    dispatch(success(response, addressType));

                    if (!validDistance) {
                        //if the address is not airport and the distance between origin address and new revers geo address is more then 100 then stop the service by address
                        dispatch(coordsFailure());
                        return;
                    }

                    if (
                        addressType === addressConstants.PICK_UP_ADDRESS ||
                        addressType === addressConstants.CURRENT_LOCATION_ADDRESS
                    ) {
                        if (addr.airport) {
                            //if airport update the name of the address to the received address
                            dispatch(
                                uiActions.updateAddressDescription(
                                    response.addr.addressDescription,
                                    addressType
                                )
                            );
                        }
                        // Update save service by address response.
                        dispatch(saveResponse(response));
                        //all attributes from the response
                        if (
                            addr.airport &&
                            (!lists.airlineList ||
                                (Array.isArray(lists.airlineList) &&
                                    lists.airlineList.length === 0)) &&
                            addressType !== addressConstants.CURRENT_LOCATION_ADDRESS
                            &&
                            !trip[addressConstants.PICK_UP_ADDRESS].airlineCode

                        ) {
                            dispatch(carmelActions.getAirlineList(addr.airportCode));
                        }

                        // Clear any Service By Address timeout if existing
                        dispatch(carmelActions.clearServiceByAddressTimeout());
                        // Set timeout to refresh the Service By Address information.
                        pickTimeout = setTimeout(
                            () => {
                                dispatch(serviceByAddr(addressType, validDistance, currentLocation, addrToDisplay, addressValidationNeeded, true));
                            },
                            // The refresh frequency realise on the Carmel API.
                            response.result.refreshFrequency
                        );
                        // Save the timeout ID to be able to clear in in the nex user interruption.
                        dispatch(saveTimeout(pickTimeout));

                        if (forms[formsConstants.TIME_FORM]) {
                            const dateTimeForm = forms[formsConstants.TIME_FORM];
                            // Initial time objects
                            // Retrieve date and time from current state
                            const date = dateTimeForm[formsConstants.DATE_FIELD],
                                time = dateTimeForm[formsConstants.TIME_FIELD],
                                {timeZoneId} = trip[addressType];
                            // create date time instance off current picked times
                            const currentSelectedTime = luxon.DateTime.fromObject({
                                year: date.split("/")[0],
                                month: date.split("/")[1],
                                day: date.split("/")[2],
                                hour: time.split(":")[0],
                                minute: time.split(":")[1],
                                zone: timeZoneId
                            });

                            //parse the closestCar to hours and minutes
                            let hours = Math.floor(response.carList.closestCar / 60);
                            let minutes = response.carList.closestCar % 60;

                            //create min  time available to pick
                            const minServiceTime = luxon.DateTime.local()
                                .setZone(timeZoneId)
                                .plus({
                                    hours: hours,
                                    minutes: minutes
                                });

                            console.log(
                                "compare dates ",
                                currentSelectedTime < minServiceTime
                            );
                            // Update the minimum time of service if it's after the current time and date
                            if (currentSelectedTime < minServiceTime || !fromTimeout) {
                                //in selected time is less then minimum time available then reset form
                                dispatch(
                                    formsActions.updateFieldsValue(formsConstants.TIME_FORM, {
                                        [formsConstants.TIME_FIELD]: minServiceTime.toLocaleString(
                                            luxon.DateTime.TIME_24_SIMPLE
                                        ),
                                        [formsConstants.DATE_FIELD]: `${minServiceTime.year}/${minServiceTime.month}/${minServiceTime.day}`
                                    })
                                );
                                dispatch(
                                    tripActions.timeUpdate(
                                        minServiceTime.toLocaleString(luxon.DateTime.TIME_24_SIMPLE)
                                    )
                                );
                                dispatch(
                                    tripActions.dateUpdate(
                                        `${minServiceTime.year}-${minServiceTime.month}-${minServiceTime.day}`
                                    )
                                );
                            }
                        } else {
                            const {timeZoneId} = trip[addressType];
                            //parse the closestCar to hours and minutes
                            let hours = Math.floor(response.carList.closestCar / 60);
                            let minutes = response.carList.closestCar % 60;

                            //create min  time available to pick
                            const minServiceTime = luxon.DateTime.local()
                                .setZone(timeZoneId)
                                .plus({
                                    hours: hours,
                                    minutes: minutes
                                });
                            dispatch(formsActions.updateFieldsValue(
                                formsConstants.TIME_FORM,
                                {
                                    [formsConstants.TIME_FIELD]: minServiceTime.toFormat('HH:mm'),
                                    [formsConstants.DATE_FIELD]: minServiceTime.toFormat('yyyy/MM/dd'),
                                }
                            ));
                            dispatch(
                                tripActions.timeUpdate(
                                    minServiceTime.toLocaleString(luxon.DateTime.TIME_24_SIMPLE)
                                )
                            );
                            dispatch(
                                tripActions.dateUpdate(
                                    `${minServiceTime.year}-${minServiceTime.month}-${minServiceTime.day}`
                                )
                            );
                        }
                        dispatch(
                            addressActions.displayClosestAirportsOnPriceSupport()
                        );

                    }
                } else {
                    dispatch(failure(response));
                }
            })
            .catch(error => {
                dispatch(addressActions.clearAddress(addressType));
                dispatch(failure(error));
            });
    };

    function saveAirportListFromServiceByAddr(airportList) {
        return {
            type: carmelConstAPILists.SERVICE_BY_ADDRESS_AIR_PORT_LIST,
            airportList
        };
    }

    function saveTimeout(timeout) {
        return {type: tempConstants.SERVICE_BY_ADDRESS_INTERVAL, timeout};
    }

    function saveResponse(serviceByAddr) {
        return {type: tempConstants.SERVICE_BY_ADDRESS_SAVE, serviceByAddr};
    }

    function saveDropOffResponse(serviceByAddr, addressType) {
        return {
            type: tempConstants.SERVICE_BY_ADDRESS_DROPOFF_SAVE,
            serviceByAddr,
            addressType
        };
    }

    function request(address) {
        return {type: carmelConstantsActions.SERVICE_BY_ADDRESS_REQUEST, address};
    }

    function success(serviceByAddress, addressType) {
        return {
            type: carmelConstantsActions.SERVICE_BY_ADDRESS_SUCCESS,
            serviceByAddress,
            addressType
        };
    }

    function failure(serviceByAddressResponse) {
        return {
            type: carmelConstantsActions.SERVICE_BY_ADDRESS_FAILURE,
            serviceByAddressResponse
        };
    }

    function coordsFailure() {
        return {type: carmelConstantsActions.ADDRESS_GENERATION_FAILURE};
    }
}

function clearServiceByAddressTimeout(timeoutId = null) {
    const intervalsState = store.getState().temp.intervals;
    if (intervalsState.serviceByAddressTimeout) {
        //clear the service by addr intervals
        clearTimeout(intervalsState.serviceByAddressTimeout);
        clearTimeout(pickTimeout);
        pickTimeout = timeoutId;
    }

    return {
        type: carmelConstantsActions.CLEAR_SERVICE_BY_ADDRESS_TIMEOUT
    };
}


function loadBasicInfo() {
    return (dispatch, getState) => {
        dispatch(request());

        carmelServices
            .loadBasicInfo()
            .then(response => {
                for (const result of response.results) {
                    dispatch(carmelActions.errorHandling(result.data));
                }
                //sort county list by name
                let countyList = response.results[0].data.countryList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                const popularCountries = countyList.filter(county => {
                    return (
                        county.code === "GB" ||
                        county.code === "US" ||
                        county.code === "FR" ||
                        county.code === "DE"
                    );
                });
                countyList = countyList.filter(county => {
                    return (
                        county.code !== "GB" &&
                        county.code !== "US" &&
                        county.code !== "FR" &&
                        county.code !== "DE"
                    );
                });

                popularCountries.unshift({
                    name: "Popular Countries",
                    class: "selectListTitle"
                });
                popularCountries.push({
                    name: "All Countries",
                    class: "selectListTitle"
                });

                // Array relies on the server to return the data in the correct order
                console.log(response.results[1]);

                const lists = {
                    [carmelMethodsConstants.COUNTRY_LIST]: [
                        ...popularCountries,
                        ...countyList
                    ],
                    [carmelMethodsConstants.FOP_TYPE_LIST]:
                        response.results[1].data[
                            carmelMethodsConstants.FOP_TYPE_LIST_FIELD
                            ],
                    [carmelConstAPILists.AIRPORT_LIST_SERVICE]:
                    response.results[2].data.airportList,
                    [carmelConstAPILists.AIRPORT_LIST_ALL]:
                    response.results[3].data.airportList,
                    [carmelConstAPILists.STATE_LIST]: response.results[4].data.stateList,
                    [carmelMethodsConstants.FULL_FOP_TYPES_LIST]: response.results[1].data
                };

                // Fop have more information beside the fopTypeList so we load all of it into
                // the 'fop' object. Copy the object to avoid collision.
                const fop = {
                    ...response.results[1].data
                };
                // delete the list from fop - We already have it in lists
                delete fop[carmelMethodsConstants.FOP_TYPE_LIST];

                // Save the retrieved data into redux.
                dispatch(success(lists));

                // Save the basic info to a local storage item ofr future use.
                // localStorage.setItem("basicInfo", JSON.stringify({lists, fop}));
                // generalService.saveStateToLocalStorage();
            })
            .catch(error => {
                // Load basic info from local storage
                const state = getState();
                const {lists} = state.user;
                // const basicInfo = JSON.parse(localStorage.getItem('basicInfo'));

                // Check if there is basic info saved into local storage (cache)
                // If it exists load it if not fail to load the application and try again.
                if (lists) {
                    dispatch(success(lists, lists.fop));
                } else {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error.message, alertConstants.GENERAL));

                    setTimeout(function () {
                        dispatch(loadBasicInfo());
                    }, 10000);
                }
            });
    };

    function request() {
        return {type: carmelConstants.BASIC_INFO_REQUEST};
    }

    function success(lists) {
        return {type: carmelConstants.BASIC_INFO_SUCCESS, lists};
    }

    function failure(error) {
        return {type: carmelConstants.BASIC_INFO_FAILURE, error};
    }
}

function getAirlineList(airlineCode) {
    return dispatch => {
        dispatch(getAirlineListRequest(airlineCode));
        carmelServices
            .getAirlineList(airlineCode)
            .then(response => {
                dispatch(
                    errorHandling(response, false, () =>
                        dispatch(getAirlineList(airlineCode))
                    )
                );
                if (response.status === 200) {
                    let airlineList =
                        response.data.results[0].data.airlineListByAirport[airlineCode]
                            .airlineList;
                    if (airlineList.length === 0)
                        airlineList = response.data.results[0].data.airlineList;
                    dispatch(success(airlineList));
                    dispatch(uiActions.showAirportSelect());
                } else {
                    fail(response.result.message);
                }
            })
            .catch(error => fail(error));
    };

    function getAirlineListRequest() {
        return {
            type: carmelConstantsActions.AIRLINE_LIST_REQUEST
        };
    }

    function success(airlineList) {
        return {
            type: carmelConstantsActions.AIRLINE_LIST_SUCCESS,
            airlineList
        };
    }

    function fail(error) {
        return {
            type: carmelConstantsActions.AIRLINE_LIST_FAILURE,
            error
        };
    }
}

// Carmel services error handling - What to do if there are any of the Carmel error messages
// This function supposed to be used on every Carmel API response.
function errorHandling(response, register = false, resendFunction = null) {
    return (dispatch, getState) => {
        const state = getState();
        const {auth} = state.authentication;
        const {modals} = state.ui.modalsUi;
        if (response.result) {
            if (response.result.code === carmelMassageCodes.INVALID_CREDENTIALS) {

            } else if (response.result.code === carmelMassageCodes.INVALID_REQUEST) {
                // dispatch(alertActions.error(response.result.message));
            } else if (response.result.code === carmelMassageCodes.ACCESS_DENIED) {
                // dispatch(alertActions.error(response.result.message));
            } else if (response.result.code === carmelMassageCodes.UPDATE_REQUIRED) {
                // dispatch(alertActions.error(response.result.message));
            } else if (response.result.code === carmelMassageCodes.UPDATE_AVAILABLE) {
                // dispatch(alertActions.error(response.result.message));
            } else if (response.result.code === carmelMassageCodes.ERROR) {
                // dispatch(alertActions.error(response.result.message));
            } else if (response.result.code === carmelMassageCodes.CUST_ACTIVATE) {
                if (
                    !register &&
                    (!appHelp.validateArr(modals) ||
                        (appHelp.validateArr(modals) &&
                            !modals.find(
                                modal =>
                                    modal.modal === modalsTypes.CUST_ACTIVATE ||
                                    modal.modal ===
                                    modalsTypes.CUST_ACTIVATE_FROM_REGISTER
                            )))
                ) {
                    if (resendFunction) {
                        dispatch(uiActions.hideSpinner());
                        //save current func to activate after cust activate
                        dispatch(
                            generalActions.resendFunctionAfterCustActive(resendFunction)
                        );
                        dispatch(
                            uiActions.showModal(modalsTypes.CUST_ACTIVATE)
                        );
                    }
                }
            }else if (response.result.code === carmelMassageCodes.ACCESS_TOKEN_EXPIRED) {
                // appHelp.deleteCookie('access_token');
                //save current func to resend after cust activate
                dispatch(
                    carmelActions.refreshToken(resendFunction)
                );
            }
        }
    };
}

function convertTo24hours(time, AMPM) {
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
}

function nextCarError(nextCarTime, dispatch, tripUpdate = false, closeModal) {
    //split the time format and the time
    const state = store.getState();
    const timeform = state.forms[formsConstants.TIME_FORM];
    const {timeZoneId} = state.trip[addressConstants.PICK_UP_ADDRESS];

    const ampm = nextCarTime.match(/[a-zA-Z]+/g)[0];
    const time = nextCarTime.replace(ampm, "");
    const timeIn24Hours = convertTo24hours(time, ampm);

    const newDateAndTime = luxon.DateTime.fromObject({
        year: timeform[formsConstants.DATE_FIELD].split("/")[0],
        month: timeform[formsConstants.DATE_FIELD].split("/")[1],
        day: timeform[formsConstants.DATE_FIELD].split("/")[2],
        hour: timeIn24Hours.split(":")[0],
        minute: timeIn24Hours.split(":")[1],
        zone: timeZoneId
    });
    //current date set
    //new date time set with the current time zone
    const currentDateAndTime = luxon.DateTime.local().setZone(timeZoneId);


    let updateDate = false;
    if (currentDateAndTime < newDateAndTime) {
        //if current date with new time is still less then the current time zone time set flag to true to update the date
        updateDate = true;
    }
    const options = [
        {
            abort: false,
            optionText: "Accept & book",
            actionObject: () => {
                if (updateDate) {
                    //if need to update date
                    dispatch(
                        tripActions.dateUpdate(newDateAndTime.toFormat('yyyy/MM/dd'))
                    );
                }
                dispatch(tripActions.timeUpdate(timeIn24Hours));
                //call again price list with new time
                dispatch(getPriceList(tripUpdate));
            }
        },
        {
            abort: true,
            optionText: "Abort",
            actionObject: () => {
                //reset booking
                dispatch(addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS));
                dispatch(
                    addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS)
                );
                history.push(`${urls.MAIN_PAGE}`);
            }
        }
    ];
    const buttonsArray = options.map(option => {
        return {
            color: option.abort ? "red" : "steelblue",
            messageId: option.optionText,
            //after button click call function with the selected option
            action: option.actionObject
        };
    });
    dispatch(uiActions.setPopUpButtons(buttonsArray));
}

function defaultDialogError(dispatch, closeModal) {
    const options = [
        {
            color: "steelblue",
            optionText: "Change trip details",
            actionObject: () => {
                //go to booking
                history.push(`${urls.MAIN_PAGE}`)
            }
        },
        {
            color: "steelblue",
            optionText: "Call support",
            actionObject: () => {
                //go to booking and show call support modal
                history.push(`${urls.MAIN_PAGE}`);
                dispatch(uiActions.showModal(modalsTypes.CALL_FOR_SUPPORT));
            }
        },
        {
            color: "red",
            optionText: "Abort trip",
            actionObject: () => {
                //reset booking
                dispatch(addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS));
                dispatch(
                    addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS)
                );
                history.push(`${urls.MAIN_PAGE}`);
            }
        }
    ];
    const buttonsArray = options.map(option => {
        return {
            color: option.color,
            messageId: option.optionText,
            action: option.actionObject
        };
    });
    dispatch(uiActions.setPopUpButtons(buttonsArray));
}

function priceListError(error, closeModal) {
    return (dispatch, getState) => {
        dispatch(
            uiActions.setPopUpTitle(
                error.result.title ? error.result.title : "Attention"
            )
        );
        dispatch(uiActions.setPopUpMessage(error.result ? error.result.message : ''));
        if (error.result.code === "NEXT_CAR") {
            nextCarError(error.result.nextCarTime, dispatch, null, closeModal);
        } else {
            defaultDialogError(dispatch, closeModal);
        }

        dispatch(uiActions.showPopUp());
    };
}


function getPriceList(tripUpdate = false) {
    return dispatch => {
        dispatch(request({}));
        dispatch(uiActions.displaySpinner("spinner.just_A_Moment_Loader"));
        carmelServices.getPriceList().then(
            response => {
                dispatch(uiActions.hideSpinner());

                dispatch(
                    errorHandling(response, false, () =>
                        dispatch(getPriceList(tripUpdate))
                    )
                );
                if (response.result.code !== carmelMassageCodes.OK) {
                    dispatch(failure(response));
                    dispatch(priceListError(response));
                } else {
                    dispatch(success(response));
                    if (tripUpdate) {
                        dispatch(carmelActions.tripUpdate());
                    }
                }
            },
            error => {
                dispatch(uiActions.hideSpinner());
                history.push(`${urls.MAIN_PAGE}`);
                dispatch(failure(error));
                dispatch(uiActions.setPopUpTitle("ERROR"));
                dispatch(
                    uiActions.setPopUpMessage(
                        "Something went wrong, please change your drop-off address."
                    )
                );
                dispatch(uiActions.showPopUp());
            }
        );
    };

    function request() {
        return {type: carmelConstantsActions.GET_PRICE_LIST_REQUEST};
    }

    function success(response) {
        return {type: carmelConstantsActions.GET_PRICE_LIST_SUCCESS, response};
    }

    function failure(error) {
        return {type: carmelConstantsActions.GET_PRICE_LIST_FAILURE, error};
    }
}

function clearPriceList() {
    return {
        type: carmelConstantsActions.CLEAR_PRICE_LIST
    };
}

function tripUpdate(enableButton = null) {
    return (dispatch, getState) => {
        dispatch(request());
        if (getState().forms.invalidHouseAccountFields) {
            dispatch(formsActions.invalidHouseAccountFields(""));
        }
        dispatch(uiActions.displaySpinner("spinner.reserving_Your_Car_Loader"));
        carmelServices
            .tripUpdate()
            .then(response => {
                if (enableButton) enableButton();
                dispatch(uiActions.hideSpinner());
                dispatch(
                    carmelActions.errorHandling(response, false, () =>
                        tripUpdate(enableButton)
                    )
                );
                if (response.result.code === carmelMassageCodes.CUST_ACTIVATE) return;
                tripUpdateSuccessOrRetry(dispatch, response);
            })
            .catch(error => tripUpdateFailure(error, dispatch, getState));
    };

    function tripUpdateSuccessOrRetry(dispatch, response) {
        dispatch(uiActions.hideSpinner());
        if (response.result.code === carmelMassageCodes.OK) {
            dispatch(success(response));
            dispatch(userActions.chooseTrip(response));
            dispatch(formsActions.clearAllFormFields(formsConstants.TIME_FORM));
            if (
                response.tripOptions.find(option => option === "DisplayCarLocation")
            ) {
                //TODO add show my car
                history.push(`${defaultConstants.defaultStartPath}${urls.THANK_YOU}`);
            } else {
                history.push(`${defaultConstants.defaultStartPath}${urls.THANK_YOU}`);
            }

            // clear all trip Details
            dispatch(clearAllFieldsTrip());
            dispatch(addressActions.clearServiceByAddress());
            dispatch(addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS));
            dispatch(addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS));
            dispatch(carmelActions.clearServiceByAddressTimeout());

        } else if (response.result.code === carmelMassageCodes.TIMEOUT) {
            const requestHistoryElement = store.getState().temp.requestHistory[
                response.id
                ];

            if (requestHistoryElement.retries <= 2) {
                generalService
                    .requestRetry(requestHistoryElement.requestOptions, "tripUpdate")
                    .then(response => tripUpdateSuccessOrRetry(dispatch, response))
                    .catch(error => {
                        dispatch(failure(error));
                    });
            } else {
                dispatch(handleTripUpdateErrors(response, dispatch));
                // tripUpdateFailure(response);
            }
        } else {
            dispatch(handleTripUpdateErrors(response, dispatch));
            // tripUpdateFailure(response, dispatch);
        }
    }

    function tripUpdateFailure(error, dispatch, getState) {
        if (getState().trip.fop.fopCode === paymentTypes.HOUSE_ACCOUNT) {
            dispatch(uiActions.createBasicPopup("", error.result.message));
        } else dispatch(handleTripUpdateErrors(error, dispatch, getState));
        dispatch(failure(error));
    }

    function request(fop) {
        return {type: carmelConstantsActions.TRIP_UPDATE_REQUEST, fop};
    }

    function success(response) {
        return {type: carmelConstantsActions.TRIP_UPDATE_SUCCESS, response};
    }

    function failure(error, fop) {
        return {type: carmelConstantsActions.TRIP_UPDATE_FAILURE, error, fop};
    }
}

function handleTripUpdateErrors(error, dispatch, closeModal) {
    return dispatch => {
        dispatch(uiActions.hideSpinner());
        if (!error.result)
            return;
        const state = store.getState();
        if (
            state.trip.fop.fopCode === paymentTypes.HOUSE_ACCOUNT &&
            error.result.code === carmelMassageCodes.ERROR
        ) {
            dispatch(formsActions.invalidHouseAccountFields(error.result.message));
            return;
        }
        dispatch(
            uiActions.setPopUpTitle(
                error.result && error.result.title ? error.result.title : "Attention"
            )
        );
        dispatch(uiActions.setPopUpMessage(error.result ? error.result.message : ''));
        switch (error.result.code) {
            case "NEXT_CAR":
                nextCarError(error.result.nextCarTime, dispatch, true, closeModal);
                break;
            case "CARD_ERROR":
                let creditCard = error.fop;
                dispatch(
                    formsActions.updateFieldsValue(formsConstants.CREDIT_CARD, {
                        [formsConstants.CARD_NUMBER]: creditCard.cardNumber,
                        [formsConstants.CARD_EXPIRATION_DATE_YEAR]: creditCard.cardExpYear,
                        [formsConstants.CARD_EXPIRATION_DATE_MONTH]:
                        creditCard.cardExpMonth,
                        [formsConstants.CARD_HOLDER_NAME]: creditCard.cardHolder,
                        [formsConstants.CARD_CVV]: "",

                        [formsConstants.CARD_BILLING_ADDRESS]: creditCard.billingAddr,
                        [formsConstants.CARD_BILLING_CITY]: creditCard.billingCity,
                        [formsConstants.CARD_BILLING_STATE]: creditCard.billingState || "",
                        [formsConstants.CARD_BILLING_ZIP]: creditCard.billingZip,
                        [formsConstants.CARD_BILLING_COUNTRY_CODE]:
                        creditCard.billingCountryCode,
                        [formsConstants.CARD_SEQ_NUM]: creditCard.cardSeq
                    })
                );
                dispatch(uiActions.showModal(modalsTypes.CREDIT_CARD));
                break;
            case "CUST_PROFILE_ERROR":
                history.push(`/${urls.ACCOUNT}`);
                break;
            case "TIMEOUT":
                const options = [
                    {
                        color: "steelblue",
                        optionText: "Try Again",
                        actionObject: () => {
                            dispatch(tripUpdate());
                        }
                    },
                    {
                        color: "steelblue",
                        optionText: "Call support",
                        actionObject: () => {
                            history.push(`${urls.MAIN_PAGE}`);
                            dispatch(
                                uiActions.showModal(modalsTypes.CALL_FOR_SUPPORT)
                            );
                        }
                    },
                    {
                        color: "red",
                        optionText: "Abort trip",
                        actionObject: () => {
                            //reset booking
                            dispatch(
                                addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS)
                            );
                            dispatch(
                                addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS)
                            );
                            history.push(urls.MAIN_PAGE)
                        }
                    }
                ];
                const buttonsArray = options.map(option => {
                    return {
                        color: option.color,
                        messageId: option.optionText,
                        //after button click call function with the selected option
                        action: option.actionObject
                    };
                });
                dispatch(uiActions.setPopUpButtons(buttonsArray));
                break;
            default:
                defaultDialogError(dispatch, closeModal);
                break;
        }
        dispatch(uiActions.showPopUp());
    };
}

function clearAllFieldsTrip() {
    return {
        type: addressConstants.CLEAR_TRIP_FIELDS
    };
}

function addFop(closeModal) {
    return dispatch => {
        dispatch(request());
        dispatch(uiActions.displaySpinner("spinner.adding_Card_Loader"));
        carmelServices
            .addFop()
            .then(response => {
                dispatch(uiActions.hideSpinner());
                dispatch(errorHandling(response, false, () => dispatch(addFop(closeModal))));

                if (response.result.code === carmelMassageCodes.OK) {
                    dispatch(
                        uiActions.createBasicPopup(
                            response.result.title,
                            response.result.message
                        )
                    );
                    dispatch(success(response));
                    dispatch(userActions.loadBasicUserInformation());
                    closeModal(true);
                } else {
                    if (response.result.field === "fopCode") {
                        response.result.field = formsConstants.CARD_NUMBER;
                    }
                    dispatch(missingInfo(response.result));
                    dispatch(
                        formsActions.setFocusOnField(
                            formsConstants.CREDIT_CARD,
                            response.result.field
                        )
                    );
                    dispatch(
                        formsActions.setFieldErrors(
                            formsConstants.CREDIT_CARD,
                            response.result.field,
                            response.result.message
                        )
                    );
                    dispatch(failure(response));
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                dispatch(failure(error));
            });
    };

    function missingInfo(required) {
        return {type: fopConstants.FOP_MISSING_INFO, required};
    }

    function request() {
        return {type: carmelConstantsActions.ADD_FOP_REQUEST};
    }

    function success(response) {
        return {type: carmelConstantsActions.ADD_FOP_SUCCESS, response};
    }

    function failure(error) {
        return {type: carmelConstantsActions.ADD_FOP_FAILURE, error};
    }
}

function updateCreditCard(closeModal) {
    return dispatch => {
        dispatch(request());
        dispatch(uiActions.displaySpinner("spinner.updating_Your_Info_Loader"));
        carmelServices
            .updateCreditCard()
            .then(response => {
                dispatch(uiActions.hideSpinner());
                dispatch(
                    errorHandling(response, false, () => dispatch(updateCreditCard()))
                );

                if (response.result.code === carmelMassageCodes.OK) {
                    dispatch(
                        uiActions.createBasicPopup(
                            response.result.title,
                            response.result.message
                        )
                    );
                    dispatch(success(response));
                    dispatch(userActions.loadBasicUserInformation());
                    closeModal(true);
                } else {
                    if (response.result.field === "fopCode") {
                        response.result.field = formsConstants.CARD_NUMBER;
                    }
                    dispatch(missingInfo(response.result));
                    dispatch(
                        formsActions.setFocusOnField(
                            formsConstants.CREDIT_CARD,
                            response.result.field
                        )
                    );
                    dispatch(
                        formsActions.setFieldErrors(
                            formsConstants.CREDIT_CARD,
                            response.result.field,
                            response.result.message
                        )
                    );
                    dispatch(failure(response));
                }
            })
            .catch(error => {
                dispatch(failure(error));
            });
    };

    function missingInfo(required) {
        return {type: fopConstants.FOP_MISSING_INFO, required};
    }

    function request() {
        return {type: carmelConstantsActions.UPDATE_FOP_REQUEST};
    }

    function success(response) {
        return {type: carmelConstantsActions.UPDATE_FOP_SUCCESS, response};
    }

    function failure(error) {
        return {type: carmelConstantsActions.UPDATE_FOP_FAILURE, error};
    }
}

function deleteFop(fop) {
    return dispatch => {
        dispatch(request(fop));
        dispatch(uiActions.displaySpinner("spinner.removing_card"));
        carmelServices.deleteFop(fop.cardSeq, fop.fopId).then(response => {
            dispatch(uiActions.hideSpinner());

            dispatch(errorHandling(response, false, () => dispatch(deleteFop())));
            if (response.result.code === carmelMassageCodes.OK) {
                dispatch(success(response.fopId));
            } else {
                dispatch(failure(response));
            }
        });
    };

    function request(fop) {
        return {type: carmelConstantsActions.DELETE_FOP_REQUEST, fop};
    }

    function success(fop) {
        return {type: carmelConstantsActions.DELETE_FOP_SUCCESS, fop};
    }

    function failure(error, fop) {
        return {type: carmelConstantsActions.DELETE_FOP_FAILURE, error, fop};
    }
}

function custActivate(
    validationCode = null,
    custActivateFromRegister = false,
    hidemodal = null
) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(custActivateRequest());
        dispatch(uiActions.displaySpinner("spinner.just_A_Moment_Loader"));
        carmelServices
            .sendCustActivate(validationCode)
            .then(response => {
                dispatch(uiActions.hideSpinner());
                if (response.result && response.result.code === "OK") {
                    if (validationCode) {
                        dispatch(success());
                        if (custActivateFromRegister) {
                            dispatch(
                                uiActions.createBasicPopup(
                                    "Thank You!",
                                    "Your account has been created successfully."
                                )
                            );
                            dispatch(userActions.login());
                            hidemodal(true);
                        } else {
                            //activate action that was called
                            const resendFunc =
                                state.temp.objects.resendFunctionAfterCustActivate;
                            resendFunc();
                            hidemodal(true);
                        }
                    } else {
                        dispatch(
                            uiActions.createBasicPopup(
                                response.result.title,
                                response.result.message
                            )
                        );
                        dispatch(validationCodeRequestSuccess());
                    }
                } else {
                    dispatch(
                        uiActions.createBasicPopup(
                            response.result.title,
                            response.result.message
                        )
                    );
                    fail(response.result.message);
                }
            })
            .catch(error => fail(error));
    };

    function custActivateRequest() {
        return {
            type: carmelConstantsActions.CUST_ACTIVATE_REQUEST
        };
    }

    function success() {
        return {
            type: carmelConstantsActions.CUST_ACTIVATE_SUCCESS
        };
    }

    function validationCodeRequestSuccess() {
        return {
            type: carmelConstantsActions.CUST_ACTIVATE_VALIDATION_CODE_SUCCESS
        };
    }

    function fail(error) {
        return {
            type: carmelConstantsActions.CUST_ACTIVATE_FAILURE,
            error
        };
    }
}

function rateDriver(closeModal) {
    return (dispatch, getState) => {
        dispatch(rateDriverRequest());
        const state = getState();
        const driverRatingForm = state.forms[formsConstants.DRIVER_FEEDBACK_FORM];
        const {trip} = state.user.lists.trips;
        dispatch(uiActions.displaySpinner("spinner.sending_Your_Rating"));
        carmelServices
            .sendRateDriver(driverRatingForm[formsConstants.DRIVER_FEEDBACK], driverRatingForm[formsConstants.DRIVER_RATING], trip.tripId)
            .then(response => {
                dispatch(
                    errorHandling(response, false, () => dispatch(rateDriver(closeModal)))
                );
                dispatch(uiActions.hideSpinner());
                if (response.result && response.result.code === "OK") {

                    dispatch(uiActions.setRateDriverDisplay(trip.tripId, driverRatingForm[formsConstants.DRIVER_RATING]));
                    dispatch(userActions.loadBasicUserInformation());

                    dispatch(
                        uiActions.createBasicPopup(
                            response.result.title,
                            response.result.message
                        )
                    );
                    success();
                    closeModal(true);
                } else {
                    dispatch(uiActions.setPopUpTitle(response.result.title));
                    dispatch(uiActions.setPopUpMessage(response.result.message));

                    const options = [
                        {
                            color: "steelblue",
                            messageId: "Got it",
                            action: () => {
                                console.log("got it");
                            }
                        },
                        {
                            color: "red",
                            messageId: "Close rating",
                            action: () => {
                                closeModal(true);
                            }
                        }
                    ];

                    dispatch(uiActions.setPopUpButtons(options));
                    dispatch(uiActions.showPopUp());
                    fail(response.result.message);
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                fail(error);
            });
    };

    function rateDriverRequest() {
        return {
            type: carmelConstantsActions.RATE_DRIVER_REQUEST
        };
    }

    function success() {
        return {
            type: carmelConstantsActions.RATE_DRIVER_SUCCESS
        };
    }

    function fail(error) {
        return {
            type: carmelConstantsActions.RATE_DRIVER_FAILURE,
            error
        };
    }
}

function submitReferralCode(
    sendCode = true,
    enterCode = false,
    referralCode = ""
) {
    return dispatch => {
        dispatch(uiActions.displaySpinner('spinner.sending_Your_Regards_Loader'));
        dispatch(submitReferralCodeRequest());

        carmelServices
            .submitReferralCode(sendCode, enterCode, referralCode)
            .then(response => {
                dispatch(uiActions.hideSpinner());
                dispatch(
                    uiActions.createBasicPopup(
                        response.result.title,
                        response.result.message
                    )
                );
                if (response.status === 200) {
                    dispatch(success());
                } else {
                    fail(response.result.message);
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                fail(error)
            });
    };

    function submitReferralCodeRequest() {
        return {
            type: carmelConstantsActions.REFERRAL_SUBMIT_REQUEST
        };
    }

    function success() {
        return {
            type: carmelConstantsActions.REFERRAL_SUBMIT_SUCCESS
        };
    }

    function fail(error) {
        return {
            type: carmelConstantsActions.REFERRAL_SUBMIT_FAILURE,
            error
        };
    }
}


function refreshToken(resendFunc) {
    return (dispatch, getState) => {
        dispatch(request());
        carmelServices
            .refreshToken()
            .then(response => {
                dispatch(generalAuthActions.updateCarmelUserCredentials(
                    response.data
                ));
                console.log(resendFunc);
                if(resendFunc)
                    resendFunc();
                dispatch(success());
            })
            .catch(err => {
                dispatch(fail());
            })
    };

    function request() {
        return {
            type: carmelConstantsActions.REFRESH_TOKEN_REQUEST
        };
    }

    function success() {
        return {
            type: carmelConstantsActions.REFRESH_TOKEN_SUCCESS
        };
    }

    function fail(error) {
        return {
            type: carmelConstantsActions.REFRESH_TOKEN_FAILURE,
            error
        };
    }
}