import React from 'react'
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {footerNavigation, modalsTypes, urls} from "../../constants";
import {uiActions} from "../../store/ui/actions";

const FooterNav = ({dispatch}) => {
    return (

        <nav role="navigation" className="foot-nav">
            <ul>
                {
                    footerNavigation.map((footerNavSection, index) => {
                        return (
                            <li key={index}>
                                {
                                    footerNavSection.url === urls.TERMS ?
                                        <a href={'javascript:void(0)'}
                                           onClick={() => dispatch(uiActions.showModal(modalsTypes.TERMS_MODAL))}>
                                            {footerNavSection.title}
                                        </a>
                                        :
                                        <Link
                                            to={footerNavSection.url === urls.MAIN_PAGE ? footerNavSection.url : `/${footerNavSection.url}`}>
                                            {footerNavSection.title}
                                        </Link>
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    )
};


const mapStateToProps = ({}) => {
    return {};
};

export default React.memo(connect(mapStateToProps)(FooterNav));
