import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import ModalWrapper from "../ModalWrapper";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {interactiveActions} from "../../../store/interactive/actions";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 400,
        backgroundColor: theme.palette.background.paper,
    },
}));

function renderRow(props) {
    const {index, style, data} = props;
    const dispatch = useDispatch();

    const handleClick = terminal => {
        dispatch(interactiveActions.updateSelectedTerminal(terminal.id));
        dispatch(interactiveActions.custArrived(true, true));
    };

    return (
        <ListItem button style={style} key={index}>
            <ListItemText primary={`${data[index].name}`} onClick={() => handleClick(data[index])}/>
        </ListItem>
    );
}

renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};

const TerminalList = ({dispatch, modalType, CustArrived}) => {
    const [closeModal, setCloseModal] = useState(false);
    const [windowVh, setWindowVh] = useState(window.innerHeight * 0.01);
    const classes = useStyles();


    function windowResize() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        setWindowVh(window.innerHeight * 0.01);
    }

    useEffect(() => {
        window.addEventListener("resize", windowResize);
    }, []);

    return (
        <ModalWrapper modalType={modalType} title={'Set Pickup Terminal'}
                      closeModalIndicator={closeModal}>
            <div className={`${classes.root} `}>
                <FixedSizeList height={windowVh * 90} itemSize={46} width={'100%'}
                               itemCount={CustArrived.terminalList.length} itemData={CustArrived.terminalList}>
                    {renderRow}
                </FixedSizeList>
            </div>
        </ModalWrapper>
    );
};

function mapStateToProps({ui, settings, interactive}) {
    const {modalsUi} = ui;
    const {CustArrived} = interactive;
    const {lists} = settings;
    return {
        modalsUi,
        lists,
        CustArrived
    };
}

export default injectIntl(connect(mapStateToProps)(TerminalList));