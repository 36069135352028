import {formsConstants} from "../../constants";
import {appHelp} from "../../helpers";
import {tripConstants} from "../../constants/trip.constants";

export const formsActions = {
    updateFieldsValue,
    clearFormFields,
    updateFormFieldValue,
    setFieldIsValid,
    setFieldErrors,
    setFocusOnField,
    clearFocusOnField,
    clearAllFormFields,
    invalidHouseAccountFields
};

function updateFieldsValue(form, fields_to_update) {
    return {
        type: formsConstants.UPDATE_FIELDS_VALUE,
        form,
        fields_to_update
    };
}

function updateFormFieldValue(form, fieldName, fieldValue, validators) {
    return dispatch => {
        const errors = [];
        let isValid = true;

        for (const validator of validators) {
            const {isFieldValid, message} = validator(fieldValue);

            if (isFieldValid === false) {
                isValid = false;
                errors.push(message);
            }
        }
        const validInputs = appHelp.validateInput(fieldValue);
        if (!validInputs)
            return;

        if (isValid) {
            dispatch(setFieldIsValid(form, fieldName));
        } else {
            dispatch(setFieldErrors(form, fieldName, errors));
        }
        dispatch(updateFieldsValue(form, {[fieldName]: fieldValue}));
    };
}

function clearFormFields(form, fields_to_clear) {
    return {
        type: formsConstants.CLEAR_FORM_FIELDS,
        form,
        fields_to_clear
    };
}

function clearAllFormFields(form) {
    return {
        type: formsConstants.CLEAR_ALL_FORM_FIELDS,
        form
    };
}

function setFieldErrors(form, fieldName, errors) {
    return {
        type: formsConstants.SET_FORM_FIELD_ERRORS,
        form,
        fieldName,
        errors
    };
}

function setFieldIsValid(form, fieldName) {
    return {
        type: formsConstants.SET_FIELD_IS_VALID,
        form,
        fieldName
    };
}

function setFocusOnField(form, fieldName) {
    return {
        type: formsConstants.SET_FOCUS_ON_FIELD,
        form,
        fieldName
    };
}

function clearFocusOnField(form) {
    return {
        type: formsConstants.CLEAR_FOCUS_ON_FIELD,
        form
    };
}


function invalidHouseAccountFields(message) {
    return {
        type: tripConstants.INVALID_HOUSE_ACCOUNT,
        message
    };
}