import React, {Component} from "react";
import {connect} from 'react-redux';
import {injectIntl} from "react-intl";

import {ChooseTipMethod} from './ChooseTipMethod'
import {ChooseTipPracent} from './ChooseTipPracent'
import {formsConstants} from "../../../constants";
import IntlMessages from "../../../utils/IntlMessages";

class AddTip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: 'none'
        }
    }

    displayer = (display) => {
        this.setState({display})
    };

    render() {
        const {lists} = this.props;
        if (!lists || !lists.fullFopTypeList || !lists.fullFopTypeList.autoChargeDesc)
            return null;
        return (
            <div className="full-width">
                <p className="text-center small_heading">
                    <IntlMessages id='credit-card-modal.credit-card-prepay.title'/>
                </p>
                <p className="fs_xxsmall text-center">
                    {lists.fullFopTypeList.autoChargeDesc}
                </p>
                <ChooseTipMethod displayer={this.displayer}/>
                <ChooseTipPracent display={this.state.display}/>
            </div>
        )
    }
}

function mapStateToProps({forms, settings}) {
    const creditCardForm = forms[formsConstants.CREDIT_CARD];
    const {lists} = settings;
    return {
        creditCardForm,
        lists
    }
}

const connectedPage = injectIntl(connect(mapStateToProps)(AddTip));
export {connectedPage as AddTip};