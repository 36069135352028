import escapeRegExp from "escape-string-regexp";

import {
    addressConstants,
    carmelConstAPILists,
    carmelConstAPIObjectCode,
    formsConstants,
    carmelConstantsActions
} from "../../constants";

import {googleMapsActions, carmelActions, uiActions, tripActions, formsActions, userActions} from "../actions";

import {generalService} from "../general/services";

import {appHelp} from "../../helpers";
import {store} from "../index";


export const addressActions = {
    parseGeneralDataById,
    parseAddressById,
    parseAddressByReverseGeoCoding,

    airportSelected,
    pickUpChanged,
    detectCurrentLocation,
    updateIsCurrentLocation,
    updateAddress,

    clearAddress,
    clearServiceByAddress,

    getPredictionFromLocal,
    clearPrediction,
    continueProgress,
    dropOffAddressOnHold,
    parseDropOffOnHold,
    clearAirlineList,
    displayClosestAirportsOnPriceSupport
};

function parseGeneralDataById(
    place,
    addressType,
    validDistance = true,
    distanceLessThenHalfMileAgree = false,
    addrToDisplay = null,
    addressValidationNeeded = true
) {
    // Insert additional information about the address.
    return (dispatch, getState) => {
        let result;
        const state = getState();
        dispatch(parse(place, addressType));
        if (place.hasOwnProperty("airportName") || place.favorite) {
            result = {
                latitude: place.latitude,
                longitude: place.longitude
            };
        } else {
            try {
                result = {
                    latitude: place.geometry.location.lat,
                    longitude: place.geometry.location.lng
                };
            } catch (err) {
                // dispatch(uiActions.hideSpinner());
                dispatch(failed(err));
                return;
            }
        }
        dispatch(uiActions.hideSpinner());
        // *********************
        // distance between two addresses validation start
        // *********************
        if (
            addressType === addressConstants.DROP_OFF_ADDRESS &&
            addressValidationNeeded
        ) {
            if (
                state.trip[addressConstants.PICK_UP_ADDRESS] &&
                Object.keys(state.trip[addressConstants.PICK_UP_ADDRESS]).length > 0
            ) {
                //check if the distances between the addresses is more the half a mile
                const [pickLat, pickLng] = [
                    state.trip[addressConstants.PICK_UP_ADDRESS].latitude,
                    state.trip[addressConstants.PICK_UP_ADDRESS].longitude
                ];

                if (pickLat && pickLng && result.latitude && result.longitude) {
                    //get the distance in miles between the two addresses
                    const distanceBetweenCords = appHelp.getDistance(
                        pickLat,
                        pickLng,
                        result.latitude,
                        result.longitude,
                        "miles"
                    );
                    if (0.5 > distanceBetweenCords && !distanceLessThenHalfMileAgree) {
                        // if distance is less than help a mile show pop-up for choose if continue or not
                        dispatch(uiActions.setPopUpTitle("Drop off location"));
                        dispatch(
                            uiActions.setPopUpMessage(
                                "The distance between your pick-up and drop-off is less than 1/2 a mile. Continue anyway?"
                            )
                        );
                        const buttonsArray = [
                            {
                                optionText: "Yes,Set Drop-Off",
                                action: () => {
                                    dispatch(continueProgress(true));
                                    dispatch(
                                        parseGeneralDataById(
                                            place,
                                            addressType,
                                            validDistance,
                                            true
                                        )
                                    );
                                }
                            },
                            {
                                optionText: "No",
                                action: () => {
                                    dispatch(failed(addressType));
                                    dispatch(addressActions.clearAddress(addressType))
                                }
                            }
                        ].map(option => {
                            return {
                                color: "dimgray",
                                messageId: option.optionText,
                                //after button click call function with the selected option
                                action: option.action
                            };
                        });
                        dispatch(uiActions.setPopUpButtons(buttonsArray));
                        dispatch(uiActions.showPopUp());
                        return;
                    }
                }
            }
        } else if (
            addressType === addressConstants.PICK_UP_ADDRESS &&
            !state.ui.popup.open &&
            addressValidationNeeded
        ) {
            if (
                state.trip[addressConstants.DROP_OFF_ADDRESS] &&
                Object.keys(state.trip[addressConstants.DROP_OFF_ADDRESS]).length > 0
            ) {
                //check if the distances between the addresses is more the half a mile
                const [pickLat, pickLng] = [
                    state.trip[addressConstants.DROP_OFF_ADDRESS].latitude,
                    state.trip[addressConstants.DROP_OFF_ADDRESS].longitude
                ];

                if (pickLat && pickLng && result.latitude && result.longitude) {
                    //get the distance in miles between the two addresses
                    const distanceBetweenCords = appHelp.getDistance(
                        pickLat,
                        pickLng,
                        result.latitude,
                        result.longitude,
                        "miles"
                    );
                    if (0.5 > distanceBetweenCords && !distanceLessThenHalfMileAgree) {
                        dispatch(uiActions.hideSpinner());
                        // if distance is less than half mile stop function
                        dispatch(uiActions.setPopUpTitle("Pick-Up Address Required"));
                        dispatch(
                            uiActions.setPopUpMessage("Enter address or select from list.")
                        );
                        dispatch(uiActions.showPopUp());
                        dispatch(failed(addressType));
                        dispatch(addressActions.clearAddress(addressType));
                        return;
                    }
                }
            }
        }
        // *********************
        // distance between two addresses validation end
        // *********************


        //find address nickname if exist
        let favoriteAddresses = [];
        const {addresses} = state.user.lists;
        if (addresses && addresses.addrList) {
            const {addrList} = addresses;
            if (appHelp.validateArr(addrList)) {
                //get all favorite addresses
                favoriteAddresses = addrList.filter(address => {
                    return address.favorite;
                });
            }
        }
        favoriteAddresses.forEach(favoriteAddress => {
            //the distance between addresses in meters
            const distance = appHelp.getDistance(result.latitude, result.longitude, favoriteAddress.latitude, favoriteAddress.longitude);

            if (distance <= 10) {
                state.trip[addressType].addressNickName = favoriteAddress.addressNickName;
            }
        });


        // Complete the address details by requesting the timeZone from Google API
        dispatch(googleMapsActions.getTimeZoneByAddress(result, addressType));

        // Set the address information into the global state.
        dispatch(success(result, addressType));
        // Ask for service by the address if it's the pick-up address.

        dispatch(
            carmelActions.serviceByAddr(
                addressType,
                validDistance,
                null,
                addrToDisplay,
                addressValidationNeeded
            )
        );
    };

    function parse(address_components, addressType) {
        return {
            type: addressConstants.PARSE_SELECTED_ADDRESS_BY_ID_PARSING,
            address_components,
            addressType
        };
    }

    function success(result, addressType) {
        return {
            type: addressConstants.PARSE_SELECTED_ADDRESS_BY_ID_SUCCESS,
            result,
            addressType
        };
    }

    function failed(addressType) {
        return {
            type: addressConstants.PARSE_SELECTED_ADDRESS_BY_ID_FAILURE,
            addressType
        };
    }
}

function continueProgress(continueBool) {
    return {
        type: addressConstants.CONTINUE_ADDRESS_PROGRESS,
        continue: continueBool
    };
}

function parseDropOffOnHold() {
    return (dispatch, getState) => {
        const state = getState();
        if (
            state.temp &&
            state.temp.objects &&
            state.temp.objects.dropOffAddressOnHold
        ) {
            if (
                state.trip &&
                state.trip[addressConstants.DROP_OFF_ADDRESS] &&
                Object.keys(state.trip[addressConstants.DROP_OFF_ADDRESS]).length > 0
            ) {
                //clear previous drop off address if exist
                // dispatch(carmelActions.clearServiceByAddressTimeout());
                dispatch(
                    addressActions.clearAddress(addressConstants.DROP_OFF_ADDRESS)
                );
            }
            // clear the on hold
            dispatch(clearDropOffAddressOnHold());

            const dropOffAddress = state.temp.objects.dropOffAddressOnHold;
            if (dropOffAddress.favorite) {
                //update address
                dispatch(
                    addressActions.updateAddress(
                        dropOffAddress,
                        addressConstants.DROP_OFF_ADDRESS
                    )
                );
                //update current address name
                dispatch(
                    uiActions.updateAddressDescription(
                        dropOffAddress.addressDescription,
                        addressConstants.DROP_OFF_ADDRESS
                    )
                );
                if (
                    dropOffAddress.hasOwnProperty("airportName") ||
                    dropOffAddress.airport
                ) {
                    // Set the airport to the address type in the Trip object
                    dispatch(
                        addressActions.airportSelected(
                            dropOffAddress,
                            addressConstants.DROP_OFF_ADDRESS,
                            false
                        )
                    );
                }
                //parse address data
                else
                    dispatch(
                        addressActions.parseGeneralDataById(
                            dropOffAddress,
                            addressConstants.DROP_OFF_ADDRESS,
                            true,
                            false,
                            null,
                            false
                        )
                    );
            } else if (
                dropOffAddress.hasOwnProperty("airportName") ||
                dropOffAddress.airport
            ) {
                // Set the airport to the address type in the Trip object
                dispatch(
                    addressActions.airportSelected(
                        dropOffAddress,
                        addressConstants.DROP_OFF_ADDRESS
                    )
                );
            } else {
                dispatch(
                    addressActions.updateAddress(
                        dropOffAddress,
                        addressConstants.DROP_OFF_ADDRESS
                    )
                );
                const coords = `${dropOffAddress.latitude},${dropOffAddress.longitude}`;
                // start reverse geo code on the drop off address
                dispatch(
                    googleMapsActions.getPlaceByReverseGeoCoding(
                        coords,
                        addressConstants.DROP_OFF_ADDRESS
                    )
                );
            }
        }
    };
}

function AddressComponents(resultObject, types = null) {
    let address = {};
    //get the address component array from the result object
    const addressComponentsJsonArray = resultObject.address_components;
    //if the addressComponentsJsonArray is null
    if (!addressComponentsJsonArray) {
        //set isSuccessful value is false and we exit the function
        return;
    }

    //update the name value of the address Components object
    address["name"] = resultObject.name;

    address["formatted_address"] = resultObject.formatted_address;

    for (let index = 0; index < addressComponentsJsonArray.length; index++) {
        const typesJSONArray = addressComponentsJsonArray[index].types;
        //make array list from the typesJSONArray
        //for check on the array list if it contain the type that we need for the AddressComponents object
        const typesArray = [];
        for (let i = 0; i < typesJSONArray.length; i++) {
            typesArray.push(typesJSONArray[i].toLowerCase());
        }

        for (let type of typesArray) {
            let valueLong = addressComponentsJsonArray[index].long_name;
            let valueShort = addressComponentsJsonArray[index].short_name;

            switch (type) {
                case "street_number":
                    //Attention: Short value not long!
                    address["street_number"] = valueShort;
                    break;
                //Attention: Short value not long!
                case "administrative_area_level_1":
                    address["state_code"] = valueShort;
                    break;
                case "postal_code":
                    address["postal_code"] = valueShort;
                    break;
                case "route":
                    address["street_name"] = valueShort;
                    break;
                case "country":
                    //Attention: Short value not long!
                    address["country_code"] = valueShort;
                    break;
                case "sublocality":
                    address["sublocality"] = valueLong;
                    break;
                case "locality":
                    address["locality"] = valueLong;
                    break;
                case "neighborhood":
                    address["neighborhood"] = valueLong;
                    break;
                case "sublocality_level_1":
                    address["sublocality_level_1"] = valueLong;
                    break;
                case "sublocality_level_2":
                    address["sublocality_level_2"] = valueLong;
                    break;
                case "sublocality_level_3":
                    address["sublocality_level_3"] = valueLong;
                    break;
                case "sublocality_level_4":
                    address["sublocality_level_4"] = valueLong;
                    break;
                case "sublocality_level_5":
                    address["sublocality_level_5"] = valueLong;
                    break;
                case "administrative_area_level_3":
                    address["administrative_area_level_3"] = valueLong;
                    break;
                case "administrative_area_level_4":
                    address["administrative_area_level_4"] = valueLong;
                    break;
                case "administrative_area_level_5":
                    address["administrative_area_level_5"] = valueLong;
                    break;
                case "postal_town":
                    address["postal_town"] = valueLong;
                    break;
                default:
                    break;
            }
        }
    }

    //update the city name
    address = updateCityName(address);

    return [address, types ? types : resultObject.types];
}

function updateCityName(address) {
    let city = "";
    if (address.sublocality_level_5) {
        //if hte sublocality Level 5 is available
        city = address.sublocality_level_5; //the city name value is the sublocality Level 5 value
    } else if (address.sublocality_level_4) {
        //if hte sublocality Level 4 is available
        city = address.sublocality_level_4; //the city name value is the sublocality Level 4 value
    } else if (address.sublocality_level_3) {
        //if hte sublocality Level 3 is available
        city = address.sublocality_level_3; //the city name value is the sublocality Level 3 value
    } else if (address.sublocality_level_2) {
        //if hte sublocality Level 2 is available
        city = address.sublocality_level_2; //the city name value is the sublocality Level 2 value
    } else if (address.sublocality_level_1) {
        //if hte sublocality Level 1 is available
        city = address.sublocality_level_1; //the city name value is the sublocality Level 1 value
    } else if (address.sublocality) {
        //if hte sublocality is available
        city = address.sublocality; //the city name value is the sublocality value
    } else if (address.locality) {
        //if hte locality is available
        city = address.locality; //the city name value is the locality value
    } else if (address.postal_town) {
        //if hte Postal Town is available
        city = address.postal_town; //the city name value is the  Postal Town value
    } else if (address.administrative_area_level_5) {
        //if hte Administrative Area Level 5 is available
        city = address.administrative_area_level_5; //the city name value is the Administrative Area Level 5 value
    } else if (address.administrative_area_level_4) {
        //if hte Administrative Area Level 4 is available
        city = address.administrative_area_level_4; //the city name value is the Administrative Area Level 4 value
    } else if (address.administrative_area_level_3) {
        //if hte Administrative Area Level 3 is available
        city = address.administrative_area_level_3; //the city name value is the Administrative Area Level 3 value
    }
    address["city_name"] = city;

    return address;
}

function setCurrentAddressFromAddressComponents(
    addressComponents,
    types = null
) {
    //initialize the address object data with the addressComponent object data
    let addressToDisplay = {};
    //set StreetNumber
    addressToDisplay["streetNumber"] = addressComponents.street_number;
    //set StreetName
    addressToDisplay["streetName"] = addressComponents.street_name;
    //set Sublocality
    addressToDisplay["sublocality"] = addressComponents.sublocality;
    //set StateCode
    addressToDisplay["stateCode"] = addressComponents.state_code;
    //set CountryCode
    addressToDisplay["countryCode"] = addressComponents.country_code;
    //set PostalCode
    addressToDisplay["postalCode"] = addressComponents.postal_code;
    //set CityName
    addressToDisplay["cityName"] = addressComponents.city_name;

    //types
    if (types) addressToDisplay["types"] = types;

    addressToDisplay["formatted_address"] = addressComponents.formatted_address;

    //if the address nick name is not empty
    if (!addressToDisplay.street_name) {
        //if rhw street name value is not equal the name
        if (addressToDisplay.street_name !== addressComponents.name) {
            //the address name is the address component name
            addressToDisplay["addressName"] = addressComponents.name;
        }
    } else {
        //if the types list contain point of interest
        if (addressToDisplay.types.find(type => type === "point_of_interest")) {
            //set the name object as the street name
            addressToDisplay["streetName"] = addressComponents.name;
        }
    }
    return addressToDisplay;
}

function canParseGeneralData(addr) {
    const check1 =
        addr.types.country ||
        addr.types.locality ||
        addr.types.political ||
        addr.types.postalCode;
    return !!(check1 || addr.streetName);
}


function createDescriptionStringFromAddressObject(addrToDisplay) {
    if (addrToDisplay) {

        let addressString = `${((addrToDisplay.streetNumber)
            ? `${addrToDisplay.streetNumber} `
            : '')}` +
            `${((addrToDisplay.streetName)
                ? `${addrToDisplay.streetName}`
                : '')}` +
            `${((addrToDisplay.cityName && !addrToDisplay.cityName.airport)
                ? `, ${addrToDisplay.cityName}`
                : '')}`;
        return addressString;
    }
    return null
}

function parseAddressById(placeByIdResponse, addressType) {
    return dispatch => {
        dispatch(parse(placeByIdResponse, addressType));
        // If address_components came from Google "getAddressById":
        const [addr, types] = AddressComponents(placeByIdResponse);
        // address ans from google parse
        const addrToDisplay = setCurrentAddressFromAddressComponents(addr, types);
        if (addrToDisplay.addressName) {
            const addressDescription = createDescriptionStringFromAddressObject(addrToDisplay);
            //update the address name
            dispatch(
                uiActions.updateAddressDescription(
                    addressDescription ? addressDescription : addrToDisplay.addressName,
                    addressType
                )
            );
        }

        if (canParseGeneralData(addrToDisplay)) {
            dispatch(success(addrToDisplay, addressType));

            //parse the data
            dispatch(
                addressActions.parseGeneralDataById(
                    placeByIdResponse,
                    addressType,
                    addrToDisplay
                )
            );
        } else {
            dispatch(failed(addrToDisplay, addressType));
            //go to reverse geo code
            const placeLatLong = `${placeByIdResponse.geometry.location.lat},${placeByIdResponse.geometry.location.lng}`;
            dispatch(
                googleMapsActions.getPlaceByReverseGeoCoding(
                    placeLatLong,
                    addressType,
                    addr,
                    types
                )
            );
        }
        return {isSuccess: canParseGeneralData(addrToDisplay), addrToDisplay};
    };

    function parse(address_components, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_ID_PARSING,
            address_components,
            addressType
        };
    }

    function success(result, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_ID_SUCCESS,
            result,
            addressType
        };
    }

    function failed(address, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_ID_FAILURE,
            address,
            addressType
        };
    }
}

function parseAddressByReverseGeoCoding(
    result,
    addressType,
    validDistance,
    types,
    placeByIdResponse = false
) {
    return dispatch => {
        // Notify the Reverse Geo Coding parsing is beginning
        dispatch(parse(result, addressType));
        // Loop throw the 'address_components' and get the relevant details
        const [addr, newTypes] = AddressComponents(
            result,
            types ? types : result.types
        );
        // address ans from google parse
        let addrToDisplay = setCurrentAddressFromAddressComponents(addr, newTypes);
        addrToDisplay["reverseGeo"] = true;
        // If City Name is missing from the address parsing is failed and passed into
        // final parsing step.
        if (addrToDisplay.formatted_address)
        //update the address name
            dispatch(
                uiActions.updateAddressDescription(
                    addrToDisplay.formatted_address,
                    addressType
                )
            );
        dispatch(success(addrToDisplay, addressType));
        dispatch(
            addressActions.parseGeneralDataById(
                result,
                addressType,
                validDistance,
                false,
                addrToDisplay
            )
        );
    };

    function parse(address_components, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_PARSING,
            address_components,
            addressType
        };
    }

    function success(result, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_SUCCESS,
            result,
            addressType
        };
    }

    function failed(result, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_BY_GEO_CODING_FAILURE,
            result,
            addressType
        };
    }
}

function parseIfAllFailed(address, addressType) {
    return dispatch => {
        dispatch(parse(address, addressType));

        address.cityName = address.sublocality;

        if (address.cityName !== "") {
            dispatch(success(address, addressType));
        } else {
            dispatch(failed(address, addressType));
            // dispatch(alertActions.error("Address not recognized."))
        }
    };

    function parse(previousResults, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_PARSING,
            previousResults,
            addressType
        };
    }

    function success(result, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_SUCCESS,
            result,
            addressType
        };
    }

    function failed(result, addressType) {
        return {
            type: addressConstants.PARSE_ADDRESS_COMPONENT_AFTER_ALL_FAILED_FAILURE,
            result,
            addressType
        };
    }
}

function airportSelected(airportSelected, addressType, verificationNeeded = true) {
    return dispatch => {
        dispatch(uiActions.displaySpinner());
        const fromAirportAll = generalService.getListObject(
            carmelConstAPILists.AIRPORT_LIST_ALL,
            airportSelected.value ? airportSelected.value : airportSelected.airportCode
        );

        if (fromAirportAll && fromAirportAll.hasOwnProperty("airportName")) {
            fromAirportAll.addressDescription = fromAirportAll.addressNickName =
                fromAirportAll.airportName;
            dispatch(uiActions.updateAddressDescription(fromAirportAll.addressDescription, addressType));
        }
        fromAirportAll.airport = true;
        dispatch(setAddress(fromAirportAll, addressType));

        dispatch(addressActions.parseGeneralDataById(fromAirportAll, addressType, true, false, null, verificationNeeded));
    };

    function setAddress(fromAirportAll, addressType) {
        return {
            type: addressConstants.SET_ADDRESS_OBJECT,
            fromAirportAll,
            addressType
        };
    }
}


function pickUpChanged() {
    return {
        type: addressConstants.PICK_UP_ADDRESS_CHANGED
    };
}

function clearAddress(addressType) {
    return (dispatch, getState) => {
        dispatch(addressActions.clearServiceByAddress(addressType));

        dispatch(clearAction(addressType));
        // Any change in the trip Pick-Up or Drop-Off will reset
        // the price list and price selected
        // dispatch(uiActions.clearSelectedPrice());
        // dispatch(addressActions.clearPrediction(addressType));

        //reset the time form

        const state = store.getState();
        if (addressType === addressConstants.PICK_UP_ADDRESS) {
            dispatch(formsActions.clearAllFormFields(formsConstants.TIME_FORM));
            dispatch(carmelActions.clearServiceByAddressTimeout());
            dispatch(addressActions.clearAirlineList());
            dispatch(uiActions.closeAirportSelect());
            // Clear the serviceByAddress refresh frequency
            dispatch(carmelActions.clearServiceByAddressTimeout());
            dispatch(
                addressActions.clearServiceByAddress(
                    !state.trip[addressConstants.PICK_UP_ADDRESS]
                )
            );
            dispatch(
                formsActions.clearFormFields(
                    formsConstants.AIRLINE_FORM,
                    formsConstants.AIRLINE_NAME
                )
            );
            dispatch(
                formsActions.clearFormFields(
                    formsConstants.AIRLINE_FORM,
                    formsConstants.FLIGHT_NUMBER
                )
            );
            dispatch(
                formsActions.clearFormFields(
                    formsConstants.AIRLINE_FORM,
                    formsConstants.ARRIVE_CITY
                )
            );
            dispatch(formsActions.clearAllFormFields(formsConstants.TIME_FORM));
            dispatch(tripActions.clearTrip(addressType));
            dispatch(userActions.clearAirportList());
            dispatch(uiActions.clearAddressDescription(addressType))

        } else {
            dispatch(
                addressActions.displayClosestAirportsOnPriceSupport());
        }
        dispatch(tripActions.clearTrip(addressType));
        dispatch(uiActions.clearAddressDescription(addressType));
    };

    function clearAction(addressType) {
        return {
            type: addressConstants.CLEAR_ADDRESS,
            addressType
        };
    }
}

function detectCurrentLocation() {
    return dispatch => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const latLongString = `${position.coords.latitude},${position.coords.longitude}`;

                dispatch(
                    googleMapsActions.getPlaceByReverseGeoCoding(
                        latLongString,
                        addressConstants.PICK_UP_ADDRESS
                    )
                );

                // For UI usage - Show Current Location string in the create trip form
                dispatch(updateIsCurrentLocation(position));
            },
            position => {
                // For UI usage - Hide Current Location string in the create trip form
                dispatch(updateIsCurrentLocation(position));
            }
        );
    };
}

function updateIsCurrentLocation(location) {
    return {
        type: addressConstants.DETECT_CURRENT_LOCATION,
        location
    };
}

function clearServiceByAddress(addressType = addressConstants.PICK_UP_ADDRESS) {
    return {
        type: addressConstants.CLEAR_SERVICE_BY_ADDRESS,
        addressType
    };
}

function getPredictionFromLocal(userInput, addressType) {
    return dispatch => {
        // Get the current Redux state
        const state = store.getState();

        // Get the Address (for favorites) and lists (for airport list)
        const {addresses} = state.user.lists;
        const {lists} = state.general;
        const {serviceByAddress} = state.temp.services;

        const match = new RegExp(escapeRegExp(userInput), "i");

        // Clear the prediction array in the Redux store
        dispatch(clearPrediction(addressType));

        // Filter the address and airport list with the user-input-matched items
        // The order of the addToPrediction will determine the order of the result prediction
        // We use:
        // - If addressType is "dropOff" and "addrPriceSupported" is "false" => add suggested airports
        // - Favorites address
        // - Airport list
        if (userInput !== "") {
            if (
                addressType === addressConstants.DROP_OFF_ADDRESS &&
                !serviceByAddress.addrPriceSupported
            ) {
                dispatch(
                    addToPrediction(
                        serviceByAddress.airportList.airportList.filter(airport =>
                            match.test(airport.airportName)
                        )
                    )
                );
            }
            dispatch(
                addToPrediction(
                    addresses.filter(
                        address =>
                            match.test(address.addressDescription) &&
                            address.favorite === true
                    )
                )
            );
            dispatch(
                addToPrediction(
                    lists[carmelConstAPILists.AIRPORT_LIST_ALL].filter(airport =>
                        match.test(airport.airportName)
                    )
                )
            );
        } else {
            if (
                addressType === addressConstants.DROP_OFF_ADDRESS &&
                !serviceByAddress.addrPriceSupported
            ) {
                dispatch(
                    addToPrediction(
                        serviceByAddress.airportList.airportList.filter(airport =>
                            match.test(airport.airportName)
                        )
                    )
                );
            } else {
                dispatch(clearPrediction(addressType));
            }
        }
    };

    function addToPrediction(arrayOfPredictions) {
        return {
            type: addressConstants.ADD_ADDRESS_PREDICTION,
            arrayOfPredictions,
            addressType
        };
    }
}

function clearPrediction(addressType) {
    return {type: addressConstants.CLEAR_ADDRESS_PREDICTION, addressType};
}

function dropOffAddressOnHold(address) {
    return {
        type: addressConstants.DROP_OFF_ON_HOLD,
        address
    };
}

function clearDropOffAddressOnHold() {
    return {
        type: addressConstants.CLEAR_DROP_OFF_ON_HOLD
    };
}

function updateAddress(address, addressType) {
    return dispatch => {
        dispatch(update(address, addressType));
    };

    function update(address, addressType) {
        return {
            type: addressConstants.UPDATE_ADDRESS,
            address,
            addressType
        };
    }
}

function clearAirlineList() {
    return {
        type: carmelConstantsActions.CLEAR_AIRLINE_LIST
    };
}

function displayClosestAirportsOnPriceSupport(modal = null, hide = null) {
    return (dispatch, getState) => {
        const {serviceByAddr} = getState().temp.services;
        const objects = getState().temp.objects;
        const pickup = getState().trip[addressConstants.PICK_UP_ADDRESS];
        const dropOff = getState().trip[addressConstants.DROP_OFF_ADDRESS];
        const {trips} = getState().user.lists;
        if (serviceByAddr) {
            //if the service by address of pickup is price supported then check if pick up is not airport and display closest airports choose
            if (
                serviceByAddr.hasOwnProperty("addrPriceSupported") &&
                !serviceByAddr.addrPriceSupported &&
                !objects.dropOffAddressOnHold &&
                appHelp.validateObject(pickup) &&
                !pickup.airport &&
                (!appHelp.validateObject(dropOff) || !dropOff.airport)
            ) {
                dispatch(uiActions.hideSpinner());
                dispatch(uiActions.setPopUpTitle('Select Airport for Drop-off'));
                dispatch(uiActions.setPopUpMessage('Currently we are only providing service to/from an airport in the selected drop-off location. Local service will be added shortly.'));
                // dispatch(uiActions.setPopUpMessage());
                let airportListDisplayed = [];
                if (appHelp.validateArr(trips.serviceByAddrAirPorts)) {
                    airportListDisplayed = trips.serviceByAddrAirPorts.map((airport, index) => {
                        return (
                            {
                                classes: 'color',
                                action: () => {
                                    let addressType = addressConstants.PICK_UP_ADDRESS;
                                    if (appHelp.validateObject(pickup)
                                        &&
                                        !appHelp.validateObject(dropOff)
                                    ) {
                                        addressType = addressConstants.DROP_OFF_ADDRESS;
                                    }
                                    dispatch(addressActions.airportSelected(airport, addressType));
                                },
                                title: airport.airportName

                            }
                        )
                    })
                }
                const inputs = [
                    ...airportListDisplayed,
                    {
                        classes: 'grey',
                        action: () => dispatch(clearAddress(addressConstants.PICK_UP_ADDRESS)),
                        title: 'Cancel'
                    },
                ];
                dispatch(uiActions.setPopUpInputs(inputs));
                dispatch(uiActions.showPopUp());

            } else {
                if (hide) hide();
            }
        } else {
            if (hide) hide();
        }
    };
}