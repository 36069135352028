export const carmelConstAPI = {
    // appAuthToken: "290fcfbcd724ab5933092d21b79dc96fff001aeeee4568ecf3925ac1f6972537",
    apiEndPoint: "http://127.0.0.1:8000/api",
    // facebookAppKey: "186417942240253",
    // facebookAppKey: "428102170874450",
    //FACEBOOK PRODUCTION
    facebookSecret: 'c8d130657c5267e692ac962d9c56ca63',
    facebookAppKey: "1348334208564160",
    //FACEBOOK PRODUCTION
    // facebookAppKey: "428102170874450",
    googleAppKey: "AIzaSyCbCnFivfoUZq0Q1LDeWZw0u8gWI7xN8U4",
    platform: "W",
    authTypeGuest: "G",
    authTypeCarmel: "C",
    authTypeFacebook: "F",

    FOP_TYPE_CODE_CREDIT: 'CC',
    FOP_TYPE_CODE_CASH: 'CA',
    FOP_TYPE_CODE_HOUSE: 'CH',

    FARE_DEFAULT: 'DFLT',
    FARE_BASE: 'BASE',
    FARE_ALL_INCLUSIVE: 'AI',
    FARE_BASIC_DESC: 'FARE_BASIC_DESC',
    FARE_ALL_INCLUSIVE_DESC: 'FARE_ALL_INCLUSIVE_DESC',
    FARE_CONGESTION_FEE: 'CONGESTION_FEE_DESC',
};

export const carmelConstantsActions = {
    CAR_CASH_REQUEST: 'CAR_CASH_REQUEST',
    CAR_CASH_SUCCESS: 'CAR_CASH_SUCCESS',
    CAR_CASH_FAILURE: 'CAR_CASH_FAILURE',

    TRIP_LIST_REQUEST: 'TRIP_LIST_REQUEST',
    TRIP_LIST_SUCCESS: 'TRIP_LIST_SUCCESS',
    TRIP_LIST_FAILURE: 'TRIP_LIST_FAILURE',

    PAST_TRIP_LIST_SUCCESS: 'PAST_TRIP_LIST_SUCCESS',
    FUTURE_TRIP_LIST_SUCCESS: 'FUTURE_TRIP_LIST_SUCCESS',
    FUTURE_TRIP_LIST_FAILURE: 'FUTURE_TRIP_LIST_FAILURE',
    TRIP_LIST_FETCH_SUCCESS: 'TRIP_LIST_FETCH_SUCCESS',
    TRIP_LIST_FETCH_FAILURE: 'TRIP_LIST_FETCH_FAILURE',

    UPLOAD_PROFILE_IMAGE_REQUEST: 'UPLOAD_PROFILE_IMAGE_REQUEST',
    UPLOAD_PROFILE_IMAGE_SUCCESS: 'UPLOAD_PROFILE_IMAGE_SUCCESS',
    UPLOAD_PROFILE_IMAGE_FAILURE: 'UPLOAD_PROFILE_IMAGE_FAILURE',

    SERVICE_BY_ADDRESS_REQUEST: 'SERVICE_BY_ADDRESS_REQUEST',
    SERVICE_BY_ADDRESS_SUCCESS: 'SERVICE_BY_ADDRESS_SUCCESS',
    SERVICE_BY_ADDRESS_FAILURE: 'SERVICE_BY_ADDRESS_FAILURE',


    GET_PRICE_LIST_REQUEST: 'GET_PRICE_LIST_REQUEST',
    GET_PRICE_LIST_SUCCESS: 'GET_PRICE_LIST_SUCCESS',
    GET_PRICE_LIST_FAILURE: 'GET_PRICE_LIST_FAILURE',

    CLEAR_PRICE_LIST: 'CLEAR_PRICE_LIST',
    CLEAR_SERVICE_BY_ADDRESS_TIMEOUT: 'CLEAR_SERVICE_BY_ADDRESS_TIMEOUT',

    ADD_FOP_REQUEST: 'ADD_FOP_REQUEST',
    ADD_FOP_SUCCESS: 'ADD_FOP_SUCCESS',
    ADD_FOP_FAILURE: 'ADD_FOP_FAILURE',

    UPDATE_FOP_REQUEST: 'UPDATE_FOP_REQUEST',
    UPDATE_FOP_SUCCESS: 'UPDATE_FOP_SUCCESS',
    UPDATE_FOP_FAILURE: 'UPDATE_FOP_FAILURE',

    DELETE_FOP_REQUEST: 'DELETE_FOP_REQUEST',
    DELETE_FOP_SUCCESS: 'DELETE_FOP_SUCCESS',
    DELETE_FOP_FAILURE: 'DELETE_FOP_FAILURE',

    TRIP_UPDATE_REQUEST: 'TRIP_UPDATE_REQUEST',
    TRIP_UPDATE_SUCCESS: 'TRIP_UPDATE_SUCCESS',
    TRIP_UPDATE_FAILURE: 'TRIP_UPDATE_FAILURE',

    TRIP_CANCELLATION_REQUEST: 'TRIP_CANCELLATION_REQUEST',
    TRIP_CANCELLATION_SUCCESS: 'TRIP_CANCELLATION_SUCCESS',
    TRIP_CANCELLATION_FAILURE: 'TRIP_CANCELLATION_FAILURE',

    TRIP_CHARGE_REQUEST: 'TRIP_CHARGE_REQUEST',
    TRIP_CHARGE_SUCCESS: 'TRIP_CHARGE_SUCCESS',
    TRIP_CHARGE_FAILURE: 'TRIP_CHARGE_FAILURE',

    NICKNAME_UPDATE_REQUEST: 'NICKNAME_UPDATE_REQUEST',
    NICKNAME_UPDATE_SUCCESS: 'NICKNAME_UPDATE_SUCCESS',
    NICKNAME_UPDATE_FAILURE: 'NICKNAME_UPDATE_FAILURE',

    ADDRESS_GENERATION_FAILURE: 'ADDRESS_GENERATION_FAILURE',

    DELETE_ADDRESS_REQUEST: 'DELETE_ADDRESS_REQUEST',
    DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
    DELETE_ADDRESS_FAILURE: 'DELETE_ADDRESS_FAILURE',


    DELETE_TRIP_REQUEST: 'DELETE_TRIP_REQUEST',
    DELETE_TRIP_SUCCESS: 'DELETE_TRIP_SUCCESS',
    DELETE_TRIP_FAILURE: 'DELETE_TRIP_FAILURE',

    AIRLINE_LIST_REQUEST: 'AIR_LINE_LIST_REQUEST',
    AIRLINE_LIST_SUCCESS: 'AIRLINE_LIST_SUCCESS',
    AIRLINE_LIST_FAILURE: 'AIR_LINE_LIST_FAILURE',
    CLEAR_AIRLINE_LIST: 'CLEAR_AIRLINE_LIST',

    REFERRAL_SUBMIT_REQUEST: 'REFERRAL_SUBMIT_REQUEST',
    REFERRAL_SUBMIT_SUCCESS: 'REFERRAL_SUBMIT_SUCCESS',
    REFERRAL_SUBMIT_FAILURE: 'REFERRAL_SUBMIT_FAILURE',

    PERK_LIST_REQUEST: 'PERK_LIST_REQUEST',
    PERK_LIST_SUCCESS: 'PERK_LIST_SUCCESS',
    PERK_LIST_FAILURE: 'PERK_LIST_FAILURE',

    CUST_ACTIVATE_REQUEST: 'CUST_ACTIVATE_REQUEST',
    CUST_ACTIVATE_SUCCESS: 'CUST_ACTIVATE_SUCCESS',
    CUST_ACTIVATE_FAILURE: 'CUST_ACTIVATE_FAILURE',
    CUST_ACTIVATE_VALIDATION_CODE_SUCCESS: 'CUST_ACTIVATE_VALIDAION_CODE_SUCCESS',

    RATE_DRIVER_REQUEST: 'RATE_DRIVER_REQUEST',
    RATE_DRIVER_SUCCESS: 'RATE_DRIVER_SUCCESS',
    RATE_DRIVER_FAILURE: 'RATE_DRIVER_FAILURE',

    REFRESH_TOKEN_REQUEST:'REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_SUCCESS:'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE:'REFRESH_TOKEN_FAILURE',
};

export const carmelMassageCodes = {
    OK: 'OK',
    ERROR: "ERROR",
    INVALID_REQUEST: "INVALID_REQUEST",
    INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
    FB_USER_REGISTERATION: "FB_USER_REGISTERATION",
    FB_INVALID_ACCESS_TOKEN: "FB_INVALID_ACCESS_TOKEN",
    ACCESS_DENIED: "ACCESS_DENIED",
    UPDATE_REQUIRED: "UPDATE_REQUIRED",
    UPDATE_AVAILABLE: "UPDATE_AVAILABLE",
    TIMEOUT: "TIMEOUT",
    TIMEOUT_RETRY: "TIMEOUT_RETRY",
    TIMEOUT_ABORT: "TIMEOUT_ABORT",
    NEXT_CAR: "NEXT_CAR",
    CARD_ERROR: "CARD_ERROR",
    CUST_PROFILE_ERROR: "CUST_PROFILE_ERROR",
    CUST_ACTIVATE: "CUST_ACTIVATE",
    ACCESS_TOKEN_EXPIRED: "ACCESS_TOKEN_EXPIRED"
};

export const carmelFacebookCodes = {
    PHONE_REQUIRED: "PHONE_REQUIRED",
    EMAIL_REQUIRED: "EMAIL_REQUIRED",
    USER_ACTION_REQUIRED: "USER_ACTION_REQUIRED",
    INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
    FACEBOOK_INCORRECT_PASSWORD: "FACEBOOK_INCORRECT_PASSWORD",
    INVALID_REQUEST: 'INVALID_REQUEST',
};


export const carmelConstAPIMethods = {
    // Authentication
    CUST_LOGIN: 'CustLogin',
    CUST_UPDATE: 'CustUpdate',
    FACEBOOK_LOGIN: 'FacebookLogin',
    CUST_REGISTER: 'CustRegister',
    REWARD_PROGRAM_LIST: 'RewardProgramList',

    // FOP
    CUST_FOP_UPDATE: 'CustFopUpdate',
    CUST_FOP_DELETE: 'CustFopDelete',

    // Trip
    TRIP_UPDATE: 'TripUpdate',
    TRIP_CANCEL: 'TripCancel',
    TRIP_CHARGE: 'TripCharge',
    SERVICE_BY_ADDRESS: 'ServiceByAddr',
    CUST_RECENT_TRIP_UPDATE: 'CustRecentTripUpdate',
    CUST_RECENT_ADDRESS_UPDATE: 'CustAddrUpdate',
    CUST_ADDRESS_DELETE: 'CustAddrDelete',
    CUST_TRIP_DELETE: 'CustRecentTripDelete',

    // Lists
    AIRPORT_LIST: 'airportList',
    COUNTRY_LIST: 'CountryList',
    FOP_TYPE_LIST: 'FopTypeList',
    PRICE_LIST: 'PriceList',
    TRIP_LIST: 'TripList',
    CUST_FOP_LIST: 'CustFopList',
    CUST_ADDRESS_LIST: 'CustAddrList',
    CUST_RECENT_TRIP_LIST: 'CustRecentTripList',
    AIRLINE_LIST: 'AirlineList',
    PERK_LIST: 'PerkList',

    //car cash list
    CUST_CAR_CASH_LIST: 'CustCarCashList',

    //referral code
    REFERRAL_CODE_SUBMIT: 'AppReferral',
    CUST_ACTIVATE: 'CustActivate',
    DRIVER_RATING_REQUEST: 'DriverRating',

    CUST: 'Cust'
};


export const carmelConstAPILists = {
    SERVICE_BY_ADDRESS_AIR_PORT_LIST: 'SERVICE_BY_ADDRESS_AIR_PORT_LIST',
    AIRPORT_LIST_SERVICE: 'airportListService',
    AIRPORT_LIST_ALL: 'airportListAll',
    COUNTRY_LIST: 'countryList',
    FOP_TYPE_LIST: 'fopTypeList',
    STATE_LIST: 'stateList',
    TRIP_LIST: 'tripList',
    CUST_FOP_LIST: 'fopList',
    CUST_ADDRESS_LIST: 'addrList',
    CUST_RECENT_TRIPS: 'tripList',
};

export const carmelConstAPIObjectCode = {
    AIRPORT_OBJECT: 'airportCode',
    COUNTRY_OBJECT: 'code',
    FOP_TYPE_OBJECT: 'fopCode',
};

carmelConstAPILists.objectCode = {
    [carmelConstAPILists.AIRPORT_LIST_SERVICE]: carmelConstAPIObjectCode.AIRPORT_OBJECT,
    [carmelConstAPILists.AIRPORT_LIST_ALL]: carmelConstAPIObjectCode.AIRPORT_OBJECT,
    [carmelConstAPILists.COUNTRY_LIST]: carmelConstAPIObjectCode.COUNTRY_OBJECT,
    [carmelConstAPILists.FOP_TYPE_LIST]: carmelConstAPIObjectCode.FOP_TYPE_OBJECT
};

export const carmelObjectNames = {
    TRIP_TIME: 'tripTime',
    TRIP_DATE: 'tripDate',

    PRICE_LIST: 'priceList',
};

export const carmelConstants = {
    BASIC_INFO_REQUEST: 'BASIC_INFO_REQUEST',
    BASIC_INFO_SUCCESS: 'BASIC_INFO_SUCCESS',
    BASIC_INFO_FAILURE: 'BASIC_INFO_FAILURE',
};
