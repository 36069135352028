import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import sad from '../../../assets/images/rate_driver/sad.png'
import dull from '../../../assets/images/rate_driver/dull.png'
import smile from '../../../assets/images/rate_driver/smile.png'
import angel from '../../../assets/images/rate_driver/angel.png'
import {formsActions} from "../../../store/actions";
import {formsConstants} from "../../../constants";

const RateDriverButtons = ({dispatch, displayOnly, driverRating}) => {

    const [ratingImages, setRatingImages] = useState([
        {
            image: sad,
            rate: 1,
            active: Number.isInteger(displayOnly) ? displayOnly === 1 : driverRating === 1
        }, {
            image: dull,
            rate: 2,
            active: Number.isInteger(displayOnly) ? displayOnly === 2 : driverRating === 2
        }, {
            image: smile,
            rate: 3,
            active: Number.isInteger(displayOnly) ? displayOnly === 3 : driverRating === 3
        }, {
            image: angel,
            rate: 4,
            active: Number.isInteger(displayOnly) ? displayOnly === 4 : driverRating === 4
        },
    ]);
    const setActive = (rate) => {
        if (Number.isInteger(displayOnly) && displayOnly === 0)
            return;
        const images = [...ratingImages];
        //set active rate driver image
        images.forEach(image => {
            if (image.rate === rate) {
                image.active = true;
                //update chosen feedback
                dispatch(formsActions.updateFormFieldValue(
                    formsConstants.DRIVER_FEEDBACK_FORM,
                    formsConstants.DRIVER_RATING,
                    rate,
                    []
                ))
            } else {
                image.active = false;
            }
        });
        setRatingImages(images);
    };

    if (Number.isInteger(displayOnly) && displayOnly > 0 && !ratingImages[displayOnly - 1].active) {
        setActive(displayOnly)
    }
    return (
        <ul className={Number.isInteger(displayOnly) ? 'rate-driver-buttons-display-only rating_images' : 'rating_images'}>
            {
                ratingImages.map(rateImage => {
                    return (
                        <li key={rateImage.rate} className={rateImage.active ? 'selected' : ''}
                            onClick={() => setActive(rateImage.rate)}>
                            <img src={rateImage.image} alt="sad" data-status="sad"/>
                        </li>
                    )
                })
            }
        </ul>
    );
};


const mapStateToProps = ({}) => {
    return {}
};

const connectedPage = injectIntl(connect(mapStateToProps)(RateDriverButtons));
export {connectedPage as RateDriverButtons};