import React, {useState, useEffect, Fragment} from 'react'
import {connect} from "react-redux";
import {urls} from "../../constants";
import {Link} from "react-router-dom";
import {MainActivity} from '../MainPage/MainActivity/MainActivity'
import {appHelp} from "../../helpers";

const PageInfo = ({page, fare, car}) => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        switch (page.currentUrl) {
            case urls.LOGIN:
                setTitle('Login');
                break;
            case urls.REGISTER:
                setTitle('Register');
                break;
            case urls.TRIP_UPDATE:
                setTitle('Trip Payment Details');
                break;
            case urls.THANK_YOU:
                setTitle('Thank you for using Briz.');
                break;
            case urls.MY_TRIPS:
                setTitle('My Trips');
                break;
            case urls.PAY_FOR_RIDE:
                setTitle('Pay For Ride');
                break;
            case urls.TIP_AND_TOLLS:
                setTitle('Tip & Tolls');
                break;
            case urls.ACCOUNT:
                setTitle('Personal Information');
                break;
            case urls.REFFERAL_CODE:
                setTitle('Use Referral Code');
                break;
            case urls.REWARD_PROGRAM:
                setTitle('Reward Program');
                break;
            case urls.ABOUT:
                setTitle('About');
                break;
            case urls.PERKS:
                setTitle('Carmel Perks');
                break;
            default:
                setTitle('');
        }
    }, [page.currentUrl]);

    return (
        <Fragment>
            {
                page.currentUrl === urls.TRIP_UPDATE && !appHelp.validateObject(fare) && !appHelp.validateObject(car) ?
                    <MainActivity displayOnly={true}/>
                    :

                    title && page.currentUrl !== urls.MAIN_PAGE &&
                    <header className="site-title color">
                        <div className="wrap">
                            <div className="container">
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </header>


            }
        </Fragment>
    )
};

const mapStateToProps = ({ui, trip}) => {
    const {page} = ui;
    const {fare, car} = trip;
    return {
        page,
        fare,
        car
    };
};

const connectedPage = React.memo(connect(mapStateToProps)(PageInfo));
export {connectedPage as PageInfo};
