import {googleMapsService} from "./service";
import {generalService} from "../general/services";
import {formsConstants} from '../../constants'
import {googleMapsConstants} from './googleMaps.constants'

import {appHelp} from "../../helpers";
import {carmelActions, uiActions, addressActions} from "../actions";

export const googleMapsActions = {
    getPlaceById,
    getPlaceByReverseGeoCoding,
    getTimeZoneByAddress,
    clearAutocomplete,
    getAutocomplete
};

function getAutocomplete(text, addressType) {
    return (dispatch, getState) => {
        const state = getState();
        // Notify the getAutocomplete google API request is sent.
        dispatch(request(text, addressType));

        googleMapsService.getAutocomplete(text)
            .then(data => {
                // TODO - Create an error handling function for Google API
                dispatch(success(data.predictions, addressType));
            })
            .catch(error => {
                dispatch(failure(error, addressType));
            })
    };

    function request(userInput, addressType) {
        return {type: googleMapsConstants.AUTOCOMPLETE_REQUEST, userInput, addressType}
    }

    function success(predictions, addressType) {
        return {type: googleMapsConstants.AUTOCOMPLETE_SUCCESS, predictions, addressType}
    }

    function failure(error, addressType) {
        return {type: googleMapsConstants.AUTOCOMPLETE_FAILURE, error, addressType}
    }
}

function getPlaceById(placeID, addressType) {
    return dispatch => {
        dispatch(request(placeID, addressType));

        googleMapsService.getPlaceById(placeID)
            .then(data => {
                const {result} = data;

                dispatch(success(result, addressType));
                dispatch(addressActions.parseAddressById(result, addressType));
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                dispatch(failure(error))
            })
    };

    function request(placeID, addressType) {
        return {type: googleMapsConstants.GET_PLACE_BY_ID_REQUEST, placeID, addressType}
    }

    function success(googlePlacesObj, addressType) {
        return {type: googleMapsConstants.GET_PLACE_BY_ID_SUCCESS, googlePlacesObj, addressType}
    }

    function failure(error) {
        return {type: googleMapsConstants.GET_PLACE_BY_ID_FAILURE, error}
    }
}


// This function use Google Reverse geo coding. It gets the lat,lang as a string and the addressType
//
// @param - placeLatLongString - String - format: `${lat},${lng}`
// @param - addressType - Should be 'pickUp' OR 'dropOff' - use addressConstants to avoid mismatches.
function getPlaceByReverseGeoCoding(placeLatLongString, addressType, placeByIdResponse, types = null) {
    return dispatch => {
        dispatch(request(placeLatLongString, addressType));
        googleMapsService.getPlaceByReverseGeoCoding(placeLatLongString)
            .then(data => {
                // Return the first item of the Reverse Geo Coding array because
                // this is the most reliable and accurate result.
                const result = data.results[0];
                // check the distance between old coords and new
                const coords = placeLatLongString.split(',');
                // Returns the distance, in meters, between two LatLngs.
                const distance = appHelp.getDistance(result.geometry.location.lat, result.geometry.location.lng, parseFloat(coords[0]), parseFloat(coords[1]));
                //flag if distance of to responses is less then 100 meters
                const validDistance = distance < 100;
                dispatch(success(result, addressType));
                dispatch(addressActions.parseAddressByReverseGeoCoding(result, addressType, validDistance, types, placeByIdResponse));
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                dispatch(failure(error));
            })
    };

    function request(placeID, addressType) {
        return {type: googleMapsConstants.GET_PLACE_BY_REVERSE_GEO_CODING_REQUEST, placeID, addressType}
    }

    function success(address_components, addressType) {
        return {type: googleMapsConstants.GET_PLACE_BY_REVERSE_GEO_CODING_SUCCESS, address_components, addressType}
    }

    function failure(error) {
        return {type: googleMapsConstants.GET_PLACE_BY_REVERSE_GEO_CODING_FAILURE, error}
    }
}

function getTimeZoneByAddress(location, addressType) {
    return dispatch => {
        dispatch(request(location, addressType));
        googleMapsService.getTimeZone(location)
            .then(response => {
                if (response.status === googleMapsConstants.OK) {
                    dispatch(success(response.timeZoneId, addressType));
                } else {
                    dispatch(failure(response));
                }

            })
            .catch(error => {
                dispatch(failure(error));
            })
    };

    function request(location, addressType) {
        return {type: googleMapsConstants.GET_TIME_ZONE_REQUEST, location, addressType}
    }

    function success(timeZone, addressType) {
        return {type: googleMapsConstants.GET_TIME_ZONE_SUCCESS, timeZone, addressType}
    }

    function failure(error, addressType) {
        return {type: googleMapsConstants.GET_TIME_ZONE_FAILURE, error, addressType}
    }

}

function clearAutocomplete(addressType) {
    return {
        type: googleMapsConstants.CLEAR_AUTOCOMPLETE,
        addressType
    }
}
