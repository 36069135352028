import React from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {formsConstants} from "../../../constants";
import {formsActions} from "../../../store/actions";
import IntlMessages from "../../../utils/IntlMessages";

const DriverMessageBox = ({dispatch, driverFeedbackFrom}) => {
    if (!driverFeedbackFrom) {
        return null;
    }

    const handleChange = (e) => {
        dispatch(formsActions.updateFormFieldValue(
            formsConstants.DRIVER_FEEDBACK_FORM,
            formsConstants.DRIVER_FEEDBACK,
            e.target.value,
            []
        ))
    };
    return (
        <div className="f-row">
            <div className="full-width">
                <label htmlFor="comments"><IntlMessages id='driver-feedback.text-box-title'/></label>
                <textarea
                    value={driverFeedbackFrom[formsConstants.DRIVER_FEEDBACK]}
                    placeholder="Enter your Comments here"
                    onChange={handleChange}
                    id="comments"
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({forms}) => {
    const driverFeedbackFrom = forms[formsConstants.DRIVER_FEEDBACK_FORM];
    return {
        driverFeedbackFrom
    }
};

const connectedPage = injectIntl(connect(mapStateToProps)(DriverMessageBox));
export {connectedPage as DriverMessageBox};