import React from 'react'
import {history} from "../../helpers";
import {urls} from "../../constants";

export const Logo = () => {
    return (
        <div className="logo" onClick={() => {
            history.push(`${urls.MAIN_PAGE}`)
        }}
        />
    )
};

