import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  z-index:1000000;
  top: -50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  z-index: 2000;
  &.fade-in {
    opacity: 1;
    top: 0px;
    transition: all linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    top: -50px;
    transition: all linear 0.15s;
  }
  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}


.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  min-width: 300px;
  max-width: 500px;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 15px;
  right: 20px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  background:none;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #ed6f14;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
  .pop-up-footer{
    width: 100%;
    margin-top: 20px; 
    
    div{
        margin-bottom: 10px;
        padding:0
    } 
    div:last-child{
        margin-bottom: 0px;
    }
  }
}


@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}
`;

export default Modal;
