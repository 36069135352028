import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {navigation, urls} from "../../constants";
import {appHelp} from "../../helpers";
import {userActions} from "../../store/user/actions";
import ProfilePicture from "../../components/GeneralComponents/ProfilePicture";
import logoutIcon from "../../assets/images/logout.png";
import $ from 'jquery'
import Tooltip from '@material-ui/core/Tooltip';
import {findDOMNode} from "react-dom";

const createNest = (nestedSection, deepNest = false) => {
    return (
        <li key={nestedSection.title}>
            {
                !deepNest &&
                <a href="javascript:void(0)"> {nestedSection.title}</a>
            }
            <ul>
                {
                    nestedSection.nested.map(nestedSec => {
                        return (
                            <li key={nestedSec.title}>
                                <Link to={`/${nestedSec.url}`} title={nestedSection.title}>{nestedSec.title}</Link>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    )
};


const navSectionContentCreator = (navSection, logout, loggedIn) => {
    //create nav bar sections
    if (loggedIn === navSection.loggedIn || (!loggedIn && !navSection.loggedIn) || !appHelp.notUndefined(navSection.loggedIn)) {
        if (navSection.nested)
            return (
                createNest(navSection, false)
            );
        else if (navSection.largeNest) {
            return (
                <li key={navSection.title}>
                    <a href="javascript:void(0)" title="Pages">{navSection.title}</a>
                    <div>
                        {
                            navSection.largeNest.map(largeNestSection => {
                                return (

                                    <div className="one-fourth" key={largeNestSection.title}>
                                        <h2>{largeNestSection.title}</h2>
                                        {createNest(largeNestSection, true)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </li>
            )
        } else {
            if (navSection.url === urls.ACCOUNT)
                return <ProfilePicture/>;
            return (
                <li key={navSection.title}
                    className={navSection.url ? '' : 'logout-icon hide-on-shrink tooltip'}>
                    {

                        navSection.url ?
                            <Link className={navSection.class ? navSection.class : ''}
                                  to={navSection.url === urls.MAIN_PAGE ? urls.MAIN_PAGE : `/${navSection.url}`}>
                                {
                                    navSection.title
                                }

                            </Link>
                            :
                            <Tooltip title="logout" aria-label="logout" placement={'right'}>
                                <img src={logoutIcon} alt="logout" onClick={logout}/>
                            </Tooltip>

                    }

                </li>
            )
        }
    }
};
const Nav = ({auth, openNav}) => {
    const dispatch = useDispatch();
    let navRef = useRef(null);
    const [windowVw, setWindowVw] = useState(window.innerWidth);


    function windowResize() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        setWindowVw(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", windowResize);
    }, []);

    const logout = () => {
        dispatch(userActions.logout());
    };


    useEffect(() => {
        const el = findDOMNode(navRef.current);
        if (el && windowVw <= 1050) {
            //if screen width is less then 1050 apply collapse effect
            if (openNav)
                $(el).slideDown(500);
            else {
                $(el).slideUp(500);
            }
        } else
        //if the sceen is bigger display the nav bar
            navRef.current.style.display = 'block';
    }, [openNav, windowVw]);

    return (
        <nav role="navigation" className="main-nav" ref={navRef}>
            <ul>
                {
                    navigation.map((navSection, index) => {
                        return (
                            navSectionContentCreator(navSection, logout, auth.loggedIn)
                        )
                    })
                }
            </ul>
        </nav>
    )
};


const mapStateToProps = ({authentication, user}) => {
    const {auth} = authentication;
    const {pictureURL} = user;
    return {
        auth,
        pictureURL
    };
};

export default React.memo(connect(mapStateToProps)(Nav));