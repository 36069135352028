export const interactiveConstants = {
    CUST_ARRIVED_REQUEST: 'CUST_ARRIVED_REQUEST',
    CUST_ARRIVED_SUCCESS: 'CUST_ARRIVED_SUCCESS',
    CUST_ARRIVED_FAILURE: 'CUST_ARRIVED_FAILURE',

    SAFETY_PING_REQUEST: 'SAFETY_PING_REQUEST',
    SAFETY_PING_SUCCESS: 'SAFETY_PING_SUCCESS',
    SAFETY_PING_FAILURE: 'SAFETY_PING_FAILURE',

    CUST_ARRIVED_SET_TRIP_ID: 'CUST_ARRIVED_SET_TRIP_ID',
    CUST_ARRIVED_VERIFY_TERMINAL: 'CUST_ARRIVED_VERIFY_TERMINAL',
    CUST_ARRIVED_SET_TERMINAL_LIST: 'CUST_ARRIVED_SET_TERMINAL_LIST',
    CUST_ARRIVED_UPDATE_SELECTED_TERMINAL: 'CUST_ARRIVED_UPDATE_SELECTED_TERMINAL',
};