export const modalsTypes = {
    CREDIT_CARD: 'CREDIT_CARD',
    COUNTRIES: 'COUNTRIES',
    COUNTRIES_ICE: 'COUNTRIES_ICE',
    CREDIT_CARD_COUNTRIES: 'CREDIT_CARD_COUNTRIES',
    CALL_FOR_SUPPORT: 'CALL_FOR_SUPPORT',
    PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
    PASSWORD_RECOVERY_BY_MAIL: 'PASSWORD_RECOVERY_BY_MAIL',
    SAVE_FAVORITE: 'SAVE_FAVORITE',
    TERMINAL_PICK: 'TERMINAL_PICK',
    SHOW_MY_CAR: 'SHOW_MY_CAR',
    TRIP_DETAILS: 'TRIP_DETAILS',
    CUST_ACTIVATE_FROM_REGISTER:'CUST_ACTIVATE_FROM_REGISTER',
    CUST_ACTIVATE:'CUST_ACTIVATE',
    RATE_DRIVER:"RATE_DRIVER",
    TERMS_AND_CONDITIONS:"TERMS_AND_CONDITIONS",
    TERMS_MODAL:"TERMS_MODAL",
    PRIVACY_MODAL:"PRIVACY_MODAL",
    PERKS_WELCOME:"PERKS_WELCOME",
};