import {
    tempConstants,
    tripConstants,
    addressConstants,
    userConstants,
    carmelConstantsActions
} from "../../../constants";

export function services(state = {}, action) {
    switch (action.type) {
        // Car Location actions:
        ///////////////////////////////
        case tripConstants.GET_CAR_LOCATION_SUCCESS:
            return {
                ...state,
                carLocation: action.carLocation
            };
        case userConstants.SAVE_IMAGE_FORM_DATA:
            return {
                ...state,
                imageAsFormData: action.imageAsFormData
            };
        case tripConstants.CLEAR_CAR_LOCATION:
            // Remove CarLocation object from the state
            // delete state.carLocation;

            return {
                ...state,
                carLocation: undefined
            };
        // Trip Charge actions:
        ///////////////////////////////
        case tripConstants.FIRST_TRIP_CHARGE_SUCCESS:
            return {
                ...state,
                tripCharge: action.tripChargeData
            };
        case tripConstants.CLEAR_TRIP_CHARGE:
            // Remove TripCharge object from the state
            // delete state.carLocation;

            return {
                ...state,
                tripCharge: undefined
            };
        case tempConstants.SERVICE_BY_ADDRESS_SAVE:
            return {
                ...state,
                serviceByAddr: action.serviceByAddr
            };
        case addressConstants.CLEAR_SERVICE_BY_ADDRESS:
            if (action.addressType === addressConstants.PICK_UP_ADDRESS)
                return {
                    ...state,
                    serviceByAddr: {}
                };
            else {
                return {
                    ...state,
                    [action.addressType]: {
                        ...state[action.addressType],
                        serviceByAddr: {}
                    }
                };
            }
        case carmelConstantsActions.CLEAR_SERVICE_BY_ADDRESS_TIMEOUT:
            return {
                ...state
            };
        case tempConstants.SERVICE_BY_ADDRESS_DROPOFF_SAVE:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    serviceByAddr: action.serviceByAddr
                }
            };
        default:
            return state;
    }
}
