import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import {formsActions} from "../../../../../store/actions";
import {formsConstants} from "../../../../../constants";
import {AutoCompleteAirlinePick} from "./AutoCompleteAirlinePick";
import IntlMessages from '../../../../../utils/IntlMessages'
import {appHelp} from "../../../../../helpers";

const FlightForm = ({dispatch, focusedField, setFocusOnField, airlineForm, airlineList, lists, trip, airportToggle}) => {
    const [fields, setFields] = useState(null);
    const numbersValidator = value => {
        const validator = /[0-9]|\./;
        const isFieldValid = validator.test(value);
        return isFieldValid;
    };

    const checkInput = e => {
        if (!numbersValidator(e.target.value)) {
            const input = document.getElementById(formsConstants.FLIGHT_NUMBER);
            input.value = "";
        }
    };
    const handleChange = (id, value, validators) => {
        if (focusedField === id) {
            //if input is selected as alert the clear the alert
            setFocusOnField("");
        }

        if (id === formsConstants.FLIGHT_NUMBER) {
            if (!numbersValidator(value) && value) return;
        }
        dispatch(
            formsActions.updateFormFieldValue(
                formsConstants.AIRLINE_FORM,
                id,
                value,
                validators
            )
        );
    };


    useEffect(() => {
        if (airlineForm && airlineList && lists && lists.airportListService) {
            setFields(null);

            let airportWithService = [];

            // airport list to array of the select arrived from input
            for (const airport of Object.values(lists.airportListService)) {
                if (airport.airportName) {
                    airportWithService.push(airport);
                }
            }


            //selected airline to display
            const airlineName = airlineList.find(
                option => option.code === airlineForm[formsConstants.AIRLINE_NAME]
            );
            //selected arrived city to display
            const cityArrived = airportWithService.find(
                option => option.airportCode === airlineForm[formsConstants.ARRIVE_CITY]
            );

            let airlineForms = [
                {
                    ID: "airline_name",
                    NAME: "airline_name",
                    PLACE_HOLDER: "Airline Required",
                    FORM_CONST: formsConstants.AIRLINE_NAME,
                    LABEL_FOR: "airline_name",
                    LABEL_CONTENT: "Airline",
                    value:
                        airlineName && airlineName.code
                            ? {label: airlineName.name}
                            : null,
                    options: airlineList.map(airline => {
                        return {label: airline.name, value: airline.code};
                    }),
                    zIndex: 1000
                },
                {
                    ID: "flight_no",
                    NAME: "flight_no",
                    PLACE_HOLDER: "Flight Number Required",
                    FORM_CONST: formsConstants.FLIGHT_NUMBER,
                    LABEL_FOR: "flight_no",
                    LABEL_CONTENT: "Flight#"
                },

                {
                    ID: "arriving_from",
                    NAME: "arriving_from",
                    PLACE_HOLDER: "From City Required",
                    FORM_CONST: formsConstants.ARRIVE_CITY,
                    LABEL_FOR: "arriving_from",
                    LABEL_CONTENT: "Arriving From",
                    value:
                        cityArrived && cityArrived.airportName
                            ? {label: cityArrived.airportName}
                            : null,
                    options: airportWithService.map(airport => {
                        return {label: airport.airportName, value: airport.airportCode};
                    }),
                    zIndex: 999
                }
            ];


            const formFields = airlineForms.map(form => {
                //set custom styles to the select field and when try to submit and select not picked then alert in red
                const customStyles = {
                    control: (base, state) => ({
                        ...base,
                        paddingLeft: "92px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        fontSize: "12px",
                        textAlign: "left",
                        textTransform: "uppercase",
                        border:
                            focusedField === form.FORM_CONST ? "1px solid #D73246" : "none"
                    }),
                    placeholder: defaultStyles => {
                        return {
                            ...defaultStyles,
                            color: "rgba(126,126,126,0.7)"
                        };
                    },
                    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                        return {
                            ...styles,
                            fontSize: "14px",
                            textAlign: "left"
                        };
                    }
                };

                //label alert style
                let labelStyle = {};
                if (appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST))
                    labelStyle = {background: "#D73246"};
                if (form.options)
                    return (
                        <div
                            className="form-group select one-third"
                            key={form.ID}
                        >
                            <label htmlFor={form.LABEL_FOR}>
                                {form.LABEL_CONTENT}
                            </label>
                            <AutoCompleteAirlinePick
                                focus={appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST)}
                                items={form.options}
                                formId={formsConstants.AIRLINE_FORM}
                                id={form.FORM_CONST} form={airlineForm}
                                placeholder={form.PLACE_HOLDER}
                                zIndex={form.zIndex}
                                style={{
                                    border:
                                        appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST)
                                            ? "1px solid #D73246"
                                            : "none"
                                }}
                                focusedField={focusedField}
                                setFocusOnField={setFocusOnField}
                            />{
                            appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST) &&
                            <label htmlFor={form.LABEL_FOR}>
                                <IntlMessages id='field-required-message'/>
                            </label>
                        }
                        </div>
                    );
                return (
                    <div className="form-group select one-third " key={form.ID}>
                        <label htmlFor={form.LABEL_FOR}>
                            {form.LABEL_CONTENT}
                        </label>
                        <input
                            type="number"
                            id={form.FORM_CONST}
                            name={form.NAME}
                            onKeyUp={checkInput}
                            maxLength="50"
                            placeholder={form.PLACE_HOLDER}
                            value={airlineForm[form.FORM_CONST]}
                            style={{
                                border:
                                    appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST)
                                        ? "1px solid #D73246"
                                        : "none",
                                height: '48px'
                            }}
                            onChange={event =>
                                handleChange(event.target.id, event.target.value, [])
                            }
                        />
                        {
                            appHelp.validateArr(focusedField) && focusedField.includes(form.FORM_CONST) &&
                            <label htmlFor={form.LABEL_FOR}>
                                <IntlMessages id='field-required-message'/>
                            </label>
                        }
                    </div>
                );
            });
            setFields(formFields)
        } else
            setFields(null)
    }, [lists, airlineForm, focusedField]);

    return fields;
};

function mapStateToProps({forms, temp, settings, trip, ui}) {
    const airlineForm = forms[formsConstants.AIRLINE_FORM];
    const {airlineList} = temp.lists;
    const {airportToggle} = ui.toggle;
    const {lists} = settings;
    return {
        airlineForm,
        airlineList,
        lists,
        trip,
        airportToggle
    };
}

const connectedPage = React.memo(connect(mapStateToProps)(FlightForm));
export {connectedPage as FlightForm};
