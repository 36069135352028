import React from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {brizServices} from '../../../constants'

const Service = () => {
    return (
        <div className="services iconic white">
            <div className="wrap">
                <div className="row">
                    {
                        brizServices.map(service => {
                            return (
                                <div className="one-third wow fadeIn" data-wow-delay=".4s">
                                    <span className="circle">
                                        <span className={`icon icon-themeenergy_${service.icon}`}/>
                                    </span>
                                    <h3 className='text-max-length' style={{ whiteSpace: "pre-wrap" }}>{service.title}</h3>
                                    <p>
                                    {service.description}
                                    </p>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}


function mapStateToProps() {
    return ({})
}


const connectedPage = injectIntl(connect(mapStateToProps)(Service));
export {connectedPage as Service};