import {generalConstants} from "../../../constants";

export function requestHistory(state = {}, action) {
    switch (action.type) {
        case generalConstants.SAVE_REQUEST_TO_HISTORY:
            let counter;
            try {
                counter = state[action.id].retries + 1;
            }catch(err) {
                counter = 0
            }
            return {
                ...state,
                [action.id]: {
                    requestOptions: action.requestOptions,
                    retries: counter
                }
            };
        default:
            return state;
    }
}