import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {modalsTypes} from "../../../constants";
import {appHelp} from "../../../helpers";
import {formsConstants} from "../../../constants/forms.constants";
import {formsActions} from "../../../store/forms/actions";
import InputWithIcon from "../../GeneralComponents/SearchInput";
import MaterialList from "../../GeneralComponents/MaterialList";
import ModalWrapper from "../ModalWrapper";


const CountryListModal = ({lists, dispatch, modalsUi,modalType}) => {
    const [countryListFiltered, setCountryListFiltered] = useState([]);
    const [closeModal,setCloseModal] = useState(false);

    const handleChangeCountry = value => {
        const {modals} = modalsUi;
        if (value.class) return;
        let id = "";
        if (appHelp.validateArr(modals)) {
            if (
                modalType ===
                modalsTypes.COUNTRIES
            ) {
                id = formsConstants.REGISTER_COUNTRY;
            } else if (
                modalType ===
                modalsTypes.COUNTRIES_ICE
            ) {
                id = formsConstants.REGISTER_COUNTRY_ICE;
            } else {
                id = formsConstants.CARD_BILLING_COUNTRY_CODE;
            }
        }
        if (id === formsConstants.CARD_BILLING_COUNTRY_CODE) {
            dispatch(
                formsActions.updateFormFieldValue(
                    formsConstants.CREDIT_CARD,
                    id,
                    value.code,
                    []
                )
            );
        } else {
            dispatch(
                formsActions.updateFormFieldValue(
                    formsConstants.REGISTER_FORM,
                    id,
                    value,
                    []
                )
            );
        }
        setCloseModal(true);
    };
    const handleChange = (e) => {
        const value = e.target.value;
        if (value) {
            let countryListFiltered = lists.CountryList.filter(country => country.code && country.code.toLowerCase().includes(value.toLowerCase()) || country.name && country.name.toLowerCase().includes(value.toLowerCase()));
            countryListFiltered = countryListFiltered.filter(country => country.code);
            countryListFiltered.unshift({
                name: 'Search Results'
            });
            setCountryListFiltered([...countryListFiltered]);
        } else {
            setCountryListFiltered([]);
        }
    };
    if (!lists || !lists.CountryList)
        return null;

    return (
        <ModalWrapper modalType={modalType} title={'Countries'} closeModalIndicator={closeModal}>
            <div className="modal-full-height-overflow custom-modal-body" style={{padding: '0'}}>
                <div className="f-row" style={{padding: '0'}}>
                    <InputWithIcon handleChange={handleChange}/>
                </div>
                <div className={'f-row'} style={{padding: '0'}}>
                    <MaterialList
                        list={countryListFiltered.length > 0 ? countryListFiltered : lists.CountryList}
                        onCountryPick={handleChangeCountry}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

function mapStateToProps({ui, settings}) {
    const {modalsUi} = ui;
    const {lists} = settings;
    return {
        modalsUi,
        lists
    };
}

export default injectIntl(connect(mapStateToProps)(CountryListModal));
