import React, {useState} from 'react'
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import avatar from '../../../../assets/images/default_avatar_wide.png'
import default_car from '../../../../assets/images/default_car.png'

const ContentCard = ({contentWidth, imageUrl, driver, title, subTitle}) => {
    const [imageStatus, setImageStatus] = useState("loading");

    const handleImageLoaded = () => {
        setImageStatus("loaded")
    };

    const handleImageErrored = () => {
        setImageStatus("failed to load")
    };

    return (
        <div className={contentWidth} style={{padding: '0px'}}>
            <div
                style={contentWidth === 'one-half-trip-details-image' ? driver ? {float: 'right'} : {float: 'left'} : {}}>
                <img
                    src={imageStatus === 'loading' ? driver ? avatar : default_car : imageUrl ? imageUrl : driver ? avatar : default_car}
                    alt=""
                    className={'trip-details-image'}
                    onLoad={handleImageLoaded}
                    onError={handleImageErrored}
                />
                <div className="trip-details-title">
                    <h3>
                        <span>{title}</span>
                    </h3>
                    {
                        subTitle &&
                        <h2>
                            {subTitle}
                        </h2>
                    }
                </div>
            </div>
        </div>
    )
};


export default injectIntl(ContentCard);