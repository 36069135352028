import React from 'react';
import {connect} from "react-redux";
import LoginInputs from './LoginInputs'
import {Link} from "react-router-dom";
import {modalsTypes, urls} from "../../constants";
import {formsConstants} from "../../constants/forms.constants";
import {formsActions, uiActions} from "../../store/actions";
import {userActions} from "../../store/user/actions";
import {UseCheckBox} from "../GeneralComponents/UseCheckBox";

const Login = ({dispatch}) => {
    const [check, checkbox] = UseCheckBox({label: 'Remember me next time'});
    const submitUser = e => {
        e.preventDefault();
        dispatch(formsActions.clearAllFormFields(formsConstants.REGISTER_FORM));
        dispatch(userActions.login());
    };
    return (
        <div className="content one-half modal">
            <div className="box">
                <form>
                    <LoginInputs/>
                    <div className="f-row" onClick={submitUser}>
                        <div className="full-width">
                            <input type="submit" value="Login" className="btn color medium full"/>
                        </div>
                    </div>

                    <p>
                        <span className='alert-link' onClick={() => {
                            dispatch(uiActions.showModal(modalsTypes.PASSWORD_RECOVERY))
                        }}>
                            Forgotten password?
                        </span>
                        <br/>
                        Dont have an account yet?
                        <Link to={`/${urls.REGISTER}`}> Sign up</Link>.

                    </p>
                </form>
            </div>
        </div>

    )
};

const mapStateToProps = ({}) => {
    return {};
};

export default React.memo(connect(mapStateToProps)(Login));