import {history} from '../../helpers'
import {carmelActions, formsActions, generalActions, uiActions} from '../actions'
import {userService} from './services';
import {facebookService} from './facebookService';
import {
    carmelConstantsActions, carmelConstAPI,
    carmelConstAPILists,
    carmelFacebookCodes,
    carmelMassageCodes,
    defaultConstants, fopCardTypeRegex, formsConstants, modalsTypes,
    userConstants,
    userConstLists,
} from '../../constants';
import {urls} from "../../constants";

import {store} from '../index'
import {generalAuthActions} from "../authentication/actions";
import {uiConstants} from "../../constants/";
import {appHelp} from "../../helpers/app";

export const userActions = {
    login,
    logout,
    register,
    Cust,
    facebookLogin,
    uploadImage,
    loadBasicUserInformation,
    clearAirportList,
    chooseTrip,
    passwordRecovery,
    getTripList,
    updateTripOptions,
    updateUserInfo,
    updatePassword,
    clearLists,
    addGuestFop,
    clearMissingInfo
};

function login() {
    return dispatch => {
        dispatch(request());
        // dispatch(uiActions.displaySpinner('spinner.just_A_Moment_Loader'));

        userService.login()
            .then(
                user => {
                    dispatch(carmelActions.errorHandling(user, true, () => dispatch(login())));
                    // dispatch(uiActions.hideSpinner());
                    // login successful if the code is "OK"
                    if (user.result.code === carmelMassageCodes.OK) {
                        // If the login popup is open every successful login dispatching
                        // should remove that popup from the interface
                        //clear all login related fields
                        dispatch(formsActions.clearAllFormFields(formsConstants.REGISTER_FORM));
                        dispatch(formsActions.clearAllFormFields(formsConstants.LOGIN_FORM));
                        //update credentials
                        dispatch(generalAuthActions.updateCarmelUserCredentials(user.result.accessToken));
                        dispatch(success(user));


                        // Load user basic information: Fop List, Trip List, Address List, Recent Trip List
                        dispatch(userActions.loadBasicUserInformation());
                        history.push(`${urls.MAIN_PAGE}`);

                    }
                    else if (user.result.code === carmelMassageCodes.CUST_ACTIVATE) {
                        dispatch(success(user));
                        // Update Credentials object with custLogin and custPassword information
                        dispatch(generalActions.updateCarmelUserCredentials(
                            user.result.accessToken
                        ));
                        // If the user uploaded an image upload it to his account
                        const {imageAsFormData} = store.getState().temp.services;
                        if (!!imageAsFormData) {
                            dispatch(userActions.uploadImage(imageAsFormData));
                        }
                        // update user fields with the fields updated from register
                        dispatch(formsActions.updateFieldsValue(
                            formsConstants.CUST_ACTIVATE_FORM, {
                                [formsConstants.CUST_ACTIVATE_PHONE]: user.phone ? user.phone.number : '',
                                [formsConstants.CUST_ACTIVATE_COUNTRY]: user.phone ? user.phone.countryCode : '',
                                [formsConstants.CUST_ACTIVATE_CODE]: ''
                            }
                        ));
                        //open cust activate
                        dispatch(uiActions.showModal(modalsTypes.CUST_ACTIVATE_FROM_REGISTER));
                    }

                    else {
                        dispatch(uiActions.createBasicPopup("Attention", user.result.message));
                        dispatch(failure(user));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.LOGIN_REQUEST}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function updateFacebookUserDetails({dispatch, firstName = '', lastName = '', emailAddr = '', phone = undefined, countryId = undefined}) {
    dispatch(formsActions.updateFieldsValue(formsConstants.REGISTER_FORM, {
        [formsConstants.REGISTER_FNAME]: firstName,
        [formsConstants.REGISTER_LNAME]: lastName,
        [formsConstants.REGISTER_EMAIL]: emailAddr,
        [formsConstants.REGISTER_COUNTRY]: countryId,
        [formsConstants.REGISTER_PHONE]: phone,
    }));

}

function facebookLogin(userActionOptionId = {}, linkedAccountPassword, userResponse = null) {
    return dispatch => {
        // Get the facebook user collected earlier from the Redux Store
        userService.facebookLogin()
            .then(user => {
                dispatch(formsActions.clearAllFormFields(formsConstants.REGISTER_FORM));
                // Run default error handling before any other process.
                dispatch(carmelActions.errorHandling(user, false, () => dispatch(facebookLogin(userActionOptionId, linkedAccountPassword))));
                // Handle the dialog and options with Carmel API.
                if (user.loginResult) {
                    const {cust} = user;
                    switch (user.loginResult[0]) {
                        case carmelFacebookCodes.USER_ACTION_REQUIRED:
                            //if the response from carmel has conditions to the trip cancel
                            //creating array from the options response to display as button in the pop up
                            const buttonsArray = user.userActions[0].options.map(option => {
                                return {
                                    color: option.abort ? "deepskyblue" : "red",
                                    messageId: option.optionText,
                                    //after button click call function with the selected option
                                    action: () => {
                                        dispatch(
                                            facebookLogin(userActionOptionId, linkedAccountPassword, user.userActions)
                                        )
                                    }
                                };
                            });
                            dispatch(uiActions.setPopUpTitle("Attention"));
                            dispatch(
                                uiActions.setPopUpMessage(user.userActions[0].actionMessage)
                            );
                            dispatch(uiActions.setPopUpButtons(buttonsArray));
                            dispatch(uiActions.showPopUp());
                            break;

                        case carmelFacebookCodes.EMAIL_REQUIRED:
                            updateFacebookUserDetails({
                                dispatch,
                                firstName: cust.firstName,
                                lastName: cust.lastName,
                                emailAddr: cust.emailAddr,
                            });
                            break;
                        case carmelFacebookCodes.PHONE_REQUIRED:
                            updateFacebookUserDetails({
                                dispatch,
                                firstName: cust.firstName,
                                lastName: cust.lastName,
                                emailAddr: cust.emailAddr,
                                phone: '',
                                countryId: 1
                            });
                            break;
                        default:
                            console.log("facebook error")

                    }
                }
                // If everything is fine the user can login to the application
                else {
                    if (user.result && user.result.code === carmelMassageCodes.OK) {
                        // Save the user details to save the login state between refreshes

                        dispatch(success(user.cust));
                        // history.push(`/${urls.BOOKING}`);
                    }
                }

                // history.push('/app');
            })
            .catch(
                err => {
                    dispatch(failure(err))
                }
            )
    };


    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.CARMEL_FACEBOOK_LOGIN_FAILURE, error}
    }
}

function logout(logoutApproved = false) {
    return (dispatch, getState) => {
        const state = getState();
        const {auth} = state.authentication;
        if (auth.loggedIn || logoutApproved) {
            if (!logoutApproved) {
                dispatch(uiActions.setPopUpTitle('Sign Out'));
                dispatch(uiActions.setPopUpMessage('Are you sure you want to sign out?'));
                const options = [{
                    color: 'red',
                    messageId: "Sign Out",
                    action: () => {
                        dispatch(logout(true))
                    }
                },
                    {
                        color: 'darkgray',
                        messageId: "Cancel",
                        action: () => {
                            console.log("stay logged");
                        }
                    }];
                dispatch(uiActions.setPopUpButtons(options));
                dispatch(uiActions.showPopUp());
                return
            }
            dispatch(sendLogout());
            if (state.authentication.Credentials.custAuthType === carmelConstAPI.authTypeFacebook) {
                //if facebook user logout from facebook
                facebookService.logout();
            }
            //reset all users details
            appHelp.deleteCookie('access_token');
            dispatch(generalAuthActions.resetCredentials());
            dispatch(clearLists());
            // dispatch(generalActions.credentialsInit());
            dispatch(carmelActions.loadBasicInfo());
            window.fbAsyncInit();
        }
        history.push(defaultConstants.defaultStartPath);
    };

    function sendLogout() {
        return {type: userConstants.LOGOUT}
    }
}

function register() {
    return dispatch => {
        dispatch(request());
        dispatch(uiActions.displaySpinner('spinner.sending_Loader'));

        userService.register()
            .then(
                response => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(carmelActions.errorHandling(response, true));
                    if (response.result.code === carmelMassageCodes.OK) {
                        dispatch(success(response));
                        dispatch(uiActions.createBasicPopup(response.result.title, response.result.message));
                        // Update Credentials object with custLogin and custPassword information
                        dispatch(generalAuthActions.updateCarmelUserCredentials(
                            response.emailAddr,
                            response.password
                        ));
                        // If the user uploaded an image upload it to his account
                        const {imageAsFormData} = store.getState().temp.services;
                        if (!!imageAsFormData) {
                            dispatch(userActions.uploadImage(imageAsFormData));
                        } else {
                            dispatch(userActions.login());
                        }


                    } else if (response.result.code === carmelMassageCodes.ERROR) {
                        // If a field in the registration form is missing this will update the ui
                        if (response.result.field)
                        // which of the fields is missing's
                            dispatch(missingInfo(response.result));
                        // else
                        // dispatch(uiActions.createBasicPopup(response.result.title, response.result.message))
                    } else if (response.result.code === carmelMassageCodes.CUST_ACTIVATE) {
                        // Update Credentials object with custLogin and custPassword information
                        dispatch(generalAuthActions.updateCarmelUserCredentials(
                            response.emailAddr,
                            response.password
                        ));
                        // If the user uploaded an image upload it to his account
                        const {imageAsFormData} = store.getState().temp.services;
                        if (!!imageAsFormData) {
                            dispatch(userActions.uploadImage(imageAsFormData));
                        }
                        // update user fields with the fields updated from register
                        dispatch(formsActions.updateFieldsValue(
                            formsConstants.CUST_ACTIVATE_FORM, {
                                [formsConstants.CUST_ACTIVATE_PHONE]: response.phone ? response.phone.number : '',
                                [formsConstants.CUST_ACTIVATE_COUNTRY]: response.phone ? response.phone.countryCode : '',
                                [formsConstants.CUST_ACTIVATE_CODE]: ''
                            }
                        ));
                        //open cust activate
                        dispatch(uiActions.showModal(modalsTypes.CUST_ACTIVATE_FROM_REGISTER))
                    } else {
                        dispatch(uiActions.createBasicPopup(response.result.title, response.result.message));
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: userConstants.USER_REGISTER_REQUEST}
    }

    function success(response) {
        return {type: userConstants.USER_REGISTER_SUCCESS, response}
    }

    function failure(error) {
        return {type: userConstants.USER_REGISTER_FAILURE, error}
    }

    function missingInfo(result) {
        return {type: userConstants.REGISTRATION_MISSING_INFO, result}
    }
}

function Cust() {
    return dispatch => {
        dispatch(request());
        // dispatch(uiActions.displaySpinner('spinner.just_A_Moment_Loader'));

        userService.Cust()
            .then(
                user => {

                    dispatch(carmelActions.errorHandling(user, false, () => dispatch(Cust())));
                    dispatch(uiActions.hideSpinner());
                    // login successful if the code is "OK"
                    if (user.result.code === carmelMassageCodes.OK) {
                        // If the login popup is open every successful login dispatching
                        // should remove that popup from the interface
                        dispatch(success(user));
                        dispatch(generalAuthActions.updateCarmelUserCredentials());

                        // dispatch(uiActions.toggleSideMenu(false));
                        // dispatch(alertActions.success("Successfully Logged in!", alertConstants.LOGIN));
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        // Save the Credentials object to local storage to determine the authentication
                        // status of the app.

                        // Load user basic information: Fop List, Trip List, Address List, Recent Trip List
                        dispatch(userActions.loadBasicUserInformation());
                        history.push(`${urls.MAIN_PAGE}`);

                    } else if (user.result.code === carmelMassageCodes.CUST_ACTIVATE) {
                        dispatch(success(user));
                        // dispatch(formsActions.updateFieldsValue(
                        //     formsConstants.CUST_ACTIVATE_FORM, {
                        //         [formsConstants.CUST_ACTIVATE_PHONE]: user.phone ? user.phone.number : '',
                        //         [formsConstants.CUST_ACTIVATE_COUNTRY]: user.phone ? user.phone.countryCode : '',
                        //         [formsConstants.CUST_ACTIVATE_CODE]: ''
                        //     }
                        // ));
                        // dispatch(uiActions.showModal(modalsConstants.modalsTypes.CUST_ACTIVATE_FROM_REGISTER));
                    } else {
                        dispatch(uiActions.hideSpinner());
                        dispatch(uiActions.createBasicPopup("Attention", user.result.message));
                        dispatch(failure(user));
                    }
                },
                error => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(failure(error));
                    // dispatch(alertActions.error(""));
                    dispatch(uiActions.setPopUpMessage('There is a problem connecting to the server." +\n' +
                        '                    //     " Check your internet connection.'));
                    dispatch(uiActions.showPopUp());
                }
            );
    };

    function request() {
        return {type: userConstants.LOGIN_REQUEST}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function passwordRecovery(email, setCloseModal) {
    return dispatch => {
        dispatch(request(email));
        dispatch(uiActions.displaySpinner('spinner.performing_Loader'));

        userService.passwordRecovery(email)
            .then(response => {
                dispatch(uiActions.hideSpinner());

                dispatch(carmelActions.errorHandling(response, false, () => dispatch(passwordRecovery(email))));
                dispatch(uiActions.createBasicPopup(response.result.title, response.result.message));
                if (response.result.code === carmelMassageCodes.OK) {
                    dispatch(success(response));
                    setCloseModal(true);
                } else {
                    dispatch(failure(response));
                }
            })
            .catch(error => {
                dispatch(failure(error));
            });
    };

    function request(user) {
        return {type: userConstants.PASSWORD_RECOVERY_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.PASSWORD_RECOVERY_SUCCESS, user}
    }

    function failure(user) {
        return {type: userConstants.PASSWORD_RECOVERY_FAILURE, user}
    }
}

function updateUserInfo(fields) {
    // Update the user information in the Carmel DB.
    return dispatch => {
        dispatch(request(fields));
        userService.updateUserInfo(fields)
            .then(
                response => {
                    dispatch(uiActions.hideSpinner());
                    if (response.result.code !== carmelMassageCodes.ERROR) {
                        dispatch(uiActions.createBasicPopup('Thank You!', response.result.message));
                        delete response.result;
                        delete response.updateGroup;
                        dispatch(success(response));
                        // dispatch(generalActions.updateCarmelUserCredentials(user.loginId, user.password));
                        dispatch(userActions.loadBasicUserInformation());
                    } else {
                        dispatch(uiActions.createBasicPopup(response.result.title, response.result.message));
                        dispatch(failure(response.result.message));
                    }

                },
                error => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.UPDATE_USER_INFO_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.UPDATE_USER_INFO_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_USER_INFO_FAILURE, error}
    }
}

function updateUserLocation(location) {
    return {
        type: userConstants.UPDATE_CURRENT_LOCATION,
        location
    }
}

function updatePassword(fields, clearFields) {
    return dispatch => {
        dispatch(request(fields));
        dispatch(uiActions.displaySpinner('spinner.change_Pass_Loader'));
        userService.updatePassword(fields)
            .then(
                response => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(uiActions.createBasicPopup(response.result.title, response.result.message));
                    if (response.result.code === carmelMassageCodes.OK) {
                        clearFields(true)
                    } else {
                        dispatch(failure(response.result.message));
                    }

                },
                error => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(failure(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.UPDATE_USER_INFO_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.UPDATE_USER_INFO_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.UPDATE_USER_INFO_FAILURE, error}
    }
}


function uploadImage(imageFile) {
    return dispatch => {
        dispatch(request(imageFile));

        userService.uploadImage(imageFile)
            .then(response => {
                dispatch(carmelActions.errorHandling(response, false, () => dispatch(uploadImage(imageFile))));

                if (response.result.code === carmelMassageCodes.OK) {
                    dispatch(success(response));
                    // dispatch(userActions.login());
                }
            })
            .catch(error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(imageFile) {
        return {type: carmelConstantsActions.UPLOAD_PROFILE_IMAGE_REQUEST, imageFile}
    }

    function success(imageFile) {
        return {type: carmelConstantsActions.UPLOAD_PROFILE_IMAGE_SUCCESS, imageFile}
    }

    function failure(error) {
        return {type: carmelConstantsActions.UPLOAD_PROFILE_IMAGE_FAILURE, error}
    }
}

function addUserDetail(userInfo) {
    return dispatch => {
        dispatch(updateInfo(userInfo));

        dispatch(userActions.facebookLogin());
    };


    function updateInfo(userInfo) {
        return {type: userInfo.type, data: userInfo.data}
    }
}

function saveImageForUploadingAfterSuccessfulRegistration(imageAsFormData) {
    return {
        type: userConstants.SAVE_IMAGE_FORM_DATA,
        imageAsFormData
    }
}

function updateTripOptions(tripOptions) {
    return {
        type: userConstants.UPDATE_CHOSEN_TRIP_OPTIONS,
        tripOptions
    }
}

function clearLists() {
    return {
        type: userConstants.CLEAR_USER_LISTS
    }
}

function loadBasicUserInformation() {
    return dispatch => {
        dispatch(request());

        userService.loadBasicUserInformation()
            .then(response => {
                // Array relies on the server to return the data in the correct order
                const results = {
                    // [userConstLists.TRIP_LIST_FUTURE]: response.results[0].data[carmelConstAPILists.TRIP_LIST],
                    [userConstLists.CUST_FOP_LIST]: response.results[0].data[carmelConstAPILists.CUST_FOP_LIST],
                    [userConstLists.CUST_ADDRESS_LIST]: response.results[1].data[carmelConstAPILists.CUST_ADDRESS_LIST],
                    [userConstLists.CUST_RECENT_TRIPS]: response.results[2].data[carmelConstAPILists.CUST_RECENT_TRIPS],
                    [userConstLists.DISCRIMER]: response.results[3].data[userConstLists.DISCRIMER],
                    [userConstLists.REWARD_LIST]: response.results[3].data[userConstLists.REWARD_LIST],
                    [userConstLists.REFERRAL_LIST]: response.results[4].data,
                };
                // Save the retrieved data into redux.
                dispatch(success(results));
            })
            .catch(error => {
                dispatch(failure(error));
            })
    };

    function request() {
        return {type: userConstants.USER_BASIC_INFO_REQUEST}
    }

    function success(results) {
        return {type: userConstants.USER_BASIC_INFO_SUCCESS, results}
    }

    function failure(error) {
        return {type: userConstants.USER_BASIC_INFO_FAILURE, error}
    }
}


function clearAirportList() {
    return {
        type: userConstants.CLEAR_AIRPORT_LIST,
    }
}


function chooseTrip(trip) {
    return {
        type: userConstants.CHOSEN_TRIP,
        trip
    }
}

function getTripList() {
    return dispatch => {
        dispatch(request());

        userService.getTripList()
            .then(response => {
                // Array relies on the server to return the data in the correct order
                const results = {
                    [userConstLists.TRIP_LIST_FUTURE]: response.results[0].data.tripList,
                    [userConstLists.TRIP_LIST_HISTORY]: response.results[1].data.tripList,
                    [userConstLists.CUST_RECENT_TRIPS]: response.results[2].data.tripList,
                };

                // Save the retrieved data into redux.
                dispatch(success(results));

                // Save the basic info to a local storage item ofr future use.
                // localStorage.setItem('tripList', JSON.stringify(response));
            })
            .catch(error => {
                dispatch(failure(error));
            })
    };

    function request() {
        return {type: carmelConstantsActions.TRIP_LIST_REQUEST}
    }

    function success(lists) {
        return {type: carmelConstantsActions.TRIP_LIST_FETCH_SUCCESS, lists}
    }

    function failure(error) {
        return {type: carmelConstantsActions.TRIP_LIST_FETCH_FAILURE, error}
    }
}

function addGuestFop() {
    return (dispatch, getState) => {
        const state = getState();
        const creditCardForm = state.forms[formsConstants.CREDIT_CARD];
        //creating local credit card for user
        let fopCode = Object.keys(fopCardTypeRegex).filter(cardType => (
                creditCardForm.cardNumber.match(fopCardTypeRegex[cardType])
            )
        );
        const creditCardForGuest = {
            cardExpMonth: creditCardForm[formsConstants.CARD_EXPIRATION_DATE_MONTH],
            cardHolder: creditCardForm[formsConstants.CARD_HOLDER_NAME],
            billingState: creditCardForm[formsConstants.CARD_BILLING_STATE],
            cardExpYear: creditCardForm[formsConstants.CARD_EXPIRATION_DATE_YEAR],
            billingCountryCode: creditCardForm[formsConstants.CARD_BILLING_COUNTRY_CODE],
            cardNumber: creditCardForm[formsConstants.CARD_NUMBER],
            billingCity: creditCardForm[formsConstants.CARD_BILLING_CITY],
            billingZip: creditCardForm[formsConstants.CARD_BILLING_ZIP],
            onFile: false,
            saveNew: false,
            cardSeq: 0,
            fopCode: fopCode[0],
            prepayType: 'DFLT',
            cardCvvCode: creditCardForm[formsConstants.CARD_CVV],
            autoCharge: false,
            billingAddr: creditCardForm[formsConstants.CARD_BILLING_ADDRESS],
        };
        //adding credit card to user fop list
        dispatch(addGuestCreditCard(creditCardForGuest))
    };

    function addGuestCreditCard(card) {
        return {
            type: userConstLists.ADD_GUEST_FOP,
            card
        }

    }
}

function clearMissingInfo() {
    return {type: uiConstants.CLEAR_MISSING_INFO};
}





