import {settingsConstants, defaultConstants, localeOptions, generalConstants, carmelConstants} from '../../constants';

const INIT_STATE = {
    locale:
        localStorage.getItem('currentLanguage') &&
        localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage'))
            .length > 0
            ? localStorage.getItem('currentLanguage')
            : defaultConstants.defaultLocale,
    currentLocation: {},
    cardSeq: 0
};

export const settings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case settingsConstants.CHANGE_LOCALE:
            return {...state, locale: action.payload};

        case generalConstants.UPDATE_CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: action.location,
            };
        case generalConstants.UPDATE_LOCATION_PERMISSION:
            return {
                ...state,
                locationPermissionApproved: action.locationPermissionApproved,
            };
        case settingsConstants.UPDATE_CURRENT_VENDOR:
            return {
                ...state,
                currentVendor: action.vendor
            };
        case carmelConstants.BASIC_INFO_SUCCESS:
            return {
                ...state,
                lists: action.lists
            };
        case generalConstants.CURRENT_CREDIT_CARD:
            return {
                ...state,
                cardSeq: action.cardSeq
            };
        default:
            return state;
    }
};
