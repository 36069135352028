import React, {useEffect, useState, useRef} from "react";
import {modalsTypes} from "../../constants";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {appHelp} from "../../helpers";
import {uiActions} from "../../store/ui/actions";

const CallForSupportModal = ({modalsUi, dispatch}) => {
    const [slideIn, setSlidIn] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const wrapperRef = useRef(null);
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            dispatch(uiActions.closeModal(modalsTypes.CALL_FOR_SUPPORT))
        }
    };

    useEffect(() => {
        if (findModal()) {
            // Bind the event listener
            setFirstLoad(false);
            setSlidIn(true);
            document.addEventListener("click", handleClickOutside);
        } else {
            // Unbind the event listener on clean up
            setSlidIn(false);
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [modalsUi]);


    const findModal = () => {
        return (
            appHelp.validateArr(modalsUi.modals) &&
            modalsUi.modals.find(modal => modal === modalsTypes.CALL_FOR_SUPPORT)
        );
    };


    return (
        <div className={`airline_info_wrap animated ${slideIn ? 'slideInRight' : 'slideOutRight'} f-row`}
             style={{opacity: firstLoad ? 0 : 1}}
        >
            <div className='two-third'/>
            <aside className="one-third sidebar right text-center" style={{maxWidth: '500px'}} id={'help-menu'}
                   ref={wrapperRef}>
                <div className="widget">
                    <h4>Need help booking?</h4>
                    <div className="textwidget">
                        <p>Call our customer services team on the number below to speak to one of our advisors who will
                            help you with all of your needs.</p>
                        <p className="contact-data text-center" style={{padding: '10px 0 10px 0px'}}
                           href={`tel:+1 866-666-6666`}>
                            <a className="icon icon-themeenergy_call black" href={`tel:+1 866-666-6666`}/>
                            <a style={{color: 'black'}}
                               href={`tel:+1 866-666-6666`}
                            >
                                +1 866-666-6666
                            </a>
                        </p>
                    </div>
                </div>

            </aside>
        </div>

    )
};


function mapStateToProps({ui}) {
    const {modalsUi} = ui;
    return {
        modalsUi,
    };
}

export default React.memo(injectIntl(connect(mapStateToProps)(CallForSupportModal)));