import React, {useState, useMemo, Fragment, useEffect} from "react";
import {connect} from 'react-redux';

import {FlightForm} from './BookingForms'
import {formsConstants} from "../../../../constants";

const AirLineForm = ({airlineForm, airportFormErrors}) => {
    const [focusedField, setFocusedField] = useState([]);


    useEffect(() => {
        if (airportFormErrors)
            setFocusedField(airportFormErrors);
    }, [airportFormErrors]);

    const setFocusOnField = (id) => {
        setFocusedField(id);
    };


    return (
        <div className='airport-field-height'>
            {
                airlineForm &&
                <FlightForm focusedField={focusedField} setFocusOnField={setFocusOnField}/>
            }
        </div>
    )
};

function mapStateToProps({forms}) {
    const airlineForm = forms[formsConstants.AIRLINE_FORM];
    return ({
        airlineForm
    })
}

const connectedPage = React.memo(connect(mapStateToProps)(AirLineForm));
export {connectedPage as AirLineForm};