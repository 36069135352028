import {generalConstants} from '../../constants'
import {userActions} from "../user/actions";
import {appHelp} from '../../helpers'
export const generalAuthActions = {
    coreCredentialsInit,
    resetCredentials,
    updateCarmelUserCredentials
};


export function coreCredentialsInit() {
    const accessTokenCookie = appHelp.getCookie('access_token');
    console.log('accessTokenCookie ', accessTokenCookie);

    const Credentials = {
        "accessToken": accessTokenCookie ? accessTokenCookie : generalConstants.CARMEL_API_KEY,
        "appData": {
            "appSourceType": "D",
            "deviceAlertId": "",
            "osVersion": "Win32",
            "platform": "W",
            "timeout": 10000,
            "timeoutCode": "N"
        },
    };

    return {
        type: generalConstants.CORE_CREDENTIALS_INIT,
        Credentials
    }
}

function resetCredentials() {
    return dispatch => {
        dispatch(coreCredentialsInit());
        dispatch(reset())
    };

    function reset() {
        return {
            type: generalConstants.RESET_CREDENTIALS
        }
    }


}

function updateCarmelUserCredentials(accessToken, authType = "C") {
    return dispatch => {
        try {
            const now = new Date();
            const time = now.getTime();
            if(accessToken) {
                const expireAccessTokenTime = time + (accessToken.expires_in * 1000); // access token experation date (now time + 1 hour/response from server

                const expireRefreshTokenTime = new Date(now.setMonth(now.getMonth() + 6)); // refresh token expire time now date + half year

                now.setTime(expireAccessTokenTime); // setting new date with the expiration date

                document.cookie = `access_token = ${accessToken.access_token};expires=${expireRefreshTokenTime};secure`; // setting access token cookie
                document.cookie = `refresh_token = ${accessToken.refresh_token};expires=${expireRefreshTokenTime};secure`; // setting access token cookie
            }

            dispatch(coreCredentialsInit());// update the access token to the given token from the login
            const CredentialsUpdate = {
                // Can be "C" for Carmel user, "F" for Facebook User or "G" For Guest User
                // Put Hard Coded "C" because it's a dedicated action.
                "custAuthType": authType,

                // "accessToken": accessToken,
                // "custPassword": LoginPassword
            };
            dispatch(update(CredentialsUpdate))
        } catch (e) {
            console.log(e);
            dispatch(userActions.logout(true))
        }
    };


    function update(CredentialsUpdate) {
        return {
            type: generalConstants.CREDENTIALS_UPDATE,
            CredentialsUpdate,
        }
    }
}