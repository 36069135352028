import {addressConstants, carmelConstantsActions, tripConstants, uiConstants} from "../../../constants";

const initialAddress = {
    addressDescription: ''
};

const initialState = {
    [addressConstants.DROP_OFF_ADDRESS]: {
        ...initialAddress,
    },
    [addressConstants.PICK_UP_ADDRESS]: {
        ...initialAddress,
    },
};


export function tripDetails(state = initialState, action) {
    switch (action.type) {
        case addressConstants.DETECT_CURRENT_LOCATION:

            return {
                ...state,

                currentLocation: {
                    ...action.location,
                    isCurrentLocation: action.isCurrentLocation,
                }
            };
        case addressConstants.UPDATE_ADDRESS_DESCRIPTION:
            return {
                ...state,
                [action.addressType]: {
                    ...state[action.addressType],
                    addressDescription: action.addressDescription
                }
            };
        case addressConstants.CLEAR_ADDRESS_DESCRIPTION:
            return {
                ...state,
                [action.addressType]: {
                    addressDescription: ''
                }
            };
        case tripConstants.SET_CURRENT_LOCATION_AS_PICK_UP:
            return {
                ...state,
                [addressConstants.PICK_UP_ADDRESS]: {
                    ...state[addressConstants.PICK_UP_ADDRESS],
                    addressDescription: state[addressConstants.CURRENT_LOCATION_ADDRESS] && state[addressConstants.CURRENT_LOCATION_ADDRESS].addressDescription ? state[addressConstants.CURRENT_LOCATION_ADDRESS].addressDescription : ''
                }
            };
        case addressConstants.CLEAR_ADDRESS:
            return {
                ...state,
                [action.addressType]: {
                    addressDescription: ''
                }
            };

        case addressConstants.UPDATE_SELECTED_PRICE:
            return {
                ...state,
                selectedPrice: action.price
            };
        case addressConstants.UPDATE_SELECTED_TIP:
            return {
                ...state,
                selectedTip: action.tip
            };

        case addressConstants.AIRPORT_ADDRESS_PICK:
            return {
                ...state,
                addressType: action.addressType
            };
        case addressConstants.CLEAR_SELECTED_PRICE:
            delete state.selectedPrice;

            return {
                ...state,
            };


        default:
            return state;
    }
}