import {tempConstants} from "../../../constants";

export function intervals(state = {}, action) {
    switch (action.type) {
        case tempConstants.SERVICE_BY_ADDRESS_INTERVAL:
            return {
                ...state,
                serviceByAddressTimeout: action.timeout
            };
        case tempConstants.CAR_LOCATION_INTERVAL:
            return {
                ...state,
                carLocationTimeout: action.timeout
            };
        default:
            return state;
    }
}