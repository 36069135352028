import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    addressActions,
    carmelActions,
    tripActions,
    uiActions,
    formsActions
} from "../../../../../store/actions";
import {addressConstants, formsConstants} from "../../../../../constants";

class FlightSubmitBtn extends Component {
    closeForm = (e) => {
        e.preventDefault();
        const {dispatch} = this.props;
        //clear all forms details and close
        dispatch(uiActions.closeAirportSelect());
        dispatch(addressActions.clearAddress(addressConstants.PICK_UP_ADDRESS));
        dispatch(carmelActions.clearServiceByAddressTimeout());
        dispatch(addressActions.clearAirlineList());
        setTimeout(() => {
            dispatch(formsActions.clearAllFormFields(formsConstants.AIRLINE_FORM))
        }, 1000);
        this.props.setFocusOnField('')
    };

    submitAirport = (e) => {
        e.preventDefault();
        const {airportForm, dispatch, setFocusOnField, trip} = this.props;
        const isCurrentLocation = (trip[addressConstants.CURRENT_LOCATION_ADDRESS] && Object.keys(trip[addressConstants.CURRENT_LOCATION_ADDRESS]).length > 0 && !trip[addressConstants.PICK_UP_ADDRESS]);
        if (airportForm) {
            //check for every field if not enter update the alerted select input field
            if (airportForm[formsConstants.AIRLINE_NAME]) {
                if (isCurrentLocation) {
                    dispatch(tripActions.setPickUp());
                }
                if (airportForm[formsConstants.FLIGHT_NUMBER] || isCurrentLocation) {
                    if (airportForm[formsConstants.ARRIVE_CITY] || isCurrentLocation) {
                        //if all form is valid then update the trip with the airline detail's and close modal


                        dispatch(tripActions.updateAirportDetails(airportForm));
                        if (trip.updatePickup) {
                            //update the pickup form of trip when current location in airport
                            dispatch(tripActions.setCurrentLocationAsPickUp());
                        }
                        dispatch(uiActions.closeAirportSelect());
                        setTimeout(() => {
                            dispatch(formsActions.clearAllFormFields(formsConstants.AIRLINE_FORM))
                        }, 1000);
                        dispatch(addressActions.clearAirlineList());
                    } else {
                        setFocusOnField(formsConstants.ARRIVE_CITY);
                    }
                } else {
                    setFocusOnField(formsConstants.FLIGHT_NUMBER);
                }
            } else {
                setFocusOnField(formsConstants.AIRLINE_NAME);
            }
        }
    };

    render() {
        return (
            <div className="button-wrapper">
                <div className="proceed-button">
                    <input type="submit" value="Proceed" className="btn green medium full"
                           onClick={this.submitAirport}/>
                </div>
                <div className="cancel-btn">
                    <input type="submit" value="Cancel" className="btn white medium full" onClick={this.closeForm}/>
                </div>
            </div>
        )
    }

}

function mapStateToProps({forms, trip}) {
    const airportForm = forms[formsConstants.AIRLINE_FORM];
    return ({
        airportForm,
        trip,
    })
}

const connectedPage = React.memo(connect(mapStateToProps)(FlightSubmitBtn));
export {connectedPage as FlightSubmitBtn};