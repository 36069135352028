import {combineReducers} from 'redux';

import {popup} from './popup.reducer';
import {tripDetails} from './tripDetails.reducer';
import {pageUi} from './pageUi.reducer';
import {spinner} from './spinner.reducer';
import {toggle} from './toggle.reducer';
import {modalsUi} from './modals.reducer';
import {general} from './general.reducer';

export const ui = combineReducers({
    popup,
    page: pageUi,
    tripDetails,
    spinner,
    toggle,
    modalsUi,
    general
});
