import {generalService} from '../general/services';
import {carmelMethodsConstants, generalConstants, carmelConstAPIMethods} from "../../constants";
import {appHelp} from '../../helpers';
import {store} from '../index';
import axios from "axios";


export const tripServices = {
    getTripDetails,
    getCarLocation,
    getRouteDirection,
    getSignature,
    getTripCharge,
    cancelTrip
};

function getTripDetails() {
    const state = store.getState();

    const {CoreCredentials, Credentials} = state.authentication;
    const {tripId} = state.user.lists.trips.trip;

    return generalService.requestSender(
        carmelMethodsConstants.GET_TRIP_DETAILS,
        {
            tripId: tripId,
            "Credentials": {
                ...Credentials,
                ...CoreCredentials,
            },
        },
    )
}

function getTripCharge(chargeType, newFare = {}, charge = false, newFop = {}, useCarCash = false) {
    const state = store.getState();
    const {Credentials, CoreCredentials} = state.authentication;
    const {trip} = state.user.lists.trips;
    const {tripId, fare, fop} = trip;
    return generalService.requestSender(
        carmelMethodsConstants.TRIP_CHARGE,
        {
            'Credentials': {
                ...Credentials,
                ...CoreCredentials,
            },
            "TripCharge": {
                "fare": newFare ? newFare : fare,
                "charge": charge,
                "fop": newFop ? newFop : fop,
                "chargeType": chargeType, // "PrepayRide",
                "tripId": tripId,
                useCarCash
            }
        }
    )
}


function getCarLocation(tripId) {
    const state = store.getState();

    appHelp.print(["getCarLocation"]);
    const {CoreCredentials, Credentials} = state.authentication;
    return generalService.requestSender(
        carmelMethodsConstants.CAR_LOCATION,
        {
            tripId: tripId,
            "Credentials": {
                ...Credentials,
                ...CoreCredentials,
            },
        },
    )
}

function getRouteDirection(origin, destination) {
    const data = {
        origin: `${origin.lat},${origin.lng}`,
        destination: `${destination.lat},${destination.lng}`,
        key: `${generalConstants.GOOGLE_API_KEY}`
    };
    return axios.get(
        `${generalConstants.GOOGLE_DIRECTION_URL}json?origin=${data.origin}&destination=${data.destination}&key=${data.key}`
    )
        .then(response => (
            response.data

        ))
        .then(data => {
            appHelp.print([`Google Directions: data`, data]);
            return data || {};
        });
}


function getSignature(tripSignatureHash) {
    const requestOptions = {
        data: {
            query: tripSignatureHash
        },
        headers: {
            "Content-Type": "application/json",
        },
        method: 'POST',
    };

    return axios(
        `${generalConstants.API_ENDPOINT}signature`,
        requestOptions
    )
        .then(response => (
            response.data
        ))
        .then(data => {
            appHelp.print([`Google Directions: data`, data]);
            return data;
        });
}

function cancelTrip(trip, selectedOption = null, actionOptions = null) {
    const state = store.getState();
    const {CoreCredentials, Credentials} = state.authentication;

    const TripCancel = {
        trip,
        tripId: trip.tripId
    };

    if (selectedOption) {
        TripCancel['userActions'] = [];
        actionOptions.forEach((actionOption, index) => {
            TripCancel['userActions'].push({
                actionID: actionOption.actionID,
                selectedOptionID: Array.isArray(selectedOption) ? selectedOption[index] : selectedOption
            });
        });
        TripCancel['userActionRequired'] = true;
    }
    return generalService.requestSender(
        carmelConstAPIMethods.TRIP_CANCEL,
        {
            TripCancel,
            'Credentials': {
                ...Credentials,
                ...CoreCredentials
            }
        }
    )
}