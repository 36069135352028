import React, {Fragment, useEffect, lazy, useState, useRef} from 'react';
import {urls} from "../constants";
import MainHeader from './MainHeader/MainHeader'
import {MainPage} from '../components'
import {Loader} from '../components/GeneralComponents'
import {PageInfo} from "../components/GeneralComponents/PageInfo";
import {generalActions, uiActions, carmelActions, userActions} from '../store/actions'

import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Footer from "./Footer";
import AppLocale from "../lang";
import BrizPopUp from "./BrizPopUp";
import {DebugArgsPopUp} from "./DebugArgsPopUp";
import {Spinner} from "../components/GeneralComponents/Spinner";
import CallForSupportModal from "../components/Modals/CallForSupportModal";
import ModalCollector from "../components/Modals/ModalCollector";
import {generalAuthActions} from "../store/authentication/actions";
import {appHelp} from "../helpers/app";

const Login = lazy(() => import('../components/Login'));
const Register = lazy(() => import('../components/Register'));
const TripUpdate = lazy(() => import('../components/TripUpdate'));
const TripCompleted = lazy(() => import('../components/TripCompeted'));
const MyTrips = lazy(() => import('../components/MyTrips'));
const PayForRide = lazy(() => import('../components/PayForRide'));
const Profile = lazy(() => import('../components/Profile'));
const ReferralCode = lazy(() => import('../components/RefferalCode'));
const RewardProgram = lazy(() => import('../components/RewardProgram'));
const About = lazy(() => import('../components/About'));
const CarmelPerks = lazy(() => import('../components/CarmelPerks'));

const App = ({locale, dispatch, ui, location, auth}) => {
    const mainPageRef = useRef(null);
    //language
    const currentAppLocale = AppLocale[locale];
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(userActions.clearLists());
        dispatch(generalAuthActions.coreCredentialsInit());
        dispatch(carmelActions.loadBasicInfo());

        const accessTokenCookie = appHelp.getCookie('access_token');
        dispatch(userActions.clearLists());
        if (accessTokenCookie) {
            dispatch(userActions.Cust());
        }

        else
            dispatch(generalAuthActions.resetCredentials());
    }, []);

    useEffect(() => {
        //current page path index
        const fullPath = location.pathname;
        let currentPage = fullPath.slice(1);

        if (ui) {
            if (ui.page) {
                if (ui.page !== currentPage) {
                    window.scrollTo(0, 0); // scroll to the top of the page every page navigation change
                    dispatch(uiActions.currentPage(currentPage));
                }
            } else {
                dispatch(uiActions.currentPage(currentPage));
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        //set pop up display
        if (ui.popup.open && !isModalOpen) {
            setModalOpen(true)
        } else if (!ui.popup.open && isModalOpen)
            setModalOpen(false)
    }, [ui.popup]);


    const toggleState = e => {
        setModalOpen(!isModalOpen)
    };


    return (
        <Fragment>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <div>
                    <Spinner/>
                    <DebugArgsPopUp/>
                    <ModalCollector/>
                    <CallForSupportModal/>
                    {isModalOpen && (
                        <BrizPopUp
                            id="modal"
                            isOpen={isModalOpen}
                            onClose={toggleState}
                        >
                        </BrizPopUp>
                    )}
                    <MainHeader/>
                    <main className="main" role="main" ref={mainPageRef}>
                        <React.Suspense fallback={<Loader/>}>
                            <PageInfo/>
                            <Switch>
                                <Route exact path={`${urls.MAIN_PAGE}`} component={MainPage}/>
                                <div className="wrap">
                                    <div className="row">
                                        <Route path={`/${urls.LOGIN}`} component={Login}/>
                                        <Route path={`/${urls.REGISTER}`} component={Register}/>
                                        <Route path={`/${urls.TRIP_UPDATE}`} component={TripUpdate}/>
                                        <Route path={`/${urls.THANK_YOU}`} component={TripCompleted}/>
                                        <Route path={`/${urls.MY_TRIPS}`} component={MyTrips}/>
                                        <Route path={`/${urls.PAY_FOR_RIDE}`} component={PayForRide}/>
                                        <Route path={`/${urls.TIP_AND_TOLLS}`} component={PayForRide}/>
                                        <Route path={`/${urls.ACCOUNT}`} component={Profile}/>
                                        <Route path={`/${urls.REFFERAL_CODE}`} component={ReferralCode}/>
                                        <Route path={`/${urls.REWARD_PROGRAM}`} component={RewardProgram}/>
                                        <Route path={`/${urls.ABOUT}`} component={About}/>
                                        <Route path={`/${urls.PERKS}`} component={CarmelPerks}/>
                                    </div>
                                </div>
                            </Switch>

                        </React.Suspense>
                    </main>
                    <Footer/>

                </div>
            </IntlProvider>
        </Fragment>
    );
};

const mapStateToProps = ({settings, ui, authentication}) => {
    const {locale} = settings;
    const {auth} = authentication;
    return {
        locale,
        ui,
        auth
    };
};

export default React.memo(connect(mapStateToProps)(App));
