/*global google*/

import {
    addressConstants,
    carmelConstantsActions,
    carmelMassageCodes,
    carmelMassagesConstants,
    defaultConstants,
    formsConstants,
    paymentTypes,
    tempConstants,
    tripConstants,
    tripPaymentConstants,
    uiConstants,
    userConstants,
    carmelConstAPI,
    carmelConstAPILists,
    userConstLists,
    generalConstants,
    urls,
} from "../../constants";
import {tripServices} from "./service";
import {appHelp, history} from "../../helpers";
import {store} from "../index";
import {formsActions, uiActions, carmelActions, userActions, generalActions, addressActions} from "../actions";

import {generalService} from "../general/services";
import {tripPaymentActions} from "./tripPayment.actions";


export const tripActions = {
    getTripDetails,
    // getCarLocation,
    // clearCarLocationTimeout,
    // continueCarLocationInterval,
    clearCarLocationData,
    clearCurrentRouteMode,
    // getRouteDirection,
    // getSignature,
    clearSignature,
    firstTripCharge,
    secondTripCharge,
    resetTrip,
    updateAirportDetails,
    updateCar,
    bookSelectedPrice,
    changePaymentMethod,
    updateFopInformation,
    updateFarePaymentMethod,
    updateCarCash,
    updateSelectedTip,
    fobTypes,
    updateCustFare,
    updateTipOptions,
    updateFop,
    dateUpdate,
    timeUpdate,
    cancelTrip,
    updateDisplayTextList,
    clearTrip,
    updateCarCashAmount,
    carCashAvailable,
    setCurrentLocationAsPickUp,
    // createAndDisplayRoute,
    setPickUp,
    updateCarCashAmountForSecondTripPayment,
    removeCarCashAvailable,
    deleteFare,
    updateCarPool
};

function dateUpdate(dateString) {
    dateString = `${dateString.split("-")[1]}/${dateString.split("-")[2]}/${
        dateString.split("-")[0]
        }`;
    return {
        type: tripConstants.DATE_UPDATE,
        date: dateString
    };
}

function timeUpdate(timeString) {
    return {
        type: tripConstants.TIME_UPDATE,
        time: timeString
    };
}

function getTripDetails(tripId) {
    return dispatch => {
        dispatch(request(tripId));
        if (tripId) {
            tripServices
                .getTripDetails(tripId)
                .then(tripData => {
                    if (tripData.result.code === carmelMassagesConstants.OK) {
                        dispatch(success(tripData));
                        if (tripData.tripOptions) {
                            dispatch(userActions.updateTripOptions(tripData.tripOptions));
                        }
                    } else if (tripData.result.code === "ACCESS_DENIED") {
                        dispatch(failure(tripData));
                        // dispatch(tripActions.clearSignature());
                        history.push(`${defaultConstants.defaultStartPath}trips`);
                    } else {
                        dispatch(failure(tripData));
                        dispatch(uiActions.setPopUpTitle("Pay Attention"));
                        dispatch(uiActions.setPopUpMessage(tripData.result.message));
                        dispatch(uiActions.showPopUp());
                    }
                })
                .catch(error => {
                    dispatch(failure(error));
                });
        }
        // } else {
        //     dispatch(tripActions.getSignature(tripId, tripActions.getTripDetails, history));
        // }
    };

    function request() {
        return {
            type: tripConstants.GET_TRIP_DETAILS_REQUEST,
            tripId
        };
    }

    function success(tripData) {
        return {
            type: tripConstants.GET_TRIP_DETAILS_SUCCESS,
            tripData
        };
    }

    function failure(error) {
        return {
            type: tripConstants.GET_TRIP_DETAILS_FAILURE,
            error
        };
    }
}

function resetTrip() {
    return {
        type: tripConstants.RESET_TRIP
    };
}

function firstTripCharge(chargeType) {
    return dispatch => {
        dispatch(request(chargeType));
        dispatch(uiActions.displaySpinner('spinner.loading_Payment_Loader'));
        tripServices
            .getTripCharge(chargeType)
            .then(tripChargeData => {
                dispatch(uiActions.hideSpinner());
                if (tripChargeData.result.code === carmelMassagesConstants.OK) {
                    dispatch(success(tripChargeData));
                    //update tip options
                    dispatch(tripActions.updateTipOptions(tripChargeData.tipOptions));
                    // Set the 'chargeType before navigating to TripPayment screen
                    dispatch(tripPaymentActions.setChargeType(chargeType));
                    if (chargeType === "PrepayRide")
                        history.push(`/${urls.PAY_FOR_RIDE}`);
                    // history.push(
                    //     `${defaultConstants.defaultStartPath}${urls.PAY_FOR_RIDE}`
                    // );
                    else
                        history.push(`/${urls.TIP_AND_TOLLS}`);
                } else if (tripChargeData.result.code === "ACCESS_DENIED") {
                    dispatch(failure(tripChargeData));
                    // history.push(`${defaultConstants.defaultStartPath}${currentVendor}/thank-you`)
                } else {
                    dispatch(failure(tripChargeData));
                    dispatch(uiActions.setPopUpTitle("Pay Attention"));
                    dispatch(uiActions.setPopUpMessage(tripChargeData.result.message));
                    dispatch(uiActions.showPopUp());
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                dispatch(failure(error));
            });
    };

    function request(chargeType) {
        return {
            type: tripConstants.FIRST_TRIP_CHARGE_REQUEST,
            chargeType
        };
    }

    function success(tripChargeData) {
        return {
            type: tripConstants.FIRST_TRIP_CHARGE_SUCCESS,
            tripChargeData
        };
    }

    function failure(error) {
        return {
            type: tripConstants.FIRST_TRIP_CHARGE_FAILURE,
            error
        };
    }
}

function secondTripCharge(chargeType) {
    return (dispatch, getState) => {
        dispatch(request(chargeType));
        // Assign the variables
        let gratuity,
            gratuityPct,
            prepayType,
            gratuityOverride,
            tolls = 0,
            misc = 0;
        const state = getState();
        const {tripId} = state.user.lists.trips.trip;
        const {tripCharge} = state.temp.services;
        //get the card index in array from the screen
        const {currentCard} = state.ui.page;
        //from fop lists get the fop by the index selected
        const fop = state.user.lists.fop.fopList[currentCard];
        // Forms for use
        const paymentForm = state.forms[formsConstants.PAYMENT_FORM];
        const extraChargeForm = state.forms[formsConstants.EXTRA_CHARGER_FORM];
        // Payment Method selected
        prepayType = paymentForm[formsConstants.PAYMENT_METHOD];


        let total = tripCharge.fare.total;
        if (paymentForm && paymentForm[formsConstants.PAYMENT_TIP]) {
            if (
                prepayType === tripPaymentConstants.PAYMENT_METHOD_ALL_INCLUSIVE ||
                chargeType === tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE
            ) {
                // Tip data extraction
                gratuity = paymentForm[formsConstants.PAYMENT_TIP].tipAmt || 0;
                if (
                    paymentForm[formsConstants.PAYMENT_TIP].tipPct ===
                    formsConstants.OTHER_TIP
                ) {
                    gratuityPct = null;
                    gratuityOverride = true;
                } else {
                    gratuityPct = paymentForm[formsConstants.PAYMENT_TIP].tipPct;
                    gratuityOverride = false;
                }
            } else {
                gratuityPct = 0;
                gratuity = 0;
            }
        } else {
            gratuityPct = 0;
            gratuity = 0;
        }
        // Export credit card details from form

        if (
            extraChargeForm &&
            chargeType === tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE
        ) {
            tripCharge.fare.tolls =
                extraChargeForm[formsConstants.EXTRA_CHARGER_TOLLS];
            tripCharge.fare.misc = extraChargeForm[formsConstants.EXTRA_CHARGER_MISC];
        }
        // Total calculation:
        const baseFare =
            tripCharge.fare.fare +
            (tripCharge.fare.congestionFee ? tripCharge.fare.congestionFee : 0);
        if (chargeType === tripPaymentConstants.CHARGE_TYPE_PREPAY) {
            if (prepayType === tripPaymentConstants.PAYMENT_METHOD_ALL_INCLUSIVE) {
                total = `${parseFloat(baseFare) +
                parseFloat(gratuity) +
                parseFloat(tripCharge.fare.tolls) +
                parseFloat(tripCharge.fare.fees)}`;
            } else if (prepayType === tripPaymentConstants.PAYMENT_METHOD_BASE_FARE) {
                total = baseFare;
            }
        } else if (
            extraChargeForm &&
            chargeType === tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE
        ) {
            tolls = extraChargeForm[formsConstants.EXTRA_CHARGER_TOLLS] || 0;
            misc = extraChargeForm[formsConstants.EXTRA_CHARGER_MISC] || 0;
            total =
                `${parseFloat(gratuity) + parseFloat(tolls) + parseFloat(misc)}` || 0;
        }


        // Build the 'fare' object to send
        const fare = {
            ...tripCharge.fare,
            gratuity,
            gratuityPct: gratuityPct,
            gratuityOverride: gratuityOverride,
            total,
            tolls,
            misc,
            couponAmt: 0,
            fuel: 0,
            waitTimeMinutes: 0,
            discount: 0,
            carCashDescription: "",
            processingFee: 0,
            commissionPct: 0,
            cancelFeeDescription: "",
            tax: 0,
            jobHourQty: 0,
            nysFund: 0,
            taxPct: 0,
            stops: 0,
            couponVerificationCode: "",
            processingFeePct: 0,
            voucherCharge: 0,
            other: 0,
            carCashAmt: 0,
            waitTimeFreeMinutes: 0,
            cancelFee: 0,
            nysFundPct: 0,
            commission: 0,
            waitTimeHourlyRate: 0,
            miscDescription: "",
            discountPct: 0,
            fuelPct: 0,
            hourlyRate: 0,
            otherDescription: "",
            couponCode: "",
            waitTime: 0,
        };
        const newFop = {
            ...fop,
            prepayType,

        };
        appHelp.print(["secondTripCharge - fop", fop]);
        appHelp.print(["secondTripCharge - fare", fare]);
        appHelp.print(["secondTripCharge - chargeType", chargeType]);
        dispatch(uiActions.displaySpinner(chargeType === tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE ? 'spinner.paying_Tip_&_Toll_Loader' : 'spinner.paying_Trip_Loader'));
        tripServices
            .getTripCharge(chargeType, fare, true, newFop, false)
            .then(tripChargeData => {
                dispatch(uiActions.hideSpinner());
                if (tripChargeData.result.code === carmelMassagesConstants.OK) {
                    dispatch(success(tripChargeData));
                    dispatch(tripActions.getTripDetails(tripId));
                    if (chargeType === tripPaymentConstants.CHARGE_TYPE_EXTRA_CHARGE) {
                        dispatch(uiActions.setPopUpTitle("Tip & Toll Paid"));
                    } else {
                        dispatch(uiActions.setPopUpTitle("Trip Paid"));
                    }
                    dispatch(
                        uiActions.setPopUpMessage(
                            tripChargeData.result.message || "Something went wrong"
                        )
                    );
                    dispatch(
                        uiActions.setPopUpAction(() => {
                            // Navigate back to Trip Details
                            // Clear all forms associated with the TripCharge:
                            dispatch(
                                formsActions.clearAllFormFields(formsConstants.CREDIT_CARD)
                            );
                            dispatch(
                                formsActions.clearAllFormFields(formsConstants.EXTRA_CHARGER_FORM)
                            );
                            dispatch(
                                formsActions.clearAllFormFields(formsConstants.PAYMENT_FORM)
                            );
                            // Clear the TripCharge Data:
                            dispatch(tripPaymentActions.clearTripChargeData());
                            dispatch(userActions.loadBasicUserInformation());
                            history.goBack()
                        })
                    );
                    dispatch(uiActions.showPopUp());
                } else if (tripChargeData.result.code === "ACCESS_DENIED") {
                    dispatch(failure(tripChargeData));
                    dispatch(tripActions.clearSignature());
                    history.push(`${defaultConstants.defaultStartPath}thank-you`);
                } else if (tripChargeData.result.code === "CARD_ERROR") {

                    dispatch(failure(tripChargeData));

                } else {
                    dispatch(failure(tripChargeData));
                    dispatch(uiActions.setPopUpTitle("Pay Attention"));
                    dispatch(uiActions.setPopUpMessage(tripChargeData.result.message));
                    dispatch(uiActions.showPopUp());
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                // gtag('event', 'Payment', {
                //     'event_category': 'Networking Fail',
                //     'event_label': chargeType
                // });
                dispatch(failure(error));
            });
    };

    function request(chargeType) {
        return {
            type: tripConstants.SECOND_TRIP_CHARGE_REQUEST,
            chargeType
        };
    }

    function success(tripChargeData) {
        return {
            type: tripConstants.SECOND_TRIP_CHARGE_SUCCESS,
            tripChargeData
        };
    }

    function failure(error) {
        return {
            type: tripConstants.SECOND_TRIP_CHARGE_FAILURE,
            error
        };
    }
}

// function getCarLocation(tripId, closeModal = null, showCarLocationPage = null) {
//     return (dispatch, getState) => {
//         dispatch(request(tripId));
//
//         const state = getState();
//         tripServices
//             .getCarLocation(tripId)
//             .then(carLocation => {
//                 dispatch(uiActions.hideSpinner());
//                 if (carLocation.result.code === carmelMassagesConstants.OK) {
//                     if (showCarLocationPage) {
//                         showCarLocationPage();
//                     }
//                     const carLocationByTrip = carLocation;
//                     // Implementation of refresh frequency
//                     ////////////////////////////////////////
//                     // Set timeout to refresh the Car Location information.
//                     const state = getState();
//                     console.log("getCarLocation", state);
//                     console.log("getCarLocation", state.ui.carLocation);
//                     console.log("getCarLocation", state.ui.carLocation.stopInterval);
//
//                     if (!state.ui.carLocation.stopInterval) {
//                         const timeout = setTimeout(
//                             () => {
//                                 dispatch(getCarLocation(tripId));
//                             },
//                             // The refresh frequency realise on the Carmel API.
//                             carLocation.result.refreshFrequency
//                         );
//                         // Save the timeout ID to be able to clear in in the nex user interruption.
//                         dispatch(saveTimeout(timeout));
//                     }
//                     dispatch(uiActions.clearMapDirections());
//                     dispatch(success(carLocation));
//
//                     // Handle the response from location by trip server call
//                     ////////////////////////////////////////
//                     // if the result is for PuInstructions
//                     if (carLocationByTrip.puInstructions.puInstructions) {
//                         // Show the specify route mode (this changes the markers on the Map).
//                         dispatch(updateRouteMode(carLocationByTrip.routeMode));
//
//                         // Set the phone number of the click listener to Carmel's Phone from the server or the hard-coded default.
//                         dispatch(
//                             updatePhoneNumberLink(
//                                 carLocationByTrip.puInstructions.puInstructionsTel1 ||
//                                 defaultConstants.CARMEL_PHONE_NUMBER ||
//                                 ""
//                             )
//                         );
//
//                         // update the text to the updated text from the server
//                         dispatch(
//                             updatePuMassage(
//                                 carLocationByTrip.puInstructions.puInstructionsText
//                             )
//                         );
//                     }
//                     //     // if the result is for show my car
//                     else {
//                         // if the driver location is
//                         if (carLocationByTrip.trip) {
//                             if (
//                                 carLocationByTrip.driverLocation &&
//                                 carLocationByTrip.trip.driver &&
//                                 carLocationByTrip.trip.car
//                             ) {
//                                 // Set the driver information to the UI.
//                                 dispatch(updateDriverInfo(carLocationByTrip.trip.driver));
//                                 // Show the specify route mode (this changes the markers on the Map).
//                                 dispatch(updateRouteMode(carLocationByTrip.routeMode));
//                                 // Switch the Show My Car view to driver information
//                                 dispatch(updateShowMyInformationAvailable(true));
//                             } else {
//                                 // Switch the Show My Car view to loader
//                                 dispatch(updateShowMyInformationAvailable(false));
//
//                                 // Center the Google Map to the pickup location
//                                 dispatch(
//                                     carLocationActions.updateMapCenter({
//                                         lat: carLocationByTrip.trip.addrPu.latitude,
//                                         lng: carLocationByTrip.trip.addrPu.longitude
//                                     })
//                                 );
//                             }
//                         }
//                     }
//
//                     dispatch(carLocationActions.showMarkersByRouteMode());
//                 } else if (carLocation.result.code === "ACCESS_DENIED") {
//                     if (closeModal) {
//                         closeModal();
//                     } else {
//                         history.push(`/${urls.BOOKING}`);
//                     }
//                 } else {
//                     dispatch(failure(carLocation));
//                     dispatch(uiActions.setPopUpTitle('Attention'));
//                     dispatch(uiActions.setPopUpMessage(carLocation.result.message));
//                     dispatch(uiActions.showPopUp());
//                     dispatch(
//                         uiActions.setPopUpAction(() => {
//                             if (closeModal)
//                                 closeModal();
//                             else {
//                                 history.push(`/${urls.BOOKING}`);
//                             }
//                         })
//                     );
//                 }
//             })
//             .catch(error => {
//                 dispatch(uiActions.hideSpinner());
//                 dispatch(failure(error));
//             });
//     };
//
//     function updateShowMyInformationAvailable(isAvailable) {
//         return {
//             type: uiConstants.UPDATE_SHOW_MY_CAR_INFO_AVAILABLE,
//             isAvailable
//         };
//     }
//
//     function updatePhoneNumberLink(phoneNumber) {
//         return {
//             type: uiConstants.UPDATE_PHONE_NUMBER_LINK,
//             phoneNumber
//         };
//     }
//
//     function updateRouteMode(routeMode) {
//         return {
//             type: uiConstants.UPDATE_ROUTE_MODE,
//             routeMode
//         };
//     }
//
//     function updatePuMassage(puInstructionsMessage) {
//         return {
//             type: uiConstants.UPDATE_PICK_UP_INSTRUCTIONS_MASSAGE,
//             puInstructionsMessage
//         };
//     }
//
//     function updateDriverInfo(driverInfo) {
//         return {
//             type: uiConstants.UPDATE_DRIVER_INFO,
//             driverInfo
//         };
//     }
//
//     function saveTimeout(timeout) {
//         return {type: tempConstants.CAR_LOCATION_INTERVAL, timeout};
//     }
//
//     function request(tripId) {
//         return {
//             type: tripConstants.GET_CAR_LOCATION_REQUEST,
//             tripId
//         };
//     }
//
//     function success(carLocation) {
//         return {
//             type: tripConstants.GET_CAR_LOCATION_SUCCESS,
//             carLocation
//         };
//     }
//
//     function failure(error) {
//         return {
//             type: tripConstants.GET_CAR_LOCATION_FAILURE,
//             error
//         };
//     }
// }

// function clearCarLocationTimeout() {
//     const intervalsState = store.getState().temp.intervals;
//
//     if (intervalsState.carLocationTimeout) {
//         clearTimeout(intervalsState.carLocationTimeout);
//     }
//
//     return {
//         type: tripConstants.CLEAR_CAR_LOCATION_TIMEOUT
//     };
// }

// function continueCarLocationInterval() {
//     return {
//         type: tripConstants.CONTINUE_CAR_LOCATION_INTERVAL
//     };
// }

// function getRouteDirection(origin, destination) {
//     return (dispatch, getState) => {
//         try {
//             dispatch(request());
//
//             // google function to get the route of the trip
//             const directionsService = new google.maps.DirectionsService();
//             // const originCoords = {lat: origin.lat, lng: origin.lng};
//             // const destinationCoords = {lat: destination.lat, lng: destination.lng};
//
//             directionsService.route(
//                 {
//                     origin: destination,
//                     destination: origin,
//                     travelMode: google.maps.TravelMode.DRIVING,
//                 },
//                 (result, status) => {
//                     if (status === google.maps.DirectionsStatus.OK) {
//                         dispatch(uiActions.clearMapDirections());
//                         dispatch(carLocationActions.showRoute());
//                         dispatch(uiActions.saveMapDirections(result));
//                         dispatch(success(result));
//                     } else {
//                         console.error(`error fetching directions ${result}`);
//                     }
//                 }
//             );
//         } catch (e) {
//             failure(e);
//         }
//     };
//
//     function request() {
//         return {
//             type: tripConstants.GET_ROUTE_DIRECTION_REQUEST
//         };
//     }
//
//     function success() {
//         return {
//             type: tripConstants.GET_ROUTE_DIRECTION_SUCCESS
//         };
//     }
//
//     function failure(error) {
//         return {
//             type: tripConstants.GET_ROUTE_DIRECTION_FAILURE,
//             error
//         };
//     }
// }

// function createAndDisplayRoute() {
//     return (dispatch, getState) => {
//         const state = getState();
//         const dropOffTrip = state.trip[addressConstants.DROP_OFF_ADDRESS];
//         const pickupTrip = state.trip[addressConstants.PICK_UP_ADDRESS];
//         if (
//             appHelp.validateObject(dropOffTrip) &&
//             dropOffTrip.latitude &&
//             dropOffTrip.longitude &&
//             appHelp.validateObject(pickupTrip) &&
//             pickupTrip.latitude &&
//             pickupTrip.longitude
//         ) {
//             dispatch(carLocationActions.showRoute());
//             //set the current route
//             dispatch(
//                 tripActions.getRouteDirection(
//                     {
//                         lat: dropOffTrip.latitude,
//                         lng: dropOffTrip.longitude
//                     },
//                     {
//                         lat: pickupTrip.latitude,
//                         lng: pickupTrip.longitude
//                     }
//                 )
//             );
//         }
//     };
// }

// function getSignature(tripSignatureHash, callback, history) {
//     return (dispatch, getState) => {
//         dispatch(request(tripSignatureHash));
//
//         const savedSignature = localStorage.getItem("briz_signature");
//         const {currentVendor} = getState().settings;
//         if (!tripSignatureHash && savedSignature) {
//             const signature = JSON.parse(savedSignature);
//
//             dispatch(success(signature));
//             history.push(`${defaultConstants.defaultStartPath}${currentVendor}`);
//             dispatch(callback(signature, history));
//         } else {
//             tripServices
//                 .getSignature(tripSignatureHash)
//                 .then(data => {
//                     appHelp.print(["getSignature", data]);
//                     if (data.status === "SUCCESS") {
//                         dispatch(success(JSON.parse(data.data.signature)));
//
//                         localStorage.setItem("briz_signature", data.data.signature);
//
//                         history.push(
//                             `${defaultConstants.defaultStartPath}${currentVendor}`
//                         );
//                         dispatch(callback(JSON.parse(data.data.signature), history));
//                     } else {
//                         dispatch(failure(data));
//                         history.push(
//                             `${defaultConstants.defaultStartPath}${currentVendor}/thank-you`
//                         );
//                     }
//                 })
//                 .catch(error => {
//                     dispatch(failure(error));
//                     history.push(
//                         `${defaultConstants.defaultStartPath}${currentVendor}/thank-you`
//                     );
//                 });
//         }
//     };
//
//     function request(tripHash) {
//         return {
//             type: tripConstants.GET_TRIP_SIGNATURE_REQUEST,
//             tripHash
//         };
//     }
//
//     function success(signature) {
//         return {
//             type: tripConstants.GET_TRIP_SIGNATURE_SUCCESS,
//             signature
//         };
//     }
//
//     function failure(error) {
//         return {
//             type: tripConstants.GET_TRIP_SIGNATURE_FAILURE,
//             error
//         };
//     }
// }

function clearSignature() {
    return {
        type: tripConstants.CLEAR_SIGNATURE
    };
}

function clearCarLocationData() {
    return {
        type: tripConstants.CLEAR_CAR_LOCATION
    };
}

function clearCurrentRouteMode() {
    return {
        type: tripConstants.CLEAR_CURRENT_ROUTE_MODE
    };
}

function updateAirportDetails(
    airportDetails,
    addressType = addressConstants.PICK_UP_ADDRESS
) {
    delete airportDetails.isValid;
    return {
        type: tripConstants.ADD_AIRPORT_DETAILS,
        airportDetails,
        addressType
    };
}

function updateCar(car) {
    return {
        type: tripConstants.UPDATE_CAR,
        car
    };
}

function bookSelectedPrice(price) {
    return dispatch => {
        const state = store.getState();
        const {tipOptionDefaultSeq} = state.general.fop;

        const fare = {
            ...price.custFare
        };
        const car = {
            carClassID: price.carClassID,
            carClassDesc: price.carClassDesc
        };
        if (state.user.Cust.loggedIn) {
            dispatch(updateUI(price));
            dispatch(tripActions.updateFopInformation(1));
            dispatch(updateFareInformation(fare));
            dispatch(tripActions.updateFarePaymentMethod(carmelConstAPI.FARE_BASE));
            dispatch(updateCarInformation(car));
            dispatch(tripActions.updateSelectedTip(tipOptionDefaultSeq - 1));
        } else {
            dispatch(uiActions.clearSelectedPrice());
            dispatch(carmelActions.clearServiceByAddressTimeout());
            // dispatch(uiActions.loginPopupOpen());
            dispatch(loginRequired());
        }
    };

    function loginRequired() {
        return {
            type: generalConstants.LOGIN_REQUIRED
        };
    }

    function updateUI(price) {
        return {type: uiConstants.UPDATE_SELECTED_PRICE, price};
    }

    function updateFareInformation(fare) {
        return {type: tripConstants.UPDATE_INFO_FARE, fare};
    }

    function updateCarInformation(car) {
        return {type: tripConstants.UPDATE_INFO_CAR, car};
    }
}

function changePaymentMethod(paymentMethod, fop) {
    // If user select the CC method, we will load the first FOP from the
    // fop list of the user and put it into selected fop in Trip object
    let fopType;
    if (paymentMethod === carmelConstAPI.FOP_TYPE_CODE_CREDIT) {
        fopType = fop;
        fopType.fopTypeCode = carmelConstAPI.FOP_TYPE_CODE_CREDIT;
    } else {
        fopType = generalService.getListObject(
            carmelConstAPILists.FOP_TYPE_LIST,
            paymentMethod
        );
    }

    return {
        type: tripConstants.UPDATE_INFO_PAYMENT_METHOD,
        paymentMethod: fopType
    };
}

function updateFopInformation(requestedCardSeq) {
    const {lists} = store.getState().user;

    // Filter through Fop List of the user to get the matched item to cardSeq passed
    const fop = lists.fop[userConstLists.CUST_FOP_LIST].filter(
        item => parseInt(item.cardSeq) === parseInt(requestedCardSeq)
    )[0];

    return {
        type: tripConstants.UPDATE_INFO_FOP,
        fop: {
            ...fop,
            fopTypeCode: carmelConstAPI.FOP_TYPE_CODE_CREDIT
        }
    };
}

function updateFarePaymentMethod(method, gratuity = 0) {
    const state = store.getState();
    const fare = state.trip.fare;
    const {selectedTip} = state.ui.tripDetails;

    fare.gratuity = gratuity;

    if (method === carmelConstAPI.FARE_BASE) {
        fare.gratuity = gratuity;
        fare.total = sumFareTotal(
            ["fare", "congestionFee"],
            fare,
            state.trip.useCarCash
        );
    } else if (method === carmelConstAPI.FARE_ALL_INCLUSIVE) {
        fare.gratuity = selectedTip.tipAmt;
        fare.gratuityPct = selectedTip.tipPct;
        fare.total = sumFareTotal(
            ["fare", "fees", "tolls", "gratuity", "congestionFee"],
            fare,
            state.trip.useCarCash
        );
    }

    return {
        type: tripConstants.UPDATE_FARE_PAYMENT_METHOD,
        fare: {
            ...fare
        },
        method
    };
}

function sumFareTotal(fareParts, fare, useCarCash) {
    let result = 0;
    for (const part of fareParts) {
        if (fare.hasOwnProperty(part)) {
            result += fare[part];
        }
    }

    console.log("useCarCash", useCarCash);
    console.log("useCarCash", fare.carCashAmt);
    if (useCarCash) {
        result -= fare.carCashAmt;
    }
    return result;
}

function updateSelectedTip(tipSequence) {
    return {
        type: uiConstants.UPDATE_SELECTED_TIP,
        tip: tipSequence
    };
}

function updateDisplayTextList(displayTextList) {
    return {
        type: uiConstants.UPDATE_DISPLAY_TEXT_FIELD,
        displayTextList
    };
}

function updateCarCash() {
    return dispatch => {
        // Set the status of car cash usage
        dispatch(updateCarCashUse());

        // Get the current status of carCash usage and amount
        const state = store.getState();
        const carCashAmount = state.ui.tripDetails.selectedPrice.carCashAvailable;
        const {useCarCash} = state.trip;

        // Update the amount based on the car cash usage status
        dispatch(updateCarCashAmount(carCashAmount, useCarCash));
    };

    // These two are separated because it's updating information in two different places
    function updateCarCashUse() {
        return {
            type: tripConstants.UPDATE_CAR_CASH_USE
        };
    }
}

function updateCarCashAmount(carCashAmt, useCarCash) {
    return {
        type: tripConstants.UPDATE_CAR_CASH_AMOUNT,
        carCashAmt,
        useCarCash
    };
}

function updateCarCashAmountForSecondTripPayment(carCashAmt, useCarCash) {
    return {
        type: tripConstants.UPDATE_CAR_CASH_AMOUNT_FOR_SECOND_TRIP_PAYMENT,
        carCashAmt,
        useCarCash
    };
}

function fobTypes(fobTypes) {
    return {
        type: tripConstants.FOB_TYPES,
        fobTypes
    };
}

function updateCustFare(custFare) {
    const fare = {
        ...custFare
    };

    return {
        type: tripConstants.UPDATE_INFO_FARE,
        custFare: fare
    };
}

function deleteFare() {
    return {
        type: tripConstants.DELETE_INFO_FARE,
    };
}

function updateTipOptions(tipOptions) {
    return {
        type: tripConstants.UPDATE_TIP_OPTIONS,
        tipOptions
    };
}

function clearFop() {
    return {
        type: tripConstants.CLEAR_FOP
    };
}

function updateFop(tipOptions, tip = null, autoCharge = false, carCashAutoCharge = 0) {
    return dispatch => {
        dispatch(clearFop());

        switch (tipOptions) {
            case paymentTypes.CREDIT_CARD:
                dispatch(updateFopData(createCreditCardFop(dispatch, tip, autoCharge, carCashAutoCharge)));
                break;
            case paymentTypes.HOUSE_ACCOUNT:
                dispatch(tripActions.updateCarCashAmount(0, false));
                dispatch(updateFopData(createHouseAccountFop()));
                break;
            case paymentTypes.PAY_CASH:
                dispatch(tripActions.updateCarCashAmount(0, false));
                dispatch(updateFopData(createPayInCashFop()));
                break;
            default:
                return;
        }
    };

    function updateFopData(fop) {
        return {
            type: tripConstants.UPDATE_FOP,
            fop
        };
    }

    function createCreditCardFop(dispatch, tip, autoCharge, carCashAutoCharge) {
        let baseFare,
            gratuity,
            gratuityPct,
            prepayType,
            gratuityOverride,
            tolls = 0,
            misc = 0;
        const state = store.getState();
        const {user, settings, trip, temp} = state;
        const {fop} = user.lists;
        // user credit card
        const fopNum = settings.cardSeq;

        //get autoplay credit card
        if (!fop || !appHelp.validateArr(fop.fopList)) {
            return
        }
        let creditCard = fop.fopList.find(card => {
            return (
                card.prepayType === carmelConstAPI.FARE_ALL_INCLUSIVE ||
                card.prepayType === carmelConstAPI.FARE_BASE
            );
        });

        //if no auto pay get the selected card by seq number
        if (!creditCard) {
            creditCard = fop.fopList.find(card => {
                return card.cardSeq === fopNum;
            });
        }

        //if still no card get the last card in the arr
        if (!creditCard) {
            creditCard = fop.fopList[fop.fopList.length - 1];
        }

        const {fare} = trip;

        // Forms for use
        const paymentForm = state.forms[formsConstants.PAYMENT_FORM];

        // Payment Method selected
        prepayType =
            creditCard.prepayType &&
            (creditCard.prepayType.includes("AI") ||
                creditCard.prepayType.includes("BASE"))
                ? creditCard.prepayType
                : paymentForm[formsConstants.PAYMENT_METHOD];
        let total = fare.total;
        if (
            creditCard.autoCharge &&
            prepayType === carmelConstAPI.FARE_ALL_INCLUSIVE &&
            autoCharge
        ) {
            //if credit card is auto pay
            gratuityPct = tip.tipPct;
            gratuity = tip.tipAmt;
        } else if (paymentForm && paymentForm[formsConstants.PAYMENT_TIP]) {
            if (prepayType === tripPaymentConstants.PAYMENT_METHOD_ALL_INCLUSIVE) {
                // Tip data extraction
                gratuity = paymentForm[formsConstants.PAYMENT_TIP].tipAmt;

                if (
                    paymentForm[formsConstants.PAYMENT_TIP].tipPct ===
                    formsConstants.OTHER_TIP
                ) {
                    gratuityPct = null;
                    gratuityOverride = true;
                } else {
                    gratuityPct = paymentForm[formsConstants.PAYMENT_TIP].tipPct;
                    gratuityOverride = false;
                }
            } else {
                gratuityPct = 0;
                gratuity = 0;
            }
        } else {
            gratuityPct = 0;
            gratuity = 0;
        }

        // Total calculation:
        baseFare = fare.fare + (fare.congestionFee ? fare.congestionFee : 0);

        if (prepayType === tripPaymentConstants.PAYMENT_METHOD_ALL_INCLUSIVE) {
            total = `${parseFloat(baseFare) +
            parseFloat(gratuity) +
            parseFloat(fare.tolls) +
            parseFloat(fare.fees)}`;
        } else if (prepayType === tripPaymentConstants.PAYMENT_METHOD_BASE_FARE) {
            total = baseFare;
        }
        if (fare.carCashAmt && fare.carCashAmt > 0 && !autoCharge) {
            //if car cash included in the price
            fare.carCashAmt = parseFloat(fare.carCashAmt.toFixed(2));
            fare.useCarCash = true;
            if (total < fare.carCashAmt) {
                fare.carCashAmt = total;
                total = 0;
            } else total -= fare.carCashAmt;
        }
        if (
            (prepayType === tripPaymentConstants.PAYMENT_METHOD_ALL_INCLUSIVE ||
                prepayType === tripPaymentConstants.PAYMENT_METHOD_BASE_FARE) &&
            creditCard.autoCharge &&
            autoCharge
        ) {
            if (carCashAutoCharge > 0) {
                // if the credit card is auto pay then use car cash if available
                fare.carCashAmt = carCashAutoCharge;
                fare.useCarCash = true;
                if (total < fare.carCashAmt) {
                    fare.carCashAmt = total;
                    total = 0;
                } else total -= fare.carCashAmt;
            }
        }

        const newFare = {
            couponAmt: 0,
            fuel: 0,
            waitTimeMinutes: 0,
            discount: 0,
            carCashDescription: "",
            processingFee: 0,
            commissionPct: 0,
            cancelFeeDescription: "",
            tax: 0,
            jobHourQty: 0,
            nysFund: 0,
            taxPct: 0,
            stops: 0,
            couponVerificationCode: "",
            processingFeePct: 0,
            voucherCharge: 0,
            other: 0,
            waitTimeFreeMinutes: 0,
            cancelFee: 0,
            nysFundPct: 0,
            commission: 0,
            waitTimeHourlyRate: 0,
            miscDescription: "",
            discountPct: 0,
            fuelPct: 0,
            hourlyRate: 0,
            otherDescription: "",
            couponCode: "",
            waitTime: 0,

            ...fare,
            gratuity,
            gratuityPct: gratuityPct,
            total,
            gratuityOverride: gratuityOverride,
            tolls,
            misc
        };
        creditCard["prepayType"] = prepayType;
        dispatch(tripActions.updateCustFare(newFare));

        return {
            fopCode: paymentTypes.CREDIT_CARD,
            gratuityPct,
            // saveNew: false,
            // cardCvvCode: "123",
            // billingCountryCode: "billingCountryCode",
            ...creditCard
        };
    }

    function createHouseAccountFop() {
        return {
            autoCharge: false,
            fopCode: paymentTypes.HOUSE_ACCOUNT,
            gratuityPct: 0,
            saveNew: false
        };
    }

    function createPayInCashFop() {
        return {
            autoCharge: false,
            fopCode: paymentTypes.PAY_CASH,
            gratuityPct: 0,
            saveNew: false
        };
    }
}

function cancelTrip(trip, optionId = null, userAction = null) {
    return dispatch => {
        dispatch(request());
        if (
            !optionId ||
            (optionId !== 2 && !Array.isArray(optionId)) ||
            (Array.isArray(optionId) && optionId[1] !== 2)
        ) {
            if (
                Array.isArray(userAction) &&
                userAction.length > 1 &&
                !Array.isArray(optionId)
            ) {
                //if there is need for the second pop up
                const buttonsArray = userAction[1].options.map(option => {
                    // created the selected options array add on request sent to the appropriate action
                    let options = [optionId];
                    return {
                        color: option.abort ? "deepskyblue" : "red",
                        messageId: option.optionText,
                        //after button click call function with the selected option
                        action: () => {
                            //new option select add to options array
                            options.push(option.optionID);
                            dispatch(cancelTrip(trip, options, userAction));
                        }
                    };
                });
                dispatch(uiActions.setPopUpTitle("Attention"));
                dispatch(uiActions.setPopUpMessage(userAction[1].actionMessage));
                dispatch(uiActions.setPopUpButtons(buttonsArray));
                dispatch(uiActions.showPopUp());
                return;
            }

            if (!optionId && !userAction || Array.isArray(optionId) && optionId[1] !== 2)
                dispatch(uiActions.displaySpinner("spinner.checking_Policy_Loader"));
            //check if the selected option is not abort trip cancellation
            else
                dispatch(uiActions.displaySpinner("spinner.cancel_Trip_Loader"));

            tripServices
                .cancelTrip(trip, optionId, userAction)
                .then(response => {
                    dispatch(uiActions.hideSpinner());
                    if (
                        response.userActionRequired &&
                        Array.isArray(response.userActions) &&
                        response.userActions[0].options &&
                        response.code !== carmelMassageCodes.ERROR
                    ) {
                        //if the response from carmel has conditions to the trip cancel
                        //creating array from the options response to display as button in the pop up
                        const buttonsArray = response.userActions[0].options.map(option => {
                            return {
                                color: option.abort ? "deepskyblue" : "red",
                                messageId: option.optionText,
                                //after button click call function with the selected option
                                action: () => {
                                    dispatch(
                                        cancelTrip(trip, option.optionID, response.userActions)
                                    )
                                }
                            };
                        });
                        dispatch(uiActions.setPopUpTitle("Attention"));
                        dispatch(
                            uiActions.setPopUpMessage(response.userActions[0].actionMessage)
                        );
                        dispatch(uiActions.setPopUpButtons(buttonsArray));
                        dispatch(uiActions.showPopUp());
                    } else if (response.result.code === carmelMassageCodes.OK) {
                        //trip cancel success
                        dispatch(userActions.getTripList());
                        dispatch(uiActions.clearModalsArr());
                        dispatch(success(response));
                        dispatch(uiActions.setPopUpTitle("Trip Cancellation"));
                        dispatch(
                            uiActions.setPopUpMessage(
                                "Your trip has been cancelled successfully"
                            )
                        );
                        dispatch(uiActions.showPopUp());
                    } else {
                        dispatch(failure(response));
                        console.log(response);
                        dispatch(uiActions.setPopUpTitle(response.result.title));
                        dispatch(uiActions.setPopUpMessage(response.result.message));
                        dispatch(uiActions.showPopUp());
                    }
                })
                .catch(error => {
                    dispatch(uiActions.hideSpinner());
                    dispatch(failure(error));
                });
        }
    };

    function request() {
        return {type: carmelConstantsActions.TRIP_CANCELLATION_REQUEST};
    }

    function success(response) {
        return {type: carmelConstantsActions.TRIP_CANCELLATION_SUCCESS, response};
    }

    function failure(error) {
        return {type: carmelConstantsActions.TRIP_CANCELLATION_FAILURE, error};
    }
}

function clearTrip(tripType) {
    return {
        type: tripConstants.CLEAR_TRIP,
        tripType
    };
}

function carCashAvailable(carCash) {
    const available = carCash > 0;
    return {
        type: userConstants.CAR_CASH_AVAILABLE,
        available,
        carCash
    };
}

function removeCarCashAvailable() {
    return {
        type: userConstants.CAR_CASH_AVAILABLE,
        available: false,
        carCash: 0
    };
}

function setCurrentLocationAsPickUp() {
    return dispatch => {
        const state = store.getState();
        const {trip, forms} = state;
        const currentLocation = trip[addressConstants.CURRENT_LOCATION_ADDRESS];
        if (
            (currentLocation.airport && !forms[formsConstants.AIRLINE_FORM]) ||
            currentLocation.airport && !forms[formsConstants.AIRLINE_FORM][formsConstants.AIRLINE_NAME]
        ) {
            dispatch(carmelActions.getAirlineList(currentLocation.airportCode));
            dispatch(updateCurrentLocationToDropOff());
        } else {
            dispatch(setPickUp());
            dispatch(uiActions.openDropOffField());
            if (appHelp.validateObject(trip[addressConstants.DROP_OFF_ADDRESS])) {
                //if drop off exist display route and show time field
                // dispatch(markerActions.showDropOffMarker());
                dispatch(tripActions.createAndDisplayRoute());
                dispatch(uiActions.showTimeField());
            }
            //check if location is  in price support
            // dispatch(addressActions.displayClosestAirportsOnPriceSupport(modalsConstants.modalsTypes.PRICE_SUPPORT_AIRPORTS));
        }
    };


    function updateCurrentLocationToDropOff() {
        return {
            type: tripConstants.REPLACE_CURRENT_WITH_PICK_UP
        };
    }
}

function setPickUp() {
    return {
        type: tripConstants.SET_CURRENT_LOCATION_AS_PICK_UP
    };
}

function updateCarPool(carPoolAllowed) {
    return {
        type: tripConstants.UPDATE_CAR_POOL,
        carPoolAllowed
    };
}
