import React, {useState, useEffect, Fragment, useRef} from 'react'
import {useDispatch} from "react-redux";
import {addressActions, formsActions, googleMapsActions, uiActions} from "../../../store/actions";
import {appHelp} from "../../../helpers";
import '../../../assets/css/sass/_global.scss'
import airplane from '../../../assets/images/favorite_airplane_icon@2x.png'
import favorite_location_icon from '../../../assets/images/favorite_location_icon@2x.png'
import {modalsTypes} from "../../../constants/ui";


export const AutoCompleteSelectField = ({items, displayOnly, id, formId, tripFieldError, form, placeholder, style, focusedField, setFocusOnField, classes, addressType, airportList, handleClick, addressDescription = ''}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [showList, setShowList] = useState(false);
    const [text, setText] = useState('');
    const dispatch = useDispatch();

    let input = document.getElementById(id);
    let inputRef = useRef(null);
    if (input) {
        input.onkeydown = function (e) {
            let key = e.keyCode || e.charCode;

            if (key === 8 || key === 46)
                dispatch(addressActions.clearAddress(addressType))
        };
    }


    const wrapperRef = useRef(null);
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowList(false);
        }
    };

    useState(() => {
        return () => {
            setSuggestions([]);
            setShowList(false);
        }
    }, []);

    useEffect(() => {
        if (form && !form[id]) {
            setText('');
        }
        if (addressDescription) {
            setText(addressDescription);
        } else {
            setText('')
        }
    }, [form, addressDescription]);

    useEffect(() => {
        if (setFocusOnField) {
            inputRef.current.focus();
        }
    }, [setFocusOnField]);


    const handleChange = (e) => {
        let value = e.target.value;
        setShowList(true);
        if (!value) {
            dispatch(addressActions.clearAddress(addressType))
        }
        setSuggestions(items);
        setText(value);
        if (form[id]) {
            dispatch(formsActions.updateFormFieldValue(
                formId,
                id,
                '',
                []
            ))
        }
        dispatch(googleMapsActions.getAutocomplete(value, addressType));
    };

    const selectSuggestion = (value) => {
        setShowList(false);
        setSuggestions([]);
        setText('');
        handleClick(value);
        dispatch(formsActions.updateFormFieldValue(
            formId,
            id,
            value.value,
            []
        ))
    };

    useEffect(() => {
        if (items && items.length > 0) {
            //when got new items render them to the list
            setSuggestions(items);
            renderSuggestions();
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [items]);

    const renderSuggestions = () => {
        if (!text)
            return null;
        else {
            let itemsAndAirportsCombined = [...suggestions];
            if (appHelp.validateArr(airportList) && text !== '') {
                //filter from all combined airport list to find specific airports
                airportList = airportList.filter((airport => {
                    if (airport && airport.airportName && airport.airportDetails && airport.airportCode)
                        return airport.airportName.toLowerCase().includes(text.toLowerCase()) || airport.airportDetails.toLowerCase().includes(text.toLowerCase()) || airport.airportCode.toLowerCase().includes(text.toLowerCase())
                }));
                if (airportList) {
                    airportList = airportList.slice(0, 3).map(airport => {
                        return {
                            label: airport.airportName,
                            value: airport.airportCode,
                            airport: true
                        }
                    });
                    //combine all results for display
                    itemsAndAirportsCombined = [...airportList, ...itemsAndAirportsCombined];
                }
            }
            return (
                <ul className={classes} style={{display: showList ? 'block' : 'none'}}>
                    {
                        itemsAndAirportsCombined.map(item => {
                            return (
                                <li onClick={() => selectSuggestion(item)} key={item.label}>
                                    <img
                                        src={item.airport ? airplane : favorite_location_icon}
                                        alt="dropoff" className={'invert dropdown-image'}
                                    />
                                    <span className={'text-max-length'}>
                                    {item.label}
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
    };
    const onFocus = event => {
        setShowList(true);
        if (event.target.autocomplete) {
            event.target.autocomplete = "";
        }

    };
    const hideList = () => {
        if (suggestions.length === 0)
            setShowList(false);
    };
    return (
        <Fragment>
            <div className={'autocompleteTextField'} ref={wrapperRef}>
                <input type='search' value={text} onChange={handleChange} placeholder={placeholder} id={id}
                       autoComplete="off" style={style} onFocus={onFocus} disabled={displayOnly}
                       className={tripFieldError ? 'border-danger' : ''}
                       ref={inputRef} onBlur={hideList}
                />
                {renderSuggestions()}
            </div>

        </Fragment>
    )
};