import React, {Fragment, useEffect, useState} from 'react'
import {connect} from "react-redux";
import {appHelp} from "../../../helpers";
import TripStatusGenerator from "../../GeneralComponents/TripStatusGenerator";
import {TripOptionsBtns} from "../../GeneralComponents/TripOptionBtns";
import timeIcon from '../../../assets/images/green_time.png'
import pickupBlue from '../../../assets/images/pickupBlue.png'
import dropOff from '../../../assets/images/drop_off_red_icon.png'
import about from '../../../assets/images/about.png'
import {userActions} from "../../../store/user/actions";
import {uiActions} from "../../../store/ui/actions";
import {modalsTypes} from "../../../constants/ui";
import {RateDriverButtons} from '../../Modals/RateDriver/RateDriverButtons'

const SingleTrip = ({trip, hideTripOptions, showTripDetails, dispatch, general}) => {
    const [driverRate, setDriverRate] = useState(0);


    if (!appHelp.validateObject(trip) || !trip.tripDate || !trip.tripTime || !trip.addrPu || !trip.addrDo)
        return null;
    const {tripDate, tripTime, tripDisplayStatus, addrPu, addrDo, tripOptions, displayTextList, driver, tripId} = trip;

    if (appHelp.validateArr(tripOptions)) {
        const recentTripIndex = tripOptions.findIndex(option => option === "RecentTrip");
        if (recentTripIndex >= 0)
            tripOptions.splice(recentTripIndex, 1)
    }

    useEffect(() => {
        if (general.driverRatingDisplay && general.driverRatingDisplay.tripId === tripId) {
            setDriverRate(general.driverRatingDisplay.driverRating)
        }
    }, [general.driverRatingDisplay]);
    return (
        <Fragment>
            <div className={`${hideTripOptions ? '' : 'box'} history`}
                 style={hideTripOptions ? {
                     boxShadow: 'none',
                     webkitBoxShadow: 'none',
                     margin: '0',

                 } : {
                     cursor: 'pointer',
                     paddingBottom: appHelp.validateArr(tripOptions) ? '' : '5px'
                 }}
                 onClick={(e) => {
                     if (showTripDetails)
                         showTripDetails(e, trip);
                 }}
            >
                <div className="f-row">

                    <div className='three-fourth-trip-details'>

                        {
                            hideTripOptions &&
                            <div className='trip-no'>
                                <div>
                                    <strong>Trip No. </strong>
                                </div>
                                <div>
                                    {trip.tripId}
                                </div>
                            </div>
                        }

                        <div className='text-image-row'>
                            <img src={timeIcon}/>
                            <span className='text-max-length text-left'>
                        {appHelp.convertDateAndTimeToWords(tripDate, tripTime)}
                        </span>
                        </div>
                        <div className='text-image-row'>
                            <img src={pickupBlue}/>
                            <span className='text-max-length text-left'>
                        {addrPu.addressNickName ? addrPu.addressNickName : addrPu.addressDescription}
                        </span>
                        </div>
                        <div className='text-image-row '>
                            <img src={dropOff}/>
                            <span className='text-max-length text-left'>
                        {addrDo.addressNickName ? addrDo.addressNickName : addrDo.addressDescription}
                    </span>
                        </div>

                    </div>
                    {
                        tripDisplayStatus &&
                        <div className="one-fourth-trip-details" style={{padding: '0'}}>
                            <p className='trip-details-badge'><TripStatusGenerator status={tripDisplayStatus}/></p>
                        </div>
                    }

                </div>
                {
                    !hideTripOptions &&
                    <Fragment>
                        <div className='f-row'>
                            <TripOptionsBtns tripOptions={tripOptions} trip={trip}/>
                        </div>
                        {
                            tripDisplayStatus === 'Completed' && driver &&
                            <div className='f-row rate-driver-my-trips-section' onClick={(e) => {
                                e.stopPropagation();
                                dispatch(userActions.chooseTrip(trip));
                                dispatch(uiActions.showModal(modalsTypes.RATE_DRIVER))
                            }
                            }>
                                <div className='driver-name short-text-max-length'>
                                    <strong>Driver:</strong> {`${driver.firstName} ${driver.lastName}`}
                                </div>
                                <div className='driver-rate-button'>
                                    <RateDriverButtons displayOnly={driverRate}/>
                                </div>
                            </div>
                        }
                    </Fragment>
                }
            </div>
        </Fragment>
    )
};

const mapStateToProps = ({ui}) => {
    const {general} = ui;
    return {
        general
    };
};

export default React.memo(connect(mapStateToProps)(SingleTrip));