import React, {useEffect} from 'react'
import {formsConstants, loginForm} from "../../constants";
import {connect} from "react-redux";
import {formsActions} from "../../store/actions";
import {Loader} from '../GeneralComponents'

const LoginInputs = ({dispatch, loginUserForm}) => {

    useEffect(() => {
        dispatch(formsActions.updateFieldsValue(
            formsConstants.LOGIN_FORM,
            {
                [formsConstants.LOGIN_USERNAME]: '',
                [formsConstants.LOGIN_PASSWORD]: '',
            }
        ))
    }, []);


    const handleChange = (id, value, validators = []) => {
        dispatch(formsActions.updateFormFieldValue(
            formsConstants.LOGIN_FORM,
            id,
            value,
            validators
        ));
    };

    if (loginUserForm)
        return (
            loginForm.map(input => {
                return (
                    <div className="f-row" key={input.formConst}>
                        <div className="full-width">
                            <label htmlFor="username">{input.label}</label>
                            <input type={input.type} id={input.formConst} value={loginUserForm[input.formConst]}
                                   onChange={(e) => handleChange(input.formConst, e.target.value, [])}/>
                        </div>
                    </div>
                )
            })
        );
    else {
        return (
            <Loader/>
        )
    }

};

function mapStateToProps({user, forms}) {
    const loginUserForm = forms[formsConstants.LOGIN_FORM];
    return {
        loginUserForm,
    }
}

export default (connect(mapStateToProps)(LoginInputs));