import {userConstants, facebookConstants, userActionsConstants} from '../../constants';

const initialState =  {
    loggedIn: false
};

// Facebook user is included in the Authentication state.
export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false
            };
        case facebookConstants.FACEBOOK_LOGIN_REQUEST:
            return {
                ...state
            };
        case facebookConstants.FACEBOOK_LOGIN_SUCCESS:
            return {
                ...state,
                facebookUser: {
                    auth: action.facebookUser.auth,
                    user: action.facebookUser.info,
                    connected: true,
                }
            };
        case facebookConstants.FACEBOOK_LOGIN_FAILURE:
            return {
                facebookUser: {
                    connected: false,
                }
            };
        case userConstants.LOGOUT:
            return {
                loggedIn: false,

            };

        case userActionsConstants.UPDATE_USER_PHONE:
            return {
                facebookUser: {
                    ...state.facebookUser,
                    user: {
                        ...state.facebookUser.user,
                        phone: {
                            countryCode: action.data.countryCode,
                            number: action.data.number
                        }
                    }
                }
            };
        case userActionsConstants.UPDATE_USER_EMAIL:
            return {
                facebookUser: {
                    ...state.facebookUser,
                    user: {
                        ...state.facebookUser.user,
                        emailAddr: action.data
                    }
                }
            };
        default:
            return state
    }
}