import {addressConstants} from "../constants";
import * as luxon from "luxon";


export const appHelp = {
    print,
    loadScript,
    getNextYearsList,
    arr_diff,
    getCardType,
    creditCardMask,
    getDistance,
    isAddressInvalid,
    validateArr,
    validateObject,
    notUndefined,
    validateInput,
    validateInputLettersAndNumbers,
    validateInputNumbers,
    convertDateAndTimeToWords,
    getMobileOperatingSystem,
    getCookie,
    deleteCookie
};

function removeEmoji(value) {
    let valid = true;
    const removeEmoji = /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g;
    if (removeEmoji.test(value) && value) {
        valid = false;
    }
    return valid
}

function validateInput(value) {
    let valid = true;
    const onlyEnglishLettersNumbersAndSymbols = /^[a-z A-Z 0-9 $-/:-?{-~!@"^_`[\]]*$/;
    if (!onlyEnglishLettersNumbersAndSymbols.test(value) && value) {
        valid = false
    }
    if (valid)
        valid = removeEmoji(value);

    return valid
};

function validateInputLettersAndNumbers(value) {
    let valid = true;
    const onlyEnglishLettersNumbersAndSymbols = /^[a-z A-Z 0-9 ]*$/;
    if (!onlyEnglishLettersNumbersAndSymbols.test(value) && value) {
        valid = false;
    }
    if (valid)
        valid = removeEmoji(value);
    return valid
};

function validateInputNumbers(value) {
    let valid = true;
    const onlyEnglishLettersNumbersAndSymbols = /^[0-9 ]*$/;
    if (!onlyEnglishLettersNumbersAndSymbols.test(value) && value) {
        valid = false;
    }
    if (valid)
        valid = removeEmoji(value);

    return valid
};

function print(arrayToPrint = []) {
    if (process.env.NODE_ENV === 'development') {
        console.log(...arrayToPrint);
    }
}

function loadScript(src, attributes = {}) {
    // Create a script tag
    let script = document.createElement("script");

    for (const key in attributes) {
        if (attributes.hasOwnProperty(key)) {
            script.setAttribute(key, attributes[key]);
        }
    }

    // Set the CDN for the simplex script
    script.src = src;

    // Append the script to the end of the HTML-document head
    document.getElementsByTagName("head")[0].appendChild(script);
}

function getNextYearsList(offsetYears) {
    const currentYear = new Date().getFullYear();
    const lastYearInList = currentYear + offsetYears;

    const yearsList = [];
    let newYearInList = currentYear;
    while (newYearInList <= lastYearInList) {
        const stringNewYearInList = newYearInList.toString();
        yearsList.push({
            label: stringNewYearInList,
            value: stringNewYearInList.slice(
                stringNewYearInList.length - 2,
                stringNewYearInList.length
            )
        });
        newYearInList++;
    }

    return yearsList
}

function arr_diff(a1, a2) {
    let a = [], diff = [];

    for (let i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (let k in a) {
        if (k != 'isValid' && k != 'errors')
            diff.push(k);
    }

    return diff;
}

function getCardType(number) {
    if (number) {
        // visa
        let re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "Visa Electron";
    }
    return "";
}

function creditCardMask(creditCard, mask = '*') {
    //get last 4 characters of credit card
    if (creditCard) {
        const creditCardLastCharacters = creditCard.substr(creditCard.length - 4);
        // create display credit card (**** **** **** 1234)
        return mask.repeat(3 * 4) + creditCardLastCharacters;
    } else return '';
}

function rad(x) {
    return x * Math.PI / 180;
}

function getDistance(lat1, lng1, lat2, lng2, distanceMethod = "meter") {
    const mileRatioToMeter = 0.000621371192; // 0.000621 miles = 1 meter
    const R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(lat2 - lat1);
    let dLong = rad(lng2 - lng1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    if (distanceMethod === "meter")
        return d; // returns the distance in meter
    else
        return d * mileRatioToMeter // return in miles
}

function isAddressInvalid(currentAddress, addressType) {
    const types = currentAddress.types;
    return (
        types.includes('country') || types.includes('locality') || types.includes('political') ||
        (addressType === addressConstants.PICK_UP_ADDRESS
            && (types.includes('neighborhood')
                || types.includes('postal_code'))) || !currentAddress.streetName
    )
}


function validateArr(arr) {
    return (arr && Array.isArray(arr) && arr.length > 0);
}

function validateObject(obj) {
    return (obj && Object.keys(obj).length > 0);
}

function notUndefined(obj) {
    return typeof obj !== 'undefined';
}

function convertDateAndTimeToWords(date, time) {
    try {
        const newDateTime = luxon.DateTime.fromObject({
            year: date.split('/')[2],
            month: date.split('/')[0],
            day: date.split('/')[1],
            hour: time.split(':')[0],
            minute: time.split(':')[1]
        }).setLocale('en');
        return `${newDateTime.weekdayShort}, ${newDateTime.day} ${newDateTime.monthShort} ${newDateTime.year}, ${newDateTime.toLocaleString(luxon.DateTime.TIME_24_SIMPLE)}`;
    } catch (e) {
        console.log(e);
        return null;
    }
}


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
//Returns a string
// export const serializeFunction = (func) => (func.toString());
// //serializeFunction(()=>console.log('Hello!!'))
// // Output ==> "()=>console.log('Hello!!')"

//Returns a function
// export const deserializeFunction = (funcString) => (new Function(\`return ${funcString}\`)());
//image rotation from base64
// if (carList.hasOwnProperty('carList')) {
//     dispatch(markerActions.clearMarkersImages())
//     carList.carList.map(car => {
//         const {latitude, longitude, bearing} = car.driverLocation;
//
//         let img = new Image();
//         img.src = carMarker;
//         img.onload = () => {
//             let canvas = document.createElement("canvas");
//             const maxDim = Math.max(img.height, img.height);
//             canvas.width = img.height;
//             canvas.height = img.height;
//             let ctx = canvas.getContext("2d");
//             ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
//             ctx.rotate(bearing);
//             ctx.drawImage(img, -maxDim / 2, -maxDim / 2);
//
//             const carImage = {
//                 lat: latitude,
//                 lng: longitude,
//                 url: canvas.toDataURL("image/png")
//             };
//             dispatch(markerActions.carMarkersImages(carImage));
//         }
//     });
// }
