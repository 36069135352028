import {carmelConstantsActions, carmelObjectNames} from "../../../constants";

export function lists(state = {}, action) {
    switch (action.type) {
        case carmelConstantsActions.GET_PRICE_LIST_SUCCESS:
            return {
                ...state,
                displayTextList: action.response.displayTextList,
                [carmelObjectNames.PRICE_LIST]: action.response[carmelObjectNames.PRICE_LIST]
            };
        case carmelConstantsActions.AIRLINE_LIST_REQUEST:
            return {
                ...state,
                airlineList: []
            };
        case carmelConstantsActions.AIRLINE_LIST_SUCCESS:
            return {
                ...state,
                airlineList: action.airlineList
            };
        case carmelConstantsActions.AIRLINE_LIST_FAILURE:
            return {
                ...state,
                airlineList: []
            };
        case carmelConstantsActions.CLEAR_AIRLINE_LIST:
            return {
                ...state,
                airlineList: []
            };
        // case uiConstants.UPDATE_DISPLAY_TEXT_FIELD:
        //     return {
        //         ...state,
        //         displayTextList: action.displayTextList,
        //     };
        case carmelConstantsActions.GET_PRICE_LIST_FAILURE:
            return {
                ...state,
                [carmelObjectNames.PRICE_LIST]: action.error
            };
        case carmelConstantsActions.CLEAR_PRICE_LIST:
            delete state[carmelObjectNames.PRICE_LIST];
            return {
                ...state
            };
        case carmelConstantsActions.PERK_LIST_REQUEST:
            return {
                ...state,
                perkList: []
            };
        case carmelConstantsActions.PERK_LIST_SUCCESS:
            return {
                ...state,
                perkList: action.perkList
            };
        case carmelConstantsActions.PERK_LIST_FAILURE:
            return {
                ...state,
                perkList: []
            };
        default:
            return state;
    }
}
