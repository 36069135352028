import React from 'react'
import {connect} from "react-redux";

const Loader = () => {
    return (
        <div className="preloader">
            <div id="followingBallsG">
                <div id="followingBallsG_1" className="followingBallsG"/>
                <div id="followingBallsG_2" className="followingBallsG"/>
                <div id="followingBallsG_3" className="followingBallsG"/>
                <div id="followingBallsG_4" className="followingBallsG"/>
            </div>
        </div>
    )
};

const mapStateToProps = ({}) => {
    return {};
};

const connectedPage = connect(mapStateToProps)(Loader);
export {connectedPage as Loader};
