import {generalConstants, uiConstants} from "../../../constants";


export function popup(state = {}, action) {
    switch (action.type) {
        case uiConstants.POP_UP_SHOW:
            return {
                ...state,
                open: true,
            };
        case uiConstants.CLEAR_POP_UP:
            delete state.popup;
            return {
                ...state,
                open: false,
            };
        case uiConstants.POP_UP_HIDE:
            return {
                state: {},
                open: false,
            };
        case uiConstants.LOCATION_POP_UP_SHOW:
            return {
                ...state,
                location: true,
            };
        case uiConstants.LOCATION_POP_UP_HIDE:
            return {
                ...state,
                location: false,
            };
        case uiConstants.POP_UP_SET_TITLE:
            return {
                ...state,
                title: action.title,
            };
        case uiConstants.POP_UP_SET_MASSAGE:
            return {
                ...state,
                message: action.message,
            };
        case uiConstants.POP_UP_SET_MODE:
            return {
                ...state,
                mode: action.mode,
            };
        case uiConstants.POP_UP_BUTTONS:
            return {
                ...state,
                buttons: action.input,
            };
        case uiConstants.POP_UP_INPUTS:
            return {
                ...state,
                inputs: action.input,
            };

        case generalConstants.OPEN_DEBUG_ARGS_POP_UP:
            return {
                ...state,
                debugArgs: true
            };
        case generalConstants.CLOSE_DEBUG_ARGS_POP_UP:
            return {
                ...state,
                debugArgs: false
            };
        case uiConstants.POP_UP_SET_ACTION:
            return {
                ...state,
                action: action.action
            };
        default:
            return state;
    }
}