export const uiConstants = {
    // Unplugged
    REMOVE_MODAL: 'REMOVE_MODAL',
    // General Actions:
    TOGGLE_SIDE_MENU: 'TOGGLE_SIDE_MENU',
    CAR_TOGGLE: 'CAR_TOGGLE',
    CLEAR_CAR_TOGGLE: 'CLEAR_CAR_TOGGLE',
    AIRPORT_SELECT: 'AIRPORT_SELECT',
    CLOST_AIRPORT_SELECT: 'CLOST_AIRPORT_SELECT',
    SHOW_MODAL: 'SHOW_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    ClEAR_MODALS: 'ClEAR_MODALS',
    CURRENT_PAGE: 'CURRENT_PAGE',
    CLEAR_MISSING_INFO: 'CLEAR_MISSING_INFO',

    // Car Location fields change
    UPDATE_TITLE_TEXT: 'UPDATE_TITLE_TEXT',
    UPDATE_ROUTE_MODE: 'UPDATE_ROUTE_MODE',
    UPDATE_PHONE_NUMBER_LINK: 'UPDATE_PHONE_NUMBER_LINK',
    UPDATE_PICK_UP_INSTRUCTIONS_MASSAGE: 'UPDATE_PICK_UP_INSTRUCTIONS_MASSAGE',
    UPDATE_DRIVER_INFO: 'UPDATE_DRIVER_INFO',
    UPDATE_MAP_CENTER: 'UPDATE_MAP_CENTER',
    UPDATE_MAP_ZOOM: 'UPDATE_MAP_ZOOM',
    UPDATE_MPA_BOUNDS: 'UPDATE_MPA_BOUNDS',
    UPDATE_SHOW_MY_CAR_INFO_AVAILABLE: 'UPDATE_SHOW_MY_CAR_INFO_AVAILABLE',

    // Route Modes
    ROUTE_MODE_CUST: "Cust",
    ROUTE_MODE_DRIVER: "Driver",
    ROUTE_MODE_PICKUP: "Pu",
    ROUTE_MODE_DROP_OFF: "Do",
    ROUTE_MODE_DRIVER_CUST: "DriverCust",
    ROUTE_MODE_DRIVER_PICKUP: "DriverPu",
    ROUTE_MODE_DRIVER_DROP_OFF: "DriverDo",
    ROUTE_MODE_PICKUP_DROP_OFF: "PuDo",
    ROUTE_MODE_UNDEFINED: "Undefined",


    // Pop-Up control
    POP_UP_SET_TITLE: 'POP_UP_SET_TITLE',
    POP_UP_SET_MASSAGE: 'POP_UP_SET_MASSAGE',
    POP_UP_SHOW: 'POP_UP_SHOW',
    POP_UP_HIDE: 'POP_UP_HIDE',
    POP_UP_SET_MODE: 'POP_UP_SET_MODE',
    POP_UP_BUTTONS: 'POP_UP_BUTTONS',
    POP_UP_INPUTS: 'POP_UP_INPUTS',
    POP_UP_SET_ACTION: 'POP_UP_SET_ACTION',
    CLEAR_POP_UP: "CLEAR_POP_UP",

    LOCATION_POP_UP_SHOW: 'LOCATION_POP_UP_SHOW',
    LOCATION_POP_UP_HIDE: 'LOCATION_POP_UP_HIDE',

    // Map Control
    SAVE_MAP_FUNCTIONS: 'SAVE_MAP_FUNCTIONS',
    CLEAR_MAP_FUNCTIONS: 'CLEAR_MAP_FUNCTIONS',

    UPDATE_DISPLAY_TEXT_FIELD: "Update_DISPLAY_TEXT_FIELD",
    CURRENT_CARD: 'CURRENT_CARD',

    MAP_DIRECTIONS: 'MAP_DIRECTIONS',
    CLEAR_MAP_DIRECTIONS: 'CLEAR_MAP_DIRECTIONS',

    UPDATE_SELECTED_FOP: 'UPDATE_SELECTED_FOP',

    CREATE_BASIC_POP_UP_FAIL: 'CREATE_BASIC_POP_UP_FAIL',

    HIDE_TRIP_DETAILS: "HIDE_TRIP_DETAILS",
    SHOW_TRIP_DETAILS: "SHOW_TRIP_DETAILS",

    CARMEL_PERKS_SHOW_ERROR: 'CARMEL_PERKS_SHOW_ERROR',
    CARMEL_PERKS_HIDE_ERROR: 'CARMEL_PERKS_HIDE_ERROR',

    DISPLAY_SPINNER: 'DISPLAY_SPINNER',
    HIDE_SPINNER: 'HIDE_SPINNER',

    DISABLE_BUTTONS: 'DISABLE_BUTTONS',
    UNABLE_BUTTONS: 'UNABLE_BUTTONS',

    OPEN_DROP_FIELD: 'OPEN_DROP_FIELD',
    CLOSE_DROP_FIELD: 'CLOSE_DROP_FIELD',
    OPEN_TIME_FIELD: 'OPEN_TIME_FIELD',
    CLOSE_TIME_FIELD: 'CLOSE_TIME_FIELD',
    RESET_CUST_ACTIVATE: 'RESET_CUST_ACTIVATE',
    SET_DRIVER_PICKED_RATING_TO_DISPLAY: 'SET_DRIVER_PICKED_RATING_TO_DISPLAY'

};
