import {addressConstants, uiConstants} from "../../constants";
import {googleMapsConstants} from "./googleMaps.constants";

const initialState = {
    autocomplete: {
        [addressConstants.PICK_UP_ADDRESS]: {},
        [addressConstants.DROP_OFF_ADDRESS]: {},
    },
    continueTrip: false
};

const unavailableAutocompleteMessage = {
    description: "Autocomplete service is unavailable."
};

export function googleMaps(state = initialState, action) {
    switch (action.type) {
        case googleMapsConstants.AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    [action.addressType]: {
                        ...state.autocomplete[action.addressType],
                        loading: true
                    }
                }
            };
        case googleMapsConstants.AUTOCOMPLETE_SUCCESS:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    [action.addressType]: {
                        loading: false,
                        predictions: action.predictions
                    }
                }
            };
        case googleMapsConstants.AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    [action.addressType]: {
                        loading: false,
                        predictions: [unavailableAutocompleteMessage]
                    }
                }
            };
        case googleMapsConstants.CLEAR_AUTOCOMPLETE:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    [action.addressType]: {}
                }
            };

        case uiConstants.MAP_DIRECTIONS:
            return {
                ...state,
                directions: action.directions
            };

        case uiConstants.CLEAR_MAP_DIRECTIONS:
            return {
                ...state,
                directions: []
            };
        case addressConstants.CONTINUE_ADDRESS_PROGRESS:
            return {
                ...state,
                continueTrip: action.continue
            };
        default:
            return state;
    }
}