import {
    addressConstants,
    carmelMassagesConstants,
    defaultConstants,
    interactiveConstants,
    modalsTypes,
    urls
} from '../../constants';
import {history} from "../../helpers";
import {store} from '../index'
import {interactiveServices} from "./services";
import {tripActions, uiActions, userActions} from "../actions";


export const interactiveActions = {
    custArrived,
    custArrivedUpdateTripId,
    custArrivedVerifyTerminal,
    updateSelectedTerminal,
    safetyPing
};


function custArrived(verifyTerminal = false, updateTerminal = false) {
    return (dispatch, getState) => {
        const state = store.getState();
        const {tripId} = state.user.lists.trips.trip;
        dispatch(request());
        if (!updateTerminal) {
            if (verifyTerminal) {
                updateTerminal = false;
            } else {//user has set terminal-->call custArrived - markArrived false, when  returned mark true
                // custArrived.setMarkCustArrived(true);
                updateTerminal = true;
            }
        }
        dispatch(uiActions.displaySpinner('spinner.updating_Info_Loader'));
        interactiveServices.custArrived(verifyTerminal, updateTerminal)
            .then(CustArrivedData => {
                dispatch(uiActions.hideSpinner());
                if (CustArrivedData.result.code === carmelMassagesConstants.OK) {
                    dispatch(success(CustArrivedData));

                    // If the user have to pick a terminal from the terminal list - redirect to the
                    // pick-terminal page.
                    if (CustArrivedData.terminalList && !updateTerminal) {
                        dispatch(setTerminalList(CustArrivedData.terminalList));
                        dispatch(uiActions.showModal(modalsTypes.TERMINAL_PICK));
                    } else if (updateTerminal) {
                        dispatch(userActions.loadBasicUserInformation());
                        dispatch(tripActions.getTripDetails(tripId));
                        //open show my car modal and close airlines modal
                        dispatch(uiActions.closeModal(modalsTypes.TERMINAL_PICK));
                        setTimeout(() => {
                            dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR));
                        },1000);

                        // dispatch(tripActions.getCarLocation(tripId, () => {
                        //     console.log('show my car denied')
                        // }, () => dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR))));
                    }
                    // If the user not have to do anything just refresh the data in the redux store.
                    else {
                        dispatch(userActions.loadBasicUserInformation());
                        dispatch(tripActions.getTripDetails(tripId));
                        dispatch(uiActions.closeModal(modalsTypes.TERMINAL_PICK));
                        setTimeout(() => {
                            dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR));
                        },1000);
                        //open show my car modal and close airlines modal
                        // dispatch(tripActions.getCarLocation(tripId, () => {
                        //     console.log('show my car denied')
                        // }, () => dispatch(uiActions.showModal(modalsTypes.SHOW_MY_CAR))));
                    }
                } else {
                    dispatch(failure(CustArrivedData));
                    dispatch(uiActions.createBasicPopup(CustArrivedData.result.title, CustArrivedData.result.message));
                    dispatch(uiActions.showPopUp())
                }
            })
            .catch(error => {
                dispatch(uiActions.hideSpinner());
                dispatch(failure(error))
            });
    };

    function request(input) {
        return {
            type: interactiveConstants.CUST_ARRIVED_REQUEST,
            input
        }
    }

    function success(data) {
        return {
            type: interactiveConstants.CUST_ARRIVED_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: interactiveConstants.CUST_ARRIVED_FAILURE,
            error
        }
    }

    function setTerminalList(terminalList) {
        return {
            type: interactiveConstants.CUST_ARRIVED_SET_TERMINAL_LIST,
            terminalList
        }
    }
}

function custArrivedUpdateTripId(tripId, markCustArrived) {
    return {
        type: interactiveConstants.CUST_ARRIVED_SET_TRIP_ID,
        tripId,
        markCustArrived
    }
}

function custArrivedVerifyTerminal() {
    return {
        type: interactiveConstants.CUST_ARRIVED_VERIFY_TERMINAL,
    }
}

function updateSelectedTerminal(terminalId) {
    return {
        type: interactiveConstants.CUST_ARRIVED_UPDATE_SELECTED_TERMINAL,
        terminalId
    }
}


function safetyPing() {
    return (dispatch) => {
        dispatch(request());

        interactiveServices.safetyPing()
            .then(CustArrivedData => {
                if (CustArrivedData.result.code === carmelMassagesConstants.OK) {
                    dispatch(success(CustArrivedData));

                    history.push(`${defaultConstants.defaultStartPath}${urls.SAFETY_PING}`)
                } else if (CustArrivedData.result.code === "ACCESS_DENIED") {
                    history.push(`${defaultConstants.defaultStartPath}thank-you`)
                } else {
                    dispatch(failure(CustArrivedData));
                    dispatch(uiActions.setPopUpTitle("Pay Attention"));
                    dispatch(uiActions.setPopUpMessage(CustArrivedData.result.message));
                    dispatch(uiActions.showPopUp());
                }
            })
            .catch(error => {
                dispatch(failure(error))
            });
    };

    function request(input) {
        return {
            type: interactiveConstants.SAFETY_PING_REQUEST,
            input
        }
    }

    function success(data) {
        return {
            type: interactiveConstants.SAFETY_PING_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: interactiveConstants.SAFETY_PING_FAILURE,
            error
        }
    }
}
