import React, {Fragment, useState, useEffect} from "react";


import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {uiActions} from "../../../store/actions";
import ModalWrapper from "../ModalWrapper";
import {modalsTypes} from "../../../constants/ui";
import {generalConstants} from "../../../constants";

const IFrameLoader = ({rewards, dispatch, modalType}) => {
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(null);
    const [title, setTitle] = useState('');


    useEffect(() => {
        switch (modalType) {
            case modalsTypes.TERMS_AND_CONDITIONS:
                if (rewards && rewards.rewardList && Array.isArray(rewards.rewardList) && rewards.rewardList.length > 0) {
                    setUrl(rewards.rewardList[0].termsUrl);
                }
                setTitle('Carmel Points Terms & Conditions');
                break;
            case modalsTypes.PRIVACY_MODAL:
                setUrl(generalConstants.PRIVACY_POLICY_URL);
                setTitle('Privacy');
                break;
            case modalsTypes.TERMS_MODAL:
                setUrl(generalConstants.TERMS_N_CONDITIONS_URL);
                setTitle('Terms & Conditions');
                break;
            default:
                return;
        }
    }, []);
    useEffect(() => {
        if (loading)
            dispatch(uiActions.displaySpinner('spinner.downloading_Content_Loader'));
        else
            dispatch(uiActions.hideSpinner());
        return () => {
            dispatch(uiActions.hideSpinner());
        }
    }, [loading]);
    return (
        <Fragment>
            <ModalWrapper modalType={modalType} title={title}>
                <div className="modal-full-height "
                     style={{padding: '0', background: 'lightgrey', margin: 0}}>
                    <iframe
                        style={{
                            border: 0,
                            width: "100%",
                            height: "100%",
                            maxHeight: '700px',
                            opacity: `${loading ? 0 : 1}`
                        }}
                        onLoad={() => setLoading(false)}
                        src={`https://docs.google.com/gview?embedded=true&url=${url}`}
                    />
                </div>
            </ModalWrapper>
        </Fragment>
    )
};


function mapStateToProps({user}) {
    const {rewards} = user.lists;
    return {
        rewards
    }
}

export default injectIntl(connect(mapStateToProps)(IFrameLoader));
