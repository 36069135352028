import {facebookConstants} from "../../constants";


export function facebookLogin(state = {}, action) {
    switch(action.type) {
        case facebookConstants.FACEBOOK_LOGIN_REQUEST:
            return {
                ...state
            };
        case facebookConstants.FACEBOOK_LOGIN_SUCCESS:
            return {
                ...state,
                facebookUser: {
                    auth: action.facebookUser.auth,
                    user: action.facebookUser.info,
                    connected: true,
                }
            };
        case facebookConstants.FACEBOOK_LOGIN_FAILURE:
            return {
                facebookUser: {
                    connected: false,
                }
            };
        default:
            return state;
    }
}