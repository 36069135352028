import React, {Fragment, useState, useEffect} from "react";
import {formsConstants, modalsTypes} from "../../../constants";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import ModalWrapper from "../ModalWrapper";
import IntlMessages from "../../../utils/IntlMessages";
import {formsActions} from "../../../store/forms/actions";
import {userActions} from "../../../store/user/actions";

const PasswordRecoveyModal = ({dispatch, modalType, emailAddr, recoverForm, intl, modalsUi}) => {
    const [closeModal, setCloseModal] = useState(false);
    const [usernameMissing, setUsernameMissing] = useState(false);
    useEffect(() => {
        dispatch(formsActions.updateFieldsValue(
            formsConstants.RECOVER_PASSWORD_FORM,
            {
                [formsConstants.RECOVERY_EMAIL]: '',
            }
        ))
    }, []);


    const handleChange = (id, value, validators = []) => {
        setUsernameMissing(false);
        dispatch(formsActions.updateFormFieldValue(
            formsConstants.RECOVER_PASSWORD_FORM,
            id,
            value,
            validators
        ));
    };

    const recoverPassword = (e) => {
        e.preventDefault();
        const email = recoverForm[formsConstants.RECOVERY_EMAIL] ? recoverForm[formsConstants.RECOVERY_EMAIL] : emailAddr;
        if (email) {
            setUsernameMissing(false);
            dispatch(userActions.passwordRecovery(email, setCloseModal));
        } else
            setUsernameMissing(true);
    };

    if (!recoverForm)
        return null;

    return (
        <ModalWrapper modalType={modalType} title={'Password Recovery'}
                      closeModalIndicator={closeModal} smallModal={true}>


            <form className="text-center custom-small-modal-body" id="forgot_form" name="forgot_form"
                  type="POST" autoComplete="off">
                <div className="wrap ">
                    {
                        !emailAddr &&
                        <Fragment>
                            <div className="row">
                                <div className="form-group f-row text-center">
                                    <input
                                        type="email"
                                        id={formsConstants.RECOVERY_EMAIL}
                                        value={recoverForm[formsConstants.RECOVERY_EMAIL]}
                                        onChange={(e) => handleChange(e.target.id, e.target.value)}
                                        name="forgot_email"
                                        className={`form-control text-center ${usernameMissing ? 'border-danger' : ''}`}
                                        placeholder="ENTER EMAIL ADDRESS"
                                    />
                                </div>
                                <div className='f-row text-center'>
                                    <p className="fs_xxsmall grey-text mb-0">
                                        <IntlMessages id="forgot-password.description"/>
                                    </p>
                                </div>
                            </div>
                        </Fragment>
                    }
                    <div className="list_buttons row">
                        {
                            emailAddr &&
                            <Fragment>
                                <h4 className="modal-title text-center" id="forgotModal">
                                    Forgot Password?
                                </h4>
                                <p className="text-center">{emailAddr}</p>
                            </Fragment>
                        }

                        <div className="f-row" onClick={recoverPassword}>
                            <div className="full-width">
                                <input type="button"
                                       value={`${intl.messages[emailAddr ? 'forgot-password.btn-recover-by-mail' : 'forgot-password.btn']}`}
                                       className="btn color medium full"/>
                            </div>
                        </div>

                        <div className="f-row" onClick={() => setCloseModal(true)}>
                            <div className="full-width">
                                <input type="button"
                                       value={`${intl.messages[emailAddr ? 'forgot-password.close' : 'forgot-password.cancel']}`}
                                       className="btn color medium full"/>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

        </ModalWrapper>
    )
};

function mapStateToProps({forms, user, ui}) {
    const recoverForm = forms[formsConstants.RECOVER_PASSWORD_FORM];
    const {modalsUi} = ui;

    const emailAddr = modalsUi.modals.find(modal => modal === modalsTypes.PASSWORD_RECOVERY_BY_MAIL) ? user.Cust.emailAddr : '';
    return {
        recoverForm,
        emailAddr,
    }
}

export default injectIntl(connect(mapStateToProps)(PasswordRecoveyModal));