import {
    addressConstants,
    carmelConstantsActions,
    generalConstants,
    tripConstants,
    userConstants
} from "../../../constants";

const INIT_STATE = {
    continueTrip: false
};

export function objects(state = INIT_STATE, action) {
    switch (action.type) {
        // case uiConstants.SAVE_MAP_FUNCTIONS:
        //     return {
        //         ...state,
        //         map: action.map
        //     };
        // case generalConstants.GO_BACK_TO_HOME:
        //     // Clear the current map functions when navigating back to Home Page
        //     delete state.map;
        //
        //     return {
        //         ...state,
        //     };
        // case uiConstants.CLEAR_MAP_FUNCTIONS:
        //     delete state.map;
        //
        //     return {
        //         ...state,
        //     };

        case tripConstants.FOB_TYPES:
            return {
                ...state,
                fobTypes: action.fobTypes
            };
        case tripConstants.UPDATE_TIP_OPTIONS:
            return {
                ...state,
                tipOptions: action.tipOptions
            };
        case userConstants.CAR_CASH_AVAILABLE:
            if (action.available)
                return {
                    ...state,
                    carCash: action.carCash
                };
            else
                delete state.carCash;
            return {
                ...state
            };
        case carmelConstantsActions.TRIP_UPDATE_SUCCESS:
            return {
                ...state,
                tripUpdate: action.response
            };
        case generalConstants.SAVE_PERK_DETAILS:
            return {
                ...state,
                perkDetails: action.perkDetails
            };
        case generalConstants.CLEAR_PERK_DETAILS:
            return {
                ...state,
                perkDetails: null
            };

        case addressConstants.DROP_OFF_ON_HOLD:
            return {
                ...state,
                dropOffAddressOnHold: action.address
            };

        case addressConstants.CLEAR_DROP_OFF_ON_HOLD:
            return {
                ...state,
                dropOffAddressOnHold: null
            };
        case generalConstants.REQUEST_ID_ON_HOLD:
            return {
                ...state,
                requestIdOnHold: action.id
            };
        case generalConstants.CLEAR_REQUEST_ID_ON_HOLD:
            return {
                ...state,
                requestIdOnHold: null
            };
        case  generalConstants.RESEND_FUNC:
            return {
                ...state,
                resendFunctionAfterCustActivate: action.resendFunc
            };

        case tripConstants.UPDATE_CAR_CASH_AMOUNT_FOR_SECOND_TRIP_PAYMENT:

            if (action.useCarCash) {
                state = {
                    ...state,
                    carCashAmt: action.carCashAmt,
                };
            } else {
                delete state.carCashAmt
            }
            return state;

        default:
            return state;
    }
}
